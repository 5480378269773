@if(isLovReturned) {
<table nxTable cellpadding="0" class="payment_summary_table">
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal" class="font-grey">Upsell Product Summary</span>
      </td>
      <td nxTableCell class="std-ta-right">
        <span nxCopytext="normal" class="font-grey">Total (RM)</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">{{ formatData(upsellType) }}</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.upSellAmt | formatDecimal }}</span>
      </td>
    </tr>
  </table>
}