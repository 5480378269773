import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule} from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { TextFieldModule } from "@angular/cdk/text-field";
import { NgxsModule } from "@ngxs/store";
import { TranslateModule } from "@ngx-translate/core";
import { AgentsInformationComponent } from "./components/agents-information/agents-information.component";
import { CusCurrencyFormatDirective } from "./directives/cus-currency-format/cus-currency-format.directive";
import { CustomerPartnerDetailsComponent } from "./components/customer-partner/customer-partner-details/customer-partner-details.component";
import { CustomerPartnerEditModalComponent } from "./components/modal/customer-partner-edit-modal/customer-partner-edit-modal.component";
import { CustomerPartnerEndorsementComponent } from "./components/customer-partner/customer-partner-endorsement/customer-partner-endorsement.component";
import { CustomerPartnerFoundComponent } from './components/customer-partner/customer-partner-found/customer-partner-found.component';
import { CustomerPartnerIncompleteComponent } from './components/customer-partner/customer-partner-incomplete/customer-partner-incomplete.component';
import { CustomerPartnerNotFoundComponent } from './components/customer-partner/customer-partner-not-found/customer-partner-not-found.component';
import { CustomerPartnerSearchComponent } from './components/customer-partner/customer-partner-search/customer-partner-search.component';
import { ExternalScrollbarModule } from "./modules/external-scrollbar/external-scrollbar.module";
import { FormatDecimalPipe } from "./pipes/format-decimal/format-decimal.pipe";
import { GeolocationService } from "./components/geolocation/store/geolocation.service";
import { GeolocationState } from "./components/geolocation/store/geolocation.state";
import { IssuanceInformationComponent } from "./components/issuance-information/issuance-information.component";
import { NdbxIconModule } from "@allianz/ngx-ndbx/icon";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { NxActionModule } from "@aposin/ng-aquila/action";
import { NxAutocompleteModule } from "@aposin/ng-aquila/autocomplete";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxBreadcrumbModule } from "@aposin/ng-aquila/breadcrumb";
import { NxButtonComponent } from '@aposin/ng-aquila/button';
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { NxCheckboxModule } from "@aposin/ng-aquila/checkbox";
import { NxContextMenuModule } from "@aposin/ng-aquila/context-menu";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { NxDataDisplayModule } from "@aposin/ng-aquila/data-display";
import { NxDatefieldModule, NxNativeDateModule } from "@aposin/ng-aquila/datefield";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxErrorModule } from '@aposin/ng-aquila/base';
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxHeaderModule } from "@aposin/ng-aquila/header";
import { NxHeadlineModule } from "@aposin/ng-aquila/headline";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxIndicatorModule } from "@aposin/ng-aquila/indicator";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxLinkModule } from "@aposin/ng-aquila/link";
import { NxListModule } from "@aposin/ng-aquila/list";
import { NxMenuModule } from "@aposin/ng-aquila/menu";
import { NxMessageModule } from "@aposin/ng-aquila/message";
import { NxModalModule } from "@aposin/ng-aquila/modal";
import { NxNaturalLanguageFormModule } from "@aposin/ng-aquila/natural-language-form";
import { NxNumberStepperModule } from "@aposin/ng-aquila/number-stepper";
import { NxPageSearchModule } from "@aposin/ng-aquila/page-search";
import { NxPaginationModule } from "@aposin/ng-aquila/pagination";
import { NxPopoverComponent, NxPopoverTriggerDirective} from '@aposin/ng-aquila/popover';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NxProgressbarModule } from "@aposin/ng-aquila/progressbar";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { NxRadioToggleModule } from '@aposin/ng-aquila/radio-toggle';
import { NxSpinnerModule } from '@aposin/ng-aquila/spinner';
import { NxSwipebarModule } from "@aposin/ng-aquila/swipebar";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxTabsModule } from "@aposin/ng-aquila/tabs";
import { NxTaglistModule } from "@aposin/ng-aquila/taglist";
import { OneAllianzMessageComponent } from "./components/modal/one-allianz-message/one-allianz-message.component";
import { OnlyNumberDirective } from '@shared/directives/only-number/only-number.directive';
import { PaymentDetailsComponent } from "./components/payment-details/payment-details.component";
import { RoadTaxPayableComponent } from "./components/road-tax-payable/road-tax-payable.component";
import { PaymentInformationComponent } from "./components/payment-information/payment-information.component";
import { PaymentModeComponent } from "./components/modal/payment-mode/payment-mode.component";
import { PremiumInformationComponent } from "./components/premium-information/premium-information.component";
import { SmeCertFormComponent } from './components/customer-partner/sme-cert-form/sme-cert-form.component'
import { SortAlphabetPipe } from "@shared/pipes/sort-alphabet/sort-alphabet.pipe";
import { SstFormDetailsComponent } from './components/customer-partner/sst-form-details/sst-form-details.component'
import { SyncScrollModule } from "./modules/sync-scroll/sync-scroll.module";
import { UpsellProductComponent } from "./components/upsell-product/upsell-product.component";
import { ToUpperCaseDirective } from "./directives/to-uppercase/to-upper-case.directive";
import { AlphaNumericDirective } from "./directives/alpha-numeric/alpha-numeric.directive";

@NgModule({
  declarations: [
    AgentsInformationComponent,
    CustomerPartnerDetailsComponent,
    CustomerPartnerEndorsementComponent,
    FormatDecimalPipe,
    IssuanceInformationComponent,
    PremiumInformationComponent,
    SortAlphabetPipe,
  ],
  imports: [
    CommonModule,
    CusCurrencyFormatDirective,
    ExternalScrollbarModule,
    FormsModule,
    GoogleMapsModule,
    NdbxIconModule,
    NxAccordionModule,
    NxActionModule,
    NxAutocompleteModule,
    NxBadgeModule,
    NxBreadcrumbModule,
    NxButtonModule,
    NxCardModule,
    NxCheckboxModule,
    NxContextMenuModule,
    NxCopytextModule,
    NxDataDisplayModule,
    NxDatefieldModule,
    NxDropdownModule,
    NxErrorModule,
    NxErrorModule,
    NxFormfieldModule,
    NxGridModule,
    NxHeaderModule,
    NxHeadlineModule,
    NxIconModule,
    NxIndicatorModule,
    NxInputModule,
    NxLinkModule,
    NxListModule,
    NxMenuModule,
    NxMessageModule,
    NxModalModule.forRoot(),
    NxNativeDateModule,
    NxNaturalLanguageFormModule,
    NxNaturalLanguageFormModule,
    NxNumberStepperModule,
    NxPageSearchModule,
    NxPaginationModule,
    NxPopoverModule,
    NxProgressStepperModule,
    NxProgressbarModule,
    NxRadioModule,
    NxRadioToggleModule,
    NxSpinnerModule,
    NxSwipebarModule,
    NxSwitcherModule,
    NxTableModule,
    NxTabsModule,
    NxTaglistModule,
    OnlyNumberDirective,
    ReactiveFormsModule,
    RouterModule,
    SyncScrollModule,
    TextFieldModule,
    ToUpperCaseDirective,
    TranslateModule,
    AlphaNumericDirective
  ],
  exports: [
    AgentsInformationComponent,
    CommonModule,
    CusCurrencyFormatDirective,
    CustomerPartnerDetailsComponent,
    CustomerPartnerEndorsementComponent,
    ExternalScrollbarModule,
    FormsModule,
    IssuanceInformationComponent,
    NdbxIconModule,
    NxAccordionModule,
    NxActionModule,
    NxAutocompleteModule,
    NxBadgeModule,
    NxBreadcrumbModule,
    NxButtonComponent,
    NxButtonModule,
    NxCardModule,
    NxCheckboxModule,
    NxContextMenuModule,
    NxCopytextModule,
    NxDataDisplayModule,
    NxDatefieldModule,
    NxDropdownModule,
    NxErrorModule,
    NxFormfieldModule,
    NxGridModule,
    NxHeaderModule,
    NxHeadlineModule,
    NxIconModule,
    NxIndicatorModule,
    NxInputModule,
    NxLinkModule,
    NxListModule,
    NxMenuModule,
    NxMessageModule,
    NxModalModule,
    NxNativeDateModule,
    NxNaturalLanguageFormModule,
    NxNaturalLanguageFormModule,
    NxNumberStepperModule,
    NxPageSearchModule,
    NxPageSearchModule,
    NxPaginationModule,
    NxPopoverComponent,
    NxPopoverModule,
    NxProgressStepperModule,
    NxProgressbarModule,
    NxRadioModule,
    NxRadioToggleModule,
    NxSpinnerModule,
    NxSwipebarModule,
    NxSwitcherModule,
    NxTableModule,
    NxTabsModule,
    NxTaglistModule,
    OnlyNumberDirective,
    PremiumInformationComponent,
    ReactiveFormsModule,
    RouterModule,
    SortAlphabetPipe,
    SyncScrollModule,
    TextFieldModule,
    ToUpperCaseDirective,
    TranslateModule,
    FormatDecimalPipe,
    AlphaNumericDirective
  ]
})
export class SharedModule {}
