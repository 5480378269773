<div class="title-header">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <h1>Agent Code Selection</h1>
        </div>
      </div>
    </div>
  </div>
  
  <div class="agents">
    <div class="wrapper">
      <div class="select-agent-code-form nx-margin-bottom-3xl">
        <form [formGroup]="agentForm">

          @if (isHqOrBranchAdmin()) {
            <nx-formfield label="Select Branch">
              <nx-dropdown formControlName="branchCode" [showFilter]="true" (selectionChange)="retrieveAgentList($event.value)">
                  <ng-container *ngIf="branchList$ | async as branchList">
                    <ng-container *ngFor="let branch of branchList">
                      <nx-dropdown-item [value]="branch.code">
                        {{ branch.descp }}
                      </nx-dropdown-item>
                    </ng-container>
                  </ng-container>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>Please select branch.</strong>
              </nx-error>
            </nx-formfield>
          }

          @if (isCallCenterUser()) {
            <nx-formfield label="Customer Type">
              <nx-dropdown formControlName="customerType" [showFilter]="true">
                <ng-container *ngFor="let ct of customerType">
                  <nx-dropdown-item [value]="ct?.code"> {{ct?.name}} </nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
              <nx-error nxFormfieldError> Please select a customer type. </nx-error>
            </nx-formfield>
          }

          @if (isAgentCodeEnabled()) {
            <nx-formfield label="Search by Agent Code or Name">
              <nx-dropdown formControlName="agent" [showFilter]="true">
                  <ng-container *ngIf="agentList">
                    <ng-container *ngFor="let agent of agentList">
                      <nx-dropdown-item [value]="agent.agentCode">
                        {{ agent.agentCode }} - {{ agent.name }}
                      </nx-dropdown-item>
                    </ng-container>
                  </ng-container>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>Please select an agent code or name.</strong>
              </nx-error>
            </nx-formfield>
          }

          <button type="button" queryParamsHandling="preserve" nxButton="primary" 
            [disabled]="!agentForm.valid" (click)="goNext()" class="select-agent-code-form-cta">
            Next
          </button>
          <!-- <a routerLink="../pdpa"
            ><button class="nx-margin-top-m" nxButton="primary" type="button">
              Next
            </button></a
          > -->
        </form>
      </div>
    </div>
  </div>
  