import { Component, Inject, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from "@ngxs/store";
import { GET_ISS_CONTENT_ID, GET_ISS_PRODUCT_PDF, SAVE_ISS_EMAIL_SENT_LOG, SAVE_ISS_MESSAGE_SENT_LOG, SEARCH_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { Subject, of, takeUntil } from "rxjs";
import { SelectionModel } from '@angular/cdk/collections';
import { MarketingMaterialsModel } from '@interfaces/motor';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { GET_APPROVAL_PRODUCT_PDF, SAVE_APPROVAL_EMAIL_SENT_LOG, SAVE_APPROVAL_MESSAGE_SENT_LOG } from '@store/sat-motor-approval/sat-motor-approval.action';

type MyDialogResult = 'proceed' | 'cancel';

@Component({
  selector: 'app-marketing-material',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './marketing-material.component.html',
  styleUrl: './marketing-material.component.scss'
})
export class MarketingMaterialComponent {
  searchForm: FormGroup;
  pageFilterForm: FormGroup;

  searchModelResult: any;
  isSelectable: boolean = false;
  selected: any;
  searchTerm1 = '';

  curPage: number = 1;
  perPage: number = 10;
  itemsPerPageSelections = [5, 10, 20, 50, 100];
  pageData: any;

  selection = new SelectionModel<MarketingMaterialsModel>(true, []);
  crossSell: any;
  customerPartnerInfo: any;
  eDocumentOriginal = [];
  eDocumentOriginalWithIndex = [];
  unSubscribe$ = new Subject();
  contactNo: any;
  userInfo: any;
  agentInfo: any;
  isApproval: boolean;

  constructor(
    private dialogRef: NxModalRef<any>,
    private fb: FormBuilder,
    private store: Store,
    @Inject(NX_MODAL_DATA) data: any,
  ) {
    this.crossSell = data.crossSell;
    this.customerPartnerInfo = data.customerPartnerInfo;
    this.agentInfo = data.agentInfo;
    this.userInfo = data.userInfo;
    this.isApproval = data.isApproval;
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchBy: [this.crossSell.productName],
    });

    this.pageFilterForm = this.fb.group({
      perPage: [this.perPage]
    });
    
    if(this.isApproval) {
      this.getApprovalProductPDF();
    }else {
      this.getIssProductPDF();
    }

    this.searchForm.controls['searchBy'].valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe(value => {
      if (value) {
        this.curPage = 1;
        this.perPage = 10;

        this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);;

        console.log('pageData: ', this.pageData);
      }
    });

    if (this.customerPartnerInfo.clientMobileCode1
      && this.customerPartnerInfo.clientMobileNo1) {
      this.contactNo = this.customerPartnerInfo.clientMobileCode1 + this.customerPartnerInfo.clientMobileNo1;
    }
  }

  goToPage(n: number) {
    this.curPage = n;
    this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);
  }

  nextPage() {
    this.curPage++;
    this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);
  }

  prevPage() {
    this.curPage--;
    this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);
  }

  clearSearchBar() {
    this.searchForm.get('searchBy').setValue('');
  }

  onSearchClicked() {
    
  }

  select(item: any) {
    this.dialogRef.close({ item });
  }

  toggleAll(event) {
    if (event) {
      for (const data of this.eDocument) {
        this.selection.select(data);
      }
    }
    else {
      for (const data of this.eDocument) {
        // this.selection.deselect(data);
        this.selection.clear()
      }
    }
  }

  sendEmail() {
    const agentCode = this.agentInfo.agentCode;
    const userEmail = this.customerPartnerInfo?.clientEmail;
    const userName = this.userInfo?.preferName;

    const emailPayload = {
      agentCode: agentCode,
      emailSentBy: this.userInfo.userId,
      clients: [
        {
          partnerId: this.customerPartnerInfo.partnerId,
          idType: this.customerPartnerInfo.idType1,
          idValue: this.customerPartnerInfo.idValue1,
          clientName: this.customerPartnerInfo.clientName,
          clientMobileCode: this.customerPartnerInfo.clientMobileCode1,
          clientMobileNo: this.customerPartnerInfo.clientMobileNo1,
          email: this.customerPartnerInfo.clientEmail,
          productCode: this.crossSell.productCode,
          productName: this.crossSell.productName
        }
      ]
    };

    console.log('emailPayload: ', emailPayload);

    let bodyContent = '';

    const contentArray = [];

    for (const content of this.selection.selected) {
      const url = this.encodeURL(content.docUrl, true);
      contentArray.push(url);
    }

    bodyContent = bodyContent.concat(contentArray.join('%0D%0A'));

    let ccContact = '';

    if (this.agentInfo.agentEmail) {
      ccContact = '&cc=' + this.agentInfo.agentEmail;
    }
    else {
      ccContact = '';
    }

    const link = document.createElement('a');
    link.href = `mailto:${userEmail}?subject=Allianz%20Marketing%20Materials${ccContact}&body=Dear Customer,%0D%0A%0D%0AKindly download the link below for your reference:%0D%0A${bodyContent}%0D%0A%0D%0ARegards,%0D%0A${userName}`;

    link.click();

    if(this.isApproval) {
      this.store.dispatch(new SAVE_APPROVAL_EMAIL_SENT_LOG(emailPayload)).subscribe();
    }else {
      this.store.dispatch(new SAVE_ISS_EMAIL_SENT_LOG(emailPayload)).subscribe();
    }
    
  }

  sendWhatsapp() {
    const agentCode = this.agentInfo?.agentCode;

    const messagePayload = {
      agentCode: agentCode,
      msgSentBy: this.userInfo.userId,
      clients: [
        {
          partnerId: this.customerPartnerInfo.partnerId,
          mobileNo: this.customerPartnerInfo.clientMobileCode1 + this.customerPartnerInfo.clientMobileNo1,
          productCode: this.crossSell.productCode
        }
      ]
    };

    console.log('messagePayload: ', messagePayload);

    let bodyContent = '';

    const contentArray = [];

    for (const content of this.selection.selected) {
      const url = this.encodeURL(content.docUrl);
      contentArray.push(url);
    }

    bodyContent = bodyContent.concat(contentArray.join('%0D%0A'));

    const link = document.createElement('a');

    link.href = `https://wa.me/+${this.customerPartnerInfo.clientMobileCode1}${this.customerPartnerInfo.clientMobileNo1}?text=${bodyContent}`;

    link.click();

    if(this.isApproval) {
      this.store.dispatch(new SAVE_APPROVAL_MESSAGE_SENT_LOG(messagePayload)).subscribe();
    }else {
      this.store.dispatch(new SAVE_ISS_MESSAGE_SENT_LOG(messagePayload)).subscribe();
    }
    
  }

  private encodeURL(url: string, email?: boolean) {
    let encodedURL = encodeURI(url);
    encodedURL = this.replaceAll(encodedURL, '+', '%20');
    encodedURL = this.replaceAll(encodedURL, '%', '%25');
    if (email) {
      encodedURL = this.replaceAll(encodedURL, '=', '%3D');
    }
    return encodedURL;
  }

  private replaceAll(text: string, search: string, replace: string) {
    return text.split(search).join(replace);
  }

  closeDialog(result: MyDialogResult): void {
    this.dialogRef?.close(result);
  }

  getIssProductPDF() {
    const contentPayload = {};
    // this.store.dispatch(new GET_ISS_CONTENT_ID(contentPayload)).subscribe((state) => {
      const productPDFPayload = {
        contentId: "578580",
        fetchFoldersAndContents: true,
        fetchAllFoldersFromFirstChildOnly: false,
        fetchAllContentsFromFirstChildOnly: false,
      };

      this.store.dispatch(new GET_ISS_PRODUCT_PDF(productPDFPayload)).subscribe((state) => {
        this.eDocumentOriginal = state.SatMotorState.data.step5.folderContents;

        this.eDocumentOriginal = this.eDocumentOriginal.reduce(function (unique, obj) {
          if (!unique.some(x => x.docTitle === obj.docTitle)) {
            unique.push(obj);
          }
          return unique;
        }, []);

        let response = [];
        let i = 0;

        for (const material of this.eDocumentOriginal) {
          let object = Object.assign({}, material);
          object.index = i;

          response.push(object);
          i++;
        }

        this.eDocumentOriginalWithIndex = response;
        this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);

      });
    // });
  }

  getApprovalProductPDF() {
    const contentPayload = {};
    // this.store.dispatch(new GET_ISS_CONTENT_ID(contentPayload)).subscribe((state) => {
      const productPDFPayload = {
        contentId: "578580",
        fetchFoldersAndContents: true,
        fetchAllFoldersFromFirstChildOnly: false,
        fetchAllContentsFromFirstChildOnly: false,
      };

      this.store.dispatch(new GET_APPROVAL_PRODUCT_PDF(productPDFPayload)).subscribe((state) => {
        this.eDocumentOriginal = state.SatMotorApprovalState.folderContents;

        this.eDocumentOriginal = this.eDocumentOriginal.reduce(function (unique, obj) {
          if (!unique.some(x => x.docTitle === obj.docTitle)) {
            unique.push(obj);
          }
          return unique;
        }, []);

        let response = [];
        let i = 0;

        for (const material of this.eDocumentOriginal) {
          let object = Object.assign({}, material);
          object.index = i;

          response.push(object);
          i++;
        }

        this.eDocumentOriginalWithIndex = response;
        this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);

      });
    // });
  }

  onChangePerPage() {
    this.curPage = 1;
    this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);
  }

  checkboxCondition() {
    let totalLength = 0;

    for (const data of this.pageData) {
      if (this.selection.selected) {
        if (this.selection.selected.find(selected => selected.index == data.index)) {
          totalLength++;
        }
      }
    }

    return totalLength == this.pageData.length;
  }

  checkIndeterminate() {
    let totalLength = 0;

    for (const data of this.pageData) {
      if (this.selection.selected) {
        if (this.selection.selected.find(selected => selected.index == data.index)) {
          totalLength++;
        }
      }
    }

    return (totalLength > 0 && totalLength < this.pageData.length);
  }

  sortTable(sort: SortEvent) {
    this.eDocumentOriginalWithIndex = this.eDocumentOriginalWithIndex.slice().sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });

    this.pageData = this.eDocument.slice((this.curPage * this.perPage) - this.perPage, this.curPage * this.perPage);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }

  get eDocument() {
    let filteredList = [];
    const originalList = this.eDocumentOriginalWithIndex;

    if (this.searchForm.get('searchBy').value) {
      if (originalList.length > 0) {
        filteredList = originalList.filter((eDocument) => eDocument.docTitle.search(new RegExp(this.searchForm.get('searchBy').value, "i")) >= 0);
      }
    }
    else {
      filteredList = originalList;
    }

    return filteredList;
  }

  get start() {
    return (this.curPage * this.perPage) - this.perPage;
  }

  get end() {
    return this.curPage * this.perPage;
  }

}
