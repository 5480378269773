<form [formGroup]="pdpaForm">

    <nx-checkbox formControlName='pdpaCheck'>
      <span style="text-align: justify">
        I hereby confirm that I have read and informed the customer
        concerned of the Data Privacy Notice placed in the General
        tab section under the heading <b>PDPA Notice</b>. I further confirm
        that the said customer has given explicit consent, in
        accordance with the provisions of the Personal Data
        Protection Act 2010 (“PDPA”), on behalf of the said customer
        and the customer’s family members, dependants,
        beneficiaries, trustees, personal representatives, nominees,
        assignees or other persons (collectively referred to as
        “other persons”), to Allianz General Insurance Company
        (Malaysia) Berhad (“Company”) and/or its Group to collect,
        use, disclose, transfer, share or otherwise process the said
        customer’s Personal Data and the Personal Data of the other
        persons including Sensitive Personal Data for the purposes
        mentioned in the Data Privacy Notice and that all the
        necessary consents for the Company and/or Group to deal with
        their Personal Data of the other persons have been obtained.
        <br /><br />
        I understand that my failure to inform or explain to the
        customer on the Data Privacy Notice could lead to serious
        consequences resulting in a potential breach of the PDPA and
        hereby undertake to fully indemnify the Company and/or the
        Group for any and all losses, damages, costs and expenses
        that it might suffer arising therefrom or as a consequence
        thereof.
      </span>
    </nx-checkbox>

    @if (!isRTP) {
      <app-consent-indicator 
        [consentDetails]="consentDetails" [isEndorsement]="isEndorsement">
      </app-consent-indicator>
    }

  </form> 
