<div nxModalContent>
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
        Do you want to print clause wording?
    </h3>
</div>

<div class="clause_button">
    <button nxModalClose class="btn" nxButton="primary" type="button" (click)="onClickYes()">
        YES
    </button>
    <button nxModalClose class="btn" nxButton="primary" type="button" (click)="onClickNo()">
        NO
    </button>
</div>