import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { UserResponse } from 'src/app/interfaces/user';
import { HttpHelperService } from 'src/app/shared/services/http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	constructor(private httpHelperService: HttpHelperService) { }

	getUser(totken): Observable<Partial<UserResponse>> {
		const params = new HttpParams().set('token', totken);
		return this.httpHelperService.get('/auth/authenticate', params);
	  }
	
	  getUserMock(totken): Observable<Partial<UserResponse>> {
		const params = new HttpParams().set('token', totken);
		return this.httpHelperService.get('/auth/authenticate/mock', params);
	  }
	
	backToAlpha(userId: string) {
		return this.httpHelperService.get(`/session/logout?switch&userId=${userId}`);
	}

	backToSAT(userId: string) {
		return this.httpHelperService.get(`/session/alpha-sat/SAT_SYS_URL?switch&userId=${userId}`);
	}

	redirectSAT(payload: any) {
		return this.httpHelperService.get(`/session/alpha-sat/${payload.code}?switch&userId=${payload.userId}`);
	}
	
	signOut(userId) {
		return this.httpHelperService.get(`/session/logout?userId=${userId}`);
	}

	redirectToAlphaCCentric(payload: any) {
		return this.httpHelperService.get(`/session/logout?switchOcc&userId=${payload.userId}&opportunityId=${payload.opportunityId}&agentCode=${payload.agentCode}`);
	}
  
}
