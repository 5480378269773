import { Component, HostListener, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { TranslateService } from "@ngx-translate/core";
import { UserResponse } from "@interfaces/user";
import { map } from "rxjs/operators";
import { UserState } from "@store/user/user.state";
import { Select, Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { GET_ACTION_URL, GET_SWITCH_SITE } from "@store/sat-motor/sat-motor.action";
import { BACK_TO_ALPHA, SIGN_OUT } from 'src/app/store/user/user.action';
import { NotificationSidebarComponent } from "../notification-sidebar/notification-sidebar.component";

@Component({
  selector: "app-motor-header",
  standalone: true,
  imports: [SharedModule, NotificationSidebarComponent],
  templateUrl: "./motor-header.component.html",
  styleUrl: "./motor-header.component.scss",
})
export class MotorHeaderComponent {
  @Select(UserState.menu) menuPolicyMgmt$;
  @Select(UserState.menuList) menuList$;
  @Output() isShowMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() user: UserResponse;

  menuPolicyMgmt: any[] = [];
  menuList: any[] = [];

  userInfo: any;

  showmenu = true;
  screenWidth: number;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private elementRef: ElementRef,
    private router: Router,
  ) {
    this.screenWidth = window.innerWidth;
    this.showmenu = this.screenWidth >= 993;
  }

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);

    this.menuPolicyMgmt$.subscribe((mn: any) => {
      if (mn) this.menuPolicyMgmt = JSON.parse(JSON.stringify(mn.subMenu));
    });

    this.menuList$.subscribe((mn: any) => {
      if (mn) this.menuList = JSON.parse(JSON.stringify(mn));
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    this.showmenu = this.screenWidth >= 993;
  }

  checkScreen() {
    var screenWidth = window.innerWidth;
    if (screenWidth < 993) {
      this.showmenu = false;
    }
  }

  toggleMenu() {
    this.showmenu = !this.showmenu;
  }

  onClick(item: any) {
    item.hidden = !item.hidden;
  }

  nav(url: any, item: any) {
    console.log(`${url} ${JSON.stringify(item)}`);
    item.expanded = !item.expanded;
    this.checkScreen();
    if (url) {
      if (item.moduleOwner === 'SAT') {
        if (url.toLowerCase().includes('http') || url.toLowerCase().includes('https')) {
          this.store.dispatch(new GET_ACTION_URL(url.replace('alpha-sat-svc', 'sat-motor-svc')));
        }
        else {
          let _url = (item.type === "M" ? this.getSATRedirect(item.menuId) : url + "_SYS_URL" );
          this.switchURL(_url);
        }
      }
      else if (item.moduleOwner === 'SAT_MT') {
        if (url.toLowerCase().includes('http') || url.toLowerCase().includes('https')) {
          window.open(url, "", "fullscreen=yes");
        }
        else {
          this.switchURL(url + "_SYS_URL");
        }
      }
    }
  }

  open(url: any, submenu: any, menu: any) {
    this.checkScreen();
    submenu.expanded = !submenu.expanded;
    menu.expanded = !menu.expanded;
    window.open(url, "", "fullscreen=yes");
  }

  openURL(url, name, spec) {
    window.open(url, name, spec);
  }

  switchURL(url: any) {
    this.store.dispatch(new GET_SWITCH_SITE(url)).subscribe((state) => {
      console.log(state.SatMotorState.data.switchSiteUrl);
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });
  }

  switchToAlpha() {
    this.store.dispatch(new BACK_TO_ALPHA);
  }

  signOut() {
    this.store.dispatch(new SIGN_OUT());
  }

  getSATRedirect(id: any) {
    return {
      'MNDASHBRD'  : "SAT_SYS_URL",
      'MNELIBRARY' : "SAT_ELIBRARY_URL",
      'MNSALESMNGT': "SAT_SALES_MANAGEMENT_URL",
      'MNCUSTMNGT' : "SAT_CUSTOMER_MANAGEMENT_URL"
    }[id];
  }

  isUnifiedMenu(menuId: any) {
    return ["SA_POLSUMMARY", "SA_POLREPORTS", "SA_POLENQUIRY", "SA_POLCI"].includes(menuId);
  }

  isMotorMenu(menuId: any) {
    return ["SAT_MT_MOTOR"].includes(menuId);
  }
  
  isNonMotorMenu(menuId: any) {
    return ["SA_NONMOTOR"].includes(menuId);
  }

  isNotificationClicked = false;
  unreadNotification = 0;
  toggleNotification(event: any) {
    event.stopPropagation();
    if (this.isNotificationClicked) {
      this.isNotificationClicked = false;
      this.elementRef.nativeElement.ownerDocument.body.style.position = null;
    } else {
      this.isNotificationClicked = true;
      if (window.innerWidth < 720) {
        this.elementRef.nativeElement.ownerDocument.body.style.position = "fixed";
      }
    }
  }

  toggledNotification($event: any) {
    this.isNotificationClicked = !this.isNotificationClicked;
  }

  get userName$() {
    return this.translate.get("HEADER.WELCOME").pipe(map((text: string) => `${text}, ${this.user.preferName}`));
  }

  get lastLogin$() {
    return this.translate.get("HEADER.LAST_LOGIN_TIME").pipe(map((text: string) => `${text}: ${this.user.lastLoginDate}`));
  }

  get isAdmin$() {
    if (this.userInfo && this.userInfo.menuAccess) {
      if (this.userInfo.menuAccess.includes('SAT_ADMIN_ALERT')) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
