<form [formGroup]="roadTaxPayableForm">
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit">
          Road Tax Payable
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12,12,6">
            <!-- todo: to cater (blur)="onChangeForm($event)" or similar once confirmed requirement -->
            <nx-formfield label="{{ 'ROAD_TAX_PAYABLE.FIELD.ROAD_TAX' | translate }}">
              <input nxInput formControlName="roadTax" appCusCurrencyFormat (change)="onChangeForm()"/> 
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'ROAD_TAX_PAYABLE.FIELD.MY_EG_FEES' | translate }}">
              <nx-dropdown formControlName="myegFees" (selectionChange)="onChangeForm()">
                <nx-dropdown-item value="">
                  {{ '--' | translate }}
                </nx-dropdown-item>
                <nx-dropdown-item *ngFor="let item of lov.myEGFees2List" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6" class="nx-margin-bottom-2m">
            <p nxCopytext="large" class="nx-margin-bottom-1xs">
              Courier Fees (RM)
            </p>
            <nx-checkbox formControlName="courierFees" class="nx-margin-bottom-s" (checkedChange)="onChangeForm()">
              {{ '6.00' | translate }}
            </nx-checkbox>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6" class="nx-margin-bottom-2m">
            <nx-data-display size="small">
              <nx-data-display-label class="font-grey"
                >Merchant Charges (RM)</nx-data-display-label
              >
              <p nxCopytext="large">{{roadTaxPayableForm.get('merchantCharges').value | number: '1.2-2' }}</p>
            </nx-data-display>
          </div>
          <div nxCol="12,12,6" class="nx-margin-bottom-2m">
            <nx-data-display size="small">
              <nx-data-display-label class="font-grey"
                >Road Tax Payable (RM)</nx-data-display-label
              >
              <p nxCopytext="large">{{ roadTaxPayableForm.get('gstRoadTaxAmt').value | number: '1.2-2' }}</p>
            </nx-data-display>
          </div>
        </div>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
</form>