import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { GET_USER, GET_USER_MOCK, POPULATE_POLICY_MANAGEMENT } from 'src/app/store/user/user.action';
import { GET_NOTIFICATION_LIST } from 'src/app/store/notification/notification.action';

@Injectable({
  providedIn: 'root'
})
export class LoginResolverService implements Resolve<any> {

  constructor(private store: Store, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {
    const userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    const token = route.queryParamMap.get('token');
    const mock = route.queryParamMap.get('mock');
    const renewNow = route.queryParamMap.get('renewNow');
    const agentCode = route.queryParamMap.get('agentCode');
    const policyNo = route.queryParamMap.get('policyNo');
    const claimNo = route.queryParamMap.get('claimNo');
    const action = route.queryParamMap.get('action');
    const contractId = route.queryParamMap.get('contractId');
    const documentNo = route.queryParamMap.get('documentNo');
    const productType = route.queryParamMap.get('productType');
    const cnoteType = route.queryParamMap.get('cnoteType');
    const draftStep = route.queryParamMap.get('draftStep');
    const idType = route.queryParamMap.get('idType');
    const idValue = route.queryParamMap.get('idValue');
    const clientType = route.queryParamMap.get('clientType');
    const enquiryProduct = route.queryParamMap.get('enquiryProduct');
    const searchCriteria = route.queryParamMap.get('searchCriteria');
    const searchValue = route.queryParamMap.get('searchValue');
    const opportunityId = route.queryParamMap.get('opportunityId');
    const vehNo = route.queryParamMap.get('vehNo');
    const origin = route.queryParamMap.get('origin');

    if (!token) {
      if (userInfo) {
        return of(userInfo);
      } else {
        this.router.navigate(['/404']);
      }
    } else {
      //this.store.dispatch(new CLEAR_CN_DATA_STATE());
      if (mock) {

       // if (renewNow === 'true') {
          //this.store.dispatch(new IS_RENEW_NOW({ isRenewNow: true}));
          //return this.store.dispatch(new GET_RENEWAL_NOW_DATA_MOCK({ token: token, agentCode: agentCode, policyNo: policyNo }))
        //} else {
          let payload = {
            userId: token,
            queryParams: {
              documentNo: documentNo,
              productType: productType,
              agentCode: agentCode,
              action: action,
              cnoteType: cnoteType,
              contractId: contractId,
              claimNo: claimNo,
              policyNo : policyNo,
              draftStep: draftStep,
              idType: idType,
              idValue: idValue,
              clientType: clientType,
              enquiryProduct: enquiryProduct,
              searchCriteria: searchCriteria,
              searchValue: searchValue,
              opportunityId: opportunityId,
              renewNow: renewNow,
              vehNo: vehNo,
              origin: origin
            }
          }
          let userRes = await this.store.dispatch(new GET_USER_MOCK(payload)).toPromise();
          if (userRes) {
            
            const userId = this.store.selectSnapshot<string>(state => state.UserState.user.userId);
            const data = {
              userId: userId,
              page: 0,
              size: 10
            }
            this.store.dispatch(new GET_NOTIFICATION_LIST(data, false));
            /*let polRes =*/ await this.store.dispatch(new POPULATE_POLICY_MANAGEMENT()).toPromise();
            //if (polRes) {
            // return;
            //}
          }
      } else {
          let payload = {
            userId: token,
            queryParams: {
              documentNo: documentNo,
              productType: productType,
              agentCode: agentCode,
              action: action,
              cnoteType: cnoteType,
              contractId: contractId,
              policyNo : policyNo,
              claimNo : claimNo,
              draftStep: draftStep,
              idType: idType,
              idValue: idValue,
              clientType: clientType,
              enquiryProduct: enquiryProduct,
              searchCriteria: searchCriteria,
              searchValue: searchValue,
              vehNo: vehNo,
              opportunityId: opportunityId,
              renewNow: renewNow,
              origin: origin
            }
          }
          let userRes = await this.store.dispatch(new GET_USER(payload)).toPromise();
          if (userRes) {
            
            const userId = this.store.selectSnapshot<string>(state => state.UserState.user.userId);
            const data = {
              userId: userId,
              page: 0,
              size: 10
            }
            this.store.dispatch(new GET_NOTIFICATION_LIST(data, false));
            /*let polRes = */await this.store.dispatch(new POPULATE_POLICY_MANAGEMENT()).toPromise();
            /*if (polRes) {
              return;
            }*/
          }
       // }
      }
    }
    return null;
  }
}
