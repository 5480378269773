import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_UPSELL_PRODUCT_LOV } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-view-upsell-product',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-upsell-product.component.html',
  styleUrl: './view-upsell-product.component.scss'
})
export class ViewUpsellProductComponent implements OnInit {
  @Input() data: any;
  @Input() agentInfo: any;
  @Input() productCode: any;

  upsellProductList: any;
  isLovReturned: boolean = false;

  constructor(
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(new GET_UPSELL_PRODUCT_LOV({
      listTypes: ["upsellProductList"],
      agentCode: this.agentInfo.agentCode,
      productType: "MT",
      productCode: this.productCode,
    })).subscribe(state => {
      this.isLovReturned = true;
      this.upsellProductList = state.SatMotorState.upsellProductLov.upsellProductList;
    });
  }

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }

  get upsellType() {
    return this.upsellProductList?.find(a => a.code === this.data?.upSellType)?.descp;
  }

}
