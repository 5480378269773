import { Component, OnInit, ElementRef, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { NotificationState } from 'src/app/store/notification/notification.state';
import { GET_NOTIFICATION_LIST, SEND_READ_NOTIFICATION, RESET_NOTIFICATION_LIST } from 'src/app/store/notification/notification.action';
// import { NOTIFICATION_MESSAGE_CATEGORY } from 'src/app/shared/enums/index.enum';
import { SharedModule } from '../../shared/shared.module';
import { ACTIONS } from '@shared/enums/index.enum';
import { GET_SWITCH_SITE } from '@store/sat-motor/sat-motor.action';
// import { GET_REDIRECT_URL } from 'src/app/store/customer-management/customer-management.action';

@Component({
  selector: 'notification-sidebar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],

})
export class NotificationSidebarComponent implements OnInit, OnDestroy {
  @Select(NotificationState.getNotificationList) notificationList$;
  @Select(NotificationState.countUnreadNotification) unreadNotification$;

  @Output() toggledNotification = new EventEmitter<boolean>();
  subscription!: Subscription;
  notifArray: any = [];
  testApiNotif: any = [];
  today = new Date();
  asOfDate: string;
  isLoading: boolean = true;
  notificationFlag: boolean;
  totalPages: number = 0;
  userId: string;

  messages: any = [];

  // ...
  throttle = 680;
  scrollDistance = 2;
  direction = '';
  isEmptyNotifiList: boolean = false;
  scrolledDown: boolean = false;

  constructor(
    public store: Store,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.userId = this.store.selectSnapshot<any>(state => state.UserState.user.userId);
    this.getNotificationList();

    this.notificationList$.subscribe((lst: any) => {console.log(lst)
      this.messages = lst?.messages;
    });
  }

  getNotificationList() {
    this.notificationFlag = true;
    this.store.dispatch(new GET_NOTIFICATION_LIST({ userId: this.userId }, true)).subscribe(state => {
      let notificationList = state.NotificationState.notificationList;
      if ((notificationList && notificationList.messages) && notificationList.messages.length > 0) {
        this.asOfDate = state.NotificationState.notificationList.asOfDate;
        this.totalPages = state.NotificationState.notificationList.totalPages;
        this.isLoading = (this.totalPages <= 1) ? false : true;
        this.notificationFlag = false;
      } else {
        this.isLoading = false;
        this.notificationFlag = true
        this.isEmptyNotifiList = true;
      }
    });
  }

  notifClicked(notification: any) {
    const payload = {
      id: notification.id,
      seenInd: true,
      userId: this.userId,
    };
    // this.store.dispatch(new SEND_READ_NOTIFICATION(payload)).subscribe((state) => {
    //   const redirectPayload = {
    //     operator: this.userId,
    //     action: ACTIONS.NOTIFICATION,
    //     notificationId: notification.id
    //   }
      this.store.dispatch(new GET_SWITCH_SITE("NOTIFICATION_SYS_URL")).subscribe((state) => {
        console.log(state.SatMotorState.data.switchSiteUrl);
        window.location.href = state.SatMotorState.data.switchSiteUrl;
      });
    // });
  }

  getClass(seen: any) {
    if (seen) {
      return "notification-read"
    }
    else {
      return "notification"
    }
  }

  getIcon(notification: any) {
    if (notification.linkType == 'DOC') {
      if (notification.seenInd) {
        return 'download-active';
      } else {
        return 'download-inactive';
      }
    } else {
      if ((notification.messageCategory === "CLAIMS_MSG") || (notification.messageCategory === "RENEWAL_MSG") || (notification.messageCategory === "POLICY_MSG")) {
        return "icon-status"
      }
      else {
        return "icon-notif"
      }
    }

  }

  toCamelCase(string: string): string {
    let formattedString = '';
    const stringArr = string.split(' ');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < stringArr.length; i++) {
      if (i > 0) {
        formattedString = formattedString.concat(' ');
      }
      formattedString = formattedString.concat(stringArr[i].charAt(0).toUpperCase() + stringArr[i].substr(1).toLowerCase());
    }
    return formattedString;
  }

  @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (!this.elementRef.nativeElement.contains(event.target)) {
  //     this.toggledNotification.emit(false);
  //     this.elementRef.nativeElement.ownerDocument.body.style.position = null;
  //   }
  // }

  // onScrollDown(ev) {
  //   this.scrolledDown = true;
  //   if (!this.notificationFlag) {
  //     this.data = {
  //       page: this.data.page + 1,
  //       size: this.data.size,
  //     }
  //     if (this.data.page < this.totalPages) {
  //       this.data['dateFrom'] = this.asOfDate;
  //       this.getNotificationList(this.data);
  //     } else {
  //       this.isLoading = false;
  //     }
  //   }
  // }

  ngOnDestroy() {
    this.store.dispatch(new RESET_NOTIFICATION_LIST());
  }

}
