<div class="header-notification__float-block is-open--">
  <div class="header-notification__float-header">
    <div class="float-header__date">Notification ({{ unreadNotification$ | async }})</div>
    <div class="float-header__mark-all-read disabled--">
      <a> </a>
    </div>
  </div>
  <div class="header-notification__content-wrapper">
    <div class="header-notification__content">
      <div class="hn-content__welcome">
        <div class="hn-content__welcome-visual">
          <ng-container *ngIf="isEmptyNotifiList">
            <div class="no-record">
              <img class="bell-size" src="./assets/img/Illustration - No notification.svg" />
              <h2 class="no-notifi">You currently have no notifications. Any new notification will appear here.</h2>
            </div>
          </ng-container>
          <div class="header-notification__content-wrapper">
            <ng-container *ngIf="messages?.length > 0">
              <div class="notification-card" *ngFor="let notification of messages" [ngClass]="getClass(notification.seenInd)">
                <div class="notification-container" (click)="notifClicked(notification)">
                  <div>
                    <nx-icon [ngClass]="getIcon(notification)" class="icon-notif" size="m"></nx-icon>
                  </div>
                  <div class="notification-title-body">
                    <p class="notification-title">{{ toCamelCase(notification.title) }}</p>
                    <p class="notification-title-date">{{ notification.updateDate }}</p>
                  </div>
                </div>
                <div class="notification-body">{{ notification.message }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
