import {
  Component,
  ElementRef, Input, Renderer2, ChangeDetectorRef,
  EventEmitter,
  Output
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  STORE_ENDORSEMENT, UPDATE_ENDORSEMENT_STEP2
} from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService } from '@aposin/ng-aquila/modal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";

@Component({
  selector: 'app-no-claim-discount',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './no-claim-discount.component.html',
  styleUrl: './no-claim-discount.component.scss'
})
export class NoClaimDiscountComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.endtStep1Issuance) endtIssuanceData$;
  @Select(SatMotorState.endtVehiclePolicy) endtVehicleData$;

  @Input() endorsementType: any;
  @Output() endtCalculatePremium = new EventEmitter<unknown>();

  isEndorsement: boolean = true;
  endtPolicy: any;
  // policyNo: any;
  ncdForm: FormGroup;
  ncd: any;
  issuanceInfo: any;
  vehicleNo: any;
  endorsementEffectiveDate: any;
  lov: any;
  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;
  isNCDWithdraw: boolean = false;
  ncdPercentageOriginal: any;

  NCDAllow = 'ENDT23'; //NCD Allowed
  NCDAmend = 'ENDT24'; //NCD Amendment
  NCDWithdraw = 'ENDT25'; //NCD Withdraw

  endorsement_details = true;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit() {

    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.endtVehicleData$.subscribe((state) => {
      this.endtPolicy = state || {};
      // this.policyNo = state.policyNo;

    });

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;

        console.log("NoClaimDiscountComponent isEndorsement", this.isEndorsement);
      }
    });

    this.endtIssuanceData$.subscribe((state) => {
      if (state) {
        this.endorsementType = state.issuance.endorsementType;
        this.vehicleNo = state.issuance.vehicleNo;
        this.endorsementEffectiveDate = state.issuance.endorsementEffectiveDate;

        console.log("this.endorsementType", this.endorsementType);
        console.log("this.vehicleNo", this.vehicleNo);
        console.log("this.endorsementEffectiveDate", this.endorsementEffectiveDate);
      }
    });

    if (this.endorsementType === "ENDT25") {
      this.isNCDWithdraw = true;
    }

    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.issuance.issuance);

    this.initializeNCDForm();
    // this.loadEndorsementDetailsData();

    this.ncdForm.patchValue({
      ncdEffectiveDate: this.endorsementEffectiveDate,
      ncdVehicleNo: this.vehicleNo, //to confirm if correct
    });

    /////////////////////////////////////////////////////////////////
    this.ncd = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.ncd);
    console.log("ncd:", this.ncd);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    this.endtVehiclePolicyData = endtVehiclePolicyData
    this.endtPreviousVehiclePolicyData = endtPreviousVehiclePolicyData

    // console.log("endtVehiclePolicyData:", this.endtVehiclePolicyData);
    // console.log("endtPreviousVehiclePolicyData:", this.endtPreviousVehiclePolicyData);

    if (this.ncd) {

      this.ncdForm.patchValue(this.ncd);
      let coverList = endtVehiclePolicyData?.riskList[0]?.coverList;
      let data = coverList[0];
      this.ncdPercentageOriginal = data.ncdPercentage;

    } else if (endtVehiclePolicyData) {

      let coverList = endtVehiclePolicyData?.riskList[0]?.coverList;
      console.log("coverList:", coverList);

      if (coverList) {
        let data = coverList[0];

        this.ncdPercentageOriginal = data.ncdPercentage;

        this.ncdForm.patchValue({
          ncdPercentage: data.ncdPercentage,
        });


      } else {
        console.error("coverList cannot populate ncdForm");
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }

    this.ncdPercentage.valueChanges.subscribe((change) => {
      this.storeEndorsementDetailsData();
    });

  }

  // ngAfterViewInit() {}

  initializeNCDForm() {
    this.ncdForm = this.fb.group({
      ncdVehicleNo: ["", [Validators.required, VehicleNoValidator()]],
      prevNcdInsurer: ['', Validators.required],
      ncdPercentage: ['', Validators.required],
      ncdEffectiveDate: [{ value: '', disabled: true }]
    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state && state.ncd) {
        this.ncdForm.patchValue(state.ncd);
      }
    });
  }

  validateChangedFields() {
    let ncdPercentageOriginal = this.ncdPercentageOriginal;
    let ncdPercentage = this.normalizeValue(this.ncdForm.get('ncdPercentage')?.value);
    let hasChanged = false;

    if (ncdPercentageOriginal === undefined) {
      console.error(`Original value for ncdPercentage is undefined`);
    }

    ncdPercentageOriginal = this.convertToComparableType(ncdPercentageOriginal, ncdPercentage);
    ncdPercentage = this.convertToComparableType(ncdPercentage, ncdPercentageOriginal);

    if (ncdPercentageOriginal !== ncdPercentage) {
      hasChanged = true;
    } else {
      hasChanged = false;
    }
    console.log("Final hasChanged result:", hasChanged);
    return hasChanged;
  }

  normalizeValue(value: any) {
    if (typeof value === 'string') {
      return value.trim().toLowerCase();
    }
    return value;
  }

  convertToComparableType(value: any, referenceValue: any) {
    if (typeof referenceValue === 'number' && !isNaN(Number(value))) {
      return Number(value);
    }
    if (typeof referenceValue === 'string') {
      return String(value);
    }
    return value;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  storeEndorsementDetailsData() {
    if (this.ncdForm.valid) {
      let ncd = this.ncdForm.getRawValue();
      console.log('Saving NCD Data:', ncd);

      let ncdPercentage = parseFloat(ncd.ncdPercentage);
      let payload = {
        ncd: {
          ...ncd,
          ncdPercentage
        },
        isNCD: true
      }
      this.store.dispatch(new STORE_ENDORSEMENT(payload));

      let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
      if (!endtUpdatedData) {
        endtUpdatedData = this.endtVehiclePolicyData
      }

      endtUpdatedData = {
        ...endtUpdatedData,
        riskList: [{
          ...endtUpdatedData.riskList[0],
          coverList: [{
            ...endtUpdatedData.riskList[0].coverList[0],
            ncdPercentage: ncdPercentage,
          }]
        }]
      };

      console.log("storeEndorsementDetailsData endtUpdatedData:", endtUpdatedData);

      this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
        isReset: false,
        cngeNote: endtUpdatedData
      }));
      this.endtCalculatePremium.emit();

    } else {
      console.error('NCD form is invalid!');
      // this.logFormErrors(this.ncdForm);
      this.ncdForm.markAllAsTouched();
    }
  }

  // Accessors for form controls
  get ncdVehicleNo() {
    return this.ncdForm.get('ncdVehicleNo');
  }

  get prevNcdInsurer() {
    return this.ncdForm.get('prevNcdInsurer');
  }

  get ncdPercentage() {
    return this.ncdForm.get('ncdPercentage');
  }

  get ncdEffectiveDate() {
    return this.ncdForm.get('ncdEffectiveDate');
  }

}
