<div nxModalContent>
    <h3 size="s" class="nx-margin-bottom-xs nx-margin-top-0">Upload Document</h3>

    <p nxCopytext="normal" class="nx-margin-bottom-m">You may upload multiple files by clicking on the "+ ADD MORE".</p>

    <nx-swipebar label="Swipe to see more">
        <table nxTable zebra cellpadding="0" class="upload_table">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Document Needed</span>
                    </th>
                    <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Mandatory/<br />Optional</span>
                    </th>
                    <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Filename</span></th>
                    <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Action</span></th>
                </tr>
            </thead>
            <tbody>
                @for (selectedOptinalDoc of listOfSelectedOptionalDocuments; track $index) {
                    @for (document of selectedOptinalDoc.documents; let docIndex = $index; track docIndex) {
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="medium" [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'">
                                    <div nxList="xsmall" class="upload_content">{{ selectedOptinalDoc.descp }}</div>
                                </span>
                            </td>
                            <td nxTableCell>
                                <span nxCopytext="medium" [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'">
                                    Optional
                                </span>
                            </td>
                            <td nxTableCell>
                                <span nxCopytext="medium">
                                    @if (document?.file?.name) {
                                        {{ document?.file?.name }}
                                    } @else if (document.name) {
                                        {{ document.name }}.{{ document.type }}
                                    } @else {}

                                    <!-- {{ document?.file?.name ?? document.name + "." + document.type }} -->
                                </span>
                            </td>
                            <td nxTableCell>
                                <div class="horizontal-checkboxes">
                                    <div class="nx-margin-right-xs upload_btn_width">
                                        <input
                                            type="file"
                                            style="display: none"
                                            #fileUploadInput
                                            (change)="onChangeFileInput($event, document.docId)"
                                            [accept]="acceptFileType"
                                        />
                                        <button
                                            class="btnwidth"
                                            type="button"
                                            nxButton="secondary small"
                                            (click)="fileUploadInput.click()"
                                        >
                                            CHOOSE FILE
                                        </button>
                                    </div>
                                    <button
                                        [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'"
                                        class="nx-margin-right-xs upload_btn_width"
                                        nxButton="secondary small"
                                        type="button"
                                        (click)="handleListSelectedOptionalDocs(selectedOptinalDoc)"
                                    >
                                        <nx-icon name="plus" nxIconPositionsStart aria-hidden="true"></nx-icon>
                                        ADD MORE
                                    </button>
                                    <button
                                        class="nx-margin-right-xs"
                                        nxPlainButton
                                        type="button"
                                        aria-label="add item"
                                        (click)="openDeleteConfirmationModal(selectedOptinalDoc.code, document.docId)"
                                    >
                                        <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                                    </button>
                                    <button
                                        nxPlainButton
                                        type="button"
                                        (click)="downloadDocument(document.docId, document.name)"
                                        [disabled]="!document.docId || document.docId.startsWith('DUMMY')"
                                    >
                                        VIEW
                                    </button>
                                </div>
                            </td>
                        </tr>
                    }
                }

                <tr nxTableRow>
                    <td nxTableCell>
                        <nx-formfield label="Upload Optional Document" class="upload_dropdown">
                            <nx-dropdown ariaLabelledBy="status" [showFilter]="true">
                                @for (optionalDoc of listOfOptionalDocs; track $index) {
                                    <nx-dropdown-item
                                        (click)="handleDropdownSelection(optionalDoc)"
                                        [value]="optionalDoc.descp"
                                    ></nx-dropdown-item>
                                }
                            </nx-dropdown>
                        </nx-formfield>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">Optional</span></td>
                    <td nxTableCell></td>
                    <td nxTableCell></td>
                </tr>
            </tbody>
        </table>
    </nx-swipebar>

    @if (showMessageIfUploadSuccess) {
        <nx-message context="info" class="green_color_notification nx-margin-top-xs"
            ><span class="nx-font-weight-bold"> {{ uploadCallCounter }} file(s) have been successfully uploaded.</span>
        </nx-message>
    }
</div>

<div class="nx-margin-top-2m">
    <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">Close</button>
    <button nxButton="primary" type="button" (click)="uploadDocuments()">Upload</button>
</div>

<ng-template #deleteConfirmationDialogRef>
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Deleted file cannot be restored. Are you sure want to proceed?
        </p>
    </div>
    <div nxModalActions>
        <button
            (click)="closeDeleteConfirmationModal('cancel')"
            class="button_popup_2btn"
            nxButton="secondary"
            type="button"
        >
            NO
        </button>
        <button
            (click)="closeDeleteConfirmationModal('proceed')"
            class="nx-margin-bottom-0"
            nxButton="primary"
            type="button"
        >
            YES
        </button>
    </div>
</ng-template>
