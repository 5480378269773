import { Component, Input, OnInit } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CALCULATE_PREMIUM_PAYLOAD,
  CALCULATE_PREMIUM_SUMMARY,
  DOWNLOAD_PDF,
  GENERATE_QUOTATION,
  GET_DOCUMENT_LOV,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SEND_TO_JPJ,
  SET_CURRENT_STEP,
  SET_JPJ_STATUS_SENT,
  STORE_ISSUANCE_SUMMARY
} from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { UserResponse } from "@interfaces/user";
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { ScrollToError } from '@shared/classes/scroll-to-error';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    OkMessageComponent,
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  @Input() payment: any;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  isEndorsement: boolean = true;
  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;

  receiptNo: any;
  transactionDate: any;
  eCoverNoteNo: any;
  vehicleNo: any;
  totalPremiumDue: any;
  accountCode: any;
  insuredName: any;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
      }
    });

    // this.endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    // this.endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    // this.paymentDataRetrieval();

  }

  paymentDataRetrieval() {

    this.receiptNo = this.endtVehiclePolicyData?.receiptNo; //undefined
    this.transactionDate = this.endtVehiclePolicyData?.receiptDate; //undefined
    this.eCoverNoteNo = this.endtVehiclePolicyData?.cnoteNo;
    this.vehicleNo = this.endtVehiclePolicyData?.riskList[0]?.vehicleNo;
    this.totalPremiumDue = this.endtVehiclePolicyData?.premiumDue;
    this.accountCode = this.endtVehiclePolicyData?.mkagAgent?.agentCode;
    this.insuredName = this.endtVehiclePolicyData?.clientPartner?.insuredName; //null

    console.log("receiptNo:", this.receiptNo);
    console.log("transactionDate:", this.transactionDate);
    // console.log("eCoverNoteNo:", this.eCoverNoteNo);
    // console.log("vehicleNo:", this.vehicleNo);
    // console.log("totalPremiumDue:", this.totalPremiumDue);
    // console.log("accountCode:", this.accountCode);
    console.log("insuredName:", this.insuredName);
  }

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }

}
