<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>{{ productName?.descp ?? "MOTOR" | titlecase }}</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12,12,12,3" class="grid_width_page">
      <!-- <app-progress-bar [progressType]="getAction" /> -->
      <steps [steps]="selectedStepperList" [currentStep]="getCurrentStep" [index]="getCurrentStep"></steps>
    </div>
    <div nxCol="12,12,12,9" class="grid_width_page">
      <div nxLayout="grid" class="grid_remove_padding">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

@if (isShowCrossSell && crossSellList.length !== 0) {
  <div nxLayout="grid nopadding">
    <div nxRow>
      <div nxCol="12">
        <!--cross sell banner-->
        <app-cross-sell-banner [crossSellList]="crossSellList" [userInfo]="userInfo" [marketingConsent]="marketingConsent" [clientPartnerInfo]="customerPartnerInfo" [agentInfo]="agentInfo"> </app-cross-sell-banner>
      </div>
    </div>
  </div>
}
