import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EnquiryComponent } from '../enquiry.component';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';
import { ViewRemarksComponent } from '@shared/components/view-remarks/view-remarks.component';
import { ClaimDetailsTableComponent } from '@shared/components/claim-details-table/claim-details-table.component';
import { PremiumAdjustableComponent } from '@shared/components/premium-adjustable/premium-adjustable.component';
import { ViewVehicleDetailsComponent } from '@shared/components/view-vehicle-details/view-vehicle-details.component';
import { ViewCoverageDetailsComponent } from '@shared/components/view-coverage-details/view-coverage-details.component';
import { ViewExtraCoverComponent } from '@shared/components/view-extra-cover/view-extra-cover.component';
import { RemarksComponent } from '@shared/components/modal/remarks/remarks.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { UserResponse } from '@interfaces/user';
import { APPROVE_COVER_NOTE, CALCULATE_PREMIUM, DOWNLOAD_PDF, GET_APPROVAL_DETAILS, GET_CROSS_SELL_LIST, GET_VEHICLE_USE, REJECT_COVER_NOTE, SAVE_ADMIN_REMARKS, STORE_APPROVAL_DETAILS_CNGE } from '@store/sat-motor-approval/sat-motor-approval.action';
import { Observable, delay, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SatMotorApprovalState } from '@store/sat-motor-approval/sat-motor-approval.state';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { ClauseMessageComponent } from '@shared/components/modal/clause-message/clause-message.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PRODUCTS, STATUS_MAP } from '@shared/enums/index.enum';
import { GET_CHECKLIST_LOV, GET_SWITCH_SITE } from '@store/sat-motor/sat-motor.action';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { EndtHistoryTableComponent } from '@shared/components/endt-history-table/endt-history-table.component';
import { ChecklistComponent } from '@shared/components/checklist/checklist.component';
import { EndorsementNarrationComponent } from '@shared/components/endorsement-narration/endorsement-narration.component';

@Component({
  selector: 'app-view-approval',
  standalone: true,
  imports: [
    SharedModule,
    EnquiryComponent,
    ViewRemarksComponent,
    ClaimDetailsTableComponent,
    PremiumAdjustableComponent,
    ViewVehicleDetailsComponent,
    ViewCoverageDetailsComponent,
    ViewExtraCoverComponent,
    RemarksComponent,
    OkMessageComponent,
    CrossSellBannerComponent,
    EndtHistoryTableComponent,
    ChecklistComponent,
    EndorsementNarrationComponent
  ],
  templateUrl: './view-approval.component.html',
  styleUrl: './view-approval.component.scss'
})
export class ViewApprovalComponent implements OnInit {
  approvalForm: FormGroup;
  type: any;
  popupMessage: any;
  referralReasonList: any;
  approvalDetails: any;
  approvalDetailCngeNote: any;
  approvalDetailHtgePol: any;
  approvalDetailClaimsHistory: any = [];
  approvalDetailCoverNoteHistory: any;
  vehicleDetails: any;
  historiesData: any;
  agentData: any;
  progressType: any;
  issuanceInfo: any;
  cpData: any;
  premiumInfo: any;
  remarksInfo: any;
  lov: any;
  remarksModal?: NxModalRef<RemarksComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  clauseMsgModal?: NxModalRef<ClauseMessageComponent>;
  userInfo: any;
  userRole: string;
  addRemarksInd: boolean = false;
  cnoteStatus: any;
  isGetApprovalDetails: boolean = false;
  isGetVehUseLov: boolean = false;
  currentExtraCoverList: any;
  prevExtraCoverList: any;
  vehicleUselov: any;
  referralReamrks: any;
  adminRemarks:any;
  approvalRemarks: any;
  productName: any;
  decimalFmt: any;
  queryParam: any;
  crossSellList: any = [];
  marketingConsent: any;
  isEndorsement: boolean;
  approvalDetailsEndtHistoryList: any;
  checklistLov: any;
  endtCode: any;

  endtTypeShowClausesMsgList = [
    "ENDT17",
    "ENDT18"
  ];
  endtTypeShowEndtCertBtn = [
    "COE",
    "XP",
    "ENDT04",
    "ENDT12",
    "ENDT34",
    "ENDT23",
    "ENDT24",
    "ENDT25"
  ];
  endtTypeShowEInvoiceBtn = [
    "EC175",
    "EC176"
  ];

  jpjUpdateIndEnableEndtTypeList = [
    "ENDT04",
    "ENDT05",
    "ENDT10",
    "ENDT12"
  ];

  ismUpdateIndEnableEndtTypeList = [
    "ENDT04",
    "ENDT05",
    "ENDT10",
    "ENDT12",
    "ENDT23",
    "ENDT24",
    "ENDT25"
  ]

  constructor(
    private store: Store,
    private readonly dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ){
    this.decimalFmt = new FormatDecimalPipe();
  }

  ngOnInit() {
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.userRole = this.store.selectSnapshot<string>(state => state.UserState.role);
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);

    this.type = 'view-motor-approval-enquiry';
    this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';

    this.approvalForm = this.fb.group({});

    this.queryParam = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);

    if (this.queryParam.contractId) {
      const contractId = this.queryParam.contractId;
      let getApprovalDetailsPayload = {
        cngeNote: {
          contractId: contractId
        },
        operator: this.userInfo.userId
      }
      this.store.dispatch(new GET_APPROVAL_DETAILS(getApprovalDetailsPayload)).subscribe(state => {
        this.approvalDetails = state.SatMotorApprovalState.approvalDetails;
        this.approvalDetailCngeNote = state.SatMotorApprovalState.approvalDetails.cngeNote;
        this.isEndorsement = state.SatMotorApprovalState.approvalDetails?.isEndorsementCase;
        this.checkPregressType();
        this.productName = PRODUCTS[this.approvalDetailCngeNote.productCode];
        this.store.dispatch(new GET_VEHICLE_USE({ productCode: this.approvalDetailCngeNote.productCode })).subscribe((state) => {
          this.vehicleUselov = state.SatMotorApprovalState.lov.vehicleUse;
          this.isGetVehUseLov = true;
        });

        if(this.isEndorsement) {
          this.approvalForm = this.fb.group({
            jpjUpdatedInd: [{value: this.approvalDetailCngeNote.jpjUpdatedInd, disabled: this.disableJpjUpdateInd()}],
            ismUpdatedInd: [{value: this.approvalDetailCngeNote.ismUpdatedInd, disabled: this.disableIsmUpdatedInd()}],
            checklistForm: new FormGroup({}),
          });
        }

        this.currentExtraCoverList = this.approvalDetailCngeNote.riskList[0].coverList[0].subCoverList;

        if(state.SatMotorApprovalState.approvalDetails.histories) {
          this.historiesData = state.SatMotorApprovalState.approvalDetails.histories;
        }

        if(state.SatMotorApprovalState.approvalDetails.htgePol) {
          this.approvalDetailHtgePol = state.SatMotorApprovalState.approvalDetails.htgePol;
          this.prevExtraCoverList = this.approvalDetailHtgePol.riskList[0].coverList[0].subCoverList;
        }

        //claim history
        if(state.SatMotorApprovalState.approvalDetails.claimsHistory) {
          this.approvalDetailClaimsHistory = state.SatMotorApprovalState.approvalDetails.claimsHistory;
        }

        //cover note history
        if(state.SatMotorApprovalState.approvalDetails.histories) {
          this.approvalDetailCoverNoteHistory = state.SatMotorApprovalState.approvalDetails.histories;
        }

        if(state.SatMotorApprovalState.approvalDetails)

          console.log("approval details: ", this.approvalDetailCngeNote);

          this.cnoteStatus = this.approvalDetailCngeNote.cnoteStatus;
  
          //Agent Information
          if(this.approvalDetailCngeNote && this.approvalDetailCngeNote.mkagAgent){
            this.agentData = {
              ...this.approvalDetailCngeNote.mkagAgent,
              issuedBy : this.approvalDetailCngeNote.createBy,
              issuedDate : this.approvalDetailCngeNote.cnoteDate,
              agentAps: this.approvalDetailCngeNote.agentAps
            };
          }

          //Vehicle Details
          this.vehicleDetails = {
            ...this.approvalDetailCngeNote,
            vehicleAge: state.SatMotorApprovalState.approvalDetails.ageOfVehicle
          };
  
          //Product Information
          if (this.approvalDetailCngeNote) {
  
            this.issuanceInfo = this.issuanceInfo || {};
            this.issuanceInfo.issuance = {
              productName: this.productName,
              productCode: this.approvalDetailCngeNote.productCode,
              vehicleNo: this.approvalDetailCngeNote.vehicleNo,
              coverNoteType: this.approvalDetailCngeNote.cnoteType,
              cnoteType: this.approvalDetailCngeNote.cnoteType,
              prevPolicyNo: this.approvalDetailCngeNote.previousPolicyNo,
              effectiveDate: this.approvalDetailCngeNote.effectiveDate,
              expiryDate: this.approvalDetailCngeNote.expiryDate,
              equotationNo: this.approvalDetailCngeNote.proposalNo,
              equotationCreatedDate: this.approvalDetailCngeNote.proposalDate,
              eCoverNoteNo: this.approvalDetailCngeNote.cnoteNo,
              jpjStatus: this.approvalDetailCngeNote.jpjStatus,
              jpjMessage: this.approvalDetailCngeNote.jpjMessage,
              prevPolicyDate: this.approvalDetailCngeNote.riskList[0]?.prevNcdExpDate,
              policyNo: this.approvalDetailCngeNote.policyNo,
              endtEffectiveDate: this.approvalDetailCngeNote.endtEffectiveDate,
              endtExpiryDate: this.approvalDetailCngeNote.endtExpiryDate,
              endtMethod: this.approvalDetailCngeNote.endtCalculationMethod,
              endorsementType: this.isEndorsement ? this.approvalDetailCngeNote.cnoteType : ''
              };
          }
  
          //customer Partner
          if(this.approvalDetailCngeNote && this.approvalDetailCngeNote.clientPartner){
            this.cpData = this.approvalDetailCngeNote.clientPartner;
          }
  
          //Premium Information
          if(this.approvalDetailCngeNote){
            this.premiumInfo = this.convertCngeToPremiumInfo(this.approvalDetailCngeNote);
          }

          //refer list
          if(this.approvalDetailCngeNote.referList) {
            this.referralReasonList = this.approvalDetailCngeNote.referList.map(refer => {
              return {
                code: refer.referCode,
                desc: refer.referDescp
              };
            });
          }
  
          //Remarks
          this.remarksInfo = {
            referral: this.approvalDetailCngeNote.referRemarks,
            admin: this.approvalDetailCngeNote.referRemarkList,
            approval: this.approvalDetailCngeNote.referAdminRemarks,
            updatedBy: this.getFormattedUpdateBy()
          }

          if(this.isEndorsement) {
            this.initEndorsementData(this.approvalDetails);
          }

          this.loadCrossSell();

          this.isGetApprovalDetails = true;
      });

    }

  }

  openAddRemarksPopUp() {
    this.remarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Enter Remarks",
        primaryButtonLabel: "SUBMIT"
      }
    });

    this.remarksModal.afterClosed().subscribe(result => {
      if(result) {
        const data = {
          contractId : this.approvalDetailCngeNote.contractId,
          referRemarks: result.remarks,
          operator: this.userInfo.userId,
        };
        this.addAdminRemarks(data);
      }
    });
  }

  addAdminRemarks(data) {
      this.store.dispatch(new SAVE_ADMIN_REMARKS(data)).subscribe((state) => {
        this.approvalDetailCngeNote = state.SatMotorApprovalState.approvalDetails.cngeNote;
        console.log("state back from SAVE_ADMIN_REMARKS -->", state);

        this.cdr.detectChanges();

        this.addRemarksInd = true;
        this.remarksInfo = {
          ...this.remarksInfo,
          admin: this.approvalDetailCngeNote.referRemarkList,
          updatedBy: this.getFormattedUpdateBy()
        }    

        of(window).pipe(delay(300)).subscribe(() => {
          window.scroll(0, 0);
        });
      });
  }


  openApprovePopUp() {
    this.remarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Approved Remarks",
        primaryButtonLabel: this.isEndorsement ? "APPROVE ENDORSEMENT" : "APPROVE E-COVER NOTE"
      }
    });

    this.remarksModal.afterClosed().subscribe(result => {
      if(result) {
        const approvePayload = {
          operator: this.userInfo.userId,
          cngeNoteDto: {
            ...this.approvalDetailCngeNote
          },
          adminRemark: result.remarks,
        }

        this.approveCoverNote(approvePayload);
      }
    });
  }

  approveCoverNote(data) {
    this.store.dispatch(new APPROVE_COVER_NOTE(data)).subscribe(state => {
      this.approvalDetailCngeNote = state.SatMotorApprovalState.approvalDetails.cngeNote;
      this.addRemarksInd = false;
      this.updateCngeInfo(this.approvalDetailCngeNote);
      of(window).pipe(delay(300)).subscribe(() => {
        window.scroll(0, 0);
      });
    });
  }

  openRejectPopUp() {
    this.remarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Declined Remarks",
        primaryButtonLabel: this.isEndorsement ? "REJECT ENDORSEMENT" : "REJECT E-COVER NOTE"
      }
    });

    this.remarksModal.afterClosed().subscribe(result => {
      if(result) {
        const rejectPayload = {
          operator: this.userInfo.userId,
          cngeNoteDto: {
            ...this.approvalDetailCngeNote
          },
          adminRemark: result.remarks,
        }

        this.rejectCoverNote(rejectPayload);
      }
    });
  }

  rejectCoverNote(data) {
    this.store.dispatch(new REJECT_COVER_NOTE(data)).subscribe(state => {
      this.approvalDetailCngeNote = state.SatMotorApprovalState.approvalDetails.cngeNote;
      this.remarksInfo = {
        ...this.remarksInfo,
        approval: this.approvalDetailCngeNote.referAdminRemarks,
        updatedBy: this.getFormattedUpdateBy()
      };
      this.cnoteStatus = this.approvalDetailCngeNote.cnoteStatus;
      this.addRemarksInd = false;
      this.referralReasonList = [];
      of(window).pipe(delay(300)).subscribe(() => {
        window.scroll(0, 0);
      });
      this.redirectToSummary();
    });
  }

  redirectToSummary() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });  
  }

  convertCngeToPremiumInfo(cngeData) {
    return {
      ...cngeData.riskList[0].coverList[0],
      premiumDue: cngeData.premiumDue,
      premiumDueRounded: cngeData.premiumDueRounded,
      stamp: cngeData.stamp
    };
  }

  getFormattedDate(dateVal) {
    if (!dateVal) {
      return dateVal;
    }

    return moment(dateVal, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  getFormattedDateTime(dateString: string): string {
    if(!dateString){
      return dateString;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  }

  onClickEndtSchedule() {
    if(!this.approvalDetailCngeNote.endtNo) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        data: {
          message: "Endorsement not generated yet, please try again."
        }
      });
      return;
    }

    const payload = {
      operator: this.userInfo.userId,
      contractId: this.approvalDetailCngeNote.contractId,
      type: "endorsement",
    }

    if(this.endtTypeShowClausesMsgList.includes(this.approvalDetailCngeNote.endtType)) {
      this.clauseMsgModal = this.dialogService.open(ClauseMessageComponent);
      this.clauseMsgModal.afterClosed().subscribe(result => {
        if(result && result === "Y") {
          this.store.dispatch(new DOWNLOAD_PDF(payload));
        }
      });
    }else {
        this.store.dispatch(new DOWNLOAD_PDF(payload));
    }

  }

  onClickEndtCert() {
    if(!this.approvalDetailCngeNote.endtNo) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        data: {
          message: "Endorsement not generated yet, please try again."
        }
      });
      return;
    }

    const payload = {
      operator: this.userInfo.userId,
      contractId: this.approvalDetailCngeNote.contractId,
      type: "endorsementCI",
    }
    this.store.dispatch(new DOWNLOAD_PDF(payload));

  }

  onClickInvoicePDF() {
    const payload = {
      operator: this.userInfo.userId,
      contractId: this.approvalDetailCngeNote.contractId,
      type: "invoice",
    }
    this.store.dispatch(new DOWNLOAD_PDF(payload));
  }

  isShowEndtSchedule() {
    return this.isEndorsement && this.approvalDetailCngeNote.cnoteStatus !== "PRINTED" && this.approvalDetailCngeNote.cnoteStatus !== "SUBMITTED";
  }

  isShowEndtCertBtn() {
    if(this.approvalDetailCngeNote.cnoteStatus !== "PRINTED" && this.approvalDetailCngeNote.cnoteStatus !== "SUBMITTED") {
      return false;
    }
    return this.isEndorsement && this.endtTypeShowEndtCertBtn.includes(this.approvalDetailCngeNote.endtType);
  }

  isShowEInvoiceBtn() {
    if(this.approvalDetailCngeNote.cnoteStatus !== "PRINTED" && this.approvalDetailCngeNote.cnoteStatus !== "SUBMITTED") {
      return false;
    }

    if(this.isEndorsement) {
      return (this.approvalDetailCngeNote.einvoiceConsentInd && this.approvalDetailCngeNote.irbmStatus === "VALID" && 
        this.endtTypeShowEInvoiceBtn.includes(this.approvalDetailCngeNote.endtType));
    }else {
      return (this.approvalDetailCngeNote.einvoiceConsentInd && 
        ["VALID","INVALID", "SUBMITTED"].includes(this.approvalDetailCngeNote.irbmStatus) && 
        this.endtTypeShowEInvoiceBtn.includes(this.approvalDetailCngeNote.endtType));
    }
  }

  isShowCreditTaxInvoiceBtn() {
    if(this.approvalDetailCngeNote.cnoteStatus !== "PRINTED" && this.approvalDetailCngeNote.cnoteStatus !== "SUBMITTED") {
      return false;
    }

    if(this.isEndorsement) {
      return ((!this.approvalDetailCngeNote.irbmStatus || this.approvalDetailCngeNote.irbmStatus === "VALID") && 
      Math.sign(this.approvalDetailCngeNote.grossPremium) === -1);
    }
    return false;
  }

  isShowDebitTaxInvoiceBtn() {
    if(this.approvalDetailCngeNote.cnoteStatus !== "PRINTED" && this.approvalDetailCngeNote.cnoteStatus !== "SUBMITTED") {
      return false;
    }
    
    if(this.isEndorsement) {
      return ((!this.approvalDetailCngeNote.irbmStatus || this.approvalDetailCngeNote.irbmStatus === "VALID") && 
      Math.sign(this.approvalDetailCngeNote.grossPremium) === 1);
    }
    return false;
  }

  premAdjustableCalculatePremium($event) {
    let fieldName = $event.fieldName;
    let value = Number($event.value);
    let data = JSON.parse(JSON.stringify(this.approvalDetailCngeNote));

    switch(fieldName) {
      case "hqPremiumPct":
        data.riskList[0].coverList[0].discountPercentageHq = value;
        this.store.dispatch(new CALCULATE_PREMIUM(this.generatePremiumCalculationPayload(data))).subscribe(state => {
          let premiumRes = state.SatMotorApprovalState.premiumRes;
          if(premiumRes.isMinPremApplied) {
            this.okMessageModal = this.dialogService.open(OkMessageComponent, {
              data: {
                message: "Minimum Gross Premium Applied."
              }
            });
          } else {
            this.updatePremium(premiumRes);
            this.populatePremiumAdjustableInput(premiumRes.cngeNote.riskList[0].coverList[0]);
          }
        });
      break;
      case "riskExcess":
        data.riskList[0].coverList[0].excessAmount = value;
        this.store.dispatch(new CALCULATE_PREMIUM(this.generatePremiumCalculationPayload(data))).subscribe(state => {
          let premiumRes = state.SatMotorApprovalState.premiumRes;
          this.updatePremium(premiumRes);
          this.populatePremiumAdjustableInput(premiumRes.cngeNote.riskList[0].coverList[0]);
        });
      break;
      case "loading":
        data.riskList[0].coverList[0].loadPercentage = value;
        this.store.dispatch(new CALCULATE_PREMIUM(this.generatePremiumCalculationPayload(data))).subscribe(state => {
          let premiumRes = state.SatMotorApprovalState.premiumRes;
          this.updatePremium(premiumRes);
          this.populatePremiumAdjustableInput(premiumRes.cngeNote.riskList[0].coverList[0]);
        });
      break
    }
  }

  populatePremiumAdjustableInput(data) {
    this.approvalForm.patchValue({
      riskExcess: this.decimalFmt.transform(data.excessAmount),
      hqPremiumPct: this.decimalFmt.transform(data.discountPercentageHq),
      loading: this.decimalFmt.transform(data.loadPercentage)
    });
  }

  generatePremiumCalculationPayload(data) {
    let payload = {
      cngeNote: {
        ...data
      },
      operator: this.userInfo.userId
    };
    return payload;
  }

  updatePremium(data) {
    this.premiumInfo = this.convertCngeToPremiumInfo(data.cngeNote);
    this.store.dispatch(new STORE_APPROVAL_DETAILS_CNGE(data.cngeNote));
    this.approvalDetailCngeNote = data.cngeNote;
  }

  getFormattedUpdateBy() {
    return this.approvalDetailCngeNote.updateBy + ' at ' + this.getFormattedDate(this.approvalDetailCngeNote.updateDate);
  }

  checkPregressType() {
    if(this.isEndorsement) {
      this.progressType = "endorsement";
    }else {
      this.progressType = "cn";
    }
  }

  loadCrossSell() {
    let getCrossSellListPayload = {
      "agentCode": this.approvalDetailCngeNote.mkagAgent.agentCode,
      "partnerId": this.approvalDetailCngeNote.clientPartner.partnerId,
      "idType": this.approvalDetailCngeNote.clientPartner.idType1,
      "idValue": this.approvalDetailCngeNote.clientPartner.idValue1
    };
    this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload)).subscribe(state => {
      if (state.SatMotorApprovalState.crossSellList) {
        this.crossSellList = state.SatMotorApprovalState.crossSellList;
        this.marketingConsent = state.SatMotorApprovalState.marketingConsent;
        console.log("cross sell list: ", this.crossSellList);
      }
    });
  }

  updateCngeInfo(cngeData) {
        this.productName = PRODUCTS[cngeData.productCode];

        this.currentExtraCoverList = cngeData.riskList[0].coverList[0].subCoverList;

        console.log("approval details: ", cngeData);

        this.cnoteStatus = cngeData.cnoteStatus;
  
          //Agent Information
          if(cngeData && cngeData.mkagAgent){
            this.agentData = {
              ...cngeData.mkagAgent,
              issuedBy : cngeData.createBy,
              issuedDate : cngeData.cnoteDate,
              agentAps: cngeData.agentAps
            };
          }

          //Vehicle Details
          this.vehicleDetails = {
            ...this.vehicleDetails,
            ...cngeData,
          };
  
          //Product Information
          if (cngeData) {
  
            this.issuanceInfo = {
              ...this.issuanceInfo,
              issuance: {
                ...this.issuanceInfo.issuance,
                productName: this.productName,
                productCode: cngeData.productCode,
                vehicleNo: cngeData.vehicleNo,
                coverNoteType: cngeData.cnoteType,
                cnoteType: cngeData.cnoteType,
                prevPolicyNo: cngeData.previousPolicyNo,
                effectiveDate: cngeData.effectiveDate,
                expiryDate: cngeData.expiryDate,
                equotationNo: cngeData.proposalNo,
                equotationCreatedDate: this.getFormattedDate(cngeData.proposalDate),
                eCoverNoteNo: cngeData.cnoteNo,
                jpjStatus: cngeData.jpjStatus,
                jpjMessage: cngeData.jpjMessage,
                prevPolicyDate: cngeData.riskList[0].prevNcdExpDate
              }
            };
          }
  
          //customer Partner
          if(cngeData && cngeData.clientPartner){
            this.cpData = cngeData.clientPartner;
          }
  
          //Premium Information
          if(cngeData){
            this.premiumInfo = this.convertCngeToPremiumInfo(cngeData);
          }

          //refer list
          if(cngeData.referList) {
            this.referralReasonList = this.approvalDetailCngeNote.referList.map(refer => {
              return {
                code: refer.referCode,
                desc: refer.referDescp
              };
            });
          }else {
            this.referralReasonList = [];
          }
  
          //Remarks
          this.remarksInfo = {
            referral: cngeData.referRemarks,
            admin: cngeData.referRemarkList,
            approval: cngeData.referAdminRemarks,
            updatedBy: this.getFormattedUpdateBy()
          }
          this.cdr.markForCheck();
          this.cdr.detectChanges();
  }

  initEndorsementData(approvalDetails) {
    this.endtCode = this.approvalDetailCngeNote.endtCode;
    this.approvalDetailsEndtHistoryList = approvalDetails.endtHistoryList;

    this.store.dispatch(new GET_CHECKLIST_LOV(this.endtCode)).subscribe(state => {
      this.checklistLov = state.SatMotorState.checklistLov;
    });
  }

  getStatus(status) {
    return STATUS_MAP[status];
  }

  disableJpjUpdateInd() {
    return this.jpjUpdateIndEnableEndtTypeList.some(e => e === this.approvalDetailCngeNote.cnoteType);
  }

  disableIsmUpdatedInd() {
    return this.ismUpdateIndEnableEndtTypeList.some(e => e === this.approvalDetailCngeNote.cnoteType);
  }

  get isPackageSelected() {
    return this.approvalDetailCngeNote.riskList[0].coverList[0].subCovPackage;
  }

  get isGetInitData() {
    return this.isGetApprovalDetails && this.isGetVehUseLov;
  }

}
