<nx-accordion variant="light">
  <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title>Policy Movement
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <div>
      <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
      <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">
        <table nxTable zebra #table cellpadding="0" class="clickable_table">
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">No.</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Endorsement Type</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Effective Date</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Expiry Date</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Sum Insured (RM)</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Premium Due (RM)</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Vehicle No.</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow *ngFor="let item of policyMovemementTableData; let i = index"
              (click)="openPopup3(item)">
              <td nxTableCell>
                <span nxCopytext="medium">
                  {{ (page - 1) * perPage + i + 1 }}
                </span>
              </td>
              <td nxTableCell>
                <span nxCopytext="medium">
                  {{ item.endorsementDesc }}
                </span>
              </td>
              <td nxTableCell>
                <span nxCopytext="medium">
                  {{ item.effectiveDate }}
                </span>
              </td>
              <td nxTableCell>
                <span nxCopytext="medium">
                  {{ item.expiryDate }}
                </span>
              </td>
              <td nxTableCell class="std-ta-right">
                <span nxCopytext="medium">
                  {{ item.sumInsured }}
                </span>
              </td>
              <td nxTableCell class="std-ta-right">
                <span nxCopytext="medium">
                  {{ item.premiumDue }}
                </span>
              </td>
              <td nxTableCell>
                <span nxCopytext="medium">
                  {{ item.vehicleNo }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div nxLayout="grid nopadding" class="nx-margin-top-2xs nx-margin-bottom-2m grid_remove_padding pagination_form">
      <div nxRow *ngIf="policyMovemementTableData && policyMovemementTableData.length > 0">
        <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
          <nx-natural-language-form size="small" class="pagination_form">
            <span nxCopytext="normal">Show</span>
            <nx-word>
              <nx-dropdown [value]="perPage" (valueChange)="onPerPageChange($event)" [panelGrow]="true" [panelMaxWidth]="500">
              <!-- <nx-dropdown [value]="perPage" [panelGrow]="true" [panelMaxWidth]="500"> -->
                <nx-dropdown-item *ngFor="let option of dropdownOptions" [value]="option">
                  {{ option }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-word>
            <span nxCopytext="normal">results per page</span>
          </nx-natural-language-form>
        </div>

        <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        </div>
        <div nxCol="12,12,6" class="container_flex_center align_right">
          <nx-pagination [count]="count" [page]="page" [perPage]="perPage" type="advanced" (goPrev)="prevPage()"
            (goNext)="nextPage()" (goPage)="goToPage($event)" ariaLabel="Advanced example pagination"
            class="pagination_position">
          </nx-pagination>
        </div>
      </div>
    </div>
  </nx-expansion-panel>
</nx-accordion>