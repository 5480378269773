const PREFIX = 'SATMOTOR';

export class SET_CURRENT_STEP {
  static readonly type = `[${PREFIX}] SET CURRENT STEP`;

  constructor(public readonly payload: any) {}
}

export class STORE_AGENT_CODE {
  static readonly type = `[${PREFIX}] STORE AGENT CODE`;

  constructor(public readonly payload: any) {}
}

export class STORE_AGENT_ADDITIONAL {
  static readonly type = `[${PREFIX}] STORE AGENT ADDITIONAL`;

  constructor(public readonly payload: any) {}
}

export class STORE_DECLARATION {
  static readonly type = `[${PREFIX}] STORE DECLARATION`;

  constructor(public readonly payload: any) {}
}

export class STORE_ISSUANCE_SUMMARY {
  static readonly type = `[${PREFIX}] STORE ISSUANCE SUMMARY`;

  constructor(public readonly payload: any) {}
}

export class STORE_ENDORSEMENT {
  static readonly type = `[${PREFIX}] STORE ENDORSEMENT`;

  constructor(public readonly payload: any) {}
}

export class UPDATE_ENDORSEMENT_STEP2 {
  static readonly type = `[${PREFIX}] UPDATE ENDORSEMENT STEP2`;

  constructor(public readonly payload: any) {}
}

export class GET_LOV {
  static readonly type = `[${PREFIX}] GET LOV`;
}

export class GET_DOCUMENT_LOV {
  static readonly type = `[${PREFIX}] GET DOCUMENT LOV`;

  constructor(public readonly payload) {}
}

export class GET_AGENT_LIST_AGENT {
  static readonly type = `[${PREFIX}] GET AGENT LIST FOR AGENT`;

  constructor(public readonly payload) {}
}

export class RESET_AGENT_LIST {
  static readonly type = `[${PREFIX}] RESET AGENT LIST`;
}

export class CHECK_SINGLE_AGENT {
  static readonly type = `[${PREFIX}] CHECK SINGLE AGENT`;

  constructor(public readonly payload) {}
}

export class STORE_STEP2_SEARCH {
  static readonly type = `[${PREFIX}] STORE STEP 2 - SEARCH`;

  constructor(public readonly payload: any) {}
}

export class EDIT_CUSTOMER_PARTNER {
  static readonly type = `[${PREFIX}] Edit CUSTOMER PARTNER`;

  constructor(public readonly payload: {}) {}
}

export class SHOW_SEARCH_COMPONENT_2 {
  static readonly type = `[${PREFIX}] SHOW SEARCH COMPONENT 2`;

  constructor(public readonly payload: boolean) {}
}

export class GET_AGENT_DETAIL {
  static readonly type = `[${PREFIX}] GET AGENT DETAIL`;

  constructor(public readonly payload) {}
}

export class GET_AGENT_DETAIL_ONLY {
  static readonly type = `[${PREFIX}] GET AGENT DETAIL ONLY`;

  constructor(public readonly payload) {}
}

export class GET_BRANCH_LIST {
  static readonly type = `[${PREFIX}] GET BRANCH LIST`;

  constructor(public readonly userId: string) {}
}

export class GET_AGENT_LIST_BRANCH {
  static readonly type = `[${PREFIX}] GET AGENT LIST BY BRANCH`;

  constructor(
    public readonly operator: string,
    public readonly branchCode: string,
  ) {}
}

export class GET_REDIRECT_PAGE {
  static readonly type = `[${PREFIX}] GET REDIRECT PAGE`;

  constructor(public readonly payload) {}
}

export class GET_ACTION_URL {
  static readonly type = `[${PREFIX}] GET ACTION URL`;

  constructor(public readonly payload) {}
}

export class GET_NCD {
  static readonly type = `[${PREFIX}] GET NCD`;

  constructor(public readonly payload) {}
}

export class GET_CUSTOMER_PARTNER {
  static readonly type = `[${PREFIX}] GET CUSTOMER PARTNER`;

  constructor(public readonly payload) {}
}

export class STORE_STEP2 {
  static readonly type = `[${PREFIX}] STORE STEP2`;

  constructor(public readonly payload) {}
}

export class STORE_STEP2_CP_SCREEN {
  static readonly type = `[${PREFIX}] STORE STEP2 CP SCREEN`;

  constructor(public readonly payload) {}
}

export class VALIDATE_CP {
  static readonly type = `[${PREFIX}] VALIDATE CP`;

  constructor(public readonly payload) {}
}

export class SEARCH_MAKE_MODEL {
  static readonly type = `[${PREFIX}] SEARCH MAKE / MODAL`;

  constructor(public readonly payload) {}
}

export class SEARCH_ENDORSEMENT_MAKE_MODEL {
  static readonly type = `[${PREFIX}] SEARCH ENDORSEMENT MAKE / MODAL`;

  constructor(public readonly payload) {}
}

export class SELECTED_MAKE_MODEL {
  static readonly type = `[${PREFIX}] SELECTED MAKE / MODAL`;

  constructor(public readonly payload) {}
}

export class SELECTED_ENDORSEMENT_MAKE_MODEL {
  static readonly type = `[${PREFIX}] SELECTED ENDORSEMENT MAKE / MODAL`;

  constructor(public readonly payload) {}
}

export class GET_COVER_LIST_LOV {
  static readonly type = `[${PREFIX}] GET COVER LIST LOV`;

  constructor(public readonly payload) {}
}
export class GET_PRODUCT_CONFIG_LOV {
  static readonly type = `[${PREFIX}] GET PRODUCT CONFIG LOV`;

  constructor(public readonly payload) {}
}

export class POLICY_MOVEMENT_DATA {
  static readonly type = `[${PREFIX}] POLICY MOVEMENT DATA`;

  constructor(public readonly payload) {}
}

export class GET_PRODUCT_LIST {
  static readonly type = `[${PREFIX}] GET PRODUCT LIST`;

  constructor(public readonly payload) {}
}

export class GET_VEHICLE_USE {
  static readonly type = `[${PREFIX}] GET VEHICLE USE LOV`;

  constructor(public readonly payload) {}
}

export class INIT_STEP3 {
  static readonly type = `[${PREFIX}] INIT STEP 3`;

  constructor(public readonly payload) {}
}

export class STORE_STEP3 {
  static readonly type = `[${PREFIX}] STORE STEP 3`;

  constructor(public readonly payload) {}
}

export class STORE_SHOWED_ONE_ALLIANZ_POP_UP {
  static readonly type = `[${PREFIX}] STORE SHOWED ONE ALLIANZ POP UP`;

  constructor(public readonly payload) {}
}

export class ADD_NEW_COVERAGE {
  static readonly type = `[${PREFIX}] ADD NEW COVERAGE`;

  constructor(public readonly payload) {}
}

export class DELETE_COVERAGE_FLAG {
  static readonly type = `[${PREFIX}] DELETE COVERAGE FLAG`;

  constructor(public readonly payload) {}
}

export class DELETE_COVERAGE {
  static readonly type = `[${PREFIX}] DELETE COVERAGE`;

  constructor(public readonly payload) {}
}

export class SET_CUSTOMER_PARTNER_SEARCH_TYPE {
  static readonly type = `[${PREFIX}] SET CUSTOMER PARTNER SEARCH TYPE`;

  constructor(public readonly payload) {}
}

export class STORE_COVERAGE_VEHICLE_DETAILS {
  static readonly type = `[${PREFIX}] STORE VEHICLE DETAILS`;

  constructor(public readonly payload) {}
}

export class STORE_ENDORSEMENT_VEHICLE_DETAILS {
  static readonly type = `[${PREFIX}] STORE ENDORSEMENT VEHICLE DETAILS`;

  constructor(public readonly payload) {}
}

export class STORE_EXTRA_COVER_DETAILS {
  static readonly type = `[${PREFIX}] STORE EXTRA COVER DETAILS`;

  constructor(public readonly payload) {}
}

export class UPDATE_PAYMENT_LIST {
  static readonly type = `[${PREFIX}] UPDATE PAYMENT LIST`;

  constructor(public readonly payload) {}
}

export class STORE_COVERAGE_TYPE {
  static readonly type = `[${PREFIX}] STORE TYPE OF COVERAGE`;

  constructor(public readonly payload) {}
}
export class UPDATE_COVERAGE_TYPE {
  static readonly type = `[${PREFIX}] UPDATE TYPE OF COVERAGE`;

  constructor(public readonly payload) {}
}

export class STORE_ENDORSEMENT_COVERAGE_TYPE {
  static readonly type = `[${PREFIX}] STORE ENDORSEMENT TYPE OF COVERAGE`;

  constructor(public readonly payload) {}
}

export class CALCULATE_PREMIUM_PAYLOAD {
  static readonly type = `[${PREFIX}] PREPARE PREMIUM CALCULATION PAYLOAD `;

  constructor(public readonly payload) {}
}

export class CALCULATE_PREMIUM {
  static readonly type = `[${PREFIX}] CALCULATE PREMIUM`;

  constructor(public readonly payload) {}
}

export class CALCULATE_ENDT_PREMIUM {
  static readonly type = `[${PREFIX}] CALCULATE ENDT PREMIUM`;

  constructor(public readonly payload) {}
}

export class CALCULATE_PREMIUM_SUMMARY {
  static readonly type = `[${PREFIX}] CALCULATE PREMIUM SUMMARY`;

  constructor(public readonly payload) {}
}

export class GET_CUSTOMER_PARTNER_MOCK {
  static readonly type = `[${PREFIX}] GET CUSTOMER PARTNER INFO`;

  constructor(public readonly payload) {}
}

export class GENERATE_QUOTATION {
  static readonly type = `[${PREFIX}] GENERATE QUOTATION`;

  constructor(public readonly payload, public readonly cnoteType) {}
}

export class SEND_TO_JPJ {
  static readonly type = `[${PREFIX}] SEND TO JPJ`;

  constructor(public readonly payload) {}
}

export class GENERATE_ENDORSEMENT {
  static readonly type = `[${PREFIX}] GENERATE ENDORSEMENT`;

  constructor(public readonly payload) {}
}

export class GENERATE_ENDORSEMENT_QUOTATION {
  static readonly type = `[${PREFIX}] GENERATE ENDORSEMENT QUOTATION`;

  constructor(public readonly payload) {}
}

export class RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ {
  static readonly type = `[${PREFIX}] Reset Generate Quotation and Send to JPJ`;

  constructor(public readonly payload) {}
}


export class SET_JPJ_STATUS_SENT {
  // static readonly type = '[Motor] Set JPJ Status Sent';
  static readonly type = '[${PREFIX}] Set JPJ Status Sent';

  constructor(public payload: boolean) {}
}

export class SET_RETRIEVE_QUOTATION {
  static readonly type = '[${PREFIX}] SET RETRIEVE QUOTATION';

  constructor(public payload: boolean) {}
}

export class SET_ACTION {
  static readonly type = '[${PREFIX}] SET ACTION';

  constructor(public payload: string) {}
}

export class SEARCH_ACTIVE_QUOTATION {
  static readonly type = `[${PREFIX}] SEARCH ACTIVE QUOTATION`;

  constructor(public readonly payload) {}
}

export class ENQUIRE_ENDT_NARRATION {
  static readonly type = `[${PREFIX}] ENQUIRE ENDT NARRATION`;

  constructor(public readonly payload) {}
}

export class SEARCH_ENDT_VEHICLE {
  static readonly type = `[${PREFIX}] SEARCH ENDORSEMENT VEHICLE`;

  constructor(public readonly payload) {}
}

export class ROADTAX_SEARCH {
  static readonly type = `[${PREFIX}] ROADTAX SEARCH`;

  constructor(public readonly payload) {}
}

export class SEARCH_VIX {
  static readonly type = `[${PREFIX}] SEARCH VIX`;

  constructor(public readonly payload) {}
}

export class SAVE_REFERRAL_REMARKS {
  static readonly type = `[${PREFIX}] SAVE REFERRAL REMARKS`;

  constructor(public readonly payload) {}
}

export class DOWNLOAD_PDF {
  static readonly type = `[${PREFIX}] DOWNLOAD_PDF`;

  constructor(public readonly payload) {}
} 

export class GET_PREVIOUS_POLICY_NO {
  static readonly type = `[${PREFIX}] GET PREVIOUS POLICY NO`;

  constructor(public readonly payload) {}
}
export class GET_VIEW_POLICY {
  static readonly type = `[${PREFIX}] GET VIEW POLICY`;
  constructor(public readonly payload: any) {}
}

export class GET_VIEW_CLAIM {
  static readonly type = `[${PREFIX}] GET VIEW CLAIM`;
  constructor(public readonly claimNo, public readonly policyNo) {}
}

export class REDIRECT_TO_MIGBASE_MOTOR {
  static readonly type = `[${PREFIX}] REDIRECT TO MIGBASE MOTOR`;
  constructor(public readonly payload: any) {}
}


export class REQUEST_CANCELLATION {
  static readonly type = `[${PREFIX}] REQUEST CANCELLATION`;

  constructor(public readonly payload) {}
}

export class CANCEL_REPLACE {
  static readonly type = `[${PREFIX}] CANCEL REPLACE`;

  constructor(public readonly payload) {}
}

export class CONTINUE_ISSUANCE {
  static readonly type = `[${PREFIX}] CONTINUE ISSUANCE`;
}

export class ISSUE_NEW {
  static readonly type = `[${PREFIX}] ISSUE NEW`;

  constructor(public readonly payload) {}
}

export class SAVE_AS_DRAFT {
  static readonly type = `[${PREFIX}] SAVE AS DRAFT`;

  constructor(public readonly payload) {}
}
export class CANCEL_DRAFT {
  static readonly type = `[${PREFIX}] CANCEL DRAFT`;

  constructor(public readonly payload) {}
}

export class SAVE_CUSTOMER_PARTNER {
  static readonly type = `[${PREFIX}] SAVE CUSTOMER PARTNER`;

  constructor(public readonly payload) {}
}

export class GET_UPSELL_PRODUCT_LOV {
  static readonly type = `[${PREFIX}] GET UPSELL PRODUCT LOV`;

  constructor(public readonly payload) {}
}

export class STORE_UPSELL_PRODUCT {
  static readonly type = `[${PREFIX}] STORE UPSELL PRODUCT`;

  constructor(public readonly payload) {}
}

export class GET_PAYMENT_MODE_LOV {
  static readonly type = `[${PREFIX}] GET PAYMENT MODE LOV`;

  constructor(public readonly payload) {}
}

export class GET_PAYMENT_TO_LOV {
  static readonly type = `[${PREFIX}] GET PAYMENT TO LOV`;

  constructor(public readonly payload) {}
}

export class GET_DOCUMENTS {
  static readonly type = `[${PREFIX}] GET DOCUMENTS`;

  constructor(public readonly payload) {}
}

export class GET_POSTCODE_LOV {
  static readonly type = `[${PREFIX}] GET POSTCODE LOV`;

  constructor(public readonly payload) {}
}

export class GET_POSTCODE_INFO {
  static readonly type = `[${PREFIX}] GET POSTCODE INFO`;

  constructor(public readonly payload) {}
}

export class GET_CROSS_SELL_LIST {
  static readonly type = `[${PREFIX}] GET CROSS SELL LIST`;

  constructor(public readonly payload) {}
}

export class STORE_AGENT {
  static readonly type = `[${PREFIX}] STORE AGENT`;

  constructor(public readonly payload: any) {}
}

export class COPY_CNGE_INFO {
  static readonly type = `[${PREFIX}] COPY CNGE INFO`;

  constructor(public readonly payload) {}
}

export class GET_ISS_PRODUCT_PDF {
  static readonly type = `[${PREFIX}] GET ISS PRODUCT PDF`;

  constructor(public readonly payload) {}
}

export class SAVE_ISS_MESSAGE_SENT_LOG {
  static readonly type = `[${PREFIX} SAVE ISS MESSAGE SENT LOG]`
  constructor(public readonly payload) { }
}

export class SAVE_ISS_EMAIL_SENT_LOG {
  static readonly type = `[${PREFIX} SAVE ISS EMAIL SENT LOG]`
  constructor(public readonly payload) { }
}

export class GET_CCENTRIC_OPPORTUNITY_INFO {
  static readonly type = `[${PREFIX} GET CCENTRIC OPPORTUNITY INFO]`
  constructor(public readonly payload) { }
}

export class GET_SWITCH_SITE {
  static readonly type = `[${PREFIX} GET SWITCH SITE]`
  constructor(public readonly payload) { }
}

export class GET_DRAFT_DETAILS {
  static readonly type = `[${PREFIX} GET DRAFT DETAILS]`
  constructor(public readonly payload) { }
}

export class GET_QUOTATION_DETAIL {
  static readonly type = `[${PREFIX}] SET QUOTATION DETAIL`;
  constructor(public readonly payload: any) {}
}

export class GET_ISS_CONTENT_ID {
  static readonly type = `[${PREFIX}] GET ISS CONTENT ID`;
  constructor(public readonly payload: any) {}
}

export class DELETE_EXTRA_COVER_DETAILS {
  static readonly type = `[${PREFIX}] DELETE EXTRA COVER`;
  constructor(public readonly payload: any) {}
}

export class STORE_STEP2_DRAFT_NO {
  static readonly type = `[${PREFIX}] STORE STEP2 DRAFT NO`;
  constructor(public readonly payload: any) {}
}

export class STORE_STEP3_DRAFT_NO {
  static readonly type = `[${PREFIX}] STORE STEP3 DRAFT NO`;
  constructor(public readonly payload: any) {}
}

export class STORE_AGENT_FROM_RESPONSE {
  static readonly type = `[${PREFIX}] STORE AGENT FROM RESPONSE`;
  constructor(public readonly payload1: any, public readonly payload2: any) {}
}

export class STORE_ISSUANCE_FROM_RESPONSE {
  static readonly type = `[${PREFIX}] STORE ISSUANCE FROM RESPONSE`;
  constructor(public readonly payload1: any) {}
}

export class STORE_COVERAGE_FROM_RESPONSE {
  static readonly type = `[${PREFIX}] STORE ISSUANCE FROM RESPONSE`;
  constructor(public readonly payload1: any) {}
}


export class GET_NCD_CONFIRMATION {
  static readonly type = `[${PREFIX}] GET NCD CONFIRMATION`;
  constructor(public readonly payload: any) {}
}

export class STORE_STEP2_RENEWAL {
  static readonly type = `[${PREFIX}] STORE STEP 2 RENEWAL`;
  constructor(public readonly payload: any) {}
}

export class STORE_STEP3_RENEWAL {
  static readonly type = `[${PREFIX}] STORE STEP 3 RENEWAL`;
  constructor(public readonly payload: any) {}
}

export class COPY_CNGE_INFO_RENEWAL {
  static readonly type = `[${PREFIX}] COPY CNGE INFO RENEWAL`;
  constructor(public readonly payload: any) {}
}

export class COPY_CNGE_INFO_VIEW_QUOTATION {
  static readonly type = `[${PREFIX}] COPY CNGE INFO VIEW QUOTATION`;
  constructor(public readonly payload: any) {}
}

export class COPY_CNGE_TO_ENDORSEMENT_STATE {
  static readonly type = `[${PREFIX}] COPY CNGE TO ENDORSEMENT STATE`;
  constructor(public readonly payload: any) {}
}

export class RESET_MOTOR_STATE {
  static readonly type = `[${PREFIX}] RESET MOTOR STATE`;
  constructor() {}
}

export class RESET_PREVIOS_POLICY_DETAILS {
  static readonly type = `[${PREFIX}] RESET PREVIOS POLICY DETAILS`;
  constructor() {}
}

export class STORE_STEP3_VIEW_QUOTATION {
  static readonly type = `[${PREFIX}] STORE STEP 3 VIEW QUOTATION`;
  constructor(public readonly payload: any) {}
}

export class PRODUCT_VALIDATION {
  static readonly type = `[${PREFIX}] PRODUCT VALIDATION`;
  constructor(public readonly payload: any) {}
}

export class ENDORSEMENT_TYPE_VALIDATION {
  static readonly type = `[${PREFIX}] ENDORSEMENT TYPE VALIDATION`;
  constructor(public readonly payload: any) {}
}

export class COPY_DRAFT_INFO {
  static readonly type = `[${PREFIX}] COPY DRAFT INFO`;

  constructor(public readonly payload) {}
}

export class COPY_REQUOTE_INFO {
  static readonly type = `[${PREFIX}] COPY REQUOTE INFO`;

  constructor(public readonly payload) {}
}

export class STORE_EXTRA_COVER_PACKAGE {
  static readonly type = `[${PREFIX}] STORE EXTRA COVER PACKAGE`;

  constructor(public readonly payload) {}
}

export class RESET_SAT_MOTOR_STATE {
  static readonly type = `[${PREFIX}] RESET SAT MOTOR STATE`;
}

export class CANCEL_QUOTATION {
  static readonly type = `[${PREFIX}] CANCEL QUOTATION`;

  constructor(public readonly payload) {}
}

export class CANCEL_COVER_NOTE {
  static readonly type = `[${PREFIX}] CANCEL COVER NOTE`;

  constructor(public readonly payload) {}
}

export class STORE_COMPLETE_DATA {
  static readonly type = `[${PREFIX}] STORE COMPLETE DATA`;

  constructor(public readonly payload) { }
}

export class GET_QUOTATION_DETAIL_REQUOTE {
  static readonly type = `[${PREFIX}] GET QUOTATION DETAIL REQUOTE`;

  constructor(public readonly payload, public readonly action) { }
}

export class VALIDATE_POI_DATE {
  static readonly type = `[${PREFIX}] VALIDATE POI DATE`;

  constructor(public readonly payload) { }
}

export class GENERATE_QUOTATION_RENEWAL {
  static readonly type = `[${PREFIX}] GENERATE QUOTATION RENEWAL`;

  constructor(public readonly payload) { }
}

export class UPDATE_ISM_POLICY {
  static readonly type = `[${PREFIX}] UPDATE ISM POLICY`;

  constructor(public readonly payload) { }
}

export class GET_NCD_ENQUIRY {
  static readonly type = `[${PREFIX}] GET NCD ENQUIRY`;

  constructor(public readonly payload) { }
}

export class RESET_SUBSEQUENT_PAGE_DATA {
  static readonly type = `[${PREFIX}] RESET SUBSEQUENT PAGE DATA`;
}

export class SEARCH_POLICY_ENQUIRY {
  static readonly type = `[${PREFIX}] SEARCH POLICY ENQUIRY`;

  constructor(public readonly payload) {}
}

export class EMAIL_DOCUMENT {
  static readonly type = `[${PREFIX}] EMAIL DOCUMENT`;

  constructor(public readonly payload) {}
}

export class GET_EMAIL_LISTING {
  static readonly type = `[${PREFIX}] GET EMAIL LISTING`;

  constructor(public readonly payload) {}
}

export class UPDATE_GEO_INDICATOR {
  static readonly type = `[${PREFIX}] UPDATE GEOCODE INDICATOR`;
  
  constructor(public payload: boolean) {}
}

export class STORE_PREMIUM_CALCULATION_PAYLOAD {
  static readonly type = `[${PREFIX}] STORE PREMIUM CALCULATION PAYLOAD`;
  
  constructor(public readonly payload) {}
}

export class GET_DOCUMENT_TYPE_LOV {
  static readonly type = `[${PREFIX}] GET DOCUMENT TYPE LOV`;
  
  constructor() {}
}

export class GET_REASON_CODE_LOV {
  static readonly type = `[${PREFIX}] GET REASON CODE LOV`;
  
  constructor(public readonly payload) {}
}

export class GET_VEHICLE_CLASS_USE_LOV {
  static readonly type = `[${PREFIX}] GET VEHICLE CLASS USE LOV`;
  
  constructor(public readonly payload) {}
}

export class GET_CHECKLIST_LOV {
  static readonly type = `[${PREFIX}] GET CHECKLIST LOV`;
  
  constructor(public readonly payload) {}
}

export class GET_QUOTATION_DETAIL_PAYLOAD {
  static readonly type = `[${PREFIX}] GET QUOTATION DETAIL PAYLOAD`;
  
  constructor(public readonly payload) {}
}

export class SAVE_ENDT_CUSTOMER_PARTNER {
  static readonly type = `[${PREFIX}] SAVE ENDT CUSTOMER PARTNER`;
  
  constructor(public readonly payload) {}
}

export class SET_OPPORTUNITY_ID {
  static readonly type = `[${PREFIX}] SET OPPORTUNITY ID`;
  
  constructor(public readonly payload) {}
}

export class SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE {
  static readonly type = `[${PREFIX}] SET ENDT CUSTOMER PARTNER SEARCH TYPE`;
  
  constructor(public readonly payload) {}
}

export class STORE_ROAD_TAX_PAYABLE {
  static readonly type = `[${PREFIX}] STORE_ROLAD TAX PAYABLE`;
  
  constructor(public readonly payload) {}
}

export class SET_UPDATE_PAYMENT_IND {
  static readonly type = `[${PREFIX}] SET UPDATE PAYMENT IND`;
  
  constructor(public readonly payload) {}
}

export class SAVE_PAYMENT {
  static readonly type = `[${PREFIX}] SAVE PAYMENT`;
  
  constructor(public readonly payload) {}
}

export class STORE_PAYMENT_INFO {
  static readonly type = `[${PREFIX}] STORE PAYMENT INFO`;
  
  constructor(public readonly payload) {}
}

export class ENDT_CALCULATE_PREMIUM_SUMMARY {
  static readonly type = `[${PREFIX}] ENDT CALCULATE PREMIUM SUMMARY`;
  
  constructor(public readonly payload) {}
}

export class IS_CANCEL_AT_COMPLETE {
  static readonly type = `[${PREFIX}] IS CANCEL AT COMPLETE`;
  
  constructor(public readonly payload) {}
}

export class STORE_NCD_INFO {
  static readonly type = `[${PREFIX}] STORE NCD INFO`;
  
  constructor(public readonly payload) {}
}

export class UPDATE_COVERAGE_ALERT {
  static readonly type = `[${PREFIX}] UPDATE COVERAGE ALERT`;
  
  constructor(public readonly payload) {}
}

export class RESET_CUSTOMER_PARTNER_DATA {
  static readonly type = `[${PREFIX}] RESET CUSTOMER PARTNER DATA`;
}