import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-road-tax-payable',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-road-tax-payable.component.html',
  styleUrl: './view-road-tax-payable.component.scss'
})
export class ViewRoadTaxPayableComponent {
  @Input() data: any;

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }

}
