<div class="blue_panel nx-margin-top-xs" *ngIf="isThirdParty">
  <nx-expansion-panel expanded="[true]">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title>
        <div nxLayout="grid" class="coverage">
          <div nxRow>
            <div nxCol="12,12,8" class="m_padding_bottom_10">
              <p nxCopytext="small" style="font-size: 14px;">{{ 'COVERAGE.FIELD.COVERAGE_DESCRIPTION' | translate }}</p>
              <h2 class="nx-margin-0" style="font-size: 20px;">{{ typeOfCoverage.coverDescription | titlecase }}</h2>
            </div>
            <div nxCol="12,12,4" class="container_flex_center align_right">
              <div class="gross">
                <p nxCopytext="small">{{ 'COVERAGE.FIELD.GROSS_PREMIUM' | translate }}</p>
                <p nxCopytext="large">{{ typeOfCoverage.grossPremium | number: '1.2-2' }}</p>
              </div>
              <div class="nx-margin-left-s">
                  <nx-icon name="trash-o" size="s" class="gross_trash" (click)="deleteCover(typeOfCoverage)"></nx-icon>
              </div>
            </div>
          </div>
        </div>
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <div nxLayout="grid" class="coverage">
      <div [formGroup]="thirdPartyForm">
        <div nxRow class="nx-margin-top-m">
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.SUM_INSURED_RM' | translate }}">
              <input nxInput formControlName="sumInsured" appCusCurrencyFormat />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.ANNUAL_PREMIUM' | translate }}">
              <input nxInput formControlName="annualPremium" appCusCurrencyFormat />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.GROSS_PREMIUM' | translate }}">
              <input nxInput formControlName="grossPremium" appCusCurrencyFormat />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.BASIC_PREMIUM' | translate }}">
              <input nxInput formControlName="basicPremium" />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
        </div>
        @if (isMotorcycle()) {
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="{{ 'COVERAGE.FIELD.ALL_RIDER_PREMIUM' | translate }}">
                <input nxInput formControlName="allriderPrem" appCusCurrencyFormat />
              </nx-formfield>
            </div>
          </div>
        }
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.VEHICLE_AGE_LOADING' | translate }}">
              <nx-dropdown formControlName="vehicleAgeLoading" (selectionChange)="onChangeForm($event)">
                <nx-dropdown-item *ngFor="let item of vehicleAgeLoadingList" [value]="item.pct">
                  {{ item.description }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.DRIVER_AGE_LOADING' | translate }}">
              <nx-dropdown formControlName="driverAgeLoading" (selectionChange)="onChangeForm($event)">
                <nx-dropdown-item *ngFor="let item of driverAgeLoadingList" [value]="item.pct">
                  {{ item.description }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.CLAIMS_EXPERIENCE_LOADING' | translate }}">
              <nx-dropdown formControlName="claimExperienceLoading" (selectionChange)="onChangeForm($event)">
                <nx-dropdown-item *ngFor="let item of claimsExpLoadingList" [value]="item.pct">
                  {{ item.description }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.LOADING_AMOUNT' | translate }}">
              <input nxInput formControlName="loadingAmount" />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD' | translate }}">
              <input nxInput formControlName="ncdPercentage" />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_AMOUNT' | translate }}">
              <input nxInput formControlName="ncdAmount" />
              <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_FROM' | translate }}">
              <nx-dropdown formControlName="prevNcdInsurer">
                <nx-dropdown-item *ngFor="let item of lov.insurerList" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_VEHICLE_NO' | translate }}">
              <input nxInput formControlName="prevNcdVeh" (blur)="onChangeForm($event)" appToUpperCase/>
                @if (prevNcdVeh.errors && prevNcdVeh.errors["invalid"]) {
                  <nx-error nxFormfieldError>
                    <strong>{{ 'COVERAGE.ERROR.VEHICLE_NO.INVALID' | translate }}</strong>
                  </nx-error>
                }
            </nx-formfield>
          </div>
        </div>
      </div>

      <div nxRow>
        <div nxCol="12">
          <button nxButton="secondary small" type="button" class="btn_small_full_width" [disabled]="isDisabledGetNCD" (click)="onClickGetNCD()">
            GET NO CLAIM DISCOUNT
          </button>
        </div>
      </div>
      
      @if (isShowExtraCoverage) {
       <app-extra-coverage [coverageFmGroup]="coverageFmGroup" [isThirdParty]="isThirdParty" [defaultDriver]="defaultDriver"  [partnerInfo]="partnerInfo"></app-extra-coverage>
      }
      <app-premium-summary [isThirdParty]="true"></app-premium-summary>

    </div>
  </nx-expansion-panel>
</div>
