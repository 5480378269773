<!-- Endorsement Details -->
<ng-container *ngIf="endorsement_details">

  <div nxCol="12,12,12,12" class="grid_width_page">
    <div>
      <h2 class="nx-margin-0">Endorsement Details</h2>
    </div>

    <div class="nx-margin-top-2m">

      <!-- Policy movement accordion -->
      <app-policy-movement [endorsementType]="endorsementType">
      </app-policy-movement>

      <!-- Vehicle details accordion -->
      @if (endorsementTypeVehicleDetails())
      {
      <!-- from issuance -->
      <!-- <app-vehicle-details></app-vehicle-details> -->

      <!-- from endorsement -->
      <app-endorsement-vehicle-details [endorsementType]="endorsementType" (endtCalculatePremium)="endtCalculatePremium($event)">
      </app-endorsement-vehicle-details>

      }

      <!-- Coverage accordion -Coverage -Extra Coverage -->
      @if (endorsementTypeExtraCoverage())
      {
      <!-- from endorsement -->
 		  <!-- <app-extra-cover [endorsementType]="endorsementType" (endtCalculatePremium)="endtCalculatePremium($event)">
      </app-extra-cover> -->
 	    <app-extra-coverage-endorsement (endtCalculatePremium)="endtCalculatePremium($event)"></app-extra-coverage-endorsement>
      }

      <!-- Coverage accordion -Coverage -Named Driver-->
      @if (endorsementTypeNamedDriver())
      {
      <!-- from endorsement -->
      <app-named-driver [endorsementType]="endorsementType">
      </app-named-driver>
      }

      <!-- Coverage accordion -no claim discount -->
      @if (endorsementTypeNoClaimDiscount())
      {
      <!-- from endorsement -->
      <app-no-claim-discount [endorsementType]="endorsementType" (endtCalculatePremium)="endtCalculatePremium($event)">
      </app-no-claim-discount>
      }

      <!-- Coverage accordion -vehicle sum insured -->
      @if (endorsementTypeVehicleSumInsured())
      {
      <!-- from endorsement -->
      <app-vehicle-sum-insured [endorsementType]="endorsementType">
      </app-vehicle-sum-insured>
      }

      <!-- customer partner -->
      @if (endorsementTypeEInvoiceCustomerPartner())
      {
      <!-- from endorsement -->
      <app-endorsement-customer-partner [endorsementType]="endorsementType">
      </app-endorsement-customer-partner>
      }

    </div>

    <!-- <p nxCopytext="medium" class="nx-margin-top-2m">
      <i>
        By clicking NEXT, you confirm that the address submitted is complete
        and accurate to the best of your knowledge.
      </i>
    </p> -->

    <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">

      <button nxButton="secondary" type="button" (click)="goBack()">
        BACK
      </button>

      <button nxButton="primary" type="button" (click)="goNext()" [disabled]="nextButtonDisabled">
        NEXT
      </button>

      <!-- <button nxButton class="nx-margin-right-xs" (click)="openPopup('popupmakemodel')">
        Make / Model
      </button>

      <button nxButton class="nx-margin-right-xs" (click)="openPopup('popuptripdate')">
        Validation Trip date
      </button>

      <button nxButton class="nx-margin-right-xs" (click)="openPopup('popupnoclaim')">
        No Claim Discount
      </button>

      <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupmobileno')">
        Mobile no. validation
      </button>

      <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupemail')">
        Email validation
      </button> -->

    </div>
  </div>

</ng-container>

<ng-template #warningPopup let-popup_message="popup_message">
  <div class="std-ta-center">
    <div nxModalContent>
      <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
        Warning
      </h3>
      <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
        <!-- {{popup_message}} -->
        Kindly make changes to proceed for endorsement.
      </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
      OK
    </button>
  </div>
</ng-template>