<!-- Upsell Product -->
 <form [formGroup]="upsellProductForm">
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Upsell Product">
              <nx-dropdown formControlName="upSellType" (selectionChange)="onChangeUpsellProduct()">
                @for(upsellProduct of upsellProductList; track upsellProduct) {
                  <nx-dropdown-item [value]="upsellProduct.code">{{ upsellProduct.descp }}</nx-dropdown-item>
                }
              </nx-dropdown>
            </nx-formfield>
          </div>

          <div nxCol="12,12,6">
            <nx-formfield label="Amount (RM)">
              <input nxInput formControlName="upSellAmt" (change)="onChangeUpsellProductAmount()"/>
            </nx-formfield>
          </div>
        </div>
      </div>
  </form>