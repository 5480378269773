import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { Store } from '@ngxs/store';
import { EMAIL_DOCUMENT, GET_EMAIL_LISTING } from "@store/sat-motor/sat-motor.action";
import { UserResponse } from '@interfaces/user';

@Component({
  selector: 'app-email-document',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './email-document.component.html',
  styleUrl: './email-document.component.scss'
})
export class EmailDocumentComponent {
  
  emailDocumentForm: FormGroup;
  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  message: any;
  emailListing: any;
  edocConsentInd: boolean = false;
  clientName: any;
  clientEmail: any;
  agentEmail: any;
  userInfo: any;
  generateQuotation: any;
  emailDocumentResponse: any;
  isEmailDocumentClicked: boolean = false;
  contractId: any;

  constructor( @Inject(NX_MODAL_DATA) data: any,
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private componentDialogRef1: NxModalRef<any>) {
    console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
  }

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    const userType = this.userInfo.userType;
    const actionType = this.store.selectSnapshot<any>(state => state.SatMotorState.actionType);

    if (actionType === 'VIEW') {
      this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.viewQuotation);
      this.clientName = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation.clientPartner.clientName);
      this.clientEmail = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation.clientPartner.clientEmail);
      this.contractId = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation.contractId);

    } else if (actionType === 'ENDT') {
      let cngeNote: any;
      const generateQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation?.data?.cngeNoteDto ?? null);
      const sendToJPJ = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ?.data?.cngeNoteDto ?? null);

      if (!generateQuotation && !sendToJPJ) {
        cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation);
      } else {
        cngeNote = generateQuotation || sendToJPJ;
      }

      this.edocConsentInd = null; //Endt doesn't have document consent by default
      this.clientName = cngeNote.clientPartner.clientName;
      this.clientEmail = cngeNote.clientPartner.clientEmail;
      this.contractId = cngeNote.contractId;

    } else {
      this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.edocConsentInd);
      this.clientName = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step2.clientName);
      this.clientEmail = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step2.clientEmail);
      this.generateQuotation = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step4.generateQuotation);
      this.contractId = this.generateQuotation.data.contractId;
    }  
    
    if (userType === "AG") {
      if (actionType === 'VIEW') { 
        this.agentEmail = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation.mkagAgent.agentEmail);
      } else if (actionType === 'ENDT') { 
        if (this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation)) {
          this.agentEmail = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation.mkagAgent.agentEmail);
        } else {
          this.agentEmail = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step1?.policyData?.cngeNoteDto?.mkagAgent?.agentEmail);
        }
      } else {
        this.agentEmail = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.agentDetail.agentEmail);
      }
    }
    
    this.emailDocumentForm = this.fb.group({
      edocConsentInd: [this.edocConsentInd ? "true" : "false", [Validators.required]],
      clientEmail: [{ value: this.clientEmail, disabled: !this.edocConsentInd }, [Validators.required, Validators.email]],
      clientadditionalEmail: [{ value: "", disabled: !this.edocConsentInd }, [Validators.email]],
      agentEmail: [{ value: this.agentEmail, disabled: !this.edocConsentInd }, [Validators.email]]
    });

    this.emailDocumentForm.get('edocConsentInd').valueChanges.subscribe(value => {
      if (value === 'true') { 
        this.emailDocumentForm.get('clientEmail').enable(); 
        this.emailDocumentForm.get('clientadditionalEmail').enable(); 
        this.emailDocumentForm.get('agentEmail').enable();
      }
      else { 
        this.emailDocumentForm.get('clientEmail').disable(); 
        this.emailDocumentForm.get('clientadditionalEmail').disable(); 
        this.emailDocumentForm.get('agentEmail').disable();
      }
    });

    this.store.dispatch(new GET_EMAIL_LISTING(this.contractId)).subscribe((state) => {
      this.emailListing = state.SatMotorState.emailListing.data;
    });

  }

  onEmailDocumentClicked() {

    // if (!this.emailDocumentForm.valid) {
    //   this.emailDocumentForm.markAllAsTouched();
    //   return;
    // }

    let clientEmail = this.emailDocumentForm.get('clientEmail').value;
    let clientadditionalEmail = this.emailDocumentForm.get('clientadditionalEmail').value;
    let agentEmail = this.emailDocumentForm.get('agentEmail').value;

    let payload = {
      contractId: this.contractId,
      customerEmail: clientEmail,
      additionalEmail: clientadditionalEmail,
      agentEmail: agentEmail,
      operator: this.userInfo.userId
    };

    // Dispatch action to send email document
    this.store.dispatch(new EMAIL_DOCUMENT(payload)).subscribe((state) => {
      this.emailDocumentResponse = state.SatMotorState.emailDocumentResponse;
      if (this.emailDocumentResponse && this.emailDocumentResponse.success) {
        this.message = this.emailDocumentResponse.message;
      } else {
        this.message = this.emailDocumentResponse.message; //todo: to fine tune when handling failed 
      }
      this.isEmailDocumentClicked = true;
      this.store.dispatch(new GET_EMAIL_LISTING(this.contractId)).subscribe((state) => {
        this.emailListing = state.SatMotorState.emailListing.data;
      });
    });
  }

  formatData(value: string): string {
    if (!value || value === '' || value === 'NA') {
      return 'N/A';
    }
    return value;
  }

  formatDate(dateVal: any): any {
    if (!dateVal) {
      return null;
    }

    const date = new Date(dateVal);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Extract date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Extract time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return the formatted date and time in dd/mm/yyyy hh:mm:ss format
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

}
