import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { PaymentModeComponent } from '../modal/payment-mode/payment-mode.component';
import { Select, Store } from '@ngxs/store';
import { ISSUING_BANK_NAME, PAYMENT_MADE_TO, PAYMENT_MODE } from '@shared/enums/index.enum';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { DeletePaymentModeComponent } from '../modal/delete-payment-mode/delete-payment-mode.component';
import { GET_PAYMENT_MODE_LOV, GET_PAYMENT_TO_LOV, UPDATE_PAYMENT_LIST } from '@store/sat-motor/sat-motor.action';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-payment-details',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.scss'
})
export class PaymentDetailsComponent implements OnInit {
  @Select(SatMotorState.paymentList) paymentList$: Observable<any>;
  @Output() updatePaymentList = new EventEmitter<any>();
  @Input() userInfo: any;
  @Input() agentInfo: any;
  @Input() isEndorsement: any;
  @Input () paymentList: any = [];
  @Input() viewOnly: boolean;
  
  paymentModeModalRef?: NxModalRef<any>;
  deletePaymentModeModalRef?: NxModalRef<any>;
  editMode: boolean;
  paymentMadeToLov: any;
  paymentModeLov: any;
  issuingBankNameLov: any;
  isLovLoaded: boolean;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store
  ){}

  ngOnInit() {
    // if (!this.isEndorsement) {
    //   this.paymentList$.subscribe(data => {
    //     this.paymentList = [...data];
    //   });
    // }
    this.issuingBankNameLov = this.store.selectSnapshot<any>(state => state.SatMotorState.lov.bankList);

    const paymentToPayload = {
      agentCode: this.agentInfo.agentCode,
      bankBranchCode: this.agentInfo.branchCode ? this.agentInfo.branchCode : "",
      operator: this.userInfo.userId
    }

    const paymentModePayload= {
      criteria: "paymentTo",
      criteriaValue: "CC",
      bankBranchCode: this.agentInfo.branchCode ? this.agentInfo.branchCode : ""
    }


    this.store.dispatch(new GET_PAYMENT_TO_LOV(paymentToPayload)).subscribe(state => {
      this.paymentMadeToLov = state.SatMotorState.paymentToLov.filter(paymentTo => paymentTo.code === "CC");
      this.store.dispatch(new GET_PAYMENT_MODE_LOV(paymentModePayload)).subscribe(state => {
        this.paymentModeLov = state.SatMotorState.paymentModeLov;
        this.isLovLoaded = true;
      });
    });
  }

  addPaymentMode() {
    this.editMode = false;
    this.openPopupPaymentMode(null);
  }

  editPaymentMode(i) {
    this.editMode = true;
    this.openPopupPaymentMode(i);
  }

  deletePaymentModeComponent(i) {
    this.openPopupDeletePaymentMode(i);
  }

  utcDateFormart(value) {
    return `${moment(value).format('ddd').toLocaleString()} 
          ${moment(value).format('MMM').toLocaleString()} 
          ${moment(value).format('DD').toLocaleString()} 
          ${moment(value).format('hh').toLocaleString()} : 
          ${moment(value).format('mm').toLocaleString()} :
          ${moment(value).format('ss').toLocaleString()} 
          MYT
          ${moment(value).format('YYYY').toLocaleString()} 
    `
  }

  
  openPopupPaymentMode(i) {
    const paymentData = this.editMode ? this.paymentList[i] : null;
    
    this.paymentModeModalRef = this.dialogService.open(PaymentModeComponent, {
      showCloseIcon: true,
      height: '500px',
      data: {
        paymentList: this.paymentList,
        editMode: this.editMode,
        paymentData: paymentData,
        editIndex: i,
        paymentMadeToLov: this.paymentMadeToLov,
        paymentModeLov: this.paymentModeLov
      }
    });

    this.paymentModeModalRef.afterClosed().subscribe(result => {
      if(result) {
        this.handlePaymentListStateChange(result);
      }
    });
  }

  openPopupDeletePaymentMode(i) {
    this.deletePaymentModeModalRef = this.dialogService.open(DeletePaymentModeComponent, {
      showCloseIcon: true,
      autoFocus: false,
    });

    this.deletePaymentModeModalRef.afterClosed().subscribe(result => {
      if(result) {
        let paymentListCopy = [...this.paymentList];
        paymentListCopy.splice(i, 1);
        this.handlePaymentListStateChange(paymentListCopy);
      }
    });
  }

  private handlePaymentListStateChange(paymentList) {
    this.store.dispatch(new UPDATE_PAYMENT_LIST({isEndorsement:this.isEndorsement, paymentList:paymentList})).subscribe(state => {
      let updatedPaymentList = {};
      if(!this.isEndorsement) {
        updatedPaymentList = state.SatMotorState.motor.step4.paymentList;
      }else {
        updatedPaymentList = state.SatMotorState.endorsement.step3.paymentList
      }
      this.updatePaymentList.emit(updatedPaymentList);
    });
  }

  getPaymentToDescp(paymentToVal) {
    return this.paymentMadeToLov.find(paymentTo => paymentTo.code === paymentToVal).descp;
  }

  getPaymentModeDescp(paymentModeVal) {
    return this.paymentModeLov.find(paymentMode => paymentMode.code === paymentModeVal).descp;
  }

  getIssuingBankNameDescp(issuingBankNameVal) {
    return this.issuingBankNameLov.find(issuingBankName => issuingBankName.code === issuingBankNameVal).descp;
  }

  disableButton() {
    return this.userInfo.issueOnQuotation;
  }

  // will uncomment after payment information complete
   // paymentInformation() {
  //   const list = this.paymentList;
  //   let cngeNote;

  //   if (this.fromView) cngeNote = this.store.selectSnapshot((state) => state.ProductSummaryState?.productSummary);
  //   else cngeNote = this.store.selectSnapshot((state) => state.CoverageState?.premiumDetails?.cngeNote);

  //   if (list) {
  //     const totalAmount = list.filter((res) => list);

  //     const fireCoversWithoutProfitShieldSumInsured = totalAmount.map((res) => parseFloat(res.amount));
  //     let total = fireCoversWithoutProfitShieldSumInsured.reduce((num1, num2) => num1 + num2, 0);

  //     return {
  //       insuredName: cngeNote.insuredName,
  //       totalAmountPayable: cngeNote?.premiumDue,
  //       totalAmountPayableRounded: cngeNote?.premiumDueRounded,
  //       totalAmountPaidByCustomer: total,
  //       balancePayable: cngeNote.premiumDueRounded - total,
  //     };
  //   }
  //   return {
  //     insuredName: cngeNote.insuredName,
  //     totalAmountPayable: cngeNote?.premiumDue,
  //     totalAmountPayableRounded: cngeNote?.premiumDueRounded,
  //     totalAmountPaidByCustomer: '00.00',
  //     balancePayable: cngeNote?.premiumDue,
  //   };
  // }
}
