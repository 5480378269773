<div class="cross_sell_banner">
    <h1 class="title std-ta-center nx-font-weight-semibold">Your customers do not have the following coverage, get them
        covered?
    </h1>
    <div nxLayout="grid maxwidth" class="nx-margin-top-2m scroll">
        <div nxRow>
            @for(crossSell of crossSellListPerPage; track crossSell) {
                @if(crossSell) {
                <div nxCol="12,12,12,6">
                    <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-s nx-font-weight-semibold">{{ crossSell.productName }}</h4>
                    <div nxLayout="grid nopadding">
                        <div nxRow rowAlignItems="center" class="banner_color">
                            <div nxCol="12" class="banner_img">
                                <img class="img" [src]="crossSell.bannerImg">
                
                            </div>
                            <!-- <div nxCol="6" class="content_text"> -->
                                <!-- <p nxCopytext="normal"><span class="orange_text nx-font-weight-bold">Instant special bonus
                                        of up to 40%</span>
                                    <span class="nx-font-weight-semibold"> for Individual Private Car Comprehensive
                                        Customers.</span>
                                </p>
                                <p nxCopytext="small" class="nx-margin-top-3xs">Campaign period: 1 Apr - 31 Dec 2023</p>
                                <p nxCopytext="small" class="nx-margin-top-3xs">*Terms & conditions apply.</p> -->
                            <!-- </div> -->
                        </div>
                        <div nxRow rowAlignItems="center,center,center" class="nx-margin-top-s buttons">
                            <div nxCol="12" class="buttons_container">
                                <button nxButton="primary" type="button" (click)="openPopUpMarketingMaterial(crossSell)"
                                    [disabled]="disableMartketingAndIssueNew()">SEND MARKETING MATERIAL</button>
                                <button nxPlainButton type="button" class="float_right m_padding_top_10" (click)="getCSRedirectURL(crossSell)"
                                    [disabled]="disableMartketingAndIssueNew()">
                                    <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>ISSUE NEW
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            }
            
        </div>
    </div>
    <div nxLayout="grid" class="banner_pagination nx-margin-top-2m">
        <div nxRow>
            <div nxCol="12">
                <nx-pagination [count]="slides" [page]="activeSlide" [perPage]="perPage" type="slider" (goPrev)="prevPage()"
                    (goNext)="nextPage()" (goPage)="goToPage($event)">
                </nx-pagination>
            </div>
        </div>
    </div>

</div>