import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EnquiryComponent } from '../enquiry.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { UserResponse } from '@interfaces/user';
import { CALCULATE_PREMIUM, CANCEL_COVER_NOTE, CANCEL_QUOTATION, CANCEL_REPLACE, COPY_CNGE_INFO, COPY_CNGE_INFO_VIEW_QUOTATION, COPY_CNGE_TO_ENDORSEMENT_STATE, DOWNLOAD_PDF, GENERATE_ENDORSEMENT, GET_CHECKLIST_LOV, GET_DOCUMENT_LOV, GET_PRODUCT_LIST, GET_QUOTATION_DETAIL, GET_QUOTATION_DETAIL_REQUOTE, GET_SWITCH_SITE, RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ, SEARCH_ENDT_VEHICLE, SET_ACTION } from '@store/sat-motor/sat-motor.action';
import { firstValueFrom } from 'rxjs';
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { ViewRemarksComponent } from '@shared/components/view-remarks/view-remarks.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import { ACTIONS } from '@shared/enums/index.enum';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { ClauseMessageComponent } from '@shared/components/modal/clause-message/clause-message.component';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { EmailDocumentComponent } from '@shared/components/modal/email-document/email-document.component';
import { ChecklistComponent } from '@shared/components/checklist/checklist.component';
import { EndorsementNarrationComponent } from '@shared/components/endorsement-narration/endorsement-narration.component';
import { RemarksComponent } from '@shared/components/modal/remarks/remarks.component';

@Component({
  selector: 'app-view-endorsement',
  standalone: true,
  imports: [
    EnquiryComponent,
    SharedModule,
    CoverageSummaryComponent,
    ViewRemarksComponent,
    ChecklistComponent,
    EndorsementNarrationComponent
  ],
  templateUrl: './view-endorsement.component.html',
  styleUrl: './view-endorsement.component.scss'
})
export class ViewEndorsementComponent implements OnInit {
  
  @Select(SatMotorState.commonLov) commonLov$;
  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  clauseMsgModal?: NxModalRef<ClauseMessageComponent>;
  dialogRef?: NxModalRef<any>;
  emailDocumentModal?: NxModalRef<EmailDocumentComponent>;
  remarksModal?: NxModalRef<RemarksComponent>;
  
  enquiryDetail: any;
  type: any;
  popupMessage: any;
  referralReasonList: any;
  status: any;
  coverNoteText: any;
  agentData: any;
  progressType: any;
  issuanceInfo: any;
  cpData: any;
  premiumInfo: any;
  claimList: any;
  endorsementHistoryList: any;
  checkListItems: any;
  checklistNote: any;
  checklistRemark: any;
  remarksInfo: any; 

  viewQuotation: boolean = false;
  isEndorsement: boolean = false;
  isDataLoaded: boolean = false;
  queryParams: any = {};
  userInfo: any = {};
  lov: any;
  showRemarks: boolean = false;
  cngeNote: any;
  responseData: any;
  classType: any;
  proposalNo: any;
  coverageInfo: any;
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  productList: any;
  endorsementList: any;
  productName: any;
  endtPolicy: any;
  checklistLov: any;
  endtCode: any;
  viewForm: FormGroup;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;

  isShowBackBtn: boolean = false;
  isShowRequestCancellationBtn: boolean = false;
  isShowUpdateQuotationBtn: boolean = false;
  isShowSendToJPJBtn: boolean = false;
  isShowUploadDocumentBtn: boolean = false;
  isShowAddRemarksBtn: boolean = false;
  isUpdatePaymentBtn: boolean = false;
  isShowDownloadQuotationBtn: boolean = false;
  isShowDownloadCoverNoteBtn: boolean = false;
  isShowDownloadInvoiceBtn: boolean = false;
  isShowUpdateJPJBtn: boolean = false;
  isShowAmendCoverNoteBtn: boolean = false;
  isShowEmailDocumentBtn: boolean = false;
  isShowRequoteBtn: boolean = false;
  isShowMarketingBtn: boolean = false;
  isShowIssueNewBtn: boolean = false;

  statusMap = {
    PRINTED: "Printed",
    APPROVED: "Approved",
    PENDING: "Pending Approval",
    CANCELLED: "Cancelled",
    SUBMITTED: "Submitted",
    ACTIVE: "Active",
    EXPIRED: "Expired"
  };

  classTypeMap = {
    PRINTED: "printed_badge",
    APPROVED: "approved_badge",
    PENDING: "pending_badge",
    CANCELLED: "cancel_badge",
    SUBMITTED: "submitted_badge",
    ACTIVE: "active_badge",
    EXPIRED: "expired_badge"
  };

  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;
  
  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder
  ) {}

  async ngOnInit() {

    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.isEndorsement = true;

    this.viewForm = this.fb.group({
      checklistForm: new FormGroup({}),
    });

    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
      }
    });

    await this.searchEndorsementByContractId();

    this.store.dispatch(new GET_CHECKLIST_LOV(this.cngeNote.endtCode)).subscribe(state => {
      this.checklistLov = state.SatMotorState.checklistLov;
    });

    this.type = 'view-endorsement-enquiry';
  }

  searchEndorsementByContractId() {
    return new Promise((resolve) => {
      this.store.dispatch(new GET_QUOTATION_DETAIL(this.queryParams.contractId)).subscribe((state) => {
        this.populateCommonData(state, null);

        this.showRemarks = !!this.remarksInfo;
        this.showComponents();
        this.getDocumentListLov();
        this.updateButtons(this.cngeNote, this.issuanceInfo.issuance);
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
        resolve(true);
      });
    });
  }

  getDocumentListLov() {
    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.endtPolicy.productCode,
      productType: ""
    }));
  }

  populateCommonData(state: any, sendJPJResponse: any) {
    this.endtPolicy = state.SatMotorState.viewQuotation;
    this.responseData = state.SatMotorState.viewQuotationData;
    this.cngeNote = this.responseData.cngeNote;
    this.status = this.statusMap[this.cngeNote.cnoteStatus] || "";
    this.classType = this.classTypeMap[this.cngeNote.cnoteStatus] || "";
    this.proposalNo = sendJPJResponse ? sendJPJResponse.cnoteNo : this.cngeNote.proposalNo || this.cngeNote.cnoteNo;
  
    this.agentData = this.responseData.agentData;
    this.issuanceInfo = this.responseData.issuanceInfo;
    this.cpData = this.responseData.cpData;
    this.coverageInfo = { ...this.responseData.coverageInfo };
    this.coverageInfo.coverageDetails = {
      ...this.coverageInfo.coverageDetails,
      avInd: this.endtPolicy.riskList[0].avInd
    };

    //this.premiumInfo = this.responseData.premiumInfo;
    this.premiumInfo = {
      ...this.responseData.coverList,
      stamp: this.endtPolicy.stamp,
      premiumDue: this.endtPolicy.premiumDue,
      premiumDueRounded: this.endtPolicy.premiumDueRounded
    }
    this.remarksInfo = this.responseData.remarksInfo;
  
    this.clientClaimHistory = this.cngeNote.claimCnt ?? '0';
    this.vehicleClaimHistory = this.cngeNote.vehClaimCount ?? '0';
  
    this.updateProductList(this.responseData.mkagAgent.agentCode, this.cngeNote.productCode);
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.setStatusMessage(this.endtPolicy.cnoteStatus);
    this.setReferralCode(this.cngeNote.referList)
  }

  setReferralCode(referList: any) {
    if(referList && referList.length > 0) {
      this.referralReasonList = referList;
    } else {
      this.referralReasonList = [];
    }
  }

  setStatusMessage(status) {
    if (status === 'PENDING') {
      this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';
    } else if (status === 'CANCELLED') {
      this.popupMessage = 'Endorsement has been cancelled successfully.';
    } else {
      this.popupMessage = null;
    }
    
  }

  async updateProductList(agentCode, productCode) {
    this.productList = this.lov?.productList;
    if (!this.productList) {
      let productListPayload = {
        lob: 'MT',
        agentCode: agentCode
      };

      await firstValueFrom(this.store.dispatch(new GET_PRODUCT_LIST(productListPayload)));
      const updatedLov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
      this.productList = updatedLov.productList;
      this.endorsementList = updatedLov.endorsementList;
    }

    let product = this.productList.find(e => e.code === productCode);
    if (product) {
      let descp = product.descp
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
      console.log(this.productList);
      this.productName = {
        descp: descp
      }
    }

    this.getCoverDescription(productCode);
    this.isDataLoaded = true;
  }

  getCoverDescription(productCode) {
    const productAccessConfigList = this.lov?.productAccessConfigList;
    if (!productAccessConfigList) return;

    const coverListLov = productAccessConfigList.reduce((list, product) => {
      return list.concat(product[productCode]?.coverList || []);
    }, []);

    const coverCodeVal = this.coverageInfo?.coverageDetails?.coverCode;

    if (coverCodeVal) {
      const matchingCover = coverListLov.find(cover => cover.coverCode === coverCodeVal);
      if (matchingCover) {
        const updatedCoverageDetails = {
          ...this.coverageInfo.coverageDetails,
          coverDescription: matchingCover.coverDescription
        };

        this.coverageInfo = {
          ...this.coverageInfo,
          coverageDetails: updatedCoverageDetails
        };
      }
    }
  }

  showComponents() {
    this.showAgentInfo = true;
    this.showIssuanceInfo = true;
    this.showCustomerPartner = true;
    this.showCoverage = true;
    this.showHistoricalClaim = true;
    this.showPremiumInformation = true;
    this.viewQuotation = true;
    this.progressType = 'endorsement';

    // this.showPayment = true;
    // this.showPaymentInformation = true;
    // this.showPaymentDetails = true;
    // this.showChecklist = true;
    // this.showEndorsementNarration = true;
    // this.showRoadTaxPayable = true;
  }

  updateButtons(cngeNote: any, issuance: any) {
    const status = cngeNote.cnoteStatus;
    const isPendingApproval = status === 'PENDING';
    const isPrintQuotation = status === 'PRINTED' && cngeNote.proposalNo && !cngeNote.cnoteNo;
    const isPrintCNote = status === 'PRINTED' && cngeNote.cnoteNo;
    const isCancelled = status === 'CANCELLED';
    const isSubmitted = status === 'SUBMITTED';

    cngeNote = { ...cngeNote, isPendingApproval, isPrintQuotation, isPrintCNote };
    this.cngeNote = { ...cngeNote };

    this.isShowBackBtn = true; //enabled for all cnoteStatus

    if (isPendingApproval) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote, true),
        isShowAddRemarksBtn: !!this.userInfo.agent && this.userInfo.agent === true,
        isUpdatePaymentBtn: true,
        isShowUploadDocumentBtn: true,
      });
    } else if (isPrintQuotation) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote, false),
        isShowUpdateQuotationBtn: true,
        isShowDownloadQuotationBtn: true,
        isShowSendToJPJBtn: !!this.userInfo.agent && this.userInfo.agent === true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true
      });
    } else if (isPrintCNote) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote, false),
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true
      });
    } else if (isSubmitted) {
      Object.assign(this, {
        isUpdatePaymentBtn: true,
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
      });
    }
  }

  showRequestCancellation(cngeNote: any, checkAgent: boolean): any {
    const {
      cnoteType, jpjStatus, isPrintCNote
    } = cngeNote;

    let hideButton = false;
    let isAgentLogin = !!this.userInfo.agent && this.userInfo.agent === true;
    const allowedEndorsements = ['OTTEMP', 'COE', 'XP', 'XS'];

    if (jpjStatus === 'SENT') {
      hideButton = true;
    } else if (checkAgent && !isAgentLogin) {
      hideButton = true;
    } else if (isPrintCNote && !allowedEndorsements.includes(cnoteType)) {
      hideButton = true;
    }

    return !hideButton;
  }


  showUpdatePayment(cngeNote: any): any {
    const isCallCentreOrBancaAgent = this.userInfo.callCentreOrBancaAgent;
    const userId = this.userInfo.userId;
    const {
      cnoteStatus, issueCngeNote,
      issueEndorsement, createBy
    } = cngeNote;

    let hideButton = false;

    if (!isCallCentreOrBancaAgent ||
      cnoteStatus === "CANCELLED" ||
      (cnoteStatus === "PENDING" && (issueCngeNote === true || issueEndorsement === true) && createBy !== userId)) {
      hideButton = true;
    }

    return !hideButton;
  }

  openRequestCancellationPopUp() {
    let data = {
      summaryData: this.cngeNote
    }

    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if(result) {
        let payload = {
          cancelRemarks: result.data.cancelRemarks,
          contractId: this.cngeNote.contractId,
          operator: this.userInfo.userId
        };

        if (result.isCancelAndReplace) {
          this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
            let cancellationRes = state.SatMotorState.data.cancellationRes;
            this.store.dispatch(new COPY_CNGE_INFO(cancellationRes)).subscribe(state => {
                this.router.navigate(['endorsement/summary']);
            });
          });
          
        } else {
          if(this.cngeNote.isPrintQuotation) {
            this.store.dispatch(new CANCEL_QUOTATION(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchEndorsementByContractId();
              }
            });
          } else {
            this.store.dispatch(new CANCEL_COVER_NOTE(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchEndorsementByContractId();
              }
            });
          }
        }
      }
    });
  }

  updatePayment() {

  }

  performEndtVehicleSearch(vehicleNo) {
    if (vehicleNo != "") {
      const param = {
        "cngeNote": {
          "productType": "MT",
          "vehicleNo": vehicleNo
        }
      };

      this.store.dispatch(new SEARCH_ENDT_VEHICLE(param)).subscribe((res) => {
        let endtError = (res && res.SatMotorState?.endorsement?.step1?.endtVehicleSearchError) ?? null;
        let endtPolicy = (res && res.SatMotorState?.endorsement?.step1?.endtVehicleData) ?? null;

        if (endtPolicy && endtPolicy.mkagAgent) {
          this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: endtPolicy.mkagAgent.agentCode })).subscribe(state => {
            this.populateEndtStateData();
          });
        }

        if (endtError && endtError.message) {
          console.log("message: " + endtError.message);
          this.showOkMessage(endtError.message);
        }
      });
    }
  }

  populateEndtStateData() {
    this.store.dispatch(new COPY_CNGE_TO_ENDORSEMENT_STATE(null)).subscribe(state => {
      this.router.navigate(["/endorsement/summary"]);
    });
  }

  updateQuotation() {
    this.store.dispatch(new SET_ACTION(ACTIONS.ENDT));
    this.performEndtVehicleSearch(this.endtPolicy.riskList[0].vehicleNo);
  }

  emailDocument() {
    let innerWidth = window.innerWidth;
    let popupWidth;
    console.log(innerWidth);
    if (innerWidth < 703) {
      popupWidth = "680";
    } else if (innerWidth >= 703 && innerWidth < 991) {
      popupWidth = "680";
    } else {
      popupWidth = "1100px";
    }
    // return
    this.emailDocumentModal = this.dialogService.open(EmailDocumentComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { popup_type: 'emaildocument' },
    });
  }

  redirectBack() {
    this.redirectToSummary();
  }

  redirectToSummary() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe(state => {
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });
  }

  downloadPDF(type) {
    let payload = {
      operator: this.userInfo.userId,
      contractId: this.cngeNote.contractId,
      type: type
    };

    this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe(() => {
    });
  }

  checkDownloadPDF(type) {
    let endtNo = this.endtPolicy.endtNo ?? null;

    if (!endtNo) {
      this.showOkMessage('Endorsement not generated yet, please try again.');
    
    } else {
      const payload: any = {
        operator: this.userInfo.userId,
        contractId: this.cngeNote.contractId,
        type: type,
      };

      this.clauseMsgModal = this.dialogService.open(ClauseMessageComponent, {
        width: '450px',
        maxWidth: '450px',
      });
      this.clauseMsgModal.afterClosed().subscribe(result => {
        payload.printClauseInd = (result === 'Y');
        this.store.dispatch(new DOWNLOAD_PDF(payload));
      });
    }
  }

  sendToJPJ() {
    let resetPayload = {
      isEndorsement: true
    };

    this.endtPolicy = {
      ...this.endtPolicy,
      cnoteDate: new Date().toISOString().substring(0, 10),
      issueBy: this.userInfo.userId,
      nettPremium: this.endtPolicy.riskList[0].coverList[0].nettPremium
    };

    let payload = {
      policy: this.endtPolicy,
      endtType: this.endtPolicy.cnoteType,
      userId: this.userInfo.userId
    }

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    this.store.dispatch(new GENERATE_ENDORSEMENT(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ ?? null);

      if (response && response.data && response.success) {
        this.searchEndorsementByContractId();
      } else {
        this.showResponseError(response);
      }
    });
  }

  showResponseError(response: any) {
    console.log('showResponseError response:' + response);
    let message = (response.message && response.message != '') ? response.message : (response.errorCode && response.errorCode != '') ? response.errorCode : null;

    if (message) {
      this.showOkMessage(message);
    }
  }

  showOkMessage(message) {
    this.okMessageModal = this.dialogService.open(OkMessageComponent, {
      width: '400px',
      data: {
        message: message
      }
    });
  }

  uploadDocument() {
    let dialogConfig = {
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      height: '96%',
      width: '90%',
      panelClass: 'uploadDocumentDialogCss',
      data: {
        contractId: this.endtPolicy.contractId
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {  }
    });
  }
  
  getAdminRemarks(referAdminRemarkList: any) {
    let list = [];
    referAdminRemarkList.forEach((remark, index) => {
        let combinedString = `${index + 1}. ${remark.createdBy} ${this.getFormattedDateTime(remark.createDate)}: ${remark.referAction}`;
        list.push(combinedString);
    });
    return list;
  }

  addRemarks() {
    this.remarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Enter Remarks",
        primaryButtonLabel: "SUBMIT"
      }
    });

    this.remarksModal.afterClosed().subscribe(result => {
      if(result) {
        const data = {
          contractId : this.endtPolicy.contractId,
          referRemarks: result.remarks,
          operator: this.userInfo.userId,
        };
        //this.addAdminRemarks(data);
      }
    });
  }

  getFormattedDate(dateVal) {
    if (!dateVal) {
      return dateVal;
    }

    return moment(dateVal, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  getFormattedDateTime(dateString: string): string {
    if(!dateString){
      return dateString;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  }

  hasCoverNoteGenerated() {
    return this.cngeNote.cnoteNo && this.cngeNote.cnoteNo != '';
  }

  get coverNoteNumber() {
    return this.cngeNote.cnoteNo;
  }
}
