import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";

import { MOTOR_COMMON, STEP1 } from "src/app/interfaces/motor";
import { HttpHelperService } from "src/app/shared/services/http-helper/http-helper.service";
import { ACTIONS } from "@shared/enums/index.enum";
import { VALIDATE_POI_DATE } from "./sat-motor.action";

@Injectable({
  providedIn: "root",
})
export class SatMotorService {
  constructor(private httpHelperService: HttpHelperService) {}

  fetchLOV(): Observable<MOTOR_COMMON.lovResponse> {
    return this.httpHelperService.get("/common/lov?lob&mainProdType=MT");
  }

  getProductConfigLov(body) {
    const { lob, productCode, agentCode, partnerId, partnerVersionNo } = body;
    const data: any = { lob: lob, productCode: productCode, agentCode: agentCode, partnerId: partnerId, partnerVersionNo: partnerVersionNo };
    return this.httpHelperService.get('/common/productConfiguration', data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/lov-type-mock.json`);
  }

  getProductListLov(payload) {
    return this.httpHelperService.get('/common/productConfiguration', payload);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/product-list-lov.json`);
  }

  getVehicleUseLov(payload) {
    return this.httpHelperService.get("/common/vehUse", payload);
  }

  fetchAgentListAgent(payload): Observable<STEP1.AgentResponse> {
    return this.httpHelperService.get("/agent/list", payload);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-list-mock.json`);
  }

  fetchAgentListAgentMock(payload): Observable<STEP1.AgentResponse> {
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-list-mock.json`);
  }

  getAgentDetail(data) {
    return this.httpHelperService.post('/agent/detail', data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-detail-mock.json`);
  }

  getBranchList(userId): Observable<any> {
    if (userId) {
      const data = {
        listTypes: ["userBranchList"],
        operator: userId,
      };
      return this.httpHelperService.post('/common/lov/type', data);
      // return this.httpHelperService.getDirectUrl(`/assets/data-mock/lov-type-mock.json`);
    }
    return of({
      data: {
        userBranchList: [],
      },
    });
  }

  getAgentListByBranch(operator: string, branchCode: string): Observable<any> {
    const body = {
      operator,
      branchCode,
    };
    //return this.httpHelperService.post(`/common/agents`, body);
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/lov-type-mock.json`);
  }

  getDirectUrlFromSubMenu(body): Observable<any> {
    return this.httpHelperService.getDirectUrl(body);
  }

  getNCD(data): Observable<any> {
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/get-ncd-mock.json`);
    // return this.httpHelperService.post("/ncd/enquiry", data);
  }

  getCustomerDetail(data): Observable<any> {
    return this.httpHelperService.get("/cp/detail", data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/customer-partner-mock.json`);
  }

  getRedirectPage(body): Observable<any> {
    const { operator, productType, agentCode, action, documentNo, contractId, cnoteType, productCode, idType, idValue, clientType, notificationId, enquiryProduct, searchCriteria, searchValue } = body;
    let url = action === ACTIONS.PROFILE ? `/ud/switching?switch&userId=${operator}` : `/session/switching?userId=${operator}`;
    url += agentCode ? `&agentCode=${agentCode}` : "";
    url += productType ? `&productType=${productType}` : "";
    url += productCode ? `&productCode=${productCode}` : "";
    url += action ? `&action=${action}` : "";
    url += documentNo ? `&documentNo=${documentNo}` : "";
    url += contractId ? `&contractId=${contractId}` : "";
    url += cnoteType ? `&cnoteType=${cnoteType}` : "";
    url += idType ? `&idType=${idType}` : "";
    url += idValue ? `&idValue=${idValue}` : "";
    url += clientType ? `&clientType=${clientType}` : "";
    url += notificationId ? `&notificationId=${notificationId}` : "";
    url += enquiryProduct ? `&enquiryProduct=${enquiryProduct}` : "";
    url += searchCriteria ? `&searchCriteria=${searchCriteria}` : "";
    url += searchValue ? `&searchValue=${searchValue}` : "";

    return this.httpHelperService.get(url);
  }

  validateCP(data): Observable<any> {
    return this.httpHelperService.post("/cp/validate", data);
  }

  getMakeOrModel(data) {
    return this.httpHelperService.get('/common/search/nvicmodel', data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/make-model-list-mock.json`);
  }

  calculatePremiumPayload(payload) {
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/premium-request-mock.json`);
  }

  calculatePremium(payload) {
    // if (payload.cngeNote.riskList[0].coverList[0].coverCode === '01') 
    //   return this.httpHelperService.getDirectUrl(`/assets/data-mock/premium-comprehensive-mock.json`);
    // else if (payload.cngeNote.riskList[0].coverList[0].coverCode === '20') 
    //   return this.httpHelperService.getDirectUrl(`/assets/data-mock/premium-tp-mock.json`);
    // else if (payload.cngeNote.riskList[0].coverList[0].coverCode === '30') 
    //   return this.httpHelperService.getDirectUrl(`/assets/data-mock/premium-tpft-mock.json`);
    return this.httpHelperService.post('/cn/calculate/premium', payload);
  }

  calculateEndtPremium(payload): Observable<any> {
    return this.httpHelperService.post('/endt/calculate/Premium', payload);
  }

  getCPData(payload) {
    //return this.httpHelperService.get('/cn/', data);
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/customer-partner-mock.json`);
  }

  generateQuotation(data): Observable<any> {
    return this.httpHelperService.post('/cn/generate/quotation', data);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/generate-quotation-mock.json`);
  }

  sendToJPJ(data, isRTP, isEndorsement): Observable<any> {
    if (isRTP) {
      return this.httpHelperService.post('/roadtax/sendToJPJ', data);

    } else if (isEndorsement) {
      return this.httpHelperService.post('/endt/sendToJPJ', data);

    } else {
      return this.httpHelperService.post('/cn/sendToJPJ', data);
    }
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/send-to-jpj-mock.json`);
  }

  generateEndorsement(payload): Observable<any> {
    return this.httpHelperService.post('/endt/sendToJPJ', payload);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/endt-generate-quotation-mock.json`);
  }

  generateEndorsementQuotation(payload): Observable<any> {
    return this.httpHelperService.post('/endt/save/quotation', payload);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/endt-generate-quotation-mock.json`);
  }

  searchVIX(payload) {
    return this.httpHelperService.post('/cn/enquire/vix', payload);
  }

  searchRoadTax(payload) {
    return this.httpHelperService.post('/roadtax/search/policy', payload);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/request-cancellation-response-mock.json`);
  }
  
  searchEndorsementVehicle(payload): Observable<any> {
    return this.httpHelperService.post('/endt/search/policy', payload);
  }

  enquireEndorsementNarration(payload): Observable<any> {
    return this.httpHelperService.post('/endt/enquire/narration', payload);
  }

  postPolicyMovemementTableData(payload): Observable<any> {
    return this.httpHelperService.post('/endt/listing', payload);
  }

  searchActiveQuotation(data) {
    let url = `/cn/search/active/quotation?`;
    url += data.vehicleNo ? `vehicleNo=${data.vehicleNo}` : "";
    url += data.idNo ? `&idNo=${data.idNo}` : "";
    url += data.agentCode ? `&agentCode=${data.agentCode}` : "";
    console.log("url : " + url);
    return this.httpHelperService.get(url);
    //return this.httpHelperService.get(`/cn/update/referremarks`, data);
  }

  saveReferralRemarks(data): Observable<any> {
    return this.httpHelperService.post(`/cn/update/referremarks`, data);
  }

  downloadPDF(payload) {
    let path = `/printing?contractId=${payload.contractId}&type=${payload.type}`;
  
    if (payload.hasOwnProperty('printClauseInd')) {
      path += `&printClauseInd=${payload.printClauseInd}`;
    }
  
    let downloadPath = this.httpHelperService.getURLString(path);
    console.log("Download Path: " + downloadPath);
    return window.open(downloadPath, '_blank');
  }

  generateBlob(res) {
    const base64Data = res.file.data;
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: res.file.mime });

    return blob;
  }

  downloadPDFUsingBlob(blob) {
    const link = document.createElement("a");
    console.log("Blob size:", blob.size);
    console.log("Blob type:", blob.type);

    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = "example.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  getPreviousPolicyNo(data) {
    //return this.httpHelperService.get('/<PENDING_API>', data);
    return this.httpHelperService.post(`/policy/search/detail`, data);
    //return this.httpHelperService.getDirectUrl(`/assets/data-mock/previousInfo.json`);
  }

  validateEndtType(payload) {
    return this.httpHelperService.post('/endt/validation', payload);
  }

  requestCancellation(data): Observable<any> {
    return this.httpHelperService.post(`/cn/cancel`, data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/request-cancellation-response-mock.json`);
  }

  cancelReplace(data): Observable<any> {
    return this.httpHelperService.post(`/cn/cancel/replace`, data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/request-cancellation-response-mock.json`);
  }

  saveAsDraft(payload) {
    return this.httpHelperService.post('/cn/save/draft', payload);
  }

  cancelDraft(payload) {
    return this.httpHelperService.post('/cn/draft/cancel', payload);
  }

  deleteDocumentUploaded(body) {
    return this.httpHelperService.post('/doc/delete', body)
  }

  downloadDocument(docId) {
    return this.httpHelperService.downloadFile(`/doc/download?docId=${docId}`);
  }

  initiateUploadDocument(body) {
    return this.httpHelperService.post('/doc/initiate', body)
  }

  commitUploadDocument(body) {
    return this.httpHelperService.post('/doc/commit', body)
  }

  uploadDocuments() {
    return this.httpHelperService.postXmlRequest('/doc/upload');
  }

  getAllUpaloadedDocuments(contractId) {
    return this.httpHelperService.get(`/doc/list?contractId=${contractId}`);
  }

  getDocuments(body) {
    // return this.httpHelperService.get(`/cn/documents?key=${body.key}&value=${body.value}`);
    return this.httpHelperService.getDirectUrl("/assets/data-mock/upload-documents-mock.json");
  }

  saveCustomerPartner(payload) {
    return this.httpHelperService.post('/cp/save', payload);
  }

  getUpsellProductLov(body) {
    const { productType, listTypes, agentCode, productCode } = body;
    const data = { listTypes: listTypes, productType: productType, agentCode: agentCode, productCode: productCode };
    return this.httpHelperService.post('/common/lov/type', data);
  }

  getPaymentLov(payload) {
    return this.httpHelperService.post('/common/lov/payment', payload);
  }

  getPostcodeLov(body) {
    const { productType, listTypes, agentCode, productCode } = body;
    const data = { listTypes: listTypes, productType: productType, agentCode: agentCode, productCode: productCode };
    return this.httpHelperService.post('/common/lov/type', data);
  }

  getPostcodeInfo(code) {
    return this.httpHelperService.get(`/common/postcode/detail?postcode=${code}`); 
  }

  getCrossSellList(payload) {
    return this.httpHelperService.post('/customer/crossSellList', payload);
  }

  getProductPDF(payload) {
    // return this.httpHelperService.getDirectUrl("/assets/data-mock/marketing-material-doc-list-mock.json");
    return this.httpHelperService.post('/cms/folder/structuredcontent/folders-contents', payload);
  }

  saveMessageLog(payload): Observable<any> {
    return this.httpHelperService.post('/customer/crossSell/msgSent', payload);
  }

  saveEmailLog(payload): Observable<any> {
    return this.httpHelperService.post('/customer/crossSell/emailSent', payload);
  }

  getCCentricOpportunityInfo(payload) {
    return this.httpHelperService.get(`/occquotation/${payload.opportunityId}`);
  }

  getSwitchSite(payload){
    return this.httpHelperService.get("/session/sat-motor/"+payload); 
  }

  getDraftDetails(payload){
    return this.httpHelperService.post("/cn/search/active/quotation", payload); 
  }
  
  getQuotationDetail(payload){
    return this.httpHelperService.get("/cn/detail?contractId=" + payload); 
  }
  
  getQuotationDetailToRequote(payload, action){
    return this.httpHelperService.get("/cn/detail?contractId=" + payload+"&ACTION="+action); 
  }

  getQuotationDetailToRequoteNoAction(payload){
    return this.httpHelperService.get("/cn/detail?contractId=" + payload); 
  }

  getViewPolicy(payload) {
    return this.httpHelperService.get("/policy/detail?polNo="+ payload);
  }

  getViewClaim(claimNo, policyNo) {
    return this.httpHelperService.get("/policy/detail/claim?claimNo=" + claimNo +"&polNo=" + policyNo);
  }

  searchClaimEnquiry(body): Observable<any> {
    return this.httpHelperService.post(`/policy/search`, body);
  }

  getContentId(payload){
    return this.httpHelperService.post("/cms/category/code", payload); 
  }

  getNcdConfirmation(payload){
    return this.httpHelperService.post("/ncd/search/confirmation", payload.value); 
  }

  productValidation(data): Observable<any> {
    return this.httpHelperService.post("/product/validation", data);
  }

  cancelQuotation(data): Observable<any> {
    return this.httpHelperService.post(`/cn/cancelProposal`, data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/request-cancellation-response-mock.json`);
  }

  cancelCoverNote(data): Observable<any> {
    return this.httpHelperService.post(`/cn/cancel`, data);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/request-cancellation-response-mock.json`);
  }

  validatePoiDate(payload: any): Observable<any> {
    return this.httpHelperService.post(`/cn/product/validation`, payload);
  }

  updateIsmPolicy(payload: any): Observable<any> {
    return this.httpHelperService.post(`/cn/ism/update`, payload);
  }

  getNcdEnquiry(payload){
    return this.httpHelperService.post("/ncd/enquiry", payload); 
  }

  searchPolicyEnquiry(data) {
    let url = `/policy/search/criteria?`;
    url += data.policyNo ? `&policyNo=`+data.policyNo : "";
    url += data.vehicleNo ? `&vehicleNo=`+data.vehicleNo : "";
    url += data.customerName ? `&customerName=`+data.customerName : "";
    url += data.idType ? `&idType=`+data.idType : "";
    url += data.idValue ? `&idValue=`+data.idValue : "";

    return this.httpHelperService.get(url);
  }

  emailDocument(data) {
    let url = `/email/sendAttachmentEmail?`;
    url += data.contractId ? `contractId=` + data.contractId : "";
    url += data.customerEmail ? `&customerEmail=` + data.customerEmail : "";
    url += data.additionalEmail ? `&additionalEmail=` + data.additionalEmail : "";
    url += data.agentEmail ? `&agentEmail=` + data.agentEmail : "";
    url += data.operator ? `&operator=` + data.operator : "";

    return this.httpHelperService.get(url);
  }

  getEmailListing(data) {
    let url = `/email/listing?`;
    url += data ? `contractId=` + data : "";

    return this.httpHelperService.get(url);
  }

  getDocumentTypeLov(){
    return this.httpHelperService.get("/common/lov/document/type"); 
  }

  getDocumentLov(payload) {
    return this.httpHelperService.get(`/common/lov/documentlist`, payload);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/upload-doc-mock.json`);
  }

  getReasonCodeLov(payload){
    return this.httpHelperService.get("/common/lov/reason/code?docType="+payload); 
  }

  getVehicleClassUseLov(payload){
    return this.httpHelperService.get("/common/lov/vehicle/use?vehCls="+payload); 
  }
  getChecklistLov(payload) {
    return this.httpHelperService.get("/common/lov/checklist?endtCode="+payload);
  }
  getQuotationDetailPayload(data) {
    let url = `/cn/detail?`;
    url += data.quotationNo ? `quotationNo=${data.quotationNo}` : "";
    url += data.contractId ? `&contractId=${data.contractId}` : "";
    url += data.action ? `&action=${data.action}` : "";
    console.log("url : " + url);
    return this.httpHelperService.get(url);
    //return this.httpHelperService.get(`/cn/update/referremarks`, data);
  }
}
