<!-- WORKING: Named Driver -->

<nx-accordion variant="light">
  <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title class="accordion_edit">
        Coverage
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <!-- <div nxLayout="grid nopadding" formGroupName="driverCoverage"> -->
    <div nxLayout="grid nopadding">

      <!--  Driver Name -->
      <ng-container>
        <div nxLayout="grid" class="grid_remove_padding coverage">
          <div nxRow>
            <div nxCol="12" class="driver_list">

              <nx-swipebar label="Swipe to see more">

                <table nxTable>
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell></th>
                      <th nxHeaderCell>Driver Name</th>
                      <th nxHeaderCell>Driver ID</th>
                      <th nxHeaderCell colspan="2">Driver Age</th>
                    </tr>
                  </thead>
                  <!-- need to comment out or it will break the accordian display -->
                  <!-- <tbody formArrayName="rows">
                    @for (row of rows.controls; track row) {
                    <tr nxTableRow [formGroupName]="$index">
                      <td nxTableCell>{{ $index + 1 }}</td>
                      <td nxTableCell>
                        <nx-formfield appearance="outline">
                          <input formControlName="driverName" maxlength="100" nxInput />
                        </nx-formfield>
                      </td>
                      <td nxTableCell>
                        <nx-formfield appearance="outline" class="driver_id">
                          <input formControlName="driverId" maxlength="20" (change)="onChangeDriverId($index)"
                            nxInput />
                        </nx-formfield>
                      </td>
                      <td nxTableCell>
                        <nx-formfield appearance="outline" class="driver_age">
                          <input formControlName="driverAge" maxlength="3" nxInput />
                        </nx-formfield>
                      </td>
                      @if ($index !== 0) {
                      <td nxTableCell>
                        <button nxPlainButton type="button" class="nx-margin-left-m" (click)="deleteDriver($index)">
                          <nx-icon name="trash-o" size="s"></nx-icon>
                        </button>
                      </td>
                      }
                    </tr>
                    }
                  </tbody> -->

                </table>

              </nx-swipebar>

            </div>
          </div>

          <div nxRow class="nx-margin-y-m">
            <div nxCol="12">

              <button nxPlainButton type="button" class="nx-margin-right-m" [disabled]="true">
                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD DRIVER
              </button>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </nx-expansion-panel>
</nx-accordion>