import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { SEARCH_ENDORSEMENT_MAKE_MODEL, SEARCH_MAKE_MODEL, SEARCH_POLICY_ENQUIRY } from "@store/sat-motor/sat-motor.action";
import { Subject, debounceTime, of, takeUntil } from "rxjs";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";

@Component({
  selector: 'app-search-policy-enquiry-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './search-policy-enquiry-popop.component.html',
  styleUrl: './search-policy-enquiry-popop.component.scss',
})
export class SearchPolicyEnquiryPopupComponent implements OnInit {
  @ViewChild('tableHeader', { static: false }) tableHeader: ElementRef<HTMLTableHeaderCellElement>;
  @Select(SatMotorState.motorActionType) actionType$;

  isEndorsement: boolean = false;
  vehicleClassList: any;
  coverageTypeList: any;
  searchModelForm: FormGroup;

  searchEnquiryList: any = [];
  isSelectable: boolean = false;
  selection: any;
  selected: any;

  curpage: number = 1;
  perPage: number = 5;
  itemsPerPageSelections = [5, 10, 20, 50, 100];

  count: number = 0;
  showPagination: boolean = false;
  pageFilterForm: FormGroup;
  unsubscribe$ = new Subject();

  policySearchTypeList = [
    { code: 'vehicleNo', descp: 'VEHICLE NO.' },
    { code: 'policyNo', descp: 'E-POLICY NO' },
    { code: 'customerName', descp: 'CUSTOMER NAME' },
    { code: 'NRIC', descp: 'NRIC' },
    { code: 'oldNric', descp: 'OLD IC / POLICE ID / ARMY ID / PASSPORT NO.' },
    { code: 'businessRegistrationNo', descp: 'BUSINESS REGISTRATION NO.' },
    { code: 'newBusinessRegistrationNo', descp: 'NEW BUSINESS REGISTRATION NO.' }
  ];

  constructor(
    @Inject(NX_MODAL_DATA) public data: any,
    private dialogRef: NxModalRef<any>,
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.vehicleClassList = data.vehicleClassList;
    this.coverageTypeList = data.coverageTypeList;
  }

  ngOnInit(): void {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
        // console.log("MakeModelComponent isEndorsement", this.isEndorsement);
      }
    });

    this.searchModelForm = this.fb.group({
      policySearchType: ['', Validators.required],
      policySearchTypeValue: ['', Validators.required],
    });

    this.pageFilterForm = this.fb.group({
      perPage: [this.perPage]
    });
  }

  onSearchClicked() {
    this.searchEnquiryList = [];
    this.showPagination = false;

    if (!this.searchModelForm.valid) {
      this.searchModelForm.markAllAsTouched();
      return;
    }

    let { policySearchType, policySearchTypeValue } = this.searchModelForm.getRawValue();
    let searchParam = {
      policyNo: '',
      vehicleNo: '',
      customerName: '',
      idType: '',
      idValue: ''
    };

    // Handle different search types
    switch (policySearchType) {
      case 'vehicleNo':
        searchParam.vehicleNo = policySearchTypeValue;
        break;
      case 'policyNo':
        searchParam.policyNo = policySearchTypeValue;
        break;
      case 'customerName':
        searchParam.customerName = policySearchTypeValue;
        break;
      case 'NRIC':
        searchParam.idType = 'NRIC';
        searchParam.idValue = policySearchTypeValue;
        break;
    }

    // Dispatch action to search policy enquiry
    this.store.dispatch(new SEARCH_POLICY_ENQUIRY(searchParam)).subscribe((state) => {
      this.searchEnquiryList = state.SatMotorState.policyEnquiryDetails;
      if (this.searchEnquiryList && this.searchEnquiryList.length > 5) {
        this.showPagination = true;
      }
      this.setItemPerPage();
    });
  }


  select(item: any) {
    this.dialogRef.close({ item });
  }

  prevPage() {
    this.curpage--;
    this.scrollIntoView();
  }

  nextPage() {
    this.curpage++;
    this.scrollIntoView();
  }

  goToPage(n: number) {
    this.curpage = n;
    this.scrollIntoView();
  }

  scrollIntoView() {
    this.tableHeader.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  setItemPerPage() {
    this.pageFilterForm.get('perPage').valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.unsubscribe$)
    ).subscribe((value) => {
      this.perPage = value;
      this.curpage = 1;
      this.scrollIntoView();
    });
  }

  get paginatedResults() {
    if (this.searchEnquiryList) {
      const startIndex = (this.curpage - 1) * this.perPage;
      return this.searchEnquiryList.slice(startIndex, startIndex + this.perPage);
    }
    return [];
  }

  getResultLength() {
    if (this.searchEnquiryList) {
      return this.searchEnquiryList.length;
    }
    return 0;
  }

  onChangeFormValue(code: any, name: any) {
    if (name === 'searchType') {
      this.resetInput();
    } else if (name === 'searchValue') {

    }

  }

  resetInput() {
    this.searchModelForm.controls['policySearchTypeValue'].reset();
  }

  getVehicleClassDescription(vehicleClassCode: any) {
      const item = this.vehicleClassList.find(item => item.code === vehicleClassCode);
      return item ? item.descp : 'N/A';
  }

  getCoverageTypeDescription(coverageTypeCode: any) {
    const item = this.coverageTypeList.find(item => item.coverCode === coverageTypeCode);
    return item ? item.coverDescription : 'N/A';
}
}
