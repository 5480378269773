<div nxLayout="grid nopadding">
    <div nxRow>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                <p nxCopytext="large">{{ agentInfo.agentCode }}</p>
            </nx-data-display>
        </div>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                <p nxCopytext="large">{{ agentInfo.name }}</p>
            </nx-data-display>
        </div>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Issued By</nx-data-display-label>
                <p nxCopytext="large" class="wrap-anywhere">{{ data.issueBy | uppercase }}</p>
            </nx-data-display>
        </div>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Customer Name</nx-data-display-label>
                <p nxCopytext="large">{{ data.insuredName }}</p>
            </nx-data-display>
        </div>

        @if(!isEndorsement) {
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Effective Date</nx-data-display-label>
                <p nxCopytext="large">{{ data.effectiveDate ? (data.effectiveDate  | date: 'dd/MM/yyyy') : "N/A" }}</p>
            </nx-data-display>
        </div>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Expiry Date</nx-data-display-label>
                <p nxCopytext="large">{{ data.expiryDate ? (data.expiryDate  | date: 'dd/MM/yyyy') : "N/A" }}</p>
            </nx-data-display>
        </div>
        }

        @if(isEndorsement) {
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Endorsement Effective
                    Date</nx-data-display-label>
                <p nxCopytext="large">{{ data.endtEffectiveDate ? (data.endtEffectiveDate | date: 'dd/MM/yyyy') : "N/A" }}</p>
            </nx-data-display>
        </div>
        <div nxCol="12,12,4" class="nx-margin-bottom-m">
            <nx-data-display size="small">
                <nx-data-display-label class="font-grey">Endorsement Expiry Date</nx-data-display-label>
                <p nxCopytext="large">{{ data.endtExpiryDate ? (data.endtExpiryDate | date: 'dd/MM/yyyy') : "N/A" }}</p>
            </nx-data-display>
        </div>
        }
    </div>
</div>