@if(isLoadedChecklist) {
<form [formGroup]="checklistForm">
    <!-- Checklist -->
    <!-- /////////////////////////////////////////////////////// -->

    <!-- <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div *ngFor="let item of checklistItems" nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                    <span nxCopytext="normal">{{ item.id }}</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                    <span nxCopytext="normal">{{ item.description }}</span>
                    <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                        <div nxRow>
                            <div *ngFor="let option of item.options" nxCol="4,4,3,2" class="checklist_radio">
                                <nx-radio name="yesno">{{ option }}</nx-radio>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="item.notes && item.notes.length > 0" nxRow>
                    <div nxCol="12">
                        <p *ngFor="let note of item.notes" nxCopytext="medium" class="nx-margin-bottom-2m">
                            <i>{{ note }}</i>
                        </p>
                    </div>
                </div>
            </div>

            <div nxRow>
                <div nxCol="12">
                    <nx-formfield label="Remarks">
                        <textarea nxInput type="text" #inputToCount maxlength="200" cdkTextareaAutosize></textarea>
                        <span nxFormfieldHint>Max. 200 Characters</span>
                    </nx-formfield>
                </div>
            </div>
        </div> -->

    <div nxLayout="grid nopadding" class="grid_remove_padding">

        @if(data && data.length > 0) {
        <div formArrayName="rows">
            @for (checklist of data; track checklist) {
            <div [formGroupName]="$index">
                <div nxRow class="nx-margin-bottom-2m">
                    <div nxCol="2,2,1" class="checklist">
                        <span nxCopytext="normal">{{ $index + 1 }}</span>
                    </div>
                    <div nxCol="10,10,11" class="checklist">
                        <span nxCopytext="normal">{{ checklist.questionDesc }}</span>
                        <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                            <nx-radio-group formControlName="declareAns" [name]="'declareAns' + $index">
                                <div nxRow>
                                    <div nxCol="4,4,3,2" class="checklist_radio">
                                        <nx-radio value="1">Yes</nx-radio>
                                    </div>
                                    <div nxCol="4,4,3,2" class="checklist_radio">
                                        <nx-radio value="2">No</nx-radio>
                                    </div>
                                </div>
                            </nx-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        }
        

        @if (!isViewOnly) {
            @if (note) {
            <div nxRow>
                <div nxCol="12">
                    <p nxCopytext="medium" class="nx-margin-bottom-2m">
                        <i>{{ note }}</i>
                    </p>
                </div>
            </div>
            }

            <!-- @if(!checklistLov || checklistLov.length == 0) { -->
            <div nxRow>
                <div nxCol="12">
                    <nx-formfield label="Remarks">
                        <textarea formControlName="remarks" nxInput type="text" #inputToCount maxlength="200" cdkTextareaAutosize appToUpperCase></textarea>
                        <span nxFormfieldHint>
                            @if(inputToCount.value?.length === 0) {
                            <span>
                                max. 200 characters
                            </span>
                            }@else {
                            <span>
                                {{ 200 - (inputToCount.value.length || 0) }}
                                {{ 'COMMON.CHAR_REMAINING' | translate }}
                            </span>
                            }
                        </span>
                    </nx-formfield>
                </div>
            </div>
            <!-- } -->
        
        }@else {
            <div nxRow>
                <div nxCol="12">
                    @if(note) {
                    <p nxCopytext="medium" class="nx-margin-bottom-2m">
                        <i>{{ note }}</i>
                    </p>
                    }

                    <!-- @if(!checklistLov || checklistLov.length == 0) { -->
                    <p nxCopytext="normal" class="nx-margin-bottom-2m">
                        Remarks: {{ formatData(checklistRemarks) }}
                    </p>
                    <!-- } -->
                </div>
            </div>
        }

    </div>
</form>
}