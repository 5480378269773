<nx-accordion variant="light">
  <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title class="accordion_edit">
        <!-- Coverage -No Claim Discount -->
        Coverage
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <!-- <div nxLayout="grid nopadding" formGroupName="noClaimDiscountCoverage"> -->
    <div nxLayout="grid nopadding" [formGroup]="ncdForm">

      <ng-container>
        <div nxLayout="grid" class="grid_remove_padding coverage">

          <div nxRow>

            @if (!isNCDWithdraw) {
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="No Claim Discount From">
                <nx-dropdown formControlName="prevNcdInsurer">
                  <nx-dropdown-item *ngFor="let item of lov.insurerList" value="{{item.code}}">
                    {{ item.descp | uppercase }}
                  </nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  <strong>No Claim Discount From required.</strong>
                </nx-error>
              </nx-formfield>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="No Claim Discount Vehicle No.">
                <input nxInput type="text" formControlName="ncdVehicleNo" />
                <nx-error nxFormfieldError>
                  <strong>No Claim Discount Vehicle No. required.</strong>
                </nx-error>
                <nx-error nxFormfieldError>
                  <strong>Invalid Vehicle No.</strong>
                </nx-error>
              </nx-formfield>
            </div>
            }

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="No Claim Discount (%)">
                <nx-dropdown formControlName="ncdPercentage">
                  <nx-dropdown-item value="0">0%</nx-dropdown-item>
                  <nx-dropdown-item value="25">25%</nx-dropdown-item>
                  <nx-dropdown-item value="45">45%</nx-dropdown-item>
                  <nx-dropdown-item value="55">55%</nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="NCD Effective Date">
                <!-- <input nxDatefield nxInput formControlName="ncdEffectiveDate" [datepicker]="myDatepicker9"/>
                <span nxFormfieldHint>MM/DD/YYYY</span> -->
                <input nxDatefield nxInput formControlName="ncdEffectiveDate" [datepicker]="myDatepicker9"
                  [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle [for]="myDatepicker9" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker9></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nx-expansion-panel>
</nx-accordion>