import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-complete-information',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './complete-information.component.html',
  styleUrl: './complete-information.component.scss'
})
export class CompleteInformationComponent implements OnInit {
  @Input() data: any;
  @Input() isEndorsement: boolean = false;

  agentInfo: any;

  ngOnInit() {
    this.agentInfo = this.data.mkagAgent;
  }
}
