import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";
import { POLICY_MOVEMENT_DATA, STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { PolicyMovementPopupComponent } from "./policy-movement-popup/policy-movement-popup.component";

@Component({
  selector: 'app-policy-movement',
  standalone: true,
  imports: [
    SharedModule,
    PolicyMovementPopupComponent,
    EndorsementPopupComponent,
  ],
  templateUrl: './policy-movement.component.html',
  styleUrl: './policy-movement.component.scss'
})
export class PolicyMovementComponent {

  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.endtVehiclePolicy) endtVehiclePolicyData$;
  // @Select(SatMotorState.policyMovemementTableData) policyMovemementTableData$;

  templateDialogRef?: NxModalRef<any>;
  // componentDialogRef?: NxModalRef<CoverageComponent>;
  componentDialogRef?: NxModalRef<PolicyMovementPopupComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  @Input() endorsementType: any;

  policyNo: any;
  policyMovemementTableData: any;
  endorsementTypeListOfValues: any;
  // endorsementDesc: any;
  selectedPolicyMovement: any;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  // Dropdown options for results per page
  dropdownOptions = [5, 10, 20, 30, 40, 50];

  // Endorsement table data pagination
  // count = 100;
  // page = 1;
  // perPage = 10;
  count = 0; // Initialize count to 0
  page = 1;
  perPage = 10; // Default perPage to 10

  // Dialog management
  openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
    this.templateDialogRef = this.dialogService.open(template, {
      height: config.height || '500px',
      width: config.width || '780px',
    });
  }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  // Page navigation
  goToPage(n: number): void {
    if (n >= 1 && n <= this.totalPages) {
      this.page = n;
    }
  }

  nextPage(): void {
    if (this.page < this.totalPages) {
      this.page++;
    }
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
    }
  }

  // Calculate total pages dynamically
  get totalPages(): number {
    return Math.ceil(this.policyMovemementTableData.length / this.perPage);
  }

  // Get paginated data for the current page
  get paginatedData(): any[] {
    const startIndex = (this.page - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    return this.policyMovemementTableData.slice(startIndex, endIndex);
  }

  // Handle per page change (from the dropdown)
  onPerPageChange(newPerPage: number): void {
    this.perPage = newPerPage;
    this.page = 1; // Reset to the first page when perPage changes
  }

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit(): void {

    // const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    // let customerData = endtVehiclePolicyData?.clientPartner;
    // let agentData = endtVehiclePolicyData?.mkagAgent;
    // let coverageData = endtVehiclePolicyData?.riskList;

    this.prepareEndorsementTypeListLov();
    this.loadPolicyMovementTable();

  }
  // ngAfterViewInit() {}

  prepareEndorsementTypeListLov(): any {
    // Get endorsement type list of values
    const lov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
    this.endorsementTypeListOfValues = lov?.endorsementList;
  }

  loadPolicyMovementTable() {
    this.endtVehiclePolicyData$.subscribe((state) => {
      if (state) {
        this.policyNo = state.policyNo;
        // console.log("policyMovemementTableData policyNo=", this.policyNo);
        // Create payload for api post request
        let payload = {
          policyNo: this.policyNo,
          transactionNo: ""
        };

        this.store.dispatch(new POLICY_MOVEMENT_DATA(payload)).subscribe(() => {
          // Get policy movement table data
          this.policyMovemementTableData = this.store.selectSnapshot<any>(state => state.SatMotorState?.data?.step2?.endorsementDetails?.policyMovemementData);

          // console.log("1 policyMovemementTableData =", this.policyMovemementTableData);

          // Map the descriptions into the policy movement table data
          this.policyMovemementTableData = this.policyMovemementTableData.map((item) => {

            return {
              ...item,
              endorsementDesc: item.endorsementDesc ? item.endorsementDesc : "Type Not Found",
              effectiveDate: this.formatDate(item.effectiveDate),
              expiryDate: this.formatDate(item.expiryDate),
              sumInsured: this.formatSumInsured(item.sumInsured)
            };
          });
          // Update count based on the length of data received
          this.count = this.policyMovemementTableData.length;
          // console.log("Total policyMovemementTableData length:", this.count);
          // console.log("END policyMovemementTableData =", this.policyMovemementTableData);
        });
      }
    });
  }

  formatDate = (date) => {
    if (date) {
      const d = new Date(date);
      // return d.toLocaleDateString('ms-MY');
      const day = ('0' + d.getDate()).slice(-2); // Pad day with leading zero
      const month = ('0' + (d.getMonth() + 1)).slice(-2); // Pad month with leading zero
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return 'Invalid Date';
  };

  formatSumInsured = (amount) => {
    return parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  policyTable(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef3, {
      height: "500px",
    });
    ``;
  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  // componentDialogRef1?: NxModalRef<PolicyMovementPopupComponent>;
  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  openPopup3(item: any) {
    // Set the selectedPolicyMovement to the item clicked by the user
    this.selectedPolicyMovement = item;

    // Open the PolicyMovementPopupComponent dialog and pass the selected data
    this.componentDialogRef = this.dialogService.open(PolicyMovementPopupComponent, {
      ariaLabel: "Policy Movement Details",
      showCloseIcon: true,
      data: { popup_type: 'policyTable', selectedPolicyMovement: this.selectedPolicyMovement },
      // width: '600px', // Set width based on your design
    });
  }

  closePopup() {
    this.componentDialogRef?.close();
  }

}
