import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CLIENTTYPE, IDTYPE } from '@shared/enums/index.enum';
import { CustomerPartnerBase } from '@shared/classes/customer-partner.base';
import { NricService } from '@shared/services/nric/nric.service';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';

@Component({
  selector: 'app-customer-partner-details',
  templateUrl: './customer-partner-details.component.html',
  styleUrl: './customer-partner-details.component.scss'
})
export class CustomerPartnerDetailsComponent extends CustomerPartnerBase implements OnInit {
  @Input() customerPartnerForm: FormGroup = this.fb.group({});
  @Input() viewOnly: boolean = false;
  @Input() hideEdit: boolean = false;
  @Input() isApproval: boolean = false;
  @Input() cpType: any;
  @Input() isCompOrCustInfoIncomplete: boolean;
  @Input() isContactInfoIncomplete: boolean;
  @Input() isCorrespondenceInfoIncomplete: boolean;
  @Output() openEdit = new EventEmitter();
  @Input() viewPolicyDetails: boolean;

  @Select(SatMotorState.motorActionType) actionType$;
  isEndorsement: boolean = false;
  
  indiGeo: any;

  constructor(
    private fb: FormBuilder,
    private nricService: NricService,
    private store: Store

  ) {
    super();
  }

  ngOnInit() {
    this.indiGeo = this.store.selectSnapshot<any>((state)=>state.SatMotorState.geoCodedInd);

    this.actionType$.subscribe((state) => {
      if (state.isEndorsement) {
        this.isEndorsement = true;
        // console.log('CustomerPartnerDetailsComponent isEndorsement:', this.isEndorsement);
      }
    });

    // if (this.isEndorsement) {}
   
  }

  showNationalityDropdown(){
    return this.isIndividual && this.cpDetails.idType1 === IDTYPE.PASS && (this.cpType === "NOT_FOUND");
  }

  showNationalityText() {
    if(this.cpDetails.idType1 === IDTYPE.PASS && this.cpType === "NOT_FOUND") {
      return false;
    }
    return this.isIndividual;
  }

  showAgeAndDOB() {
    return this.cpDetails.idType1 === IDTYPE.NRIC;
  }

  getCalculateDateOfBirth(value: string): Date | string {
    try {
        return this.nricService.getDateOfBirth(value);
    } catch (error) {
        console.log('Error calculating date of birth:', error);
        return 'N/A';
    }
  }


  getCalculateAge(value) {
    return this.nricService.getAge(value);
  }

  onOpenEdit() {
    this.openEdit.emit();
  }

  showCustomerType() {
    return this.viewOnly || this.cpType === "FOUND";
  }

  showName() {
    return this.viewOnly || this.cpType !== "NOT_FOUND";
  }

  showIdValue() {
    return !this.isApproval;
  }

  showCOI() {
    return this.isCompany && ((this.viewOnly && !this.isApproval) || this.cpType === "FOUND" 
      || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showNationality() {
    return this.isIndividual && (this.viewOnly  || this.cpType === "FOUND" || this.cpType === "NOT_FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showTin() {
    return ((this.viewOnly && !this.isApproval) || this.cpType === "FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showSSTAndSMESection() {
    return this.isCompany && ((this.viewOnly && !this.isApproval) || this.cpType ==="FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showGender() {
    return this.isIndividual && (this.viewOnly || this.cpType === "FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showDOB() {
    if(!this.isIndividual) {
      return false;
    }

    if([IDTYPE.POL, IDTYPE.OLD_IC, IDTYPE.PASS].includes(this.cpDetails.idType1) && (this.cpType === "NOT_FOUND" || (this.cpType === "INCOMPLETE" && this.isCompOrCustInfoIncomplete))) {
      return false;
    }
  
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || this.cpType === "NOT_FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));  
  }

  showAge() {
    if(!this.isIndividual) {
      return false;
    }

    if([IDTYPE.POL, IDTYPE.OLD_IC, IDTYPE.PASS].includes(this.cpDetails.idType1) && (this.cpType === "NOT_FOUND" || (this.cpType === "INCOMPLETE" && this.isCompOrCustInfoIncomplete))) {
      return false;
    }

    return this.viewOnly || this.cpType === "FOUND" || this.cpType === "NOT_FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete);
  
  }

  showMartialStatus() {
    return this.isIndividual && (this.cpType === "FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

  showALIM() {
    return this.isIndividual && !this.isApproval && this.cpType === "FOUND";
  }

  showMobileNo() {
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || 
    (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete && !this.isContactInfoIncomplete && !this.isCorrespondenceInfoIncomplete));
  }

  showFixedLineNo() {
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || 
    (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete && !this.isContactInfoIncomplete && !this.isCorrespondenceInfoIncomplete));
  }

  showFaxNo() {
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || 
    (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete && !this.isContactInfoIncomplete && !this.isCorrespondenceInfoIncomplete));
  }

  showEmail() {
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || 
    (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete && !this.isContactInfoIncomplete && !this.isCorrespondenceInfoIncomplete));
  }

  showAddress() {
    return !this.isApproval && (this.viewOnly || this.cpType === "FOUND" || 
    (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete && !this.isContactInfoIncomplete && !this.isCorrespondenceInfoIncomplete));
  }

  showIdValue2() {
    if(!this.idValue2) {
      return false;
    }

    return ((this.viewOnly && !this.isApproval) || this.cpType === "FOUND" || (this.cpType === "INCOMPLETE" && !this.isCompOrCustInfoIncomplete));
  }

}

