<div [formGroup]="extraCoverDetailForm">

  <app-driver-name [isComprehensive]="isComprehensive" [isThirdParty]="isThirdParty"
    [isThirdPartyFireTheft]="isThirdPartyFireTheft" [defaultDriver]="defaultDriver" [parentForm]="extraCoverDetailForm"
    (selectExtraCover)="selectExtraCover($event)" (removeExtraCover)="removeExtraCover($event)"
    (selectWaiver)="selectWaiver($event)" [coverageFmGroup]="coverageFmGroup" [partnerInfo]="partnerInfo"
    (selectAllDriver)="selectAllDriver($event)" (calculatePremium)="calculatePremium()">
  </app-driver-name>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <hr />
    </div>
  </div>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <h4 class="nx-margin-0">Extra Coverage</h4>
    </div>
  </div>
  @if(rahmahExtraCoverList?.length > 0 || topExtraCoverList?.length > 0){
  <div nxRow class="nx-margin-top-new">
    <div nxCol="12" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no extra cost.</div>
  </div>
  }

  <div nxRow class="nx-margin-top-new">
    <div nxCol="12">
      <table class="extra_package">
        <tbody>
          @if(subCovPackageList?.packageDescription && !costInd){
          <tr nxTableRow>
            <td>
              <nx-checkbox [disabled]="!subCovPackageList.enable" [checked]="subCovPackageList.selected"
                labelSize="small" (change)="emitData(false, subCovPackageList.packageCode, 'topHeader')">
                <div class="nx-font-weight-bold">
                  {{ subCovPackageList?.packageDescription | uppercase}}
                </div>
              </nx-checkbox>
            </td>
            <td></td>
            <td class="nx-hidden-m"></td>
          </tr>
          <tr nxTableRow *ngFor="let item of rahmahExtraCoverList; let i = index" [selectable]="true">
            <td>
              <div class="sub_cover">
                <ul nxList>
                  <li nxListIcon="minus" size="s">
                    <p nxCopytext="medium">
                      {{ item.description || item.subCoverCodeDescription }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>
            <td *ngIf="item.subCoverSumInsured != 0" class="si">
              SUM INSURED (RM) : {{ item.subCoverSumInsured | number:'1.0-0'}}
            </td>
            <td *ngIf="item.subCoverAmtPerDay != 0 && item.subCoverNumberOfDays != 0" class="si">
              RM {{ item.subCoverAmtPerDay }} PER DAY / MAX {{ item.subCoverNumberOfDays }} DAYS
            </td>
            <td class="nx-hidden-m"></td>
          </tr>
          }
          <!-- top = true, disable = true -->
          <tr nxTableRow *ngFor="let item of topExtraCoverList; let i = index" [selectable]="true">
            <td class="cell-padding-bottom">
              <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                (change)="emitData(selection.select(item), item.subCoverCode, 'topTableHeader')"
                [value]="item.subCoverCode" [checked]="item.selected" [disabled]="!item.enabled">
                <div class="nx-font-weight-bold">
                  {{ item.description || item.subCoverCodeDescription }}
                </div>
              </nx-checkbox>
            </td>
            <td class="std-ta-right border-top-none" *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0"
              sty>
              <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                <ng-container *ngIf="subItem.type === 'DROPDOWN'">
                  <div class="si nx-margin-top-3xs">
                    PLAN / UNIT : {{ getDropDownDesc(item.subCoverCode, item.planCode) }}
                  </div>
                </ng-container>
              </ng-container>
              @if(item.subCoverAmtPerDay > 0 || item.subCoverAmtday > 0){ RM {{ item.subCoverAmtPerDay||
              item.subCoverAmtday}} }
              @if(item.subCoverNumberOfDays > 0 || item.subCoverDays > 0){ PER DAY / MAX {{ item.subCoverNumberOfDays ||
              item.subCoverDays }} DAYS}
              <div class="si">@if(item.subCoverSumInsured != 0){ SUM INSURED (RM) : {{ item.subCoverSumInsured |
                number:'1.0-0'}}}</div>
            </td>
          </tr>
        </tbody>
      </table>
      @if(subCovPackageList?.packageDescription && costInd){
      <div nxRow class="nx-margin-top-m">
        <div nxCol="9" class="nx-font-weight-semibold cell-padding-bottom">Extra Coverage Package</div>
      </div>
      <table class="extra_package">
        <tbody>
          <tr nxTableRow>
            <td>
              <nx-checkbox [disabled]="!subCovPackageList.enable" [checked]="subCovPackageList.selected"
                labelSize="small" (change)="emitData(false, subCovPackageList.packageCode, 'topHeader')">
                <div class="nx-font-weight-bold">
                  {{ subCovPackageList?.packageDescription | uppercase}}
                </div>
              </nx-checkbox>
            </td>
            <td></td>
            <td class="nx-hidden-m"></td>
          </tr>
          <tr nxTableRow *ngFor="let item of rahmahExtraCoverList; let i = index" [selectable]="true">
            <td>
              <div class="sub_cover">
                <ul nxList>
                  <li nxListIcon="minus" size="s">
                    <p nxCopytext="medium">
                      {{ item.description || item.subCoverCodeDescription }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>
            <td *ngIf="item.subCoverSumInsured != '0'; else condition2" class="si">
              SUM INSURED (RM) : {{ item.subCoverSumInsured | number:'1.0-0'}}
            </td>
            <ng-template #condition2>
              <td *ngIf="item.subCoverAmtPerDay != 0 && item.subCoverNumberOfDays != 0; else emptyCell" class="si">
                RM {{ item.subCoverAmtPerDay }} PER DAY / MAX {{ item.subCoverNumberOfDays }} DAYS
              </td>
            </ng-template>
            <ng-template #emptyCell>
              <td class="nx-hidden-m"></td>
            </ng-template>
          </tr>
        </tbody>
      </table>
      }
      <div class="nx-margin-top-m">
        <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
          <table nxTable class="more_extra_cover">
            <tbody>
              <tr nxTableRow>
                <th nxHeaderCell>
                  <nx-checkbox class="nx-font-weight-semibold" formControlName="checkedAll"
                    (change)="checkedAllChange()" forcheckedAllChange>
                    <div class="nx-font-weight-semibold">
                      Extra Cover
                    </div>
                  </nx-checkbox>
                </th>
                <th nxHeaderCell></th>
                <th nxHeaderCell>Premium (RM)</th>
              </tr>
              <!-- start selected = true -->
              <tr nxTableRow *ngFor="let item of extraCoverList;" [selectable]="true">
                <td nxTableCell>
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'bottom')" [value]="item.subCoverCode"
                    [checked]="true" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>

                  @if(item.cngeSubCoverBenefitDtoList && item.cngeSubCoverBenefitDtoList.length > 0){
                  <ng-container *ngIf="item.cngeSubCoverBenefitDtoList?.length;">
                    <div class="sub_cover extra-coverage-padding-top">
                      <ul nxList>
                        <li nxListIcon="minus" size="s" *ngFor="let subItem of item.cngeSubCoverBenefitDtoList">
                          <div class="horizontal-container" nxCopytext="medium">
                            <div class="extra-coverage-description">{{ subItem.subCoverBenefitDescp }}</div>
                            <div class="extra-coverage-medium-width">{{ subItem.subCoverBenefitDisplay}}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  }
                </td>

                <td nxTableCell class="std-ta-right" *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0">
                  <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                    <ng-container *ngIf="subItem.type === 'INPUT'">
                      <nx-natural-language-form size="small">{{ subItem.label }}
                        <nx-word>
                          <input nxInput type="text" [formControlName]="item.subCoverCode" required appCusCurrencyFormat
                            [value]="item.subCoverSumInsured | formatDecimal"
                            (change)="onChangeSubCoverSumInsured(item.subCoverCode, $event.target)"
                            [disabled]="subItem.disable" />
                        </nx-word>
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Amount Required.</strong>
                        </nx-error>
                      </nx-natural-language-form>
                    </ng-container>

                    <!-- Dropdown field (if subItem.type is 'DROPDOWN') -->
                    <ng-container *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode != 112">
                      <div class="dropdown-container">
                        <!-- <span> -->

                        <span class="display-flex-container">
                          <span class="label">{{ subItem.label }}:</span>
                          <nx-formfield class="formfield_no_padding">
                            <nx-dropdown [formControlName]="item.subCoverCode" [panelGrow]="true"
                              [value]="item.planCode"
                              (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)">
                              <ng-container *ngFor="let dropdownitem of subItem.data">
                                <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                  <span class="dropdown-item-desc">{{ dropdownitem.descp }}</span>
                                </nx-dropdown-item>
                              </ng-container>
                            </nx-dropdown>
                          </nx-formfield>
                        </span>

                        <br />
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Plan Required.</strong>
                        </nx-error>
                        <!-- </span> -->
                      </div>
                    </ng-container>

                    @if(item.cngeSubCoverBenefitDtoList && item.cngeSubCoverBenefitDtoList.length > 0){
                    <ng-container *ngIf="item.cngeSubCoverBenefitDtoList?.length;">
                      <div>
                        <ul nxList>
                          <li class="extra-coverage-small-width" nxListIcon="" size="s"
                            *ngFor="let subItem of item.cngeSubCoverBenefitDtoList; let i = index">
                            <div [ngClass]="{ 'padding-12': i === 0, 'padding-21': i === 1, 'no-padding': i === 2 }"
                              nxCopytext="medium">
                              <div>{{ subItem.subCoverBenefitDisplay }}</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                    }
                    <!-- p2p multi checkbox -->
                    <ng-container *ngIf="subItem.type === 'CHECKBOX_P2P'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-flex-container"
                            [ngClass]="{'display-block-container': selectedCheckboxItem()}">
                            <span class="">SERVICE PROVIDER:</span>
                            <span>
                              <nx-formfield class="formfield_no_padding">
                                <nx-multi-select formControlName="CHECKBOX_P2P" [options]="subItem.data"
                                  selectLabel="descp" selectValue="code" (selectionChange)="selectionChange($event)">
                                </nx-multi-select>
                              </nx-formfield>
                            </span>
                          </span>

                          <br />
                          <nx-error
                            *ngIf="extraCoverDetailForm.controls['CHECKBOX_P2P']?.hasError('required') && extraCoverDetailForm.controls['CHECKBOX_P2P']?.touched"
                            nxFormfieldNote>
                            <strong>Provider Name Required.</strong>
                          </nx-error>
                        </span>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'NO OF DAY'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-inline-flex"><span class="label">{{ subItem.label }}:</span>
                            <nx-formfield class="formfield_no_padding">
                              <nx-dropdown formControlName="112_NO_OF_DAY" [panelGrow]="true"
                                [value]="getSubCoverNumberOfDaysAsString(item.subCoverDays)"
                                (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                                class="margin-dropdown">
                                <ng-container *ngFor="let dropdownitem of subItem.data">
                                  <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                  </nx-dropdown-item>
                                </ng-container>
                              </nx-dropdown>
                            </nx-formfield>
                          </span>
                          <br />
                          <nx-error
                            *ngIf="extraCoverDetailForm.controls['112_NO_OF_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_NO_OF_DAY']?.touched"
                            nxFormfieldNote>
                            <strong>No. of Days Required.</strong>
                          </nx-error>
                        </span>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'AMOUNT PER DAY'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-flex-container"><span class="label">{{ subItem.label }}:</span>
                            <nx-formfield class="formfield_no_padding">
                              <nx-dropdown formControlName="112_AMOUNT_PER_DAY" [panelGrow]="true"
                                [value]="item.subCoverAmtday"
                                (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                                class="margin-dropdown">
                                <ng-container *ngFor="let dropdownitem of subItem.data">
                                  <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                  </nx-dropdown-item>
                                </ng-container>
                              </nx-dropdown>
                            </nx-formfield>
                          </span>
                          <br />
                          <nx-error
                            *ngIf="extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.touched"
                            nxFormfieldNote>
                            <strong>Amount Per Day Required.</strong>
                          </nx-error>
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>

                <td nxTableCell class="std-ta-right" *ngIf="!item.mtExtCovDtoList || item.mtExtCovDtoList.length === 0">
                </td>

                <td nxTableCell class="std-ta-right">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>

              <!-- start top = true selected = false -->
              <tr nxTableRow *ngFor="let item of defaultedExtraCoverList;" [selectable]="true">
                <td nxTableCell>
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'defaultedExtraCover')"
                    [value]="item.subCoverCode" [checked]="false" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>
                </td>


                <ng-container *ngIf="item.mtExtCovDtoList?.length && item.selected === true; else emptyCell">
                  <td *ngFor="let subItem of item.mtExtCovDtoList" nxTableCell class="std-ta-right">
                    <nx-natural-language-form size="small">
                      {{ subItem.label }}
                      <nx-word>
                        <input nxInput type="text" [disabled]="!item.enabled" />
                      </nx-word>
                    </nx-natural-language-form>
                  </td>
                </ng-container>

                <ng-template #emptyCell>
                  <td nxTableCell class="std-ta-right"></td>
                </ng-template>

                <td nxTableCell class="std-ta-right">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>
              <!-- end top = true selected = false -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <nx-link>
      <a (click)="openPopupComp('comprehensive')">
        <nx-icon name="plus" nxIconPositionStart></nx-icon>
        ADD MORE
      </a>
    </nx-link>
  </div>
</div>
@if(privateHireCarIsSelected){
<div nxRow>
  <div nxCol="12">
    <hr class="nx-margin-y-2m" />
  </div>
</div>

<div nxRow>
  <div nxCol="12">
    <app-e-hailing-driver [parentForm]="extraCoverDetailForm"></app-e-hailing-driver>
  </div>
</div>
}