import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { Moment } from 'moment';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../../popup/summary-popup/summary-popup.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { ACTIONS } from '@shared/enums/index.enum';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { ViewRemarksComponent } from '@shared/components/view-remarks/view-remarks.component';
import {
  CALCULATE_PREMIUM,
  SELECTED_MAKE_MODEL,
  CALCULATE_PREMIUM_SUMMARY,
  GET_CUSTOMER_PARTNER_MOCK,
  GENERATE_QUOTATION,
  SEND_TO_JPJ,
  DOWNLOAD_PDF,
  SET_JPJ_STATUS_SENT,
  CALCULATE_PREMIUM_PAYLOAD,
  GET_DOCUMENT_LOV,
  STORE_ISSUANCE_SUMMARY,
  GET_QUOTATION_DETAIL,
  CANCEL_REPLACE,
  REQUEST_CANCELLATION,
  STORE_AGENT_FROM_RESPONSE,
  STORE_ISSUANCE_FROM_RESPONSE,
  COPY_CNGE_INFO_RENEWAL,
  COPY_CNGE_INFO_VIEW_QUOTATION,
  GET_PRODUCT_LIST,
  STORE_EXTRA_COVER_PACKAGE,
  GET_PREVIOUS_POLICY_NO,
  GET_VIEW_CLAIM,
  GET_VIEW_POLICY,
  GET_CROSS_SELL_LIST,
  GET_SWITCH_SITE,
  GET_QUOTATION_DETAIL_REQUOTE,
  COPY_DRAFT_INFO,
  GET_REDIRECT_PAGE,
  GET_VEHICLE_USE,
  SEARCH_MAKE_MODEL
}
  from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { PaymentModeComponent } from '@shared/components/modal/payment-mode/payment-mode.component';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { UserResponse } from "@interfaces/user";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { EnquiryPopupComponent } from 'src/app/popup/enquiry-popup/enquiry-popup.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-view-policy',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    CrossSellBannerComponent,
    ViewRemarksComponent
  ],
  templateUrl: './view-policy.component.html',
  styleUrl: './view-policy.component.scss'
})
export class ViewPolicyComponent implements OnInit {
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.commonLov) commonLov$;

  @Input() coverageInfo: any;
  namedDriverList: any;


  isIssuance: boolean = false;
  isEndorsement: boolean = false;
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  cnoteStatus: any;
  quotationDetail: any;
  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  htclClaimDet: any;
  clientPartner: any;
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  premiumInfo: any;
  // coverageInfo: any;
  cpData: any;
  lov: any;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;
  retainNcdInd: any;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;
  sundriesAgent: boolean = false;
  showRemarks: boolean = false;
  isClaimEnquiry: boolean = false;
  contractId: any;
  mock: any;
  token: any;
  referralForm: FormGroup;
  referCaseForm: FormGroup;
  callIssuanceInformation: boolean = false;
  viewPolicyDetails: boolean = true;
  popupMessage: any;
  referralReasonList: any;
  status: any;
  classType: any;
  type: any;
  proposalNo: any;
  claimNo: any;
  productName: any;
  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  productList: any;
  remarksInfo: any;
  agentReferId: string;
  responseData: any;
  cngeNote: any;
  isDataLoaded: boolean = false;
  queryParams: any = {};
  crossSellList: any = [];
  marketingConsent: any;

  isShowBackBtn: boolean = false;
  isShowRequestCancellationBtn: boolean = false;
  isShowUpdateQuotationBtn: boolean = false;
  isShowSendToJPJBtn: boolean = false;
  isShowUploadDocumentBtn: boolean = false;
  isShowAddRemarksBtn: boolean = false;
  isUpdatePaymentBtn: boolean = false;
  isShowDownloadQuotationBtn: boolean = false;
  isShowDownloadCoverNoteBtn: boolean = false;
  isShowDownloadInvoiceBtn: boolean = false;
  isShowUpdateJPJBtn: boolean = false;
  isShowAmendCoverNoteBtn: boolean = false;
  isShowEmailDocumentBtn: boolean = false;
  isShowRequoteBtn: boolean = false;
  isShowMarketingBtn: boolean = false;
  isShowIssueNewBtn: boolean = false;

  monthList = [
    { code: '01', name: '01' },
    { code: '02', name: '02' },
    { code: '03', name: '03' },
    { code: '04', name: '04' },
    { code: '05', name: '05' },
    { code: '06', name: '06' },
    { code: '07', name: '07' },
    { code: '08', name: '08' },
    { code: '09', name: '09' },
    { code: '10', name: '10' },
    { code: '11', name: '11' },
    { code: '12', name: '12' },
  ];

  yearList = [
    { code: '2024', name: '2024' },
    { code: '2025', name: '2025' },
    { code: '2026', name: '2026' },
    { code: '2027', name: '2027' }
  ];

  paymentModeList = [
    { code: 'MASTER', name: 'MASTER' },
    { code: 'VISA', name: 'VISA' }
  ];

  statusMap = {
    Printed: "Printed",
    Approved: "Approved",
    Pending: "Pending Approval",
    Cancelled: "Cancelled",
    Submitted: "Submitted",
    Active: "Active",
    Expired: "Expired"
  };

  classTypeMap = {
    Printed: "printed_badge",
    Approved: "approved_badge",
    Pending: "pending_badge",
    Cancelled: "cancel_badge",
    Submitted: "submitted_badge",
    Active: "active_badge",
    Expired: "expired_badge"
  };


  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  alimCustomerInd: boolean;
  hasShowedOneAllianzPopUp: boolean;
  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;
  polNo: any;
  vehicleUseLov: any;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.referralForm = this.fb.group({
      referralId: ['', Validators.required]
    });

    this.referCaseForm = this.fb.group({
      waiveLoadInd: [{ value: false, disabled: true }],
      waiveExcessInd: [{ value: false, disabled: true }],
      underwritingInd: [true],
      remarks: ['', Validators.required]
    });
  }

  renewalAssistanceForm = new FormGroup({
    renewalAssistant: new FormControl(false),
    paymentMode: new FormControl(''),
    creditCardNo: new FormControl(''),
    creditCardExpiryDateMonth: new FormControl(''),
    creditCardExpiryDateYear: new FormControl(''),
    nameOnCard: new FormControl(''),
    termofUse: new FormControl(false),
    renewalAssistanceStartDate: new FormControl('')
  });

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.isClaimEnquiry = this.queryParams.action === ACTIONS.CLAIMPOLICY;
    this.viewPolicyDetails = this.queryParams.action === ACTIONS.VIEWPOLICY;
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
      }
    });

    this.type = 'view-policy-enquiry';
    this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.polNo = this.queryParams.policyNo;
    this.claimNo = this.queryParams.claimNo;

    if (this.isClaimEnquiry) {
      this.store.dispatch(new GET_VIEW_CLAIM(this.claimNo, this.polNo)).subscribe((state) => {
        this.responseData = state.SatMotorState.viewClaimData;
        this.agentData = this.responseData.agentData;
        this.issuanceInfo = this.responseData.issuanceInfo;
        this.htclClaimDet = this.responseData.htclClaimDet;
        this.clientPartner = this.responseData.clientPartner;
        this.retainNcdInd = this.responseData.retainNcdInd === false ? "NO" : "YES";
        this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
        this.isProgressTypeCN = true;
        this.progressType = 'cn';

        this.showComponents();
        this.isDataLoaded = true;
      });
    } else {
      this.store.dispatch(new GET_VIEW_POLICY(this.polNo)).subscribe((state) => {
        if (state.SatMotorState?.viewPolicyData) {
          this.getVehicleLov();
        }
        
        //Premium Calculation
        this.premiumInfo = state.SatMotorState.viewPolicyData.premiumInfo
        this.premiumInfo = {
          ...this.premiumInfo,
          stamp: this.premiumInfo.stamp ? this.premiumInfo.stamp : 0,
          premiumDueRounded: this.premiumInfo.premiumDueRounded ? this.premiumInfo.premiumDueRounded : 0.0
        };

        this.responseData = state.SatMotorState.viewPolicyData;
        this.cngeNote = this.responseData.cngeNote;
        this.status = this.statusMap[this.cngeNote.policyStatus] || "";
        this.classType = this.classTypeMap[this.cngeNote.cnoteStatus] || "";
        this.proposalNo = this.cngeNote.policyNo || this.cngeNote.proposalNo || this.cngeNote.cnoteNo;

        this.agentData = this.responseData.agentData;
        this.issuanceInfo = this.responseData.issuanceInfo;
        this.cpData = this.responseData.cpData;
        this.coverageInfo = this.responseData.coverageInfo;
        this.premiumInfo = this.responseData.premiumInfo;
        this.remarksInfo = this.responseData.remarksInfo;
        this.showRemarks = !!this.remarksInfo;

        //Referral Id
        this.agentReferId = this.cngeNote.agentReferId;
        this.showReferralDetails = !!this.agentReferId;

        //Historical claims
        this.clientClaimHistory = this.cngeNote.oriClaimCnt ?? '0';
        this.vehicleClaimHistory = this.cngeNote.vehClaimCount ?? '0';

        this.updateProductList(this.responseData.mkagAgent.agentCode, this.cngeNote.productCode);

        this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
        this.isProgressTypeCN = true;
        this.progressType = 'cn';

        this.showComponents();
      });
    }

    // }
  }

  getVehicleLov() {
    let payload = {
      productCode: this.store.selectSnapshot<any>((state) => state.SatMotorState.viewPolicyData.cngeNote.productCode),
      isPolicyEnquiry: true
    }
    this.store.dispatch(new GET_VEHICLE_USE(payload)).subscribe((state) => {
      this.vehicleUseLov = state.SatMotorState.data.vehicleUseLov;
    });
  }


  async updateProductList(agentCode, productCode) {
    this.productList = this.lov?.productList;
    if (!this.productList) {
      let productListPayload = {
        lob: 'MT',
        agentCode: agentCode
      };

      await firstValueFrom(this.store.dispatch(new GET_PRODUCT_LIST(productListPayload)));
      this.productList = this.lov?.productList;
    }
    this.productName = this.productList.find(e => e.code === productCode);
    console.log(this.productList);

    this.getCoverDescription(productCode);
  }

  getCoverDescription(productCode) {
    const productAccessConfigList = this.lov?.productAccessConfigList;
    if (!productAccessConfigList) {
      this.isDataLoaded = true;
      return;
    } 

    const coverListLov = productAccessConfigList.reduce((list, product) => {
      return list.concat(product[productCode]?.coverList || []);
    }, []);

    const coverCodeVal = this.coverageInfo?.coverageDetails?.coverCode;

    if (coverCodeVal) {
      const matchingCover = coverListLov.find(cover => cover.coverCode === coverCodeVal);
      if (matchingCover) {
        const updatedCoverageDetails = {
          ...this.coverageInfo.coverageDetails,
          coverDescp: matchingCover.coverDescription
        };

        this.coverageInfo = {
          ...this.coverageInfo,
          coverageDetails: updatedCoverageDetails
        };
      }
      this.isDataLoaded = true;
    } else {
      this.isDataLoaded = true;
    }
  }

  getCoverageFromPolicy(policy) {
    let riskList = policy.riskList[0];
    let coverageData: any;

    coverageData = {
      coverage: {
        coveragePremium: {
          cngeNote: policy,
        },
        coverageType: {
          ...riskList.coverList[0],
        },
        extracover: {
          driverNameDetails: {
            rows: riskList.cngeRiskDrvrDtoList,
          },
          ehailingDriverDetails: {},
          extraCoverPackage: {},
        },
        vehicleDetails: {
          ...riskList,
        }
      }
    };

    if (coverageData) {
      this.coverageInfo = coverageData;
    }
  }

  showComponents() {
    if (this.isProgressTypeCN) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.showReferCase = false;
    }

    if (this.isRTP) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.viewPolicyDetails = false;
      this.progressType = 'rtp';
    }

    if (this.isClaimEnquiry) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = false;
      this.showCoverage = false;
      this.showHistoricalClaim = false;
      this.showPremiumInformation = false;
      this.showReferralDetails = false;
    }

  }

  componentDialogRef?: NxModalRef<SummaryPopupComponent>;

  openPopup(popup_type: any) {

    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopupOCC(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: '500px',
      data: { popup_type: popup_type },
    });
  }

  formatData(value: string): string {
    if (!value) {
      return 'N/A';
    }
    return value.toUpperCase();
  }
  
  // goToIssueDeclaration() {

  //   this.router.navigate(['issuance/declaration-issuance-info'], {
  //     queryParamsHandling: 'preserve',
  //   });

  // }

  validateReferral() {

    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
    }

  }

  validateReferCase() {
    if (this.referCaseForm.invalid) {
      console.log("referCaseForm invalid!");
      this.referCaseForm.markAllAsTouched();
    } else {
      console.log("referCaseForm valid...");
    }
  }

  validateRenewalAssistance() {
    if (this.renewalAssistanceForm.invalid) {
      console.log("renewalAssistanceForm invalid!");
      this.renewalAssistanceForm.markAllAsTouched();
    } else {
      console.log("renewalAssistanceForm valid...");
    }
  }

  redirectBack() {
    this.store.dispatch(new GET_SWITCH_SITE("ENQUIRY_SYS_URL")).subscribe(state => {
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });
  }

  onChangeRenewalAssistanceForm(fieldName: any, value: any) {
    console.log('onChangeValue..' + value.checked);
    if (fieldName == 'renewalAssistant') {
      //this.renewalAssistanceForm.controls.paymentMode.reset();
      this.renewalAssistanceForm.controls.paymentMode.clearValidators();
      this.renewalAssistanceForm.controls.creditCardNo.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.clearValidators();
      this.renewalAssistanceForm.controls.nameOnCard.clearValidators();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.clearValidators();
      this.renewalAssistanceForm.controls.termofUse.clearValidators();
      if (value == true) {
        this.renewalAssistanceForm.controls.paymentMode.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.setValidators([Validators.required]);
      } else {
        this.renewalAssistanceForm.controls.paymentMode.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.removeValidators([Validators.required]);
      }
      this.renewalAssistanceForm.controls.paymentMode.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardNo.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.updateValueAndValidity();
      this.renewalAssistanceForm.controls.nameOnCard.updateValueAndValidity();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.updateValueAndValidity();
      this.renewalAssistanceForm.controls.termofUse.updateValueAndValidity();
    }
  }

  openPopupRequestCancellation(popup_type: any) {

    let data = {
      summaryData: this.quotationDetail,
      viewPolicyDetails: true
    }
    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if (result) {
        if (result.isCancelAndReplace) {
          const payload = {
            cancelRemarks: result.data.cancelRemarks,
            operator: this.userInfo.userId,
            // contractId: 
            productType: "MT"
          };
          this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
            this.router.navigate(['issuance/summary']);
          });

        } else {
          this.store.dispatch(new REQUEST_CANCELLATION(result.data));
        }
      }
    });
  }

  // updateQuotation() {
  //   this.store.dispatch(new GET_QUOTATION_DETAIL_REQUOTE(this.queryParams.contractId, "REQUOTE")).subscribe((state) => {
  //     let requoteQuotationDetails = state.SatMotorState.requoteQuotation;
  //     if (requoteQuotationDetails) {
  //       this.store.dispatch(new GET_PRODUCT_LIST({ lob: requoteQuotationDetails.productType, agentCode: requoteQuotationDetails.mkagAgent.agentCode })).subscribe((state) => {
  //         this.store.dispatch(new COPY_DRAFT_INFO(requoteQuotationDetails)).subscribe(state => {
  //           if (requoteQuotationDetails.progress) {
  //             this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: requoteQuotationDetails }));
  //           }
  //           this.router.navigate(["/issuance/summary"]);
            
  //         });
  //       });
  //     }
  //   });
  // }

  getDateFormat(value: string): string {
    if (!value || value === '') {
      return null;
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

}


