
import {
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { NxDialogService } from "@aposin/ng-aquila/modal";
import { Select, Store } from '@ngxs/store';
import { IssuanceComponent } from "@shared/components/declaration-issuance/issuance/issuance.component";
import { PdpaComponent } from "@shared/components/declaration-issuance/pdpa/pdpa.component";
import { SET_CURRENT_STEP } from '@store/sat-motor/sat-motor.action';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { SharedModule } from "../../../../shared/shared.module";
import { IssueEndorsementValidationComponent } from "../../endorsement/issue-endorsement-validation/issue-endorsement-validation.component";

@Component({
  selector: 'app-declaration-issuance-info',
  standalone: true,
  imports: [
    SharedModule, 
    ProgressBarComponent, 
    IssueEndorsementValidationComponent,
    PdpaComponent,
    IssuanceComponent
  ],
  templateUrl: './declaration-issuance-info.component.html',
  styleUrl: './declaration-issuance-info.component.scss'
})
export class DeclarationIssuanceInfoComponent {

  @Select(SatMotorState.motorActionType) actionType$;

  isIssuance: boolean = false; 
  isEndorsement: boolean = false; 
  isEnquiry: boolean = false;
  isRTP: boolean = false;
  agentData: any;
  isSingleAgent: boolean = false;

  constructor(
    private dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    this.isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    this.setStep();
    
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

  }
  setStep() {
    let step;
    if (this.isRTP) {
      step = 1;
    } else if (this.isEndorsement) {
      step = 1;
    } else {
      if (((!!this.agentData?.customerType) || this.agentData?.bancaAgent || this.agentData?.sundriesAgent) && !this?.isSingleAgent) {
        step = 2;
      } else {
        step = 1;
      }
    }
    this.store.dispatch(new SET_CURRENT_STEP(step));
  }
}