<div class="title-header nx-margin-0">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <h1>{{ title }}</h1>
        </div>
      </div>
    </div>
</div>

<div class="button_area_bg" *ngIf="popupMessage">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <!-- pending approval (to be move to new component) -->
          <nx-message context="info" class="green_color_notification">
            <span class="nx-font-weight-bold nx-margin-bottom-3xs"
              >{{popupMessage}}</span
            ><br />
  
            <span *ngIf="referralReasonList.length > 0" nxCopytext="normal"
              >Referral reason:
              <ul nxList="small" class="completed_list">
                <li *ngFor="let referrer of referralReasonList">
                  <span class="nx-font-weight-bold">{{ referrer.referCode }}</span> - {{ referrer.referDescp }}
                </li>
              </ul>
            </span>
          </nx-message>
        </div>
      </div>

      @if(remarksSuccessInd) {
        <div nxRow>
          <div nxCol="12">
            <!-- pending approval (to be move to new component) -->
            <nx-message context="info" class="green_color_notification">
              <span class="nx-font-weight-bold">Add remarks is successful.</span>
            </nx-message>
          </div>
        </div>
      }
    </div>
  </div>