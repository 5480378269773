<!-- Payment Information -->
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit">
          Payment Information
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <table nxTable cellpadding="0" class="payment_summary_table">
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal" class="font-grey"
              >Payment Summary</span
            >
          </td>
          <td nxTableCell class="std-ta-right">
            <span nxCopytext="normal" class="font-grey">Total (RM)</span>
          </td>
        </tr>
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Total Amount Payable</span>
          </td>
          <td nxTableCell class="column_cp_content std-ta-right">
            <span nxCopytext="normal">{{data?.totalPayable | formatDecimal}}</span>
          </td>
        </tr>
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Total Amount Payable (Rounded)</span>
          </td>
          <td nxTableCell class="column_cp_content std-ta-right">
            <span nxCopytext="normal">{{data?.totalPayableRounded | formatDecimal}}</span>
          </td>
        </tr>
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Total Amount Paid by Customer</span>
          </td>
          <td nxTableCell class="column_cp_content std-ta-right">
            <span nxCopytext="normal">{{data?.totalPaid | formatDecimal}}</span>
          </td>
        </tr>
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Balance Payable</span>
          </td>
          <td nxTableCell class="column_cp_content std-ta-right">
            <span nxCopytext="normal">{{data?.balancePayable | formatDecimal}}</span>
          </td>
        </tr>
      </table>
    </nx-expansion-panel>
  </nx-accordion>