<h1 nxHeadline size="s" class="nx-font-weight-semibold nx-margin-bottom-2m">Summary</h1>

<!-- //////////////////////////////////////////////////////////////////// -->
<!-- Agent Information -->
@if (showAgentInfo) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Agent Information </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <div nxLayout="grid nopadding">
        <app-agents-information [agentData]="agentData"> </app-agents-information>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- //////////////////////////////////////////////////////////////////// -->
<!-- Issuance Information -->
@if (showIssuanceInfo) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit">
          Issuance Information
          @if (showEditButton) {
          <button nxPlainButton type="button" (click)="goToIssueDeclaration()">EDIT</button>
          }
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <div nxLayout="grid nopadding">
        <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo" [isTypeIssuance]=true></app-issuance-information>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- //////////////////////////////////////////////////////////////////// -->
<!-- Customer Partner -->
@if (showCustomerPartner) {
  <nx-accordion variant="light">
    <nx-expansion-panel [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Customer Partner
            @if (showEditButton) {
            <button nxPlainButton type="button" (click)="goToCPScreen()">EDIT</button>
            }
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-customer-partner-details [viewOnly]="true" [cpType]="'FOUND'" [hideEdit]="true" [cpDetails]="cpData" [lov]="lov"> </app-customer-partner-details>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- ///////////////////////////////////////////////////////////////////// -->
<!-- Referral Details -->
@if (showReferralDetails) { <!-- agentData.sundriesAgent -->
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Referral Details </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12,12,6">
            <form [formGroup]="referralForm">
              <nx-formfield label="Referral ID No.">
                <input nxInput type="text" appToUpperCase formControlName="referralId" style="text-transform: uppercase" (blur)="onBlurReferralId()" maxlength="15" />
                <nx-error nxFormfieldError>
                  <strong>Please enter Referral ID No.</strong>
                </nx-error>
              </nx-formfield>
            </form>
          </div>
        </div>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- ////////////////////////////////////////////// -->
<!-- Coverage -->
@if (showCoverage) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordione_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Coverage
            @if (showEditButton) {
            <button nxPlainButton type="button" (click)="goToCoverageScreen()">EDIT</button>
            }
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-coverage-summary [coverageInfo]="coverageInfo" [isRTP]="isRTP"></app-coverage-summary>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- ////////////////////////////////////////////// -->
<!-- Historical Claim -->
@if (showHistoricalClaim) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Historical Claim </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <table nxTable cellpadding="0" class="summary_table">
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Client Claim History</span>
          </td>
          <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ clientClaimHistory }}</span>
          </td>
        </tr>
        <tr nxTableRow>
          <td nxTableCell>
            <span nxCopytext="normal">Vehicle Claim History</span>
          </td>
          <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ vehicleClaimHistory }}</span>
          </td>
        </tr>
      </table>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- ////////////////////////////////////////////// -->
<!-- Premium Information -->
@if (showPremiumInformation) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Premium Information </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-premium-information [premiumInfo]="premiumInfo" [isRTP]="isRTP" [isIssuance]="isIssuance" [isEndorsement]="isEndorsement"> </app-premium-information>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- ////////////////////////////////////////////// -->
<!-- Refer Case (if any) -->
@if (showReferCase) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Refer Case (if any) </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <form [formGroup]="referCaseForm">
        <div nxLayout="grid nopadding" class="grid_remove_padding">
          <div nxRow>
            <div nxCol="12,12,4" class="nx-margin-bottom-2m">
              <nx-checkbox formControlName="waiveLoadInd" (change)="saveSummaryStep()">Refer Waive Load Ind.</nx-checkbox>
            </div>
            <div nxCol="12,12,4" class="nx-margin-bottom-2m">
              <nx-checkbox formControlName="waiveExcessInd" (change)="saveSummaryStep()">Refer Waive Excess Ind.</nx-checkbox>
            </div>
            <div nxCol="12,12,4" class="nx-margin-bottom-2m">
              <nx-checkbox formControlName="underwritingInd" (change)="saveSummaryStep()">Refer for Underwriting</nx-checkbox>
            </div>
          </div>
        </div>

        <nx-formfield label="Referral Remarks">
          <textarea required nxInput type="text" appToUpperCase formControlName="remarks" style="text-transform: uppercase" #remarksCount maxlength="200" cdkTextareaAutosize (blur)="saveSummaryStep()"></textarea>
          @if (remarksCount.value?.length === 0) {
          <span nxFormfieldHint>Max. 200 Characters</span>
          }

          @if (remarksCount.value.length > 0) {
          <span nxFormfieldHint>{{ 200 - (remarksCount.value.length || 0) }} {{ 'COMMON.CHAR_REMAINING' | translate }}</span>
          }
          <nx-error nxFormfieldError>
            <strong>Please enter referral remarks</strong>
          </nx-error>
        </nx-formfield>

        <!-- <nx-error nxFormfieldError class="nx-margin-bottom-2m">
                            <strong>Please enter referral remarks</strong>
                        </nx-error> -->
      </form>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- OCC Section (to be move to new component) -->

<!-- ////////////////////////////////////////////// -->
<!-- Payment -->
@if (showPayment) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Payment </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-payment [payment]="payment"></app-payment>

    </nx-expansion-panel>
  </nx-accordion>
}
<!-- ////////////////////////////////////////////// -->
<!-- Road Tax Payable -->
@if (showRoadTaxPayable) {
  <app-road-tax-payable (onChangePaymentSection)="onChangePaymentSection()" [parentForm]="summaryForm" [data]="roadTaxPayable">
  </app-road-tax-payable>
}
<!-- ////////////////////////////////////////////// -->
<!-- Upsell Product -->
@if (showUpsellProduct) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Upsell Product </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <app-upsell-product [parentForm]="summaryForm"></app-upsell-product>
    </nx-expansion-panel>
  </nx-accordion>
}
<!-- ////////////////////////////////////////////// -->
<!-- Payment Information -->
@if (showPaymentInformation) {
  <app-payment-information [premiumInfo]="paymentInformation"></app-payment-information>
}
<!-- ////////////////////////////////////////// -->
<!-- Payment Details -->
@if (showPaymentDetails) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Payment Details </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <app-payment-details [userInfo]="userInfo" [agentInfo]="agentData" (updatePaymentList)="updatePaymentList($event)"
      [paymentList]="paymentList">
      </app-payment-details>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- Endorsement Checklist (to be move to new component) -->
<!-- ////////////////////////////////////////////////// -->
<!-- Checklist -->
@if (showChecklist) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Checklist </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <div nxLayout="grid nopadding" class="grid_remove_padding">
        <!-- Amendment of Period, Extra Benefits, NCD Allowed, NCD Amendment, NCD Withdraw, Reinstatement of Extra Coverage, Revision of Sum Insured -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">1</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Insured or Agent Authorised letter / form / email (with contact number).</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- NCD Allowed, NCD Amendment -->
        <div nxRow>
          <div nxCol="12">
            <p nxCopytext="medium" class="nx-margin-bottom-2m">
              <i>Note: Insured IC copy/E-payment form/copy of bank book-statement required (if refund involved).</i>
            </p>
          </div>
        </div>

        <!-- Amendment of Period -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">2</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Copy of VOC / Registration card (for brand new only).</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cancellation by Insured, Hire Purchase/Employers' Loan/Leasing, Named Driver -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">1</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Insured Authorised letter/form/email (with contact number).</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <p nxCopytext="medium" class="nx-margin-bottom-2m">
              <i>Note: Warranted no claim as at todate.</i>
            </p>
          </div>
        </div>

        <!-- Change of Bancassurance Details -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">1</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Internal email communication for changes.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">1</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Insured or Agent Authorised letter/form/email (with contact number) OR</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">2</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Copy of VOC/Registration card.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Extra Benefits, Named Driver, Revision of Sum Insured -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">2</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Vehicle inspection done.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Extra Benefits, Named Driver -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">3</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Warranted no claim as at todate.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <p nxCopytext="medium" class="nx-margin-bottom-2m">
              <i>Note: Inclusion of extra benefits will only be allowed warranted no claim as at the date of endt.</i>
            </p>
          </div>
        </div>

        <!-- Named Driver -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">4</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Copy of I/C / passport.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- NCD Withdraw -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">2</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Claim/damage to the vehicle.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <p nxCopytext="medium" class="nx-margin-bottom-2m">
              <i>Note: Insured IC copy/Epayment form/copy of bank book-statement required (if refund involved).</i>
            </p>
          </div>
        </div>

        <!-- Reinstatement of Extra Coverage -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">2</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Inspection done (required only if exceeded 14 days).</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Revision of Sum Insured -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">3</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Requested by Bank/Finance owner.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Revision of Sum Insured -->
        <div nxRow class="nx-margin-bottom-2m">
          <div nxCol="2,2,1" class="checklist">
            <span nxCopytext="normal">4</span>
          </div>
          <div nxCol="10,10,11" class="checklist">
            <span nxCopytext="normal">Purchase invoice - for new cases & vehicles from Langkawi/Labuan only.</span>
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
              <div nxRow>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">Yes</nx-radio>
                </div>
                <div nxCol="4,4,3,2" class="checklist_radio">
                  <nx-radio name="yesno">No</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <p nxCopytext="medium" class="nx-margin-bottom-2m">
              <i>Note: Warranted no claim as at todate.</i>
            </p>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Remarks">
              <textarea nxInput type="text" #inputToCount maxlength="200" cdkTextareaAutosize></textarea>
              <span nxFormfieldHint>Max. 200 Characters</span>
            </nx-formfield>
          </div>
        </div>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- Renewal Assistance (to be move to new component) -->

<!-- ///////////////////////////////////////////////////// -->
<!-- Renewal Assistance -->
@if (showRenewalAssistant) {
  <form [formGroup]="renewalAssistanceForm">
    <nx-accordion variant="light">
      <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
          <nx-expansion-panel-title class="accordion_edit"> Renewal Assistance </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <nx-checkbox formControlName="renewalAssistant" (checkboxChange)="onChangeRenewalAssistanceForm('renewalAssistant', $event.checked)" class="nx-margin-bottom-2m nx-margin-top-xs"> Renewal Assistance </nx-checkbox>

        <div nxLayout="grid nopadding">
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="Payment Mode">
                <nx-dropdown formControlName="paymentMode">
                  <ng-container *ngFor="let mode of paymentModeList">
                    <nx-dropdown-item [value]="mode.code">{{ mode.name }}</nx-dropdown-item>
                  </ng-container>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  <strong>Please enter Payment mode.</strong>
                </nx-error>
              </nx-formfield>
            </div>

            <div nxCol="12,12,6">
              <nx-formfield label="Credit Card No">
                <input formControlName="creditCardNo" nxInput />
                <span nxFormfieldHint class="std-ta-left">eg. 5500000000000004</span>
                <nx-error nxFormfieldError>
                  <strong>Please enter Credit Card No.</strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
        </div>

        <p nxCopytext="normal" class="nx-margin-bottom-2m">Credit Card Expiry Date</p>

        <div nxLayout="grid nopadding">
          <div nxRow>
            <div nxCol="12,12,6">
              <div nxLayout="grid nopadding" class="grid_remove_padding">
                <div nxRow>
                  <div nxCol="5" class="renewal_monthyear">
                    <nx-formfield label="Month">
                      <nx-dropdown formControlName="creditCardExpiryDateMonth">
                        <ng-container *ngFor="let month of monthList">
                          <nx-dropdown-item [value]="month.code">{{ month.name }}</nx-dropdown-item>
                        </ng-container>
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please enter Month.</strong>
                      </nx-error>
                    </nx-formfield>
                  </div>

                  <div nxCol="2" class="renewal_monthyear">
                    <p nxCopytext="large">/</p>
                  </div>

                  <div nxCol="5" class="renewal_monthyear">
                    <nx-formfield label="Years">
                      <nx-dropdown formControlName="creditCardExpiryDateYear">
                        <ng-container *ngFor="let year of yearList">
                          <nx-dropdown-item [value]="year.code">{{ year.name }}</nx-dropdown-item>
                        </ng-container>
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please enter Year.</strong>
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>
            </div>

            <div nxCol="12,12,6">
              <nx-formfield label="Name on Card">
                <input formControlName="nameOnCard" nxInput />
                <nx-error nxFormfieldError>
                  <strong>Please enter Name on Card.</strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="Renewal Assistance Start Date">
                <input formControlName="renewalAssistanceStartDate" nxDatefield nxInput [datepicker]="myDatepicker2" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker2></nx-datepicker>
                <nx-error nxFormfieldError>
                  <strong>Please enter Renewal Assistance Start Date</strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
        </div>

        <nx-checkbox formControlName="termofUse">Term of Use</nx-checkbox>
      </nx-expansion-panel>
    </nx-accordion>
  </form>
}

<!-- Endorsement Narration (to be move to new component) -->

<!-- ///////////////////////////////////////////////// -->
<!-- Endorsement Narration -->
@if (showEndorsementNarration) {
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Endorsement Narration </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-endorsement-narration></app-endorsement-narration>

      <!-- <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY</u>

      <p nxCopytext="normal" class="nx-margin-y-m">
        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
        <span class="nx-font-weight-bold">13/12/2023</span> THE ENGINE NUMBER AND/OR CUBIC CAPACITY IS/ARE AMENDED TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
      </p>

      <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">ENGINE / MOTOR NO. : 2NROP28330<br />CUBIC CAPACITY : 1,298 CC</p>

      <p nxCopytext="normal" class="nx-margin-y-m">
        IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
        <span class="nx-font-weight-bold">RM0.00</span> IS HEREBY CHARGED TO THE INSURED.
      </p>

      <p nxCopytext="normal">ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.</p>

      <div class="nx-margin-y-2m"></div>

      <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF MAKE & MODEL</u>

      <p nxCopytext="normal" class="nx-margin-y-m">
        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
        <span class="nx-font-weight-bold">13/12/2023</span> THE MAKE & MODEL IS AMENDED TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
      </p>

      <p nxCopytext="normal">MAKE & MODEL:</p>
      <span nxCopytext="normal">
        <ol nxList="small" class="upload_content nx-margin-bottom-2m">
          <li>
            <span class="nx-font-weight-bold">PERODUA MYVI 1.3 (A) SE</span>
          </li>
        </ol>
      </span>

      <p nxCopytext="normal">ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.</p>

      <div class="nx-margin-y-2m"></div>

      <u nxCopytext="normal" class="nx-font-weight-semibold">INCLUSION OF NAMED DRIVER(S)</u>

      <p nxCopytext="normal" class="nx-margin-y-m">
        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
        <span class="nx-font-weight-bold">13/12/2023</span> THE FOLLOWING NAMED DRIVER(S) IS/ARE INCLUDED INTO THE POLICY.
      </p>

      <p nxCopytext="normal" class="nx-font-weight-bold">NO NAMED DRIVER(S) - DRIVER ID</p>
      <span nxCopytext="normal">
        <ol nxList="small" class="upload_content_bold nx-margin-bottom-2m nx-font-weight-bold">
          <li>
            <span class="nx-font-weight-bold">WONG KAM WAI - 880914145277</span>
          </li>
        </ol>
      </span>

      <p nxCopytext="normal">ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.</p> -->
    </nx-expansion-panel>
  </nx-accordion>
}

<!-- //////////////////////////////////////////////////////////////////// -->
@if (showEndorsementErrMessage) {
  <p nxCopytext="medium" class="nx-margin-top-2m">
    <i>* You will not be able to save as quotation, please proceed to generate Endorsement if details has been finalised.</i>
  </p>
}

<div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
  <!-- <a routerLink="../coverage"><button nxButton="secondary" type="button">BACK</button></a> -->
  @if(!isUpdatePayment) {
  <a><button nxButton="secondary" type="button" (click)="goBack()">BACK</button></a>
  }

  <button *ngIf="!isRTP && !isUpdatePayment && !generateQuotationIsDone" nxButton="primary" type="button" (click)="generate()">Generate e-Quotation</button>

  @if(isUpdatePayment) {
  <button nxButton="primary" type="button" (click)="savePayment()">SAVE</button>
  }

  @if(!isUpdatePayment && !isCCentric) {
  <a><button nxButton="primary" type="button" (click)="sendToJPJ()">SEND TO JPJ</button></a>
  }

  <!-- <a><button nxButton="primary" type="button" (click)="downloadPDF()"> e-Quotation PDF </button></a> -->
  <button *ngIf="!isRTP && !isUpdatePayment" nxButton="primary" type="button" (click)="uploadDocument()">UPLOAD DOCUMENT</button>
</div>
