import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

import { SharedModule } from "@shared/shared.module";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { UPDATE_COVERAGE_ALERT, STORE_SHOWED_ONE_ALLIANZ_POP_UP, UPDATE_COVERAGE_TYPE } from "@store/sat-motor/sat-motor.action";
import { CoverageComprehensiveComponent } from "@shared/components/coverages/coverage-comprehensive/coverage-comprehensive.component";
import { CoverageThirdPartyComponent } from "@shared/components/coverages/coverage-third-party/coverage-third-party.component";
import { CoverageThirdPartyFireTheftComponent } from "@shared/components/coverages/coverage-third-party-fire-theft/coverage-third-party-fire-theft.component";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Observable } from "rxjs";
import { UserResponse } from "@interfaces/user";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { OneAllianzMessageComponent } from "@shared/components/modal/one-allianz-message/one-allianz-message.component";
import { OkMessageComponent } from "@shared/components/modal/ok-message/ok-message.component";

@Component({
  selector: "app-type-of-coverage",
  standalone: true,
  imports: [SharedModule, CoverageComprehensiveComponent, CoverageThirdPartyComponent, CoverageThirdPartyFireTheftComponent],
  templateUrl: "./type-of-coverage.component.html",
  styleUrl: "./type-of-coverage.component.scss",
})
export class TypeOfCoverageComponent implements OnInit {
  @ViewChild(CoverageComprehensiveComponent) coverageComprehensiveComponentChild: CoverageComprehensiveComponent;
  @ViewChild(CoverageThirdPartyComponent) coverageThirdPartyComponentChild: CoverageThirdPartyComponent;
  @ViewChild(CoverageThirdPartyFireTheftComponent) coverageThirdPartyFireTheftComponentChild: CoverageThirdPartyFireTheftComponent;

  @Input() coverageFmGroup!: FormGroup;
  @Input() partnerInfo: any;
  @Input()
  get coverListLov() {
    return this.coverList;
  }
  set coverListLov(coverList: any) {
    this.coverList = coverList;
  }

  @Output() calculatePremium = new EventEmitter<any>();

  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;
  @Select(SatMotorState.coverageDefaultDriver) coverageDefaultDriver$: Observable<SatMotorState>;

  
  lov: any;
  userInfo: any;
  issuanceInfo: any;
  coverList: any;
  typeOfCoverage: any;
  isComprehensive = false;
  isThirdParty = false;
  isThirdPartyFireTheft = false;
  hasShowedBettermentPopUp: boolean = false;

  vehicleNo: any;

  decimalFmt: any;
  numberFmt: any;

  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;
  bettermentModal?: NxModalRef<OkMessageComponent>;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService) {
      this.decimalFmt = new FormatDecimalPipe();
      this.numberFmt = new RemoveCommaPipe();
  }

  ngOnInit() {
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.issuanceInfo = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor.step1.issuance);
    this.vehicleNo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.vehicleNo);

    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);

    this.setupCoverList(true);

    if (this.typeOfCoverage) {
      this.setupCoverList(false);
      this.isComprehensive = this.typeOfCoverage.coverCode === "01";
      this.isThirdParty = this.typeOfCoverage.coverCode === "20";
      this.isThirdPartyFireTheft = this.typeOfCoverage.coverCode === "30";
    }
    
    this.coveragePremium$.subscribe((state) => {
      if (state) {
        const cngeNote = state["cngeNote"];
        if (cngeNote) {
          const riskList = cngeNote["riskList"];
          if (riskList?.length) {
            for (let riskListItem of riskList) {
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  this.coverageFmGroup.controls["typeOfCoverage"].patchValue(this.transform(coverListItem, true));
                  if (coverListItem["coverCode"] === "20") {
                    this.coverageFmGroup.controls["typeOfCoverage"].patchValue({
                      grossPremium: this.decimalFmt.transform(coverListItem["grossPremium"])
                    });
                  }
                  this.store.dispatch(new UPDATE_COVERAGE_TYPE(this.transform(coverListItem, false)));
                }
              }
            }
          }
        }
      }
    });
    
  }

  transform(value, output) {
    let tvalue = { prevNcdVeh: this.vehicleNo };
    ["sumInsured", "excessAmount", "annualPremium", "basicPremium", "grossPremium", "allriderPrem", "ncdPercentage", "ncdAmount"].forEach(v => {
      tvalue = { ...tvalue, [v]: (output ? this.decimalFmt.transform(value[v]) : this.numberFmt.transform(value[v]) ) };
    });
    return tvalue;
  }

  private setupCoverList(enabled: boolean) {
    let coverList = JSON.parse(JSON.stringify(this.coverList));
    coverList.forEach((cv: { enable: boolean }) => {
      cv.enable = enabled;
    });
    this.coverList = coverList;
  }

  addCover(cover: any) {
    if (this.validateVehicleDetails()) {
      this.typeOfCoverage = cover;
      this.isComprehensive = cover.coverCode === "01";
      this.isThirdParty = cover.coverCode === "20";
      this.isThirdPartyFireTheft = cover.coverCode === "30";
  
      this.setupCoverList(false);
  
      this.calculatePremium.emit(cover);
    }
  }

  onCoverDeleted() {
    this.isComprehensive = this.isThirdParty = this.isThirdPartyFireTheft = false;
    this.setupCoverList(true);
    this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(false)).subscribe(() => {
      this.store.dispatch(new UPDATE_COVERAGE_ALERT({}))
    });
    this.hasShowedBettermentPopUp = false;
  }

  onCoverFormChanged(data) {
    this.calculatePremium.emit(data);
  }

  openPopupOneAllianz() {
    if (!this.store.selectSnapshot<boolean>((state) => state.SatMotorState.data?.hasShowedOneAllianzPopUp)) {
      this.oneAllianzModal = this.dialogService.open(OneAllianzMessageComponent, {
        showCloseIcon: false,
        width: "400px",
        maxWidth: "400px",
      });

      this.oneAllianzModal.afterClosed().subscribe((result) => {
        this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(true)).subscribe(() => {
          this.store.dispatch(new UPDATE_COVERAGE_ALERT({ alimCustomerEligible: false, alimCustomerEligibleShowed: true }));
        });
      });
    }
  }

  openPopupBetterment() {
    if (!this.hasShowedBettermentPopUp) {
      this.bettermentModal = this.dialogService.open(OkMessageComponent, {
        showCloseIcon: false,
        width: "400px",
        maxWidth: "400px",
        data: {
          message: "Your vehicle is subject to betterment, kindly consider purchasing this coverage."
        }
      });

      this.bettermentModal.afterClosed().subscribe((result) => {
        this.hasShowedBettermentPopUp = true;
        this.store.dispatch(new UPDATE_COVERAGE_ALERT({ bettermentEligible: false, bettermentEligibleShowed: true }));
      });
    }

  }
  
  validateVehicleDetails() {
    let vehicleDetailsFm = ((this.coverageFmGroup.controls["vehicleDetails"]) as FormGroup); 
    if (!vehicleDetailsFm.controls["makeModelDesc"].valid) {
      vehicleDetailsFm.controls["makeModelDesc"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }
    return true;
  }

  callTypeOfCoverageComponent(){
    if(this.isComprehensive){
      return this.coverageComprehensiveComponentChild.callComprehensiveComponent();

    }else if(this.isThirdParty){
      return this.coverageThirdPartyComponentChild.callThirdPartyComponent();

    }else if(this.isThirdPartyFireTheft){
      return this.coverageThirdPartyFireTheftComponentChild.callThirdPartyFireTheftComponent();
    }

    return true;
  }
}
