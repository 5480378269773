import { Component, Input } from "@angular/core";
import { NxMultiStepperComponent, NxMultiStepperDirection } from "@aposin/ng-aquila/progress-stepper";
import { Select } from "@ngxs/store";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";

@Component({
    selector: "steps",
    standalone: true,
    imports: [SharedModule],
    templateUrl: "./steps.component.html",
    styleUrls: ["./steps.component.scss"],
    host: {
        "[class.is-vertical]": 'direction === "vertical"',
    },
})
export class StepsComponent {
    @Select(SatMotorState.progressStep) progressStep$;

    @Input("steps") steps: any = [];
    @Input("index") index = 1;
    @Input("currentStep") currentStep: number = 1;

    direction: NxMultiStepperDirection = "vertical";
    stepper!: NxMultiStepperComponent;

    isCurrentStep(index: number): boolean {
        return index + 1 === this.currentStep;
    }

    isBold(i: number): boolean {
        return i + 1 === Number(this.index);
    }

    getNumber(index: any, step: any) {
        // index + 1 is the one selected by user
        // this.currentStep is the progress that already fill by user
        // this.index is the current active step

        const selectedIndex = index + 1;
        // does not allow change.
        // step.disable does not allow user to select the step.
        // this.steps[this.index - 1].disable does not allow user to step out of the step.
        if (step.disable || this.steps[this.index - 1]?.disable) {
            console.warn("not change");
            return null;
        }
        return;
    }
}
