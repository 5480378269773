import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-information',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './payment-information.component.html',
  styleUrl: './payment-information.component.scss'
})
export class PaymentInformationComponent implements OnInit {
  @Select(SatMotorState.summaryPremium) summaryPremium$: Observable<SatMotorState>;
  @Select(SatMotorState.endtSummaryPremium) endtSummaryPremium$: Observable<SatMotorState>;
  @Input() premiumInfo: any;
  @Input() isEndorsement: boolean;
  data: any = {};

  ngOnInit() {
    if (this.premiumInfo) {
      this.data = this.premiumInfo;
    }

    if (!this.isEndorsement) {
      this.summaryPremium$.subscribe((state) => {
        if (state) {
          const riskList = state['riskList'][0];
          const coverList = riskList['coverList'][0];
          this.data = {
            totalPayable: state['totalPayable'],
            totalPayableRounded: state['totalPayableRounded'],
            totalPaid: state['totalPaid'],
            balancePayable: state['balancePayable']
          };
        }
      });
    }else {
      this.endtSummaryPremium$.subscribe((state) => {
        if (state) {
          const cngeNote = state['data']['cngeNoteDto'];
          this.data = {
            totalPayable: cngeNote['totalPayable'],
            totalPayableRounded: cngeNote['totalPayableRounded'],
            totalPaid: cngeNote['totalPaid'],
            balancePayable: cngeNote['balancePayable']
          };
        }
      });
    }
    
  }
  
}
