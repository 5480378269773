
import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  ViewChild,
} from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../../../popup/pdpa-popup/pdpa-popup.component";
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { UserResponse } from "@interfaces/user";
import { SET_CURRENT_STEP, STORE_AGENT_ADDITIONAL } from "@store/sat-motor/sat-motor.action";

@Component({
  selector: 'app-agent-additional-info',
  standalone: true,
  imports: [SharedModule, ProgressBarComponent],
  templateUrl: './agent-additional-info.component.html',
  styleUrl: './agent-additional-info.component.scss'
})
export class AgentAdditionalInfoComponent {

  
  @Select(SatMotorState.motorAgentAdditionalDetail) agentAdditionalDetail$;

  userInfo: any;

  agentData: any;
  customerType: any;
  isBACustomer: Boolean = false;
  isFranchiseCustomer: Boolean = false;
  isBancaUser: Boolean = false;
  isSundriesUser: Boolean = false;
  commonLov: any;
  isSingleAgent: boolean = false;

  bankChannels: any[] = [];
  bankSubChannels: any[] = [];
  bankBranches: any[] = [];
  salesCampaigns: any[] = [];
  bankClientTypes: any[] = [];

  selectedBankChannel: any;
  selectedBankSubChannel: any
  additionalInfoForm: any;

  constructor(
    private dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
  ) {
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.commonLov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    if(this.agentData) {
      this.setUserTypes(this.agentData);
      this.bankChannels = this.agentData.bankChannels;
      if (this.agentData.bankClientTypes) {
        this.bankClientTypes = this.agentData.bankClientTypes.map(client => ({
          ...client,
          descp: client.descp.toUpperCase()
        }));
      }
    }
  }

  setUserTypes(data: any) {
    if (data.customerType) {
      this.isBACustomer = data.customerType === 'BA';
      this.isFranchiseCustomer = data.customerType === 'FR';
      this.customerType = this.isBACustomer ? "BANCASSURANCE" : "FRANCHISE & OTHERS";
    } else {
      this.isBancaUser = data.bancaAgent;
      this.isSundriesUser = data.sundriesAgent;
    }
    this.setFormStructure();
  }

  setFormStructure() {
    if (this.isBACustomer) {
      this.additionalInfoForm = new FormGroup({
        bankType: new FormControl('', Validators.required),
        bankChannel: new FormControl('', Validators.required),
        bankSubChannel: new FormControl('', Validators.required),
        bankBranch: new FormControl('', Validators.required)
      });
    } else if (this.isFranchiseCustomer) {
      this.additionalInfoForm = new FormGroup({
      });
    
    } else if (this.isBancaUser) {
      this.additionalInfoForm = new FormGroup({
        bankType: new FormControl('', Validators.required),
        bankChannel: new FormControl('', Validators.required),
        bankSubChannel: new FormControl('', Validators.required),
        bankBranch: new FormControl('', Validators.required),
        bankStaffNo: new FormControl(''),
        bankStaffName: new FormControl(''),
        referralSalesStaffId: new FormControl(''),
        referralSalesStaffName: new FormControl(''),
        bankClientType: new FormControl('', Validators.required),
        salesCampaign: new FormControl(''),
        rebateType: new FormControl('')
      });
    
    } else if (this.isSundriesUser) {
      this.additionalInfoForm = new FormGroup({
        rebateType: new FormControl('')
      });
    }
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.setStep();
    this.agentAdditionalDetail$.subscribe((state) => {
      if (state) {
        this.populateFormFields(state);
      }
    });
  }

  setStep() {
    let step;
    {
      step = 1;
      this.store.dispatch(new SET_CURRENT_STEP(step));
    }
  }

  populateFormFields(state: any) {
    if (this.isBACustomer) {
      this.additionalInfoForm.controls?.bankType?.setValue(state.bankType);
      this.additionalInfoForm.controls?.bankChannel?.setValue(state.bankChannel);
      this.onBankChannelChange(state.bankChannel);
      this.additionalInfoForm.controls?.bankSubChannel?.setValue(state.bankSubChannel);
      this.onBankSubChannelChange(state.bankSubChannel);
      this.additionalInfoForm.controls?.bankBranch?.setValue(state.bankBranch);
    
    } else if (this.isFranchiseCustomer) {
      //this.additionalInfoForm.controls?.rebateType?.setValue(state.rebateType);
    
    } else if (this.isBancaUser) {
      this.additionalInfoForm.controls?.bankType?.setValue(state.bankType);
      this.additionalInfoForm.controls?.bankChannel?.setValue(state.bankChannel);
      this.onBankChannelChange(state.bankChannel);
      this.additionalInfoForm.controls?.bankSubChannel?.setValue(state.bankSubChannel);
      this.onBankSubChannelChange(state.bankSubChannel);
      this.additionalInfoForm.controls?.bankBranch?.setValue(state.bankBranch);

      this.additionalInfoForm.controls?.bankStaffNo?.setValue(state.bankStaffNo);
      this.additionalInfoForm.controls?.bankStaffName?.setValue(state.bankStaffName);
      this.additionalInfoForm.controls?.referralSalesStaffId?.setValue(state.referralSalesStaffId);
      this.additionalInfoForm.controls?.referralSalesStaffName?.setValue(state.referralSalesStaffName);

      this.additionalInfoForm.controls?.salesCampaign?.setValue(state.salesCampaign);
      this.additionalInfoForm.controls?.bankClientType?.setValue(state.bankClientType);
    
    } else if (this.isSundriesUser) {
      this.additionalInfoForm.controls?.rebateType?.setValue(state.rebateType);
    }
  }

  onBankChannelChange(code: string) {
    const selectedChannel = this.bankChannels.find(channel => channel.code === code);
    if (selectedChannel) {
      this.bankSubChannels = selectedChannel.subList || [];
      this.bankBranches = [];
      this.salesCampaigns = [];
      this.additionalInfoForm.controls?.bankSubChannel?.setValue('');
      this.additionalInfoForm.controls?.bankBranch?.setValue('');
      this.additionalInfoForm.controls?.salesCampaign?.setValue('');
    }
  }

  onBankSubChannelChange(code: string) {
    const selectedSubChannel = this.bankSubChannels.find(subChannel => subChannel.code === code);
    if (selectedSubChannel) {
      this.bankBranches = selectedSubChannel.subList || [];
      this.salesCampaigns = selectedSubChannel.salesCampaignList || [];
      this.additionalInfoForm.controls?.bankBranch?.setValue('');
      this.additionalInfoForm.controls?.salesCampaign?.setValue('');
    }
  }

  goToSelectAgentCode() {
    console.log('goToSelectAgentCode..');
    this.router.navigate(['agent-selection'], {
      queryParamsHandling: 'preserve',
      });
  }

  goNext() {
    let agentAdditionalData = this.additionalInfoForm.getRawValue();
    this.store.dispatch(new STORE_AGENT_ADDITIONAL(agentAdditionalData)).subscribe(() => {
      this.router.navigate(['issuance/declaration-issuance-info'], {
        queryParamsHandling: 'preserve',
      });
    });

  }

}
