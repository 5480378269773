<div *ngIf="popup_type == 'sanction'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Unable to Process this Request Due to Sanction/Compliance Requirement.,
      <br />
      Policy Holder - Name: WONG KAM WAI, <br />
      Insured - Name: WONG KAM WAI <br />
      ID: 880914145277
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'blacklist'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Your application has been blocked, kindly contact your branch.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'doc'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Please provide the mandatory supporting documents via Upload Document.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'cn'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Cover Note Stock is not available.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'cnnull'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Cover Note Stock Limit is null.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'cn0'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Your cover note stock limit is zero. Kindly contact your branch.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'oustanding'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Please settle outstanding payment to comply <br />
      with CBC Regulations and to avoid suspension of account.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
    >
      OK
    </button>
  </div>
</div>


<!-- pop up address -->
<div *ngIf="popup_type == 'address'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Customer full correspondence address are mandatory.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
      (click)="returnCP()"
    >
      OK
    </button>
  </div>
</div>

<!-- pop up address & email checking-->
<div *ngIf="popup_type == 'addrEmailSum'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Customer full correspondence address and email address are mandatory.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
      (click)="returnCP()"

    >
      OK
    </button>
  </div>
</div>

<!-- pop up email -->
<div *ngIf="popup_type == 'emailSum'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Customer email address is mandatory.
    </p>
    <button
      nxModalClose
      nxButton="primary"
      type="button"
      (click)="returnCP()" 
    >
      OK
    </button>
  </div>
</div>


<!-- PopUp for Add / Edit payment at OCC (to be move to new component) -->
<div *ngIf="popup_type == 'occ'">
  <h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">Add Payment Mode</h3>

  <h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">
    Edit Payment Mode
  </h3>

  <nx-formfield label="Payment Paid by Customer (RM) ">
    <input nxInput readonly value="1,843.40" />
  </nx-formfield>

  <nx-formfield label="Payment Mode">
    <nx-dropdown
      [showFilter]="true"
      filterPlaceholder="Type to search payment mode"
    >
      <nx-dropdown-item value="CASH (OVER THE COUNTER)"></nx-dropdown-item>
      <nx-dropdown-item value="CASH DEPOSIT MACHINE"></nx-dropdown-item>
      <nx-dropdown-item value="CHEQUE"></nx-dropdown-item>
      <nx-dropdown-item value="ATM TRANSFER"></nx-dropdown-item>
      <nx-dropdown-item value="CREDIT CARD"></nx-dropdown-item>
      <nx-dropdown-item
        value="I-FUND TRANSFER / CIMB CLICKS"
      ></nx-dropdown-item>
      <nx-dropdown-item value="INTER BANK (GIRO)"></nx-dropdown-item>
    </nx-dropdown>
  </nx-formfield>

  <nx-formfield label="Issuing Bank Name ">
    <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search bank">
      <nx-dropdown-item
        value="ALLIANCE BANK MALAYSIA BERHAD"
      ></nx-dropdown-item>
      <nx-dropdown-item value="ALLIANCE ISLAMIC BANK"></nx-dropdown-item>
      <nx-dropdown-item value="ABN AMRO BANK BERHAD"></nx-dropdown-item>
      <nx-dropdown-item value="ASIAN FINANCE BANK BERHAD"></nx-dropdown-item>
      <nx-dropdown-item value="AFFIN BANK BERHAD"></nx-dropdown-item>
      <nx-dropdown-item value="AFFIN ISLAMIC BANK"></nx-dropdown-item>
    </nx-dropdown>
  </nx-formfield>

  <nx-formfield label="Issuing Bank Branch">
    <input nxInput />
  </nx-formfield>

  <nx-formfield label="Cheque No.">
    <input nxInput />
  </nx-formfield>

  <nx-formfield label="Pay in Slip / Ref No.">
    <input nxInput value="12345678" />
    <span nxFormfieldHint class="std-ta-left">eg: 123456789123456</span>
  </nx-formfield>

  <nx-formfield label="CDM ID/BRCD">
    <input nxInput value="9815" />
    <span nxFormfieldHint class="std-ta-left">eg: S145/9815</span>
  </nx-formfield>

  <nx-formfield label="SEQ No.">
    <input nxInput value="5431" />
    <span nxFormfieldHint class="std-ta-left">eg: 5431</span>
  </nx-formfield>

  <nx-formfield label="ATM ID">
    <input nxInput value="SEL7890" />
    <span nxFormfieldHint class="std-ta-left">eg: FCXB1408</span>
  </nx-formfield>

  <nx-formfield label="Trace No.">
    <input nxInput value="1234" />
    <span nxFormfieldHint class="std-ta-left">eg: 5431</span>
  </nx-formfield>

  <nx-formfield label="Approval Code">
    <input nxInput value="T0415000" />
    <span nxFormfieldHint class="std-ta-left">eg: T0415000</span>
  </nx-formfield>

  <nx-formfield label="Credit Card No.">
    <input nxInput value="1124441002206152" />
    <span nxFormfieldHint class="std-ta-left">eg: 5424441002206152</span>
  </nx-formfield>

  <nx-formfield label="Acc Holder's Name">
    <input nxInput />
  </nx-formfield>

  <nx-formfield label="Transaction Date">
    <input nxDatefield nxInput [datepicker]="myDatepicker2" />
    <span nxFormfieldHint>DD/MM/YYYY</span>

    <nx-datepicker-toggle
      [for]="myDatepicker2"
      nxFormfieldSuffix
    ></nx-datepicker-toggle>
    <nx-datepicker #myDatepicker2></nx-datepicker>
  </nx-formfield>


  <button
    nxModalClose
    class="button_popup_2btn"
    nxButton="secondary"
    type="button"
  >
    Cancel
  </button>
  <button nxModalClose nxButton="primary" type="button" disabled>Save</button>
</div>
