@if (isDataLoaded && !isUpdateJPJScreen && !isAmendDateScren) {
<div class="title-header">
    <div nxLayout="grid maxwidth">
        <div nxRow>
            <div nxCol="12">
                @if(productName){<h1>{{productName.descp}}</h1>}
            </div>
        </div>
    </div>
</div>
<div nxLayout="grid maxwidth">
    <div nxRow>

        <div nxCol="12,12,12,12" class="grid_width_page">
            <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                <div nxRow class="enquiry_status">
                    <div nxCol="12,12,10">
                        <h3 nxHeadline size="s" class="nx-font-weight-regular">
                            e-Cover Note No. :
                            <span class="nx-font-weight-bold">{{proposalNo}}</span>
                        </h3>
                    </div>
                    <div nxCol="12,12,2" class="enquiry_status">
                        <nx-badge type="active" [class]="classType">{{status}}</nx-badge>
                    </div>
                </div>
            </div>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Agent Information -->
            @if (showAgentInfo && this.agentData) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Agent Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <div nxLayout="grid nopadding">
                        <app-agents-information [agentData]="agentData">
                        </app-agents-information>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>
            }


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Issuance Information -->
            @if (showIssuanceInfo && (productList && productList.length > 0)) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Issuance Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid nopadding">
                        <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo"
                            [viewQuotation]=true [productList]="productList">
                        </app-issuance-information>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>
            }

            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Customer Partner -->
            @if (showCustomerPartner) {
            <nx-accordion variant="light">
                <nx-expansion-panel [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Customer Partner
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <app-customer-partner-details [viewOnly]="true" [cpType]="'FOUND'" [hideEdit]="true" [cpDetails]="cpData" [lov]="lov">
                    </app-customer-partner-details>

                </nx-expansion-panel>
            </nx-accordion>
            }

            <!-- ///////////////////////////////////////////////////////////////////// -->
            <!-- Referral Details -->
            @if (showReferralDetails) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Referral Details
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <table nxTable cellpadding="0" class="summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Referral ID No.</span>
                            </td>
                            <td nxTableCell class="column_cp_content">
                                <span nxCopytext="normal">{{agentReferId}}</span>
                            </td>
                        </tr>
                    </table>
                </nx-expansion-panel>
            </nx-accordion>
            }


            <!-- ////////////////////////////////////////////// -->
            <!-- Coverage -->
            @if (showCoverage) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordione_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Coverage
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <app-coverage-summary [coverageInfo]="coverageInfo"
                        [viewQuotation]="viewQuotation" [isRTP]="isRTP" [isIssuance]="isIssuance"></app-coverage-summary>


                </nx-expansion-panel>
            </nx-accordion>
            }


            <!-- ////////////////////////////////////////////// -->
            <!-- Historical Claim -->
            @if (showHistoricalClaim) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Historical Claim
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <table nxTable cellpadding="0" class="summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Client Claim History</span>
                            </td>
                            <td nxTableCell class="column_cp_content">
                                <span nxCopytext="normal">{{clientClaimHistory}}</span>
                            </td>
                        </tr>
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Vehicle Claim History</span>
                            </td>
                            <td nxTableCell class="column_cp_content">
                                <span nxCopytext="normal">{{vehicleClaimHistory}}</span>
                            </td>
                        </tr>
                    </table>
                </nx-expansion-panel>
            </nx-accordion>
            }

            <!-- ////////////////////////////////////////////// -->
            <!-- Premium Information -->
            @if (showPremiumInformation) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Premium Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <app-premium-information [premiumInfo]="premiumInfo" [viewOnly]=true [isRTP]="isRTP" [isIssuance]="isIssuance">

                    </app-premium-information>

                </nx-expansion-panel>
            </nx-accordion>
            }

            @if (showPayment) {
                <nx-accordion variant="light">
                  <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit"> Payment </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
              
                    <app-payment [payment]="payment"></app-payment>
              
                  </nx-expansion-panel>
                </nx-accordion>
              }

              @if (showRoadTaxPayable) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                      <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit"> Road Tax Payable </nx-expansion-panel-title>
                      </nx-expansion-panel-header>
                      <app-view-road-tax-payable [data]="roadTaxPayable"></app-view-road-tax-payable>
                    </nx-expansion-panel>
                  </nx-accordion>
              }

              @if (showUpsellProduct) {
                <nx-accordion variant="light">
                  <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit"> Upsell Product </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-view-upsell-product [data]="upsellProduct" [agentInfo]="agentInfo" [productCode]="cngeNote.productCode"></app-view-upsell-product>
                  </nx-expansion-panel>
                </nx-accordion>
              }

              @if (showPaymentInformation) {
                <app-payment-information [premiumInfo]="paymentInformation"></app-payment-information>
              }

              @if (showPaymentDetails) {
                <nx-accordion variant="light">
                  <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit"> Payment Details </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-payment-details [userInfo]="userInfo" [agentInfo]="agentInfo" [paymentList]="paymentList"
                    [viewOnly]="true">
                    </app-payment-details>
                  </nx-expansion-panel>
                </nx-accordion>
              }

            <!-- ////////////////////////////////////////////// -->
            <!-- Remarks -->
            @if (showRemarks) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Remarks
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-view-remarks [remarksInfo]="remarksInfo" [isQuotationView]=true></app-view-remarks>
                </nx-expansion-panel> 
            </nx-accordion>
            }

            <!-- ////////////////////////////////////////////// -->
            <!-- Refer Case (if any) -->
            @if (showReferCase) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Refer Case (if any)
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <form [formGroup]="referCaseForm">
                        <div nxLayout="grid nopadding" class="grid_remove_padding">
                            <!-- <div nxRow>
                                <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                    <nx-checkbox formControlName="waiveLoadInd" (change)="saveSummaryStep()">Refer Waive
                                        Load Ind.</nx-checkbox>
                                </div>
                                <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                    <nx-checkbox formControlName="waiveExcessInd" (change)="saveSummaryStep()">Refer
                                        Waive Excess Ind.</nx-checkbox>
                                </div>
                                <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                    <nx-checkbox formControlName="underwritingInd" (change)="saveSummaryStep()">Refer
                                        for Underwriting</nx-checkbox>
                                </div>
                            </div> -->
                        </div>

                        <!-- <nx-formfield label="Referral Remarks">
                            <textarea required nxInput type="text" formControlName="remarks" #inputToCount
                                maxlength="200" cdkTextareaAutosize (blur)="saveSummaryStep()"></textarea>
                            <span nxFormfieldHint>Max. 200 Characters</span>
                            <nx-error nxFormfieldError>
                                <strong>Please enter referral remarks</strong>
                            </nx-error>
                        </nx-formfield> -->

                        <!-- <nx-error nxFormfieldError class="nx-margin-bottom-2m">
                          <strong>Please enter referral remarks</strong>
                      </nx-error> -->
                    </form>
                </nx-expansion-panel>
            </nx-accordion>
            }

            <!-- Endorsement Checklist (to be move to new component) -->
            <!-- ////////////////////////////////////////////////// -->
            <!-- Checklist -->
            @if (showChecklist) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Checklist
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid nopadding" class="grid_remove_padding">
                        <!-- Amendment of Period, Extra Benefits, NCD Allowed, NCD Amendment, NCD Withdraw, Reinstatement of Extra Coverage, Revision of Sum Insured -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">1</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Insured or Agent Authorised letter / form / email (with
                                    contact number).</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- NCD Allowed, NCD Amendment -->
                        <div nxRow>
                            <div nxCol="12">
                                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                                    <i>Note: Insured IC copy/E-payment form/copy of bank
                                        book-statement required (if refund involved).</i>
                                </p>
                            </div>
                        </div>

                        <!-- Amendment of Period -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">2</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Copy of VOC / Registration card (for brand new only).</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Cancellation by Insured, Hire Purchase/Employers' Loan/Leasing, Named Driver -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">1</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Insured Authorised letter/form/email (with contact
                                    number).</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                                    <i>Note: Warranted no claim as at todate.</i>
                                </p>
                            </div>
                        </div>

                        <!-- Change of Bancassurance Details -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">1</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Internal email communication for changes.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">1</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Insured or Agent Authorised letter/form/email (with contact
                                    number) OR</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">2</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Copy of VOC/Registration card.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Extra Benefits, Named Driver, Revision of Sum Insured -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">2</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Vehicle inspection done.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Extra Benefits, Named Driver -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">3</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Warranted no claim as at todate.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                                    <i>Note: Inclusion of extra benefits will only be allowed
                                        warranted no claim as at the date of endt.</i>
                                </p>
                            </div>
                        </div>

                        <!-- Named Driver -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">4</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Copy of I/C / passport.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- NCD Withdraw -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">2</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Claim/damage to the vehicle.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                                    <i>Note: Insured IC copy/Epayment form/copy of bank
                                        book-statement required (if refund involved).</i>
                                </p>
                            </div>
                        </div>

                        <!-- Reinstatement of Extra Coverage -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">2</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Inspection done (required only if exceeded 14 days).</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Revision of Sum Insured -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">3</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Requested by Bank/Finance owner.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Revision of Sum Insured -->
                        <div nxRow class="nx-margin-bottom-2m">
                            <div nxCol="2,2,1" class="checklist">
                                <span nxCopytext="normal">4</span>
                            </div>
                            <div nxCol="10,10,11" class="checklist">
                                <span nxCopytext="normal">Purchase invoice - for new cases & vehicles from
                                    Langkawi/Labuan only.</span>
                                <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                                    <div nxRow>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">Yes</nx-radio>
                                        </div>
                                        <div nxCol="4,4,3,2" class="checklist_radio">
                                            <nx-radio name="yesno">No</nx-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                                    <i>Note: Warranted no claim as at todate.</i>
                                </p>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12">
                                <nx-formfield label="Remarks">
                                    <textarea nxInput type="text" #inputToCount maxlength="200"
                                        cdkTextareaAutosize></textarea>
                                    <span nxFormfieldHint>Max. 200 Characters</span>
                                </nx-formfield>
                            </div>
                        </div>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>
            }

            <!-- Renewal Assistance (to be move to new component) -->

            <!-- ///////////////////////////////////////////////////// -->
            <!-- Renewal Assistance -->
            <!-- @if (showRenewalAssistant) {
            <form [formGroup]="renewalAssistanceForm">
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Renewal Assistance
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <nx-checkbox formControlName="renewalAssistant"
                            (checkboxChange)="onChangeRenewalAssistanceForm('renewalAssistant', $event.checked)"
                            class="nx-margin-bottom-2m nx-margin-top-xs">
                            Renewal Assistance
                        </nx-checkbox>

                        <div nxLayout="grid nopadding">
                            <div nxRow>
                                <div nxCol="12,12,6">
                                    <nx-formfield label="Payment Mode">
                                        <nx-dropdown formControlName="paymentMode">
                                            <ng-container *ngFor="let mode of paymentModeList">
                                                <nx-dropdown-item [value]="mode.code">{{ mode.name }}</nx-dropdown-item>
                                            </ng-container>
                                        </nx-dropdown>
                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Payment mode.</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6">
                                    <nx-formfield label="Credit Card No">
                                        <input formControlName="creditCardNo" nxInput />
                                        <span nxFormfieldHint class="std-ta-left">eg. 5500000000000004</span>
                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Credit Card No.</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>
                        </div>

                        <p nxCopytext="normal" class="nx-margin-bottom-2m">
                            Credit Card Expiry Date
                        </p>

                        <div nxLayout="grid nopadding">
                            <div nxRow>
                                <div nxCol="12,12,6">
                                    <div nxLayout="grid nopadding" class="grid_remove_padding">
                                        <div nxRow>
                                            <div nxCol="5" class="renewal_monthyear">
                                                <nx-formfield label="Month">
                                                    <nx-dropdown formControlName="creditCardExpiryDateMonth">
                                                        <ng-container *ngFor="let month of monthList">
                                                            <nx-dropdown-item [value]="month.code">{{ month.name
                                                                }}</nx-dropdown-item>
                                                        </ng-container>
                                                    </nx-dropdown>
                                                    <nx-error nxFormfieldError>
                                                        <strong>Please enter Month.</strong>
                                                    </nx-error>
                                                </nx-formfield>
                                            </div>

                                            <div nxCol="2" class="renewal_monthyear">
                                                <p nxCopytext="large">/</p>
                                            </div>

                                            <div nxCol="5" class="renewal_monthyear">
                                                <nx-formfield label="Years">
                                                    <nx-dropdown formControlName="creditCardExpiryDateYear">
                                                        <ng-container *ngFor="let year of yearList">
                                                            <nx-dropdown-item [value]="year.code">{{ year.name
                                                                }}</nx-dropdown-item>
                                                        </ng-container>
                                                    </nx-dropdown>
                                                    <nx-error nxFormfieldError>
                                                        <strong>Please enter Year.</strong>
                                                    </nx-error>
                                                </nx-formfield>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6">
                                    <nx-formfield label="Name on Card">
                                        <input formControlName="nameOnCard" nxInput />
                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Name on Card.</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,12,6">
                                    <nx-formfield label="Renewal Assistance Start Date">
                                        <input formControlName="renewalAssistanceStartDate" nxDatefield nxInput
                                            [datepicker]="myDatepicker2" />
                                        <span nxFormfieldHint>DD/MM/YYYY</span>

                                        <nx-datepicker-toggle [for]="myDatepicker2"
                                            nxFormfieldSuffix></nx-datepicker-toggle>
                                        <nx-datepicker #myDatepicker2></nx-datepicker>
                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Renewal Assistance Start Date</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>
                        </div>

                        <nx-checkbox formControlName="termofUse">Term of Use</nx-checkbox>
                    </nx-expansion-panel>
                </nx-accordion>
            </form>
            } -->

            <!-- Endorsement Narration (to be move to new component) -->

            <!-- ///////////////////////////////////////////////// -->
            <!-- Endorsement Narration -->
            @if (showEndorsementNarration) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Endorsement Narration
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <!-- CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY -->
                    <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF VEHICLE ENGINE NO./CUBIC
                        CAPACITY</u>

                    <p nxCopytext="normal" class="nx-margin-y-m">
                        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                        <span class="nx-font-weight-bold">13/12/2023</span> THE ENGINE
                        NUMBER AND/OR CUBIC CAPACITY IS/ARE AMENDED TO READ AS FOLLOWS AND
                        NOT AS OTHERWISE STATED.
                    </p>

                    <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">
                        ENGINE / MOTOR NO. : 2NROP28330<br />CUBIC CAPACITY : 1,298 CC
                    </p>

                    <p nxCopytext="normal" class="nx-margin-y-m">
                        IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
                        <span class="nx-font-weight-bold">RM0.00</span> IS HEREBY CHARGED TO
                        THE INSURED.
                    </p>

                    <p nxCopytext="normal">
                        ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
                    </p>

                    <!-- This is just spacing, you can remove it -->
                    <div class="nx-margin-y-2m"></div>

                    <!-- CHANGE OF MAKE & MODEL -->
                    <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF MAKE & MODEL</u>

                    <p nxCopytext="normal" class="nx-margin-y-m">
                        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                        <span class="nx-font-weight-bold">13/12/2023</span> THE MAKE & MODEL
                        IS AMENDED TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
                    </p>

                    <p nxCopytext="normal">MAKE & MODEL:</p>
                    <span nxCopytext="normal">
                        <ol nxList="small" class="upload_content nx-margin-bottom-2m">
                            <li>
                                <span class="nx-font-weight-bold">PERODUA MYVI 1.3 (A) SE</span>
                            </li>
                        </ol>
                    </span>

                    <p nxCopytext="normal">
                        ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
                    </p>

                    <!-- This is just spacing, you can remove it -->
                    <div class="nx-margin-y-2m"></div>

                    <!-- INCLUSION OF NAMED DRIVER(S) -->
                    <u nxCopytext="normal" class="nx-font-weight-semibold">INCLUSION OF NAMED DRIVER(S)</u>

                    <p nxCopytext="normal" class="nx-margin-y-m">
                        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                        <span class="nx-font-weight-bold">13/12/2023</span> THE FOLLOWING
                        NAMED DRIVER(S) IS/ARE INCLUDED INTO THE POLICY.
                    </p>

                    <p nxCopytext="normal" class="nx-font-weight-bold">
                        NO NAMED DRIVER(S) - DRIVER ID
                    </p>
                    <span nxCopytext="normal">
                        <ol nxList="small" class="upload_content_bold nx-margin-bottom-2m nx-font-weight-bold">
                            <li>
                                <span class="nx-font-weight-bold">WONG KAM WAI - 880914145277</span>
                            </li>
                        </ol>
                    </span>

                    <p nxCopytext="normal">
                        ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
                    </p>
                </nx-expansion-panel>
            </nx-accordion>
            }



            <!-- //////////////////////////////////////////////////////////////////// -->
            @if (showEndorsementErrMessage) {
            <p nxCopytext="medium" class="nx-margin-top-2m">
                <i>* You will not be able to save as quotation, please proceed to
                    generate Endorsement if details has been finalised.</i>
            </p>
            }
        </div>
    </div>
</div>
@if (!isRTP) {
<div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
            <div class="button-container">
                <button nxButton="secondary" type="button" 
                    (click)="redirectBack()" *ngIf="isShowBackBtn">
                    BACK
                </button>
                <button nxButton="secondary" type="button" 
                    (click)="openRequestCancellationPopUp()"
                    *ngIf="isShowRequestCancellationBtn">
                    Request Cancellation
                </button>
                <button nxButton="primary" type="button" 
                    (click)="addRemarks()" *ngIf="isShowAddRemarksBtn">
                    Add Remarks
                </button>
                <button nxButton="primary" type="button" 
                    (click)="updatePayment()" *ngIf="isUpdatePaymentBtn">
                    Update Payment
                </button>
                <button nxButton="primary" type="button" 
                    (click)="updateQuotation()" *ngIf="isShowUpdateQuotationBtn">
                    Update e-Quotation
                </button>
                <button nxButton="primary" type="button" 
                    (click)="downloadPDF('quotation', false)" *ngIf="isShowDownloadQuotationBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    E-Quotation PDF
                </button>
                <button nxButton="primary" type="button" 
                    (click)="downloadPDF('covernote', false)" *ngIf="isShowDownloadCoverNoteBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    E-Cover Note PDF
                </button>
                <button nxButton="primary" type="button" 
                    (click)="downloadPDF('invoice', false)" *ngIf="isShowDownloadInvoiceBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    E-Invoice PDF
                </button>
                <button nxButton="primary" type="button" 
                    (click)="sendToJPJ()" *ngIf="isShowSendToJPJBtn">
                    SEND TO JPJ
                </button>
                <button nxButton="primary" type="button" 
                    (click)="updateJPJDetails()" *ngIf="isShowUpdateJPJBtn">
                    UPDATE JPJ DETAILS
                </button>
                <button nxButton="primary" type="button" 
                    (click)="amendCoverNote()" *ngIf="isShowAmendCoverNoteBtn">
                    AMEND OPEN DATED COVER NOTE
                </button>
                <button nxButton="primary" type="button" 
                    (click)="uploadDocument()" *ngIf="isShowUploadDocumentBtn">
                    UPLOAD DOCUMENT
                </button>
                <button nxButton="primary" type="button" 
                    (click)="emailDocument()" *ngIf="isShowEmailDocumentBtn">
                    EMAIL DOCUMENT
                </button>
                <button nxButton="primary" type="button" 
                    (click)="requote()" *ngIf="isShowRequoteBtn">
                    RE-QUOTE
                </button>
            </div>
        </div>
      </div>
    </div>
  </div>
}
@if (isRTP) {
<div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
        <div nxRow>
            <div nxCol="12">
                <button nxButton="secondary" type="button" (click)="redirectBack()">Back</button>
            </div>
        </div>
    </div>
</div>
}
}

@if (isDataLoaded && (isUpdateJPJScreen || isAmendDateScren)) {
    <div class="title-header">
        <div nxLayout="grid maxwidth">
            <div nxRow>
                <div nxCol="12">
                    @if(productName){<h1>{{productName.descp}}</h1>}
                </div>
            </div>
        </div>
    </div>

    <div nxLayout="grid maxwidth">
        <div nxRow>
            <div nxCol="12,12,12,12" class="grid_width_page">
                
                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Title -->
                <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                    <div nxRow class="enquiry_status">
                        <div nxCol="12,12,10">
                            <h3 nxHeadline size="s" class="nx-font-weight-regular">
                                <span class="nx-font-weight-bold">{{ isUpdateJPJScreen ? 'Update JPJ Details' : 'Amend Open Dated Cover Note'}}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                    <div nxRow class="enquiry_status">
                        <div nxCol="12,12,10">
                            <h3 nxHeadline size="s" class="nx-font-weight-regular">
                                e-Cover Note No. :
                                <span class="nx-font-weight-bold">{{proposalNo}}</span>
                            </h3>
                        </div>
                    </div>
                </div>

                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Agent Information -->
                @if (showAgentInfo && this.agentData) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Agent Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxLayout="grid nopadding">
                            <app-agents-information [agentData]="agentData">
                            </app-agents-information>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }
                
                
                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Issuance Information -->
                @if (showIssuanceInfo && isUpdateJPJScreen) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                View / Update JPJ Details
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                
                        <div nxLayout="grid nopadding">
                            <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo" [viewQuotation]=true [isUpdateJPJScreen]=true>
                            </app-issuance-information>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }


                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- coverage Section - Vehicle Details -->
                @if (showCoverage) {
                <app-vehicle-view-amendment 
                    [isUpdateJPJScreen]="isUpdateJPJScreen" 
                    [isAmendDateScren]="isAmendDateScren"
                    [cngeNote]="cngeNote"
                    [coverageInfo]="coverageInfo">
                </app-vehicle-view-amendment>
                }

            </div>
        </div>
    </div>

    <div class="button_area_bg nx-margin-top-50">
        <div nxLayout="grid maxwidth">
            <div nxRow>
                <div nxCol="12">
                    <div class="button-container">
                        <button nxButton="secondary" type="button" 
                        (click)="exitUpdateScreen()" *ngIf="isShowBackBtn">
                            BACK
                        </button>
                        <button nxButton="primary" type="button" 
                        (click)="resendJPJAction()" *ngIf="isUpdateJPJScreen">
                            RESEND TO JPJ
                        </button>
                        <button nxButton="primary" type="button" 
                        (click)="saveCNDate()" *ngIf="isAmendDateScren">
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
}
<!-- Endorsement Premium Summary (to be move to new component) -->


<!-- @if(crossSellList.length !== 0) {
<app-cross-sell-banner [crossSellList]="crossSellList" [userInfo]="userInfo" [marketingConsent]="marketingConsent"
    [clientPartnerInfo]="cpData" [agentInfo]="agentData">
</app-cross-sell-banner>
} -->

@if(crossSellList && crossSellList.length !== 0) {
    <app-cross-sell-banner [crossSellList]="crossSellList" [userInfo]="userInfo" 
    [marketingConsent]="marketingConsent" [clientPartnerInfo]="cpData" [agentInfo]="agentData">
    </app-cross-sell-banner>
}