import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { UPDATE_COVERAGE_ALERT, STORE_SHOWED_ONE_ALLIANZ_POP_UP } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-one-allianz-message',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './one-allianz-message.component.html',
  styleUrl: './one-allianz-message.component.scss'
})
export class OneAllianzMessageComponent {

  constructor(
    private store: Store
  ) {}

  onClickOK() {
    // this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(true)).subscribe(() => {
    //   this.store.dispatch(new UPDATE_COVERAGE_ALERT({ alimCustomerEligible: false, alimCustomerEligibleShowed: true }));
    // });
  }

}
