import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  CALCULATE_PREMIUM,
  CALCULATE_PREMIUM_PAYLOAD,
  GET_PREVIOUS_POLICY_NO,
  SELECTED_MAKE_MODEL,
  STORE_COVERAGE_TYPE,
  STORE_COVERAGE_VEHICLE_DETAILS,
  STORE_ENDORSEMENT_VEHICLE_DETAILS,
  STORE_ENDORSEMENT_COVERAGE_TYPE,
  SELECTED_ENDORSEMENT_MAKE_MODEL,
  GET_VEHICLE_USE,
  SEARCH_VIX,
  UPDATE_ENDORSEMENT_STEP2,
  CALCULATE_ENDT_PREMIUM,
} from "@store/sat-motor/sat-motor.action";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../../../popup/endorsement-popup/endorsement-popup.component";
import { SharedModule } from '@shared/shared.module';
import { CoverageComponent } from '../../../issuance/coverage/coverage.component';
import { STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { UserResponse } from "@interfaces/user";

import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { formatNumber } from "@angular/common";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-endorsement-vehicle-details',
  standalone: true,
  imports: [SharedModule],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './endorsement-vehicle-details.component.html',
  styleUrl: './endorsement-vehicle-details.component.scss'
})
export class EndorsementVehicleDetailsComponent implements OnInit {

  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.endtStep1Issuance) endtIssuanceData$;
  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  @Select(SatMotorState.endtVehiclePolicy) endtVehicleData$;
  @Select(SatMotorState.endtPreviousVehiclePolicy) endtPreviousVehicleData$;

  @Select(SatMotorState.endtStep2VehicleDetails) endtVehicleDetailsData$;

  // coverageFmGroup!: FormGroup; //to change or remove
  // vehicleUselov!: any; //to change or remove

  // vehicleDetailsForm: FormGroup;

  dialogRef?: NxModalRef<any>;

  isEndorsement: boolean = true;
  @Input() endorsementType: any;
  // nextButtonDisabled: boolean = true;

  @Output() endtCalculatePremium = new EventEmitter<unknown>();

  vehicleDetailForm: FormGroup;

  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;

  lov: any;
  userInfo: any;
  vehicleUseLov: any;
  agentDetail: any;
  issuanceInfo: any;
  coverageType: any;
  endtPolicy: any;
  policyNo: any;

  originalRegion: any;
  originalVehicleUse: any;
  originalVehicleNo: any;
  originalVehicleMakeYear: any;
  // originalVehModelNvicDesc: any;
  originalMakeModelDesc: any;
  originalVehicleUom: any;
  originalVehicleCapacity: any;
  originalEngineType: any;
  originalVehicleSeat: any;
  originalVehicleEngine: any;
  originalVehicleChassis: any;

  vehicleDetails: any;
  makeModel: any;

  decimalFmt: any;
  numberFmt: any;

  templateDialogRef?: NxModalRef<any>;

  openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
    this.templateDialogRef = this.dialogService.open(template, {
      height: config.height || '500px',
      width: config.width || '780px',
    });
  }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();

    this.vehicleDetailForm = this.fb.group({
      region: ["", [Validators.required]],
      vehicleUse: ["", [Validators.required]],
      vehicleNo: ["", [Validators.required, VehicleNoValidator()]],
      vehicleMakeYear: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.min(1924), Validators.max(new Date().getFullYear())]],
      // vehModelNvicDesc: ["", [Validators.required]],
      makeModelDesc: ["", [Validators.required]],
      vehicleUom: [{ value: "", disabled: true }],
      vehicleCapacity: ["", [Validators.required, Validators.min(1)]],
      engineType: [{ value: "", disabled: true }, [Validators.required]],
      vehicleSeat: ["", [Validators.required, Validators.min(1)]],
      vehicleEngine: ["", [Validators.required]],
      vehicleChassis: ["", [Validators.required]],
      vehicleLoanType: [""],
      vehicleLoanCompany: [""],
      vehiclePurchaseDate: [""],
      makeModelPrintInd: [false],
      variantDesc: [""],
    });
  }

  ngOnInit() {

    this.endtVehicleData$.subscribe((state) => {
      this.endtPolicy = state || {};
      this.policyNo = state.policyNo;

    });

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
        // console.log("EndorsementVehicleDetailsComponent isEndorsement", this.isEndorsement);
      }
    });

    this.endtIssuanceData$.subscribe((state) => {
      if (state) {
        this.endorsementType = state.issuance.endorsementType;
        console.log("endorsementType", this.endorsementType);
      }
    });

    this.store.dispatch(new GET_VEHICLE_USE({ isEndorsement: true, productCode: this.endtPolicy.productCode })).subscribe((state) => {
      this.vehicleUseLov = state.SatMotorState.endorsement.step2.vehicleUseLov;
      // console.log("Vehicle Use LOV updated after dispatch:", this.vehicleUseLov);
    });

    // this.vehicleDetails = this.vehicleDetailForm;

    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    // this.vehicleUseLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step2.endorsementDetails.vehicleUseLov);
    // this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.issuance.issuance);
    // this.coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step2?.endorsementDetails?.coverageType);

    this.vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.vehicleDetails);
    console.log("vehicleDetails:", this.vehicleDetails);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    this.endtVehiclePolicyData = endtVehiclePolicyData
    this.endtPreviousVehiclePolicyData = endtPreviousVehiclePolicyData

    // console.log("endtVehiclePolicyData:", this.endtVehiclePolicyData);

    if (endtVehiclePolicyData) {
      // let customerData = endtVehiclePolicyData?.clientPartner;
      // let agentData = endtVehiclePolicyData?.mkagAgent;
      let riskList = endtVehiclePolicyData?.riskList[0];

      console.log("riskList:", riskList);

      if (riskList) {
        let data = riskList;

        if (endtVehiclePolicyData && !this.vehicleDetails) {
          this.vehicleDetailForm.patchValue({
            region: endtVehiclePolicyData.region,
            vehicleUse: data.vehicleUse,
            vehicleNo: data.vehicleNo,
            vehicleMakeYear: data.vehicleMakeYear,
            makeModelDesc: data.makeModelDesc, //opus is null
            vehicleUom: data.vehicleUom,
            vehicleCapacity: data.vehicleCapacity,
            engineType: data.engineType, //opus is null
            vehicleSeat: data.vehicleSeat,
            vehicleEngine: data.vehicleEngine,
            vehicleChassis: data.vehicleChassis,
          });
          console.log("endtVehiclePolicyData vehicleDetailForm:", this.vehicleDetailForm);
        }
        else if (endtVehiclePolicyData && this.vehicleDetails) {
          this.vehicleDetailForm.patchValue(this.vehicleDetails);
          console.log("vehicleDetails vehicleDetailForm:", this.vehicleDetailForm);
          // // this.vehicleDetailForm.patchValue({ ...this.vehicleDetails, vehicleCapacity: formatNumber(this.vehicleDetails.vehicleCapacity, 'en-US') });
        }

        this.applyFormControlRules();

        //store original db values
        this.originalRegion = endtVehiclePolicyData.region;
        this.originalVehicleUse = data.vehicleUse;
        this.originalVehicleNo = data.vehicleNo;
        this.originalVehicleMakeYear = data.vehicleMakeYear;
        // this.originalVehModelNvicDesc = data.vehModelNvicDesc;
        this.originalMakeModelDesc = data.makeModelDesc;
        this.originalVehicleUom = data.vehicleUom;
        this.originalVehicleCapacity = data.vehicleCapacity;
        this.originalEngineType = data.engineType;
        this.originalVehicleSeat = data.vehicleSeat;
        this.originalVehicleEngine = data.vehicleEngine;
        this.originalVehicleChassis = data.vehicleChassis;
      } else {
        console.error("riskList cannot populate vehicleDetailForm");
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }
    ////////////////////////////////////////////////////////
    this.vehicleLoanType.valueChanges.subscribe((change) => {
      console.log("Vehicle Loan Type changed:", change);
      this.vehicleDetailForm.patchValue({ vehicleLoanCompany: "" });
      this.appendValidators(change, this.vehicleLoanCompany);
    });

    this.makeModelPrintInd.valueChanges.subscribe((change) => {
      let selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step2.endorsementDetails.selectedMakeModel);
      this.vehicleDetailForm.patchValue({
        variantDesc: change ? selectedMakeModel?.variantFullDesc : ""
      });
      this.makeModel = selectedMakeModel?.piamMakeModelDesc;
      this.appendValidators(change, this.variantDesc);
    });
  }

  normalizeValue(value: any) {
    if (typeof value === 'string') {
      return value.trim().toLowerCase();
    }
    return value;
  }

  convertToComparableType(value: any, referenceValue: any) {
    if (typeof referenceValue === 'number' && !isNaN(Number(value))) {
      return Number(value);
    }
    if (typeof referenceValue === 'string') {
      return String(value);
    }
    return value;
  }

  validateChangedFields() {
    const fieldsToValidate = this.getFieldsToValidate();
    console.log("fieldsToValidate:", fieldsToValidate);

    let changedFieldsCount = 0;
    let sameFieldsCount = 0;
    let totalFields = fieldsToValidate.length;
    let hasChanged = false;

    fieldsToValidate.forEach((field) => {
      let formValue = this.normalizeValue(this.vehicleDetailForm.get(field)?.value);
      console.log("validateChangedFields formValue:", formValue);

      let originalValue = this.normalizeValue(this[`original${this.capitalizeFirstLetter(field)}`]);
      console.log("validateChangedFields originalValue:", originalValue);

      if (originalValue === undefined) {
        console.error(`Original value for ${field} is undefined`);
      }

      formValue = this.convertToComparableType(formValue, originalValue);
      originalValue = this.convertToComparableType(originalValue, formValue);

      if (formValue !== originalValue) {
        changedFieldsCount++;
        console.log(`Field ${field} has changed.`);
      } else if (formValue === originalValue) {
        sameFieldsCount++;
        console.log(`Field ${field} has not changed.`);
      }
    });

    if (this.endorsementType === 'ENDT18') {
      // console.log("validateChangedFields endorsementType:", this.endorsementType);
      const vehicleLoanTypeValue = this.vehicleDetailForm.get('vehicleLoanType')?.value;
      if (vehicleLoanTypeValue === "") {
        changedFieldsCount = totalFields;
      } else {
        const vehicleLoanCompanyValue = this.vehicleDetailForm.get('vehicleLoanCompany')?.value;
        if (vehicleLoanCompanyValue === "") {
          changedFieldsCount = 1;
        }
      }
    }

    if (this.endorsementType === 'ENDT34') {
      // console.log("validateChangedFields endorsementType:", this.endorsementType);
      const makeModelPrintIndValue = this.vehicleDetailForm.get('makeModelPrintInd')?.value;
      if (makeModelPrintIndValue === true) {
        // totalFields = 5;
        totalFields = 3;
        changedFieldsCount++;

        const variantDescValue = this.vehicleDetailForm.get('variantDesc')?.value;
        if (variantDescValue !== "") {
          changedFieldsCount++;
        }
      }
    }

    if (changedFieldsCount === totalFields && sameFieldsCount === 0) {
      console.log("changedFieldsCount:", changedFieldsCount);
      console.log("sameFieldsCount:", sameFieldsCount);
      hasChanged = true;
    } else {
      hasChanged = false;
    }
    console.log("Final hasChanged result:", hasChanged);
    return hasChanged;
  }

  getFieldsToValidate() {
    switch (this.endorsementType) {
      case 'COE': //Change of Engine
        return ['vehicleEngine'];
      // return ['vehicleEngine', 'vehicleCapacity'];
      case 'ENDT10': //Change of Vehicle Chassis No
        return ['vehicleChassis'];
      case 'ENDT12': //Change of Vehicle Registration No
        return ['vehicleNo'];
      case 'ENDT18': //Hire Purchase/Employers' Loan/Leasing
        return ['vehicleLoanType', 'vehicleLoanCompany'];
      case 'ENDT34': // Change of Make & Model
        return ['makeModelDesc'];
      // return ['vehModelNvicDesc'];
      // return ['vehModelNvicDesc', 'vehicleCapacity', 'vehicleSeat'];
      default:
        return [];
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  applyFormControlRules() {
    if (this.endorsementType === 'COE') { //Change of Engine
      this.vehicleDetailForm.get('vehicleEngine')?.enable();
      this.vehicleDetailForm.get('vehicleCapacity')?.enable();
      this.disableOtherControls(['vehicleEngine', 'vehicleCapacity']);

      const vehicleUom = this.vehicleDetailForm.get('vehicleUom')?.value;

      if (vehicleUom === "CC") {
        this.vehicleDetailForm.get('vehicleCapacity')?.setValidators([
          Validators.required, NumberRangeValidator(40, 99999)
        ]);
      } else {
        this.vehicleDetailForm.get('vehicleCapacity')?.setValidators([
          Validators.required, Validators.min(1)
        ]);
      }
      this.vehicleDetailForm.get('vehicleCapacity')?.updateValueAndValidity();

    } else if (this.endorsementType === 'ENDT10') { //Change of Vehicle Chassis No
      this.vehicleDetailForm.get('vehicleChassis')?.enable();
      this.disableOtherControls(['vehicleChassis']);

    } else if (this.endorsementType === 'ENDT12') { //Change of Vehicle Registration No
      this.vehicleDetailForm.get('vehicleNo')?.enable();
      this.disableOtherControls(['vehicleNo']);

    } else if (this.endorsementType === 'ENDT18') { //Hire Purchase/Employers' Loan/Leasing
      this.vehicleDetailForm.get('vehicleLoanType')?.enable();
      this.vehicleDetailForm.get('vehicleLoanCompany')?.enable();
      this.disableOtherControls(['vehicleLoanType', 'vehicleLoanCompany']);

      // const vehicleLoanType = this.vehicleDetailForm.get('vehicleLoanType')?.value;

    } else if (this.endorsementType === 'ENDT34') { // Change of Make & Model
      this.vehicleDetailForm.get('makeModelDesc')?.enable();
      // this.vehicleDetailForm.get('vehModelNvicDesc')?.enable();
      this.vehicleDetailForm.get('vehicleCapacity')?.enable();
      this.vehicleDetailForm.get('vehicleSeat')?.enable();
      this.vehicleDetailForm.get('makeModelPrintInd')?.enable();
      this.vehicleDetailForm.get('variantDesc')?.enable();
      this.disableOtherControls(['makeModelDesc', 'vehicleCapacity', 'vehicleSeat', 'makeModelPrintInd', 'variantDesc']);
    }
  }

  disableOtherControls(allowedControls: string[]) {
    Object.keys(this.vehicleDetailForm.controls).forEach((control) => {
      if (!allowedControls.includes(control)) {
        this.vehicleDetailForm.get(control)?.disable();
      }
    });
  }
  ///////////////////////////////////////////////////////////////
  onChangeCapacity($event, comma: boolean, calculate: boolean) {
    if (!comma) {
      this.vehicleCapacity.patchValue(this.numberFmt.transform($event.target.value));
    } else {
      if (calculate)
        // this.calculatePremiumIfRequired();
        this.vehicleCapacity.patchValue(formatNumber($event.target.value, 'en-US'));
    }
  }

  onUseOfVehicleChange($event) { //to reconfirm the flow with state here
    if (this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step2.endorsementDetails?.coverageType)) {
      // this.calculatePremium();
    }
  }

  // to confirm if vehicle no. retrieval is necessary
  onChangeVehicleNo($event) {
    // this.store.dispatch(new GET_PREVIOUS_POLICY_NO({ vehicleNo: $event.target.value, agentCode: this.agentDetail.agentCode })).subscribe((res) => {
    //   let previousPolicy = res.SatMotorState.endorsement?.step1?.previousPolicy;
    //   console.log(previousPolicy);
    // });
  }

  // Make Model search pop up
  openPopupMakeModel() {

    // if (!this.vehicleMakeYear.valid) {
    //   this.vehicleMakeYear.markAllAsTouched();
    //   return;
    // }

    // Make Model search component
    this.dialogRef = this.dialogService.open(MakeModelComponent, {
      showCloseIcon: false,
      width: "920px",
      maxWidth: "920px",
      // Make Model data to send for the api request
      data: {
        region: this.endtVehiclePolicyData.region.value,
        vehicleClass: this.vehicleUseLov.filter((v) => v.code === this.vehicleUse.value)[0].vehicleClass,
        yearMnftr: this.vehicleMakeYear.value
      }
    });
    // console.log("dialogRef:", this.dialogRef);

    // Make Model selected data stored after closing search bar
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new SELECTED_ENDORSEMENT_MAKE_MODEL(result.item)).subscribe((state) => {
          this.patchMakeModelForm(result);

          console.log("EndorsementVehicleDetailsComponent result:", result);

          let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
          endtUpdatedData = {
            ...endtUpdatedData,
            riskList: [{
              ...endtUpdatedData.riskList[0],
              vehModelNvic: result.item.vehModelCodeNvic,
              vehicleMakeYear: result.item.yearMnftr,
              vehicleSeat: result.item.seat,
              vehicleCapacity: result.item.capacity,
              vehicleUom: result.item.uom,
              avCode: result.item.avCode,
              vehicleEngine: result.item.engineType,
              variantDesc: result.item.variantFullDesc,
              recommendDesc: result.item.piamMakeModelDesc + ' ' + result.item.variantFullDesc,
              makeModelDesc: result.item.piamMakeModelDesc,
              vehicleModel: result.item.vehModelCodeNvic,
              vehicleModelDescp: result.item.piamMakeModelDesc + ' ' + result.item.transmission,
              importStatus: result.item.importStatus,
            }]
          };
          this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
            isReset: false,
            cngeNote: endtUpdatedData
          }));
          this.endtCalculatePremium.emit();

        });
      }
    });
  }

  patchMakeModelForm(dt) {
    let data = dt.item ?? dt;
    console.log("EndorsementVehicleDetailsComponent data:", data);

    this.vehicleDetailForm.patchValue({
      makeModelDesc: [data.piamMakeModelDesc, data.variantFullDesc].join(" ").trim(),
      vehicleUom: data.uom,
      vehicleCapacity: formatNumber(data.capacity, 'en-US'),
      engineType: data.engineType,
      vehicleSeat: data.seat,
    });
    console.log("EndorsementVehicleDetailsComponent vehicleDetailForm:", this.vehicleDetailForm);
  }
  //////////////////////////////////////////////////////////////////////
  private appendValidators(change: any, element: AbstractControl<any>) {
    if (change) {
      element.setValidators([Validators.required]);
    } else {
      element.clearValidators();
    }
    element.updateValueAndValidity();
  }

  characterCount: number = 0;
  maxlength = 100;

  onKeyUp(event: any) {
    this.characterCount = event.target.value.length;
  }

  get vehicleUse() {
    return this.vehicleDetailForm.controls["vehicleUse"];
  }

  get vehicleNo() {
    return this.vehicleDetailForm.controls["vehicleNo"];
  }

  get vehicleMakeYear() {
    return this.vehicleDetailForm.controls["vehicleMakeYear"];
  }

  // get vehModelNvicDesc() {
  //   return this.vehicleDetailForm.controls["vehModelNvicDesc"];
  // }

  get vehicleCapacity() {
    return this.vehicleDetailForm.controls["vehicleCapacity"];
  }

  get engineType() {
    return this.vehicleDetailForm.controls["engineType"];
  }

  get vehicleSeat() {
    return this.vehicleDetailForm.controls["vehicleSeat"];
  }

  get vehicleEngine() {
    return this.vehicleDetailForm.controls["vehicleEngine"];
  }

  get vehicleChassis() {
    return this.vehicleDetailForm.controls["vehicleChassis"];
  }

  get vehicleLoanType() {
    return this.vehicleDetailForm.controls["vehicleLoanType"];
  }

  get vehicleLoanCompany() {
    return this.vehicleDetailForm.controls["vehicleLoanCompany"];
  }

  get makeModelPrintInd() {
    return this.vehicleDetailForm.controls["makeModelPrintInd"];
  }

  get makeModelDesc() {
    return this.vehicleDetailForm.controls["makeModelDesc"];
  }

  get variantDesc() {
    return this.vehicleDetailForm.controls["variantDesc"];
  }
  //////////////////////////////////////////////////////

  storeEndorsementDetailsData() {
    if (this.vehicleDetailForm.valid) {
      let vehicleDetails = this.vehicleDetailForm.getRawValue();
      console.log('Saving Vehicle Details Data:', vehicleDetails);
      let payload = {
        vehicleDetails: vehicleDetails,
        isVehicleDetails: true
      }
      this.store.dispatch(new STORE_ENDORSEMENT(payload));

      let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
      if (!endtUpdatedData) {
        endtUpdatedData = this.endtVehiclePolicyData
      }
      endtUpdatedData = {
        ...endtUpdatedData,
        riskList: [{
          ...endtUpdatedData.riskList[0],
          vehicleUse: vehicleDetails.vehicleUse,
          vehicleNo: vehicleDetails.vehicleNo,
          vehicleMakeYear: vehicleDetails.vehicleMakeYear,
          makeModelDesc: vehicleDetails.makeModelDesc, //opus is null
          vehicleUom: vehicleDetails.vehicleUom,
          vehicleCapacity: vehicleDetails.vehicleCapacity,
          engineType: vehicleDetails.engineType, //opus is null
          vehicleSeat: vehicleDetails.vehicleSeat,
          vehicleEngine: vehicleDetails.vehicleEngine,
          vehicleChassis: vehicleDetails.vehicleChassis,
        }]
      };
      this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
        isReset: false,
        cngeNote: endtUpdatedData
      }));
      this.endtCalculatePremium.emit();
      
    } else {
      console.error('Vehicle Details form is invalid!');
      // this.logFormErrors(this.vehicleDetailForm);
      this.vehicleDetailForm.markAllAsTouched();
    }
  }
////////////////////////////////////////////////
}
