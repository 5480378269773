@if (isDataLoaded) {
<base-enquiry [type]="type" [popupMessage]="popupMessage" [referralReasonList]="referralReasonList">
</base-enquiry>

<div nxLayout="grid maxwidth" class="nx-margin-top-m">
  <div nxRow>
    <div nxCol="12,12,12,12" class="grid_width_page">

      <!-- //////////////////////////////////////////////////////////////////// -->
      <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
        <div nxRow class="enquiry_status">
          <div nxCol="12,12,10">
            <h3 nxHeadline size="s" class="nx-font-weight-regular">
              {{ hasCoverNoteGenerated() ? 'e-Cover Note No. :' : '' }}
              <span class="nx-font-weight-bold">{{ hasCoverNoteGenerated() ? coverNoteNumber : '' }}</span>
            </h3>
          </div>
          <div nxCol="12,12,2" class="enquiry_status">
            <nx-badge type="active" [class]="classType">{{status}}</nx-badge>
          </div>
        </div>
      </div>


      <!-- //////////////////////////////////////////////////////////////////// -->
      <!-- Agent Information -->
      @if (showAgentInfo) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Agent Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <app-agents-information [agentData]="agentData">
            </app-agents-information>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- //////////////////////////////////////////////////////////////////// -->
      <!-- Issuance Information -->
      @if (showIssuanceInfo) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Issuance Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <div nxLayout="grid nopadding">
            <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo"
              [productList]="productList" [endorsementList]="endorsementList" [agentData]="agentData">
            </app-issuance-information>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- //////////////////////////////////////////////////////////////////// -->
      <!-- Customer Partner -->
      @if (showCustomerPartner) {
      <nx-accordion variant="light">
        <nx-expansion-panel [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Customer Partner
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <app-customer-partner-details [viewOnly]="true" [cpType]="'FOUND'" [hideEdit]="true" [cpDetails]="cpData"
            [lov]="lov">
          </app-customer-partner-details>

        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- ////////////////////////////////////////////// -->
      <!-- Coverage -->
      @if (showCoverage) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordione_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Coverage
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <app-coverage-summary [coverageInfo]="coverageInfo" [viewQuotation]="viewQuotation"
            [isEndorsement]="isEndorsement" [productCode]="endtPolicy.productCode"></app-coverage-summary>

        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- ////////////////////////////////////////////// -->
      <!-- Historical Claim -->
      @if (showHistoricalClaim) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Historical Claim
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Client Claim History</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{clientClaimHistory}}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Vehicle Claim History</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{vehicleClaimHistory}}</span>
              </td>
            </tr>
          </table>
        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- ////////////////////////////////////////////// -->
      <!-- Premium Information -->
      @if (showPremiumInformation) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Premium Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <app-premium-information [premiumInfo]="premiumInfo" [viewOnly]=true [isEndorsement]="isEndorsement">

          </app-premium-information>

        </nx-expansion-panel>
      </nx-accordion>
      }

      @if(checklistLov && checklistLov.length > 0) {
        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Checklist
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
  
            <app-checklist [checklistAns]="cngeNote.declareList" [parentForm]="viewForm" [isViewOnly]="true"
            [endtCode]="cngeNote.endtCode" [checklistRemarks]="cngeNote.chklistRemarks" [checklistLov]="checklistLov">
            </app-checklist>

          </nx-expansion-panel>
        </nx-accordion>
      }

      @if(cngeNote.endtNarration) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Endorsement Narration
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <app-endorsement-narration [endtNarration]="cngeNote.endtNarration"></app-endorsement-narration>
        </nx-expansion-panel>
      </nx-accordion>
      }


      <!-- ////////////////////////////////////////////// -->
      <!-- Remarks -->
      @if (showRemarks) {
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Remarks
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <app-view-remarks [remarksInfo]="remarksInfo" [isQuotationView]=true></app-view-remarks>
        </nx-expansion-panel>
      </nx-accordion>
      }

    </div>
  </div>
</div>

<div class="button_area_bg nx-margin-top-50">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <div class="button-container">
          <button nxButton="secondary" type="button" (click)="redirectBack()" *ngIf="isShowBackBtn">
            BACK
          </button>
          <button nxButton="secondary" type="button" (click)="openRequestCancellationPopUp()"
            *ngIf="isShowRequestCancellationBtn">
            Request Cancellation
          </button>
          <button nxButton="primary" type="button" (click)="addRemarks()" *ngIf="isShowAddRemarksBtn">
            REFERRAL REMARKS
          </button>
          <button nxButton="primary" type="button" (click)="updateQuotation()" *ngIf="isShowUpdateQuotationBtn">
            Update e-Quotation
          </button>
          <button nxButton="primary" type="button" (click)="updatePayment()" *ngIf="isUpdatePaymentBtn">
            Update Payment
          </button>
          <button nxButton="primary" type="button" (click)="downloadPDF('quotation')"
            *ngIf="isShowDownloadQuotationBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            E-Quotation PDF
          </button>
          <button nxButton="primary" type="button" (click)="sendToJPJ()" *ngIf="isShowSendToJPJBtn">
            {{cngeNote.cnoteType === 'COE' || cngeNote.cnoteType === 'XP' ? 'SEND TO JPJ' : 'GENERATE ENDORSEMENT' }}
          </button>
          <button nxButton="primary" type="button" (click)="checkDownloadPDF('endorsement')"
            *ngIf="isShowDownloadCoverNoteBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            ENDORSEMENT SCHEDULE PDF
          </button>
          <button nxButton="primary" type="button" (click)="checkDownloadPDF('endorsementCI')"
            *ngIf="isShowDownloadCoverNoteBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            ENDORSEMENT CERTIFICATE PDF
          </button>
          <button nxButton="primary" type="button" (click)="downloadPDF('invoice')" *ngIf="isShowDownloadInvoiceBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            E-Invoice PDF
          </button>
          <button nxButton="primary" type="button" (click)="downloadPDF('invoice')" *ngIf="isShowDownloadInvoiceBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            CREDIT TAX INVOICE PDF
          </button>
          <button nxButton="primary" type="button" (click)="downloadPDF('invoice')" *ngIf="isShowDownloadInvoiceBtn">
            <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
            DEBIT TAX INVOICE PDF
          </button>
          <button nxButton="primary" type="button" (click)="uploadDocument()" *ngIf="isShowUploadDocumentBtn">
            UPLOAD DOCUMENT
          </button>
          <button nxButton="primary" type="button" (click)="emailDocument()" *ngIf="isShowEmailDocumentBtn">
            EMAIL DOCUMENT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
}