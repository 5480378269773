import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { UserResponse } from '@interfaces/user';
import { Select, Selector, Store } from '@ngxs/store';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { RemarksComponent } from '@shared/components/modal/remarks/remarks.component';
import { RemarksAddeddComponent } from '@shared/components/modal/remarks-addedd/remarks-addedd.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import { SharedModule } from '@shared/shared.module';
import { CALCULATE_PREMIUM, CANCEL_COVER_NOTE, CANCEL_QUOTATION, CANCEL_REPLACE, CONTINUE_ISSUANCE, COPY_CNGE_INFO, DOWNLOAD_PDF, GET_CROSS_SELL_LIST, GET_REDIRECT_PAGE, GET_SWITCH_SITE, IS_CANCEL_AT_COMPLETE, REQUEST_CANCELLATION, SAVE_REFERRAL_REMARKS, SEND_TO_JPJ, SET_CURRENT_STEP, SET_JPJ_STATUS_SENT, STORE_COMPLETE_DATA } from '@store/sat-motor/sat-motor.action';
import { EnquiryPopupComponent } from 'src/app/popup/enquiry-popup/enquiry-popup.component';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { StatusMessageComponent } from '@shared/components/status-message/status-message.component';
import { CompleteInformationComponent } from '@shared/components/complete-information/complete-information.component';
import { STORE_QUERY_PARAMS } from '@store/user/user.action';
import { SummaryPopupComponent } from 'src/app/popup/summary-popup/summary-popup.component';
import { ClauseMessageComponent } from '@shared/components/modal/clause-message/clause-message.component';
import { EmailDocumentComponent } from '@shared/components/modal/email-document/email-document.component';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-complete',
  standalone: true,
  imports: [
    SharedModule,
    RemarksComponent,
    CrossSellBannerComponent,
    StatusMessageComponent,
    CompleteInformationComponent
  ],
  templateUrl: './complete.component.html',
  styleUrl: './complete.component.scss'
})
export class CompleteComponent implements OnInit{
  @Select(SatMotorState.motorStep4Summary) step4$: Observable<any>;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.rtpStep2Summary) rtpSummary$: Observable<any>;

  isIssuance: boolean = false; 
  isEndorsement: boolean = false; 
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CompleteComponent>;
  componentDialogRef2?: NxModalRef<SummaryPopupComponent>;


  componentDialogRef1?: NxModalRef<EmailDocumentComponent>;

  referralRemarksModal?: NxModalRef<RemarksComponent>;
  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  remarksAddedModal?: NxModalRef<RemarksAddeddComponent>;
  clauseMsgModal?: NxModalRef<ClauseMessageComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;
  dialogRef?: NxModalRef<any>;

  agentInfo: any;
  userInfo: UserResponse;
  declarationInfo: any;
  customerPartnerInfo: any;
  crossSellList: any = [];
  marketingConsent: any;
  data: any;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  queryParam: any;
  cnoteType: any;
  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: string;
  lovGeo: any;
  emailCheck : any;
  edocConsentInd: boolean = false;
  eInvoiceConsentInd : boolean;
  isUpdatePayment: any;
  isCCentric: boolean = false

  constructor(
    private store: Store,
    private readonly dialogService: NxDialogService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    
    this.store.dispatch(new SET_CURRENT_STEP(5));
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.queryParam = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1?.issuance?.issuance?.cnoteType);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.edocConsentInd);
    this.eInvoiceConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.einvoiceConsentInd);
    this.isUpdatePayment = this.store.selectSnapshot<any>(state => state.SatMotorState.isUpdatePayment);
    this.isCCentric = this.store.selectSnapshot<any>((state) => state.SatMotorState.opportunityId);

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    let completeData;

    if(this.isIssuance) {
      completeData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step5?.cngeNote);
    }else if(this.isRTP) {
      completeData = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp.step3?.cngeNote);
    }

    if(!completeData) {
      if (this.isIssuance) {
        this.loadIssuanceData();
      }

      if (this.isRTP) {
        this.loadRTPData();
      }

      if (this.isEndorsement) {
        this.loadEndorsementData();
      }
    }else {
      if(this.isIssuance) {
        let generateQuotationData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data);
        let sendToJPJData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ?.data);
        if (sendToJPJData) {
          if(completeData.contractId !== sendToJPJData.contractId) {
            this.data = sendToJPJData;
            this.storeCompleteData();
          }else {
            this.data = completeData;
          }
          
        } else if (generateQuotationData) {
          if (completeData.contractId !== generateQuotationData.contractId) {
            this.data = generateQuotationData;
            this.storeCompleteData();
          } else {
            this.data = completeData;
          }
        }
      }else {
        this.data = completeData;
      }
      
    }
  }

  loadEndorsementData() {
    //endt data
  }

  loadRTPData() {
    this.rtpSummary$.subscribe(summary => {
      let sendToJPJData = summary.sendToJPJ?.data?.cngeNoteDto;

      if (sendToJPJData) {
        this.data = sendToJPJData;
        this.agentInfo = this.data.mkagAgent;
        this.customerPartnerInfo = this.data.clientPartner;
        this.storeCompleteData();
      }
    });
  }

  loadIssuanceData() {
    this.step4$.subscribe(step4 => {
      let generateQuotationData = step4?.generateQuotation?.data;
      let sendToJPJData = step4?.sendToJPJ?.data;
      let updatePaymentData = step4?.updatePayment?.data;

      if(updatePaymentData) {
        this.data = updatePaymentData;
        this.storeCompleteData();
      }else if (sendToJPJData && step4?.sendToJPJ?.success) {
        this.data = sendToJPJData;
        this.storeCompleteData();
      } else if (generateQuotationData) {
        this.data = generateQuotationData;
        this.storeCompleteData();
      }

    });
    
    this.agentInfo = this.data.mkagAgent;
    this.customerPartnerInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step2);

    // let getCrossSellListPayload = {
    //   "agentCode": this.agentInfo.agentCode,
    //   "partnerId": this.customerPartnerInfo.partnerId,
    //   "idType": this.customerPartnerInfo.idType1,
    //   "idValue": this.customerPartnerInfo.idValue1
    // };
    // this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload)).subscribe(state => {
    //   if (state.SatMotorState.data.step5.crossSellList) {
    //     this.crossSellList = state.SatMotorState.data.step5.crossSellList;
    //     this.marketingConsent = state.SatMotorState.data.step5.marketingConsent;
    //     console.log("cross sell list: ", this.crossSellList);
    //   }
    // });
  }

  // openFromTemplate(): void {
  //     this.dialogService.open(this.reasoncancellationRef, {
  //         ariaLabel: 'A simple modal',
  //         disableClose: true,
  //     });
  // }

  openPopupReferralRemarks() {
    this.referralRemarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Please add remarks",
        primaryButtonLabel: "SUBMIT"
      }
    });

    this.referralRemarksModal.afterClosed().subscribe(result => {
      if(result) {
        const data = {
          remark: result.remarks,
          operator: this.userInfo.userId, // uncomment after SAVE_REFERRAL_REMARKS BE complete
          contractId: this.data.contractId // uncomment after SAVE_REFERRAL_REMARKS BE complete
        };
        this.store.dispatch(new SAVE_REFERRAL_REMARKS(data)).subscribe(state => {
          this.remarksAddedModal = this.dialogService.open(RemarksAddeddComponent, {
            showCloseIcon: false
          });
        });
      }
    });
  }

  options = [
    "BMW",
    "Audi",
    "VW",
    "Mercedes",
    "Porsche",
    "Tesla",
    "Lada",
    "Opel",
    "Fiat",
    "Ford",
    "Kia",
    "Toyota",
    "Ferrari",
  ];

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  openRequestCancellationPopUp() {
    let data = {
      summaryData: this.data
    }

    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if(result) {
        let payload = {
          cancelRemarks: result.data.cancelRemarks,
          contractId: this.data.contractId,
          operator: this.userInfo.userId
        };

        if (result.isCancelAndReplace) {
          this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
            let cancellationRes = state.SatMotorState.data.cancellationRes;
            this.store.dispatch(new COPY_CNGE_INFO(cancellationRes)).subscribe(state => {
                this.router.navigate(['issuance/summary']);
            });
          });
          
        } else {
          if(this.isEquotation) {
            this.store.dispatch(new CANCEL_QUOTATION(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.data = cancellationRes;
                this.storeCompleteData();
                this.store.dispatch(new IS_CANCEL_AT_COMPLETE(true));
                // this.goToViewPage();
              }
            });
          }else {
            this.store.dispatch(new CANCEL_COVER_NOTE(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.data = cancellationRes;
                this.storeCompleteData();
                this.store.dispatch(new IS_CANCEL_AT_COMPLETE(true));
              }
            });
          }
          // this.store.dispatch(new REQUEST_CANCELLATION(cancellationPayload));
        }
      }
    });
  }

  backToSummaryListing() {

    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
      let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
      console.log("SwitchSiteUrl Url : " + switchSiteUrl);
      window.location.href = switchSiteUrl;
    });

  }

  goToViewPage() {
    this.queryParam = {
      ...this.queryParam,
      contractId: this.data.contractId,
      action: 'COMPLETE'
    }
    this.store.dispatch(new STORE_QUERY_PARAMS(this.queryParam)).subscribe(() => {
      this.router.navigate(["/view-quotation"]);
    });
  }

  onContinueIssuance() {
    let isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);

    if (isSingleAgent) {
      this.store.dispatch(new CONTINUE_ISSUANCE()).subscribe(state => {
        this.router.navigate(['issuance/declaration-issuance-info']);
      });
    } else {
      this.store.dispatch(new CONTINUE_ISSUANCE()).subscribe(state => {
        this.router.navigate(["/agent-selection"]);
      });
    }
  
  }

  get isCoverNote() {
    if(this.data.cnoteNo) {
      return true;
    }else {
      return false;
    }
  }

  get isEquotation() {
    if(!this.data.cnoteNo && this.data.proposalNo && !this.data.draftInd) {
      return true;
    }else {
      return false;
    }
  }

  downloadPDF(contractId: string, type: string, autoDownload: boolean) {
    const payload: any = {
      operator: this.userInfo.userId,
      contractId: contractId || this.data.contractId,
      type: type,
    };
  
    let vehicleNo = this.data.riskList[0].vehicleNo;
    let cnoteType = this.data.cnoteType;

    if (type === 'covernote' && !autoDownload && !(cnoteType === 'NWNN' && vehicleNo === 'NA')) {
      this.clauseMsgModal = this.dialogService.open(ClauseMessageComponent, {
        width: '450px',
        maxWidth: '450px',
      });
      this.clauseMsgModal.afterClosed().subscribe(result => {
        payload.printClauseInd = (result === 'Y');
        this.store.dispatch(new DOWNLOAD_PDF(payload));
      });
    } else {
      this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe();
    }
  }

  sendToJPJ() {
    let address1 = this.data.clientPartner.clientPartnerAddress.clientAddress1Tmp;
    let emailValue = this.data.clientPartner.clientEmail;
    this.motorPC = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1?.issuance?.issuance?.productCode);
    this.lovproductConfig = this.store.selectSnapshot<any>((state)=>state.SatMotorState.lov.productAccessConfigList);

    
    for (let i = 0; i < this.lovproductConfig.length; i++) {
    this.productAccessListData = this.lovproductConfig[i];
    this.lovPC = Object.keys( this.productAccessListData)[0]; 
  
      if (this.lovPC === this.motorPC) {
       this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;
      }
    }


  if(((address1 =="") && (emailValue =="")) && ((this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd))){
    this.openPopup("addrEmailSum");
    }
    else if(((address1!="") && (emailValue =="")) && ((this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd))){
      this.openPopup("emailSum");
    }
    else if ((address1 == "") && ((emailValue != "")||(emailValue==""))) {
      this.openPopup("address");
    }
    else {
    let payload = {
      userId: this.userInfo.userId,
      isRTP: this.isRTP,
      proposalNo: this.data.proposalNo,
      contractId: this.data.contractId,
      cnoteDate: this.data.cnoteDate,
      issueBy: this.data.issueBy,
      issuedDate: this.data.cnoteDate,
      cnoteType: this.cnoteType
    };

    this.store.dispatch(new SEND_TO_JPJ(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
      console.log(response);

      if (response && response.data && response.success && response.data.contractId) {
        let contractId = response?.data?.contractId;

        this.data = response.data;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        
        if(response?.data?.cnoteStatus === 'PRINTED') {
          this.downloadPDF(contractId, 'covernote', true);
        }
        
      }

      let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
      if (message) {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: message
          }
        });
      } else if (response.messageConfirm && response.messageHeader) {
        this.showConfirmationMessage(response, payload);
      }
    });
  }
  }

  showConfirmationMessage(response, payload) {
    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: response.messageConfirm, type: response.messageHeader },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        let resendPayload = {
          ...payload,
          skipErrorAndProceed: true
        }
        this.store.dispatch(new SEND_TO_JPJ(resendPayload)).subscribe(() => {
          let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
          console.log(response);

          if (response && response.data && response.success && response.data.contractId) {
            let contractId = response?.data?.contractId;

            this.data = response.data;
            this.cdr.markForCheck();
            this.cdr.detectChanges();

            if (response?.data?.cnoteStatus === 'PRINTED') {
              this.downloadPDF(contractId, 'covernote', true);
            }

          }

          let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
          if (message) {
            this.okMessageModal = this.dialogService.open(OkMessageComponent, {
              width: '400px',
              data: {
                message: message
              }
            });
          } else if (response.messageConfirm && response.messageHeader) {
            this.showConfirmationMessage(response, payload);
          }

        });
      }
    });
  }

  storeCompleteData() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    
    let payload = {
      isIssuance: this.isIssuance,
      isRTP: this.isRTP,
      cngeNote: this.data
    }

    this.store.dispatch(new STORE_COMPLETE_DATA(payload));
  }
  openPopup(popup_type: any) {

    this.componentDialogRef2 = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }

  openPopupEmailDoc(popup_type: any) {
    let innerWidth = window.innerWidth;
    let popupWidth;
    console.log(innerWidth);
    if (innerWidth < 703) {
      popupWidth = "680";
    } else if (innerWidth >= 703 && innerWidth < 991) {
      popupWidth = "680";
    } else {
      popupWidth = "1100px";
    }
    // return
    this.componentDialogRef1 = this.dialogService.open(EmailDocumentComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { popup_type: popup_type },
    });
  }

  uploadDocument() {

    let dialogConfig = {
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      height: '96%',
      width: '90%',
      panelClass: 'uploadDocumentDialogCss',
      data: {
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
    
  }

}
