export const STEPPER_CONFIGURATIONS = {
    ISSUE: [
        { name: "Declaration & Issuance Info" },
        { name: "Customer Partner" },
        { name: "Coverage" },
        { name: "Summary" },
        { name: "Complete" },
    ],
    ENDT: [
        { name: "Declaration & Issuance Info" },
        { name: "Endorsement Details" },
        { name: "Summary" },
        { name: "Complete" },
    ],
    RTP: [{ name: "Declaration & Issuance Info" }, { name: "Summary" }, { name: "Complete" }],
    unification: [
        { name: "Agent Additional Info" },
        { name: "Declaration & Issuance Info" },
        { name: "Customer Partner" },
        { name: "Coverage" },
        { name: "Summary" },
        { name: "Complete" },
    ],
};
