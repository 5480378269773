export const defaultSatMotorState = {
    motor: {
      step1: null,
      step2: null,
      step3: null,
      step4: null,
      step5: null,
    },
    endorsement: {
      step1: null,
      step2: null,
      step3: null,
      step4: null,
    },
    rtp: {
      step1: null,
      step2: null,
      step3: null,
    },
    data: {
      step1: {},
      step2: {
        search: null,
        showSearchComponent: null,
        store: null,
        cpType: null,
        isCompOrCustInfoIncomplete: false,
        isContactInfoIncomplete: false,
        isCorrespondenceInfoIncomplete: false,
        isValidIdValue1: false,
        isValidIdValue2: false,
        endorsementDetails: {
          policyMovemementData: null,
          coverageTypeLov: null,
          vehicleUseLov: null,
          searchMakeModelList: null,
          selectedMakeModel: null,
          vehicleDetails: null,
          coverageType: null,
          coveragePremiumPayload: null,
          coveragePremium: null,
          coverageNCD: null,
        },
      },
      step3: {
        coverage: {
          coverageTypeLov: null,
          vehicleUseLov: null,
          searchMakeModelList: null,
          selectedMakeModel: null,
          vehicleDetails: null,
          coverageType: null,
          coveragePremiumPayload: null,
          coveragePremium: null,
          coverageNCD: null,
          coverageShowDialog: null,
        },
      },
      step4: {
        paymentList: null,
        summary: {
          premiumCalculation: null,
        },
        generate: {
          response: null,
        },
        sendToJPJ: {
          response: null,
        },
        upsellProductLov: null,
        upsellProduct: null,
        paymentModeLov: null,
        paymentToLov: null,
      },
      step5: {
        referRemarkList: null,
        crossSellList: null,
        marketingConsent: null,
        folderContents: null,
        contentId: null,
      },
      isRenewNow: null,
      agentDetail: null,
      branchList: null,
      hasShowedOneAllianzPopUp: false,
      postCodeInfo: null,
      switchSiteUrl: null,
      cancellationRes: null,
    },
    previousPolicyDetails: null,
    previousPolicyDetailsError: null,
    viewPolicyDetails: null,
    viewPolicyDetailsError:null,
    viewClaimDetails: null,
    viewClaimDetailsError:null,
    viewPolicyData:null,
    viewClaimData:null,
    currentStep: null,
    isCPUpdated: false,
    isSingleAgent: false,
    lov: {},
    agentInfo: null,
    branchList: null,
    declarationInfo: null,
    actionType: '',
    premiumCalculation: null,
    viewQuotation: null,
    viewQuotationData: null,
    requoteQuotation: null,
    cartNavigation: null,
    cartDocumentDetails: null,
    jpjStatusSent: false,
    draftDetails: null,
    step: null,
    ncdDetails: null,
    poiValidateResult: null,
    updateIsmPolicyMessage: null,
    ncdEnquiryDetails: null,
    policyEnquiryDetails: null,
    emailDocumentResponse: null,
    emailListing: null,
    geoCodedInd: false,
    vehicleUseLov: null,
    documentTypeLov: null,
    reasonCodeLov: null,
    vehicleClassLov: null,
    vehicleClassUseLov: null,
    productConfigLov: null,
    retrieveQuotation: null,
	  checklistLov: null,
	  cCentricInfo: null,
    quotationDetail: null,
    opportunityId: null,
    summaryPremium: null,
    upsellProductLov: null,
    paymentToLov: null,
    paymentModeLov: null,
    isUpdatePayment: false,
    endtSummaryPremium: null,
    isCancelAtComplete: false,
  };
  