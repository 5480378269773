import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { CPPopupComponent } from "../../../../popup/cp-popup/cp-popup.component";
import { Observable, Subscription, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { CLIENTTYPE, IDTYPE } from 'src/app/shared/enums/index.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEP2 } from 'src/app/interfaces/motor';
import { Router } from '@angular/router';
import { CANCEL_DRAFT, EDIT_CUSTOMER_PARTNER, GET_SWITCH_SITE, SAVE_AS_DRAFT } from '@store/sat-motor/sat-motor.action';
import { SharedModule } from '@shared/shared.module';
import { CustomerPartnerEditModalComponent } from '../../modal/customer-partner-edit-modal/customer-partner-edit-modal.component';
import { CustomerPartnerBase } from '@shared/classes/customer-partner.base';
import { CustomerPartnerDetailsComponent } from '../customer-partner-details/customer-partner-details.component';
import moment from 'moment';
import { SimpleModalComponent } from '@shared/components/modal/simple-modal/simple-modal.component';
import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';
import { LoadingService } from "@shared/services/loading/loading.service";

@Component({
  selector: 'app-customer-partner-found',
  standalone: true,
  imports: [
    SharedModule,
    CustomerPartnerEditModalComponent
  ],
  templateUrl: './customer-partner-found.component.html',
  styleUrl: './customer-partner-found.component.scss'
})
export class CustomerPartnerFoundComponent extends CustomerPartnerBase implements OnInit{
  @Output() searchAgain: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back = new EventEmitter();
  @Input() dataSearch: STEP2.SearchDataStore;
  @Input() mkagAgent: any;
  dialogRef?: NxModalRef<any, undefined>;
  time$: Observable<number> = timer(500);
  subscription: Subscription;
  cpEditModal: boolean = false;
  componentDialogRef?: NxModalRef<CPPopupComponent>;
  cp_found:boolean = true;
  individual: boolean = false;
  company: boolean = true;
  customerEditForm: FormGroup;
  isUpdatedCP: boolean;
  eInvoiceConsent: boolean = false;
  edocConsentInd: boolean = false;
  autoRenewal: boolean = false;
  userInfo: any;
  infoMessageModal?: NxModalRef<SimpleModalComponent>;

  customerEditModal?: NxModalRef<CustomerPartnerEditModalComponent>;
  isSaved: any;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;


  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private readonly loadingService: LoadingService,
  ) {
    super();
  }

  ngOnInit() {
    const step2 = this.store.selectSnapshot<any>(state => state.SatMotorState.data.step2);
    this.eInvoiceConsent = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.einvoiceConsentInd);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.edocConsentInd);
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    this.isSaved = this.store.selectSnapshot<any>((state) => state.SatMotorState?.draftDetails);
  }

  openEdit(data) {
    this.loadingService.startLoading();

    this.subscription = this.time$.subscribe(val => {
      this.customerEditModal = this.dialogService.open(CustomerPartnerEditModalComponent, {
        showCloseIcon: false,
        data: {
          cpDetails: this.cpDetails,
          eInvoiceConsent: this.eInvoiceConsent,
          edocConsentInd: this.edocConsentInd,
          mkagAgent: this.mkagAgent,
          lov: this.lov,
          cpType: "FOUND"
        }
      });

      this.customerEditModal.afterClosed().subscribe(result => {
        if (result) {
          console.log("customer edit form data: ", result);
          this.updateCpData(result);
        }
      });

      this.loadingService.stopLoading();
      this.subscription.unsubscribe();
    });
    

  }

  restartSearch() {
    // this.store.dispatch(new IS_STEP_3(false));
    this.searchAgain.emit(true);
  }

  updateCpData(data) {
    if(data) {
      const payload = this.generateSaveCPPayload(data);
      this.store.dispatch(new EDIT_CUSTOMER_PARTNER(payload)).subscribe(res => {
        const isCPUpdated = this.store.selectSnapshot<boolean>(state => state.SatMotorState.isCPUpdated);
        if(isCPUpdated) {
          this.isUpdatedCP = true;
        }else {
          this.isUpdatedCP = false;
        }
      });
    }
    
  }


  generateSaveCPPayload(data) {
    const formValue = data;
    const searchCriteria = this.store.selectSnapshot(state => state.SatMotorState.data.step2.search);
    const date = new Date();
    const effectiveDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-M-D').format('YYYY-MM-DD');
    const user = this.store.selectSnapshot<string>((state) => state.UserState.user.userId);

    const payload = {
      clientPartner: {
        clientAge: formValue.age,
        clientDateofBirth: formValue.dob ? moment(formValue.dob, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientEmail: formValue.email,
        clientGender: formValue.gender,
        clientMobileCode1: formValue.mobileNoCode,
        clientMobileNo1: formValue.mobileNo,
        clientName: formValue.name,
        clientNationality: formValue.nationality,
        clientMaritalStatus: formValue.maritalStatus,
        clientPartnerAddress: {
          clientUnitNo: formValue.unitNo,
          clientAddress1: formValue.address1,
          clientAddress2: formValue.address2,
          clientAddress3: formValue.address3,
          clientAddressType: formValue.addressType,
          clientCity: formValue.city,
          clientCountry: formValue.country,
          clientPhoneCode: formValue.fixedLineNoCode,
          clientPhoneNo: formValue.fixedLineNo,
          clientFax1Code: formValue.faxNoCode,
          clientFax1No: formValue.faxNo,
          clientPostcode: formValue.postCode,
          clientState: formValue.state,
          clientGeoLatitude : formValue.clientGeoLatitude,
          clientGeoLongitude : formValue.clientGeoLongitude,
          clientGeoPlaceId : formValue.clientGeoPlaceId,
          clientGeoPlusCode : formValue.clientGeoPlusCode,
          clientGeoFormattedAddress : formValue.clientGeoFormattedAddress
        },
        clientType: searchCriteria.clientType,
        defaultMailReq: false,
        idType1: searchCriteria.idType,
        idType2: formValue.idType2,
        idValue1: searchCriteria.idValue,
        idValue2: formValue.idValue2,
        updateCustomerPartner: false,
        clientTinNo: formValue.tinNo,
        clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
        clientSstRegNo: formValue.sstNo,
        clientSstRegDate: formValue.sstEffDate && moment(formValue.sstEffDate).isValid() ? moment(formValue.sstEffDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstDeRegDate: formValue.sstDeRegDate && moment(formValue.sstDeRegDate).isValid() ? moment(formValue.sstDeRegDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstResidentStatus: formValue.sstResidentStatus,
        clientSstCountry: formValue.sstCountry,
        smeCategory: formValue.smeCategory,
        smeEndDate: formValue.smeEndDate ? moment(formValue.smeEndDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        smeRegisteredInd: formValue.smeRegisteredInd === 'Y' ? true : false,
        smeRegisteredNumber: formValue.smeRegNo,
        smeStartDate: formValue.smeStartDate ? moment(formValue.smeStartDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : ''
      },
      mkagAgent: this.mkagAgent,
      effectiveDate: effectiveDate,
      productType: 'MT',
      operator: user
    }

    return payload;
  }

  nextPage() {
    if(this.cpDetails.idType1 === IDTYPE.OLD_IC && this.cpDetails.idType2 !== IDTYPE.NRIC) {
      this.openNricNotUpdatePopup();
      return;
    }

    if(!this.isCompany && this.cpDetails.clientAge < 16) {
      this.openAgeNotMetPopup();
      return;
    }

      this.router.navigate(['issuance/coverage']);
  }

  goBack() {
    this.back.emit();
  }

  saveAsDraft() {
    this.store.dispatch(new SAVE_AS_DRAFT({ userId: this.userInfo.userId })).subscribe(state => {
      this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "630px",
        data: { 
          type: "e-Quotation Saved", 
          message: "Your e-Quotation has been saved as a Draft. Here is your <br/>Draft <br/>Reference No. <b>" + state.SatMotorState.motor.step2.draftNo + "</b>. You may return to this e-Quotation through the Draft Summary Screen.",
          btnOkLabel: "BACK TO SUMMARY LISTING", 
        },
      });
      this.infoMessageModal.afterClosed().subscribe((result) => {
        this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
          window.location.href = state.SatMotorState.data.switchSiteUrl;
        });       	      
      });
    });
  }

  cancelDraft() {

    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: "Do you want to cancel the Draft?" },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          operator: this.userInfo.userId,
          cngeNote: this.isSaved
        };
        this.store.dispatch(new CANCEL_DRAFT(data)).subscribe(state => {
          if (state) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "500px", maxWidth: "500px",
              data: { message: "Cancel Draft Success" },
            })
            .afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });       	      
            });
          }
        });
      }
    });

  }

  openNricNotUpdatePopup() {
    this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
      showCloseIcon: false,
      width: '400px',
      data: { 
        type: "Warning", 
        message: "NRIC not updated for Policyholder. Please update."
      },
    });
  }

  openAgeNotMetPopup() {
    this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
      showCloseIcon: false,
      width: '400px',
      data: { 
        type: "Warning", 
        message: "Minimum age of the client is not met."
      },
    });
  }
  

}
