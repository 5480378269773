@if (isDataLoaded) {
<div nxRow>
    <div nxCol="12">
        <h2 class="nx-margin-bottom-2m nx-margin-top-0">Complete</h2>

        @if (showTitleMessage) {
        <nx-message context="info" class="green_color_notification nx-margin-bottom-2m">
            <span class="nx-font-weight-bold">
                {{ titleMessage }}
            </span>
        </nx-message>
        }

        <nx-card class="completed_card">
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="nx-margin-0">{{isQuotationOrCoverNote()}}</h2>
                    </div>
                    <div nxCol="12,12,2" class="enquiry_status">
                        <nx-badge type="active" [class]="classType">{{status}}</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0">{{ getProductName() }}</h2>

            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">
                {{ getCoverNoteNumber() }}
            </h3>
            <p nxCopytext="normal" [ngClass]="referralReasonList? 'nx-margin-bottom-0' : 'nx-margin-bottom-m'">{{ getQuotationMessage() }}</p>
            <ul nxList="small" class="completed_list" *ngIf="referralReasonList">
                <li *ngFor="let referrer of referralReasonList">
                    <span class="nx-font-weight-bold">{{ referrer.referCode }}</span> - {{ referrer.referDescp }}
                </li>
            </ul>

            <div class="button-container nx-margin-y-m">
                <button nxButton="secondary small" type="button" 
                    (click)="openRequestCancellationPopUp()" *ngIf="isShowCancellationBtn">
                    Request Cancellation
                </button>
                <button nxButton="secondary small" type="button" 
                    (click)="openPopupReferralRemarks()" *ngIf="isShowReferralRemarksBtn">
                    Referral Remarks
                </button>
                <button nxButton="primary small" type="button" 
                    (click)="downloadPDF(data.contractId, 'quotation')" *ngIf="isShowQuotationPDFBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    e-Quotation PDF
                </button>
                <button nxButton="primary small" type="button" 
                    (click)="checkDownloadPDF(data.contractId, 'endorsement', true)" *ngIf="isShowSchedulePDFBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    Endorsement Schedule PDF
                </button>
                <button nxButton="primary small" type="button" 
                    (click)="checkDownloadPDF(data.contractId, 'endorsementCI', false)" *ngIf="isShowCertificatePDFBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    Endorsement Certificate PDF
                </button>
                <button nxButton="primary small" type="button" 
                    (click)="checkDownloadPDF(data.contractId, 'invoice', false)" *ngIf="isShowInvoicePDFBtn">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>
                    e-Invoice PDF
                </button>
                <button nxButton="primary small" type="button" (click)="uploadDocument()" *ngIf="isShowUploadBtn">
                    Upload Document
                </button>
            </div>

            <hr class="nx-margin-bottom-m">
            <app-complete-information [data]="data" [isEndorsement]=true></app-complete-information>
        </nx-card>

        <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
            <button nxButton="secondary" type="button" (click)="backToSummaryListing()">Back to Summary listing</button>
            <button nxButton="primary" type="button" (click)="goToViewPage()">VIEW {{ isEndorsementGenerated ? 'ENDORSEMENT' : 'E-QUOTATION' }}</button>
            <button nxButton="primary" type="button" (click)="emailDocument()" *ngIf="getStatus() === 'PRINTED'">EMAIL DOCUMENT</button>
            <button nxButton="primary" type="button" (click)="onContinueIssuance()">Continue issuance</button>
        </div>

    </div>
</div>
}