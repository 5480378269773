import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";
import {
  STORE_ENDORSEMENT,
  UPDATE_ENDORSEMENT_STEP2
} from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { ProgressBarComponent } from "../../../components/progress-bar/progress-bar.component";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CoveragePopupComponent } from "../../../popup/coverage-popup/coverage-popup.component";
import { Subject, takeUntil } from 'rxjs';
import { combineToDate, inBetween, splitNRIC } from '../../helpers/service.helper';
import { NricService } from '@services/nric/nric.service';


@Component({
  selector: 'app-named-driver',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './named-driver.component.html',
  styleUrl: './named-driver.component.scss'
})
export class NamedDriverComponent implements OnInit {
  /*
  * pending type coverage to connect to BE to get the default driver name, driver age and driver id list
  */

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoverageComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  // @Input() isComprehensive = false;
  // @Input() isThirdParty = false;
  // @Input() isThirdPartyFireTheft = false;
  @Input() defaultDriver: any;
  @Input() parentForm: FormGroup;
  @Output() selectExtraCover = new EventEmitter<any>();
  @Output() removeExtraCover = new EventEmitter<any>();
  @Output() endtCalculatePremium = new EventEmitter<unknown>();

  userInfo: any;
  driverNameForm: FormGroup;
  driverNameList: any;
  isDisableAddDriverBtn: boolean;
  /*
    'PRDCTA' = 'PRIVATE CARS EXCLUDING GOODS',
    'PRDCTB' = 'PRIVATE CARS INCLUDING GOODS',
    'PRDCTC' = 'PRIVATE CARS COMPANY REGISTERED',
    'PRDCTD' = 'MOTOR CYCLE',
    'PRDCTE' = 'MOTORCYCLE PLUS'
  */
  product: any;

  unsubscribe$ = new Subject<void>();

  @Input() endorsementType: any;

  namedDriverForm: FormGroup;
  endtVehiclePolicyData: any;

  // Dialog management
  openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
    this.templateDialogRef = this.dialogService.open(template, {
      height: config.height || '500px',
      width: config.width || '780px',
    });
  }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private nricService: NricService,
  ) { }

  ngOnInit() {
    this.endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    this.product = this.store.selectSnapshot<String>(state => state.SatMotorState.endorsement.step1.issuance.issuance.productCode);

    this.driverNameForm = this.fb.group({

      rows: this.fb.array([
        this.fb.group({
          driverName: [{ value: this.defaultDriver?.cngeRiskDrvrPkDto?.driverName ? this.defaultDriver.cngeRiskDrvrPkDto.driverName : '', disabled: true }],
          driverId: [{ value: this.defaultDriver?.driverId ? this.defaultDriver.driverId : '', disabled: true }],
          driverAge: [{ value: this.defaultDriver?.driverAge ? this.defaultDriver.driverAge : '', disabled: true }]
        })
      ])
    });

    this.parentForm.controls["driverNameDetails"] = this.driverNameForm;

    this.isDisableAddDriverBtn = this.disableAddDriver();

    this.setMockData(); //to be deleted when connected to BE

    /////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
  }

  // Mock data to populate 3 rows in the table
  setMockData() {
    this.addRow('THE POLICYHOLDER', '880914145277', '36'); // 1st row
    this.addRow('ALICE WONG', '200914145277', '23'); // 2nd row
    this.addRow('PHILIP WONG', '200914145277', '23'); // 3rd row
  }

  // Method to add a row to the FormArray
  addRow(driverName: string, driverId: string, driverAge: string) {
    const rowGroup = this.fb.group({
      driverName: [driverName, [Validators.required, Validators.maxLength(100)]],
      driverId: [driverId, [Validators.required, Validators.maxLength(20)]],
      driverAge: [driverAge, [Validators.required, Validators.maxLength(3)]]
    });
    this.rows.push(rowGroup);
  }

  defaultAllDriverInd() {
    if (this.product === '080200' || this.product === '080300') {
      this.selectExtraCover.emit("M02");
      return true;
    } else {
      return false;
    }
  }

  addDriver() {
    let driverNameRow;

    driverNameRow = this.fb.group({
      driverName: [''],
      driverId: [''],
      driverAge: ['']
    });

    this.rows.push(driverNameRow);

    this.isDisableAddDriverBtn = this.disableAddDriver();


    console.log("rows: ", this.rows);
  }

  deleteDriver(index) {
    this.rows.removeAt(index);

    this.isDisableAddDriverBtn = this.disableAddDriver();

  }

  allDriverOnChange() {
    let allDriverInd = this.driverNameForm.controls['allDriverInd'].value;

    if (allDriverInd) {
      this.deleteSecondRowOnwards();
      this.rows.at(0).get('driverName').setValue(this.defaultDriver.cngeRiskDrvrPkDto.driverName);
      this.rows.at(0).get('driverId').setValue(this.defaultDriver.driverId);
      this.rows.at(0).get('driverAge').setValue(this.defaultDriver.driverAge);
      this.selectExtraCover.emit("M02");
    } else {
      this.rows.at(0).reset();
      this.removeExtraCover.emit("M02");
    }
    this.isDisableAddDriverBtn = this.disableAddDriver();
  }

  deleteSecondRowOnwards() {
    for (let i = this.rows.length - 1; i >= 1; i--) {
      this.rows.removeAt(i);
    }
  }

  disableAddDriver() {
    let allDriverInd = this.driverNameForm.controls['allDriverInd'].value;
    let waiverInd = this.driverNameForm.controls['waiverInd'].value;
    let driverListLength = this.rows.length;
    let disable = false;

    if (allDriverInd || waiverInd) {
      disable = true;
    } else {
      switch (this.product) {
        case '080100':
          disable = driverListLength >= 3 ? true : false;
          break;

        case '080200':
        case '080300':
          disable = driverListLength >= 2 ? true : false;
          break;
      }
    }
    return disable;
  }

  onChangeDriverId(index) {
    let driverId = this.rows.at(index).get('driverId').value;
    let isValidNric;

    try {
      isValidNric = this.nricService.isNRICValid(driverId);
    } catch (err) {
      isValidNric = false;
    }

    if (isValidNric) {
      const [input, year, month, day, birthPlace, ...rest] = splitNRIC(driverId);
      const driverBirthDate = combineToDate(year, month, day);
      const todayDate = new Date();

      if (driverBirthDate.getFullYear() > todayDate.getFullYear()) {
        throw new Error('Invalid Driver ID');
      } else {
        this.rows.at(index).get('driverAge').disable();
        let driverAge = todayDate.getFullYear() - driverBirthDate.getFullYear();
        this.rows.at(index).get('driverAge').setValue(driverAge);
      }
    } else {
      this.rows.at(index).get('driverAge').enable();
    }
  }

  get isPrivateCarsExcludingGoods() {
    return this.product === '080100';
  }

  get isPrivateCarsIncludingGoods() {
    return this.product === '080200';
  }

  get isPrivateCarsCompanyRegistered() {
    return this.product === '080300';
  }

  get rows() {
    return this.driverNameForm.get('rows') as FormArray;
  }

  //////////////////////////////////////////////////////////////////////

  loadNamedDriverForm() {
    this.namedDriverForm = new FormGroup({
      vehicleNo: new FormControl('', [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{1,20}$')
      ]),
      policyNo: new FormControl(''),
      productCode: new FormControl(''),
      policyEffectiveDate: new FormControl(''),
      policyExpiryDate: new FormControl(''),
      endorsementType: new FormControl(''), //Validators.required
      endorsementEffectiveDate: new FormControl(''),
      endorsementExpiryDate: new FormControl(''),
    });
    this.loadEndorsementDetailsData();
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.namedDriver) {
          this.namedDriverForm.patchValue(state.namedDriver);
        }
      }
    });
  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }

  openPopup3(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }

  storeEndorsementDetailsData() {
    if (this.namedDriverForm.valid) {
      let namedDriver = this.namedDriverForm.getRawValue();
      console.log('Saving Named Driver Data:', namedDriver);
      let payload = {
        namedDriver: namedDriver,
        isNamedDriver: true
      }
      this.store.dispatch(new STORE_ENDORSEMENT(payload));

      let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
      if (!endtUpdatedData) {
        endtUpdatedData = this.endtVehiclePolicyData
      }

      //need to change the endtUpdatedData to include the new drivers

      // endtUpdatedData = { 
      //   ...endtUpdatedData,
      //   riskList: [{
      //     ...endtUpdatedData.riskList[0],
      //     vehicleUse: vehicleDetails.vehicleUse,
      //     vehicleNo: vehicleDetails.vehicleNo,
      //     vehicleMakeYear: vehicleDetails.vehicleMakeYear,
      //     makeModelDesc: vehicleDetails.makeModelDesc, //opus is null
      //     vehicleUom: vehicleDetails.vehicleUom,
      //     vehicleCapacity: vehicleDetails.vehicleCapacity,
      //     engineType: vehicleDetails.engineType, //opus is null
      //     vehicleSeat: vehicleDetails.vehicleSeat,
      //     vehicleEngine: vehicleDetails.vehicleEngine,
      //     vehicleChassis: vehicleDetails.vehicleChassis,
      //   }]
      // };

      this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
        isReset: false,
        cngeNote: endtUpdatedData
      }));
      this.endtCalculatePremium.emit();

    } else {
      console.error('Named Driver form is invalid!');
      // this.logFormErrors(this.namedDriverForm);
      this.namedDriverForm.markAllAsTouched();
    }
  }

}
