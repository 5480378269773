<table nxTable cellpadding="0" class="payment_summary_table">
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal" class="font-grey">Road Tax Summary</span>
      </td>
      <td nxTableCell class="std-ta-right">
        <span nxCopytext="normal" class="font-grey">Total (RM)</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Road Tax</span></td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.roadTax | formatDecimal }}</span>
      </td>
    </tr>
    <!-- <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Runner Fees</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">0.00</span>
      </td>
    </tr> -->
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">MyEG Fees</span></td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.myegFees | formatDecimal }}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Courier Fees</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.courierFees | formatDecimal }}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Merchant Charges</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.merchantCharges | formatDecimal }}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Road Tax Payable</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{ data?.gstRoadTaxAmt | formatDecimal }}</span>
      </td>
    </tr>
  </table>
