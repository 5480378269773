<div class="grid_width_page">
        <div nxRow>
            <div nxCol="12">
                <h2 class="nx-margin-0">Customer Partner</h2>
                <span nxCopytext="small">
                    <i>All fields are mandatory.</i>
                </span>
            </div>
        </div>

        @if (showSearchComponent$ | async) {

        <app-customer-partner-search (search)="onSearch($event)" (back)="goBack()">
        </app-customer-partner-search>

        } @else {

        <div class="nx-margin-top-m">
            @if ((cpType$ | async) === 'NOT_FOUND' && lovReturned) {
            <app-customer-partner-not-found [dataSearch]="dataSearch2$ | async" (searchAgain)="searchAgain($event)"
                (back)="goBack()" [lov]="lov" [autoRenewal]="autoRenewal" [eInvoiceConsent]="eInvoiceConsent" [mkagAgent]="mkagAgent" [edocConsentInd]="edocConsentInd">
            </app-customer-partner-not-found>
            } @else if((cpType$ | async) === 'FOUND' && lovReturned) {
            <app-customer-partner-found (searchAgain)="searchAgain($event)" [dataSearch]="dataSearch2$ | async"
                (back)="goBack()" [cpDetails]="dataStore2$ | async" [lov]="lov" [mkagAgent]="mkagAgent">
            </app-customer-partner-found>
            } @else if ((cpType$ | async) === 'INCOMPLETE' && lovReturned) {
            <app-customer-partner-incomplete [dataSearch]="dataSearch2$ | async" (searchAgain)="searchAgain($event)"
                (back)="goBack()" [isCompOrCustInfoIncomplete]="isCompOrCustInfoIncomplete"
                [isContactInfoIncomplete]="isContactInfoIncomplete"
                [isCorrespondenceInfoIncomplete]="isCorrespondenceInfoIncomplete" [cpDetails]="dataStore2$ | async"
                [lov]="lov" [mkagAgent]="mkagAgent" [autoRenewal]="autoRenewal" [eInvoiceConsent]="eInvoiceConsent" [edocConsentInd]="edocConsentInd">
            </app-customer-partner-incomplete>
            }
        </div>
        }
</div>