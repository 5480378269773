import { Component, Inject, InjectionToken, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { Select, Store } from "@ngxs/store";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable } from "rxjs";
import { NxDialogService, NxModalRef, NX_MODAL_DATA } from "@aposin/ng-aquila/modal";
import { SatMotorService } from "@store/sat-motor/sat-motor.service";
import { UserResponse } from "@interfaces/user";
import { LoadingService } from "@shared/services/loading/loading.service";

@Component({
    selector: "app-upload-document",
    templateUrl: "./upload-document.component.html",
    styleUrl: "./upload-document.component.scss",
    standalone: true,
    imports: [SharedModule, NxGridModule],
})
export class UploadDocumentComponent implements OnInit {
    documentIdCounter = 1000;
    listOfOptionalDocs: any;
    fullListOfOptionalDocs: any;
    groupedDocuments = [];
    listOfSelectedOptionalDocuments: any[] = [];
    dialogRef?: NxModalRef<any>;
    uploadCallCounter = 0;
    showMessageIfUploadSuccess: boolean;
    contractId: string;
    acceptFileType: any = "";

    @ViewChild("deleteConfirmationDialogRef") deleteConfirmationDialogRef!: TemplateRef<any>;
    @Select(SatMotorState.getOptionalDocumentList) $optionalDocumentList: Observable<any>;

    constructor(
        @Inject(NX_MODAL_DATA) public data: any,
        private readonly dialogService: NxDialogService,
        private readonly satMotorService: SatMotorService,
        private readonly loadingService: LoadingService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.contractId = this.data.contractId;
        this.getDocumentTypes();
        this.getAllUploadedDocuments();
    }

    uploadDocuments() {
        this.removeDummyDocIds();

        // step 1: call the initial API to get the transactionId // dummy 299369
        this.satMotorService.initiateUploadDocument({ contractId: this.contractId }).subscribe({
            next: (response: any) => {
                const transactionId = response.data.trxnId;

                // step 2: extract files and prepare requests
                const fileRequests = this.listOfSelectedOptionalDocuments
                    .flatMap((docGroup) =>
                        docGroup.documents.map((document) => {
                            if (document.file) {
                                return { formData: this.prepareFormData(transactionId, document), document };
                            }
                            return null;
                        }),
                    )
                    .filter(Boolean);

                // step 3: execute uploads sequentially using XMLHttpRequest
                this.executeSequentialUploads(fileRequests, transactionId);
            },
            error: (err) => {
                console.error("Error initiating document upload:", err);
            },
        });
    }

    removeDummyDocIds() {
        this.listOfSelectedOptionalDocuments.forEach((docGroup) => {
            docGroup.documents.forEach((doc: any) => {
                if (doc.docId && doc.docId.startsWith("DUMMY_")) {
                    doc.docId = "";
                }
            });
        });
    }

    // Helper method to prepare FormData
    prepareFormData(transactionId: string, document: any): FormData {
        const appUserId = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user.userId);

        const formData = new FormData();

        if (document.file) {
            formData.append("file", document.file);
        }

        const requestPayload = {
            operator: appUserId,
            transactionId: transactionId,
            documents: [
                {
                    docId: document.docId,
                    contractId: document.contractId,
                    code: document.code,
                    updatePreviousRecordInd: document.updatePreviousRecordInd || false,
                    requoteInd: document.requoteInd || false,
                    productCode: document.productCode,
                },
            ],
        };
        formData.append("request", JSON.stringify(requestPayload));

        return formData;
    }

    executeSequentialUploads(requests: { formData: FormData; document: any }[], transactionId: string) {
        const executeRequest = (index: number) => {
            this.loadingService.startLoading();
            if (index >= requests.length) {
                const commitPayload = {
                    trxnId: transactionId,
                };

                this.satMotorService.commitUploadDocument(commitPayload).subscribe({
                    next: (response) => {
                        this.showMessageIfUploadSuccess = true;
                        this.getAllUploadedDocuments();
                        this.loadingService.stopLoading();
                    },
                    error: (err) => {
                        console.error("error committing document upload:", err);
                    },
                });

                return;
            }

            const { formData } = requests[index];
            const xhr = new XMLHttpRequest();
            xhr.open("POST", this.satMotorService.uploadDocuments(), true);

            xhr.onload = () => {
                if (xhr.status === 200) {
                    this.uploadCallCounter++;
                    executeRequest(index + 1);
                } else {
                    console.error(`error uploading document ${index + 1}:`, xhr.responseText);
                }
            };

            xhr.onerror = () => {
                console.error(`network error occurred for document ${index + 1}`);
            };

            xhr.send(formData);
        };

        executeRequest(0);
    }


    getOptionaDocslList() {
        this.$optionalDocumentList.subscribe((res) => {
            this.fullListOfOptionalDocs = res["080100"];

            if (this.fullListOfOptionalDocs?.length) {
                // remove object from dropdown if docs types is already available
                const responseCodes = this.listOfSelectedOptionalDocuments.map((item) => item.code);
                this.listOfOptionalDocs = this.fullListOfOptionalDocs.filter((item) => !responseCodes.includes(item.code));
            }
        });
    }

    getAllUploadedDocuments() {
        this.satMotorService.getAllUpaloadedDocuments(this.contractId).subscribe((response) => {
            if (response?.data?.length) {
                const groupedData = Object.entries(
                    response.data.reduce((acc, value) => {
                        if (!acc[value.code]) {
                            acc[value.code] = [];
                        }
                        acc[value.code].push(value);

                        return acc;
                    }, {}),
                ).map(([code, documents]) => ({ code, descp: documents[0]?.description, documents }));

                this.listOfSelectedOptionalDocuments = groupedData;
                this.getOptionaDocslList();
            }
        });
    }

    downloadDocument(docId: string, fileName: string) {
        this.satMotorService.downloadDocument(docId).subscribe({
            next: (res: Blob) => {
                // if (res.size === 0) {
                //     this.notificationService.openNotificationDialog('The file is not ready yet. Please try again later.');
                //     return;
                // }

                const file = new Blob([res]);
                const fileUrl = window.URL.createObjectURL(file);
                const anchor = document.createElement("a");

                anchor.href = fileUrl;
                anchor.download = fileName;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

                // revoke the object URL to free up memory
                window.URL.revokeObjectURL(fileUrl);
            },
            error: (err) => {
                console.error("error downloading document:", err);
            },
        });
    }

    getDocumentTypes() {
        this.acceptFileType = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov.documentList.fileTypeList).map(t => "." + t).join(", ");
    }

    handleListSelectedOptionalDocs(selectedOptinalDoc: any) {
        const newDocument = {
            // assign temporary id
            docId: `DUMMY_${this.documentIdCounter++}`,
            contractId: this.contractId,
            code: selectedOptinalDoc.code,
            updatePreviousRecordInd: false,
            requoteInd: false,
            productCode: selectedOptinalDoc.productCode,
        };

        // check if the 'code' already exists
        const existingDocIndex = this.listOfSelectedOptionalDocuments.findIndex(
            (doc) => doc.code === selectedOptinalDoc.code,
        );

        if (existingDocIndex !== -1) {
            // if the 'code' exists, add to the existing documents array
            this.listOfSelectedOptionalDocuments[existingDocIndex].documents.push(newDocument);
        } else {
            // if the 'code' does not exist, create a new object
            const newEntry = {
                descp: selectedOptinalDoc.descp,
                code: selectedOptinalDoc.code,
                documents: [newDocument],
            };
            this.listOfSelectedOptionalDocuments.push(newEntry);
        }
    }

    handleDropdownSelection(selectedOptinalDoc: any) {

        const existingGroup = this.listOfSelectedOptionalDocuments.find(
            (docGroup) => docGroup.code === selectedOptinalDoc.code,
        );

        if (!existingGroup) {
            const newDocument = {
                docId: `DUMMY_${this.documentIdCounter++}`,
                contractId: this.contractId,
                code: selectedOptinalDoc.code,
                updatePreviousRecordInd: false,
                requoteInd: false,
                productCode: selectedOptinalDoc.productCode,
            };

            const newGroup = {
                descp: selectedOptinalDoc.descp,
                code: selectedOptinalDoc.code,
                documents: [newDocument],
            };

            this.removeWhenFileItemSelected(selectedOptinalDoc);

            this.listOfSelectedOptionalDocuments.push(newGroup);
        }
    }

    private removeWhenFileItemSelected(selectedFileItem: any) {
        console.log('eee', selectedFileItem)
        const listClone = [...this.listOfOptionalDocs];
    
        const groupIndex = listClone.findIndex((group) => group.code === selectedFileItem.code);
    
        if (groupIndex !== -1) {
            listClone.splice(groupIndex, 1);
        }
    
        this.listOfOptionalDocs = listClone;
    
    }
    

    onChangeFileInput(event: Event, docId: string) {
        const file = (event.target as HTMLInputElement).files?.[0];
        for (let item of this.listOfSelectedOptionalDocuments) {
            const document = item.documents.find((doc) => doc.docId === docId);
            if (document) {
                document.file = file;

                // set updatePreviousRecordInd to true if replace the doc
                // docsId that startwith DUMMY is not availabe in server - only in local
                if (!docId.startsWith("DUMMY")) {
                    document.updatePreviousRecordInd = true;
                }
            }
        }
    }

    handleDeleteDocs(code: string, docId: string) {
        const appUserId = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user.userId);

        // find the group by 'code'
        const group = this.listOfSelectedOptionalDocuments.find((doc) => doc.code === code);

        if (group) {
            // filter out the document with the matching 'docId'
            group.documents = group.documents.filter((doc) => doc.docId !== docId);

            // remove the group if documents array is empty
            if (group.documents.length === 0) {
                this.listOfSelectedOptionalDocuments = this.listOfSelectedOptionalDocuments.filter(
                    (doc) => doc.code !== code,
                );
            }
        }

        const payloadForDeleteApi = {
            operator: appUserId,
            documents: [
                {
                    docId,
                    contractId: this.contractId,
                    lob: "MT",
                },
            ],
            currentEquoteNo: null,
        };

        this.satMotorService.deleteDocumentUploaded(payloadForDeleteApi).subscribe({
            next: (response) => {
                console.log("document deleted successfully:", response);
            },
            error: (err) => {
                console.error("Error deleting document:", err);
            },
        });
    }

    // delete doc confirmation modal
    openDeleteConfirmationModal(code: string, docId: string): void {
        this.dialogRef = this.dialogService.open(this.deleteConfirmationDialogRef, {
            ariaLabel: "A simple modal",
            showCloseIcon: true,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result === "proceed") {
                this.handleDeleteDocs(code, docId);

                const responseCodes = this.listOfSelectedOptionalDocuments.map((item) => item.code);
                this.listOfOptionalDocs = this.fullListOfOptionalDocs.filter((item) => !responseCodes.includes(item.code));
            }
        });
    }

    closeDeleteConfirmationModal(result: any): void {
        this.dialogRef?.close(result);
    }
}
