<div nxLayout="grid nopadding">
  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Receipt No.</nx-data-display-label>
        <p nxCopytext="large">{{formatData(payment?.receiptNo)}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Transaction Date</nx-data-display-label>
        <p nxCopytext="large">{{payment?.transactionDate ? (payment.transactionDate | date: 'dd/MM/yyyy') : 'N/A'}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
        <p nxCopytext="large">{{formatData(payment?.eCoverNoteNo)}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
        <p nxCopytext="large">{{formatData(payment?.vehicleNo)}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Account Code</nx-data-display-label>
        <p nxCopytext="large">{{formatData(payment?.accountCode)}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Insured Name</nx-data-display-label>
        <p nxCopytext="large">{{formatData(payment?.insuredName)}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Total Premium Due (RM)</nx-data-display-label>
        <p nxCopytext="large">{{payment?.totalPremiumDue | formatDecimal}}</p>
      </nx-data-display>
    </div>
  </div>
</div>