<div>
    <div nxModalContent>
      <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
      <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
          The information that you've entered will be deleted. Would you like to
          proceed?
      </p>
    </div>
  
    <div class="button_area2">
      <button nxModalClose nxButton="secondary" type="button" (click)="cancel()">Cancel</button>
      <button nxModalClose nxButton="primary" type="button" (click)="proceed()">Proceed</button>
    </div>
  </div>