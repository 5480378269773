<nx-accordion variant="light">
  <nx-expansion-panel expanded="[true]">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title >{{ 'COVERAGE.TITLE.VEHICLE_DETAILS' | translate }}</nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <div [formGroup]="vehicleDetailForm">
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12">
            <nx-radio-group name="region" formControlName="region" (groupValueChange)="onChangeRegion($event)">
              <span nxCopytext="normal">{{ 'COVERAGE.FIELD.VEHICLE_USAGE_LOCATION' | translate }}</span><br />
              <div nxLayout="grid nopadding" class="nx-margin-top-s">
                <div nxRow>
                  <div nxCol="12,12,4">
                    <nx-radio value="W">{{ 'COVERAGE.FIELD.VEHICLE_USAGE_LOCATION_WEST' | translate }}</nx-radio>
                  </div>
                  <div nxCol="12,12,8" class="m_margin_top_20">
                    <nx-radio value="E">{{ 'COVERAGE.FIELD.VEHICLE_USAGE_LOCATION_EAST' | translate }}</nx-radio>
                  </div>
                </div>
              </div>
            </nx-radio-group>
          </div>

          <div nxCol="12" class="nx-margin-top-2m">
            <nx-formfield label="{{ 'COVERAGE.FIELD.USE_OF_VEHICLE' | translate }}">
              <nx-dropdown formControlName="vehicleUse" (selectionChange)="onChangeUseOfVehicle($event)">
                <nx-dropdown-item *ngFor="let item of vehicleUselov" value="{{item.code}}">
                  {{ item.description }}
                </nx-dropdown-item>
              </nx-dropdown>
              @if (vehicleUse.errors && vehicleUse.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.USE_OF_VEHICLE' | translate
                  } }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>

          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.VEHICLE_NO' | translate }}">
              <input nxInput formControlName="vehicleNo" (change)="onChangeVehicleNo($event)" appToUpperCase />
              @if (vehicleNo.errors && vehicleNo.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.VEHICLE_NO' | translate }
                  }}</strong><br />
              </nx-error>
              }
              @if (vehicleNo.errors && vehicleNo.errors["invalid"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.VEHICLE_NO.INVALID' | translate }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.YEAR_OF_MANUFACTURE' | translate }}">
              <input nxInput formControlName="vehicleMakeYear" [appOnlyNumber]="true" maxlength="4" />
              @if (vehicleMakeYear.errors && vehicleMakeYear.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.YEAR_OF_MANUFACTURE' |
                  translate } }}</strong><br />
              </nx-error>
              }
              @if (vehicleMakeYear.errors && (vehicleMakeYear.errors['minlength'])) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.YEAR_OF_MANUFACTURE.INVALID' | translate }}</strong>
              </nx-error>
              }
              @if (vehicleMakeYear.errors && !vehicleMakeYear.errors['minlength'] && (vehicleMakeYear.errors['min'] || vehicleMakeYear.errors['max'])) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.YEAR_OF_MANUFACTURE.INVALID_MAKE_YEAR' | translate }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>

          <div nxCol="12">
            <nx-formfield label="{{ 'COVERAGE.FIELD.MAKE_MODEL' | translate }}" (click)="openPopupMakeModel()">
              <input nxInput formControlName="makeModelDesc" appToUpperCase />
              <span nxFormfieldSuffix>
                <nx-icon aria-hidden="true" name="search"></nx-icon>
              </span>
              @if (isRenewal && vehicleDetails?.vehicleModelDescp) {
                <span nxFormfieldHint class="custom_hint">
                  <span nxCopytext="large">
                    {{ 'COVERAGE.FIELD.PREVIOUS_YEAR_MODEL' | translate }} : {{ vehicleDetails.vehicleModelDescp}}
                  </span>
                </span>
              }
              @if (makeModelDesc.errors && makeModelDesc.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.MAKE_MODEL' | translate }
                  }}</strong>
              </nx-error>
              }
            </nx-formfield>
         
          </div>

          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.UNIT_OF_MEASUREMENT' | translate }}">
              <input nxInput formControlName="vehicleUom" [appOnlyNumber]="true" />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.CAPACITY' | translate }}">
              <input nxInput formControlName="vehicleCapacity" [appOnlyNumber]="true" (keyup)="onChangeCapacity($event, false, false)" (blur)="onChangeCapacity($event, true, true)" />
              @if (vehicleCapacity.errors && vehicleCapacity.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.CAPACITY' | translate }
                  }}</strong>
              </nx-error>
              }
              @if (vehicleCapacity.errors && vehicleCapacity.errors["min"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.CAPACITY.CORRECT_SELECTION' | translate }}</strong>
              </nx-error>
              }
              @if (vehicleCapacity.errors && vehicleCapacity.errors["range"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.CAPACITY.CC_VALUE' | translate }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.ENGINE_TYPE' | translate }}">
              <nx-dropdown formControlName="engineType">
                <nx-dropdown-item *ngFor="let item of lov.engineTypeList" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.ENGINE_TYPE' | translate }
                  }}</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.NO_OF_SEATS' | translate }}">
              <input nxInput formControlName="vehicleSeat" [appOnlyNumber]="true" />
              @if (vehicleSeat.errors && vehicleSeat.errors['required']) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.NO_OF_SEATS' | translate } }}</strong>
              </nx-error>
              }
              @if (vehicleSeat.errors && vehicleSeat.errors['min']) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COVERAGE.ERROR.NO_OF_SEATS.POSITIVE_ONLY' | translate }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.ENGINE_MOTOR_NO' | translate }}">
              <input nxInput formControlName="vehicleEngine" appToUpperCase />
              @if (vehicleEngine.errors) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.ENGINE_MOTOR_NO' | translate } }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.CHASSIS_NO' | translate }}">
              <input nxInput formControlName="vehicleChassis" appToUpperCase />
              @if (vehicleChassis.errors) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.CHASSIS_NO' | translate } }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.LOAN_TYPE' | translate }}">
              <nx-dropdown formControlName="vehicleLoanType">
                <nx-dropdown-item *ngFor="let item of lov.vehicleLoanTypeList" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.LOAN_COMPANY' | translate }}">
              <input nxInput formControlName="vehicleLoanCompany" appToUpperCase />
              @if (vehicleLoanCompany.errors && vehicleLoanCompany.errors["required"]) {
              <nx-error nxFormfieldError>
                <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.LOAN_COMPANY_MANDATORY' | translate } }}</strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.VEHICLE_PURCHASE_DATE' | translate }}">
              <input nxDatefield nxInput [datepicker]="myDatepicker" formControlName="vehiclePurchaseDate" 
              		[parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY"/>
              <span nxFormfieldHint>{{ 'GLOBAL.DATE.DD/MM/YYYY' | translate }}</span>
              <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker></nx-datepicker>
              @if (vehiclePurchaseDate.errors) {
                <nx-error nxFormfieldError>
                  <strong>{{ 'COVERAGE.ERROR.VEHICLE_PURCHASE_DATE.INVALID' | translate }}</strong>
                </nx-error>
              }
            </nx-formfield>
          </div>
          <div nxCol="12">
            <nx-checkbox formControlName="makeModelPrintInd" class="nx-margin-bottom-s">
              {{ 'COVERAGE.FIELD.AMEND_MAKE_MODAL' | translate }}
            </nx-checkbox>
            <div class="make_model  nx-margin-top-2m" [hidden]="!makeModelPrintInd.value">
              <div class="field_label">
                {{ 'COVERAGE.TITLE.MAKE_MODAL_DESCRIPTION' | translate }}
              </div>
              <div class="custom">
                <div class="make_container_edit">
                  <span class="make_label_edit">{{ makeModel }}</span>
                </div>
                <div class="input_container customField formfield_no_padding">
                  <nx-formfield>
                    <textarea nxInput cdkTextareaAutosize (keyup)="onKeyUp($event)" formControlName="variantDesc"
                      appToUpperCase [maxLength]="maxlength">
                    </textarea>
                  </nx-formfield>
                </div>
              </div>
              <div>
                <div class="container_flex_center align_right">
                  <span nxCopytext="small" class="nx-margin-top-2xs">
                    <span *ngIf="characterCount === 0">
                      {{ 'COMMON.CHAR_MAX_LIMIT' | translate: { fieldName: maxlength } }}
                    </span>
                    <span *ngIf="characterCount > 0">
                      {{ maxlength - (characterCount || 0) }} {{ 'COMMON.CHAR_REMAINING' | translate }}
                    </span>
                  </span>
                </div>
                @if (variantDesc.errors && variantDesc.errors["required"]) {
                <nx-error nxFormfieldError class="nx-margin-top-xs">
                  <strong>
                    {{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.MAKE_MODAL_DESCRIPTION' | translate } }}
                  </strong>
                </nx-error>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nx-expansion-panel>
</nx-accordion>
