<header nx-header>
  <nx-header-row class="header_container">
    <nx-header-brand>
      <nx-link class="nx-margin-right-l">
        <a href="#">
          <figure nxFigure>
            <img src="../../../assets/img/logo.svg" />
          </figure>
        </a>
      </nx-link>
      <div class="container_flex_center nx-hidden-xs nx-hidden-s nx-hidden-m">
        <nx-link (click)="openURL('https://www.allianz.com.my', '_blank', '')">Allianz Malaysia</nx-link>
        <nx-link (click)="switchToAlpha()" class="nx-margin-left-2m">Alpha Portal</nx-link>
      </div>
    </nx-header-brand>
    <nx-header-actions class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <nx-icon name="bars" aria-hidden="true" (click)="toggleMenu()"></nx-icon>
    </nx-header-actions>
    <nx-header-actions class="nx-hidden-xs nx-hidden-s nx-hidden-m">
      <div>
        <span nxCopytext="medium">{{ userName$ | async }}</span>
        <span class="font-grey nx-margin-left-3xs" nxCopytext="small"> {{ lastLogin$ | async }} </span>
      </div>
      <div *ngIf="isAdmin$ == true">
        <button (click)="switchURL('SAT_ADMIN_MENU')" nxButton="secondary small" type="button" class="nx-margin-left-m">Administrator</button>
      </div>
      <div class="border-line nx-margin-x-m"></div>
      <button nxPlainButton class="button_black" (click)="signOut()">
        <nx-icon name="export-and-share" class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
          nxIconPositionStart aria-hidden="true"></nx-icon><span nxCopytext="medium">{{ 'HEADER.SIGN_OUT' | translate }}</span>
      </button>
    </nx-header-actions>
  </nx-header-row>
  <nx-header-row class="header_border">
  </nx-header-row>
  <nx-header-row class="bottom_header_menu header_container">
    <nx-header-brand class="nx-margin-0 nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <div nxCopytext="medium" class="nx-margin-right-2xs">
        {{ userName$ | async }}
      </div>
      <div class="font-grey nx-margin-top-3xs" nxCopytext="small">
        {{ lastLogin$ | async }}
      </div>
    </nx-header-brand>
    <nx-header-navigation *ngIf="showmenu">
      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a nxHeaderLink>Alpha Portal</a>
        <hr />
      </nx-header-navigation-item>

      @for (menu of menuList; track menu;) {
        @if (menu.menuId === 'SA_MNPOLICIES') {
          <nx-header-navigation-item>
            <div class="nx-header__link policytab" nxMenuButton (click)="onClick(menu)" menuButtonType="root" [expandable]="!!menu.subMenu" nxMenuItem [expanded]="menu.hidden">
              <a class="policy" nxHeaderLink>{{ menu.description }}</a>
            </div>
            <div nxMenuItem *ngIf="menu.subMenu && menu.hidden" class="sub_menu">
              <div nxLayout="grid maxwidth nogutter">
                <div nxRow>
                  <div nxCol="12,12,12,2,2,2" class="nx-margin-bottom-m">
                    @for (unifiedMenuItem of menuPolicyMgmt; track unifiedMenuItem) {
                      @if (isUnifiedMenu(unifiedMenuItem.menuId)) {
                        @if (!unifiedMenuItem.hidden) {
                          <a (click)="nav(unifiedMenuItem.link, unifiedMenuItem)">{{ unifiedMenuItem.description }}</a><br />
                        }
                      }
                    }
                  </div>
                  <div nxCol="12,12,12,4,4,4" class="nx-margin-bottom-m">
                    @for (motorMenuItem of menuPolicyMgmt; track motorMenuItem) {
                      @if (isMotorMenu(motorMenuItem.menuId)) {
                        <span class="nx-font-weight-semibold">{{ motorMenuItem.description }}</span><br />
                        @for (motorMenuSubItem of motorMenuItem.subMenu; track motorMenuSubItem;) {
                          @if (motorMenuSubItem.type === "M") {
                            @if (!motorMenuSubItem.subMenu) {
                              <a>{{ motorMenuSubItem.description }}</a><br />
                            }
                            @else {
                              <a class="padding_0 header_nested_sub_menu" nxMenuButton (click)="onClick(motorMenuSubItem)" menuButtonType="root" [expandable]="!!motorMenuSubItem.subMenu" nxMenuItem [expanded]="motorMenuSubItem.hidden">
                                <nx-icon nxMenuButtonIcon name="chevron-right" size="s" aria-hidden="true"></nx-icon>
                                {{ motorMenuSubItem.description }}
                              </a>
                              <div nxMenuItem *ngIf="motorMenuSubItem.subMenu && motorMenuSubItem.hidden">
                                <a class="nested_sub_menu" *ngFor="let submenu of motorMenuSubItem.subMenu" nxMenuLink (click)="open(submenu.link, submenu, menu)">
                                  {{ submenu.description }}
                                </a>
                              </div>
                            }
                          }
                          @else {
                            @if (!motorMenuSubItem.hidden) {
                              <a (click)="nav(motorMenuSubItem.link, motorMenuSubItem)">{{ motorMenuSubItem.description }}</a><br />
                            }
                          }
                        }
                      }
                    }
                  </div>
                  <div nxCol="12,12,12,3,3,3" class="nx-margin-bottom-m">
                    @for (nonMotorMenuItem of menuPolicyMgmt; track nonMotorMenuItem) {
                      @if (isNonMotorMenu(nonMotorMenuItem.menuId)) {
                        <span class="nx-font-weight-semibold">{{ nonMotorMenuItem.description }}</span><br />
                        @for (nonMotorMenuSubItem of nonMotorMenuItem.subMenu; track nonMotorMenuSubItem) {
                          @if (!nonMotorMenuSubItem.hidden) {
                            <a (click)="nav(nonMotorMenuSubItem.link, nonMotorMenuSubItem)">{{ nonMotorMenuSubItem.description }}</a><br />
                          }
                        }
                      }
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
          </nx-header-navigation-item>
        }
        @else {
          <nx-header-navigation-item>
            <a (click)="nav(menu.link, menu)" nxHeaderLink>{{ menu.description }}</a>
            <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
          </nx-header-navigation-item>
        }
      }

      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a class="color_dark_blue" (click)="openURL('https://www.allianz.com.my', '_blank', '')" nxHeaderLink>Allianz Malaysia</a>
        <hr />
      </nx-header-navigation-item>
      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a nxHeaderLink>
          <button nxPlainButton class="button_black" (click)="signOut()">
            <nx-icon name="export-and-share" class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
              nxIconPositionStart aria-hidden="true"></nx-icon>{{ 'HEADER.SIGN_OUT' | translate }}
          </button>
        </a>
        <hr />
      </nx-header-navigation-item>
    </nx-header-navigation>

    <nx-header-actions>
      <nx-icon (click)="switchURL('SAT_PROFILE_URL')" name="user-o" size="s" class="nx-margin-s"></nx-icon>
      <img class="notif-icon" [ngClass]="!isNotificationClicked ? 'display' : 'display-none'"
        (click)="toggleNotification($event)" height="24px" width="24px" [src]="
          (unreadNotification) === 0
              ? 'assets/img/Icon - Notification - Read - Inactive.svg'
              : 'assets/img/Icon - Notification - New - Inactive.svg'
      " />
    </nx-header-actions>
  </nx-header-row>

</header>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12">
      <div class="container">
        <div [ngClass]="{
                'notification-sidebar-closed': isNotificationClicked == false,
                'notification-sidebar-open ': isNotificationClicked,
                'cust-notification': isNotificationClicked
            }">
          <notification-sidebar *ngIf="isNotificationClicked" (toggledNotification)="toggledNotification($event)">
          </notification-sidebar>
        </div>
      </div>
    </div>
  </div>
</div>