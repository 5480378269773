import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_CHECKLIST_LOV } from '@store/sat-motor/sat-motor.action';

interface ChecklistItem {
  id: number;
  title: string;
  description: string;
  options: string[];
  notes?: string[];
}

@Component({
  selector: 'app-checklist',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.scss'
})

export class ChecklistComponent implements OnInit {
  @Input() checklistLov: any;
  @Input() checklistAns: any;
  @Input() checklistRemarks: any;
  @Input() parentForm: FormGroup;
  @Input() isViewOnly: boolean = false;
  @Input() endtCode: any;
  checklistForm: FormGroup;
  note: any;
  isLoadedChecklist: boolean = false;
  data: any;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.data = [...this.checklistLov];

    this.checklistForm = this.fb.group({
      rows: this.fb.array([]),
      remarks:[]
    });

    this.parentForm.controls["checklistForm"] = this.checklistForm;

      // let checklistLovRes = state.SatMotorState.checklistLov;
      // if(checklistLovRes) {
        // this.checklistLov = [
        //   ...checklistLovRes
        // ];

        let lastElement = this.checklistLov[this.checklistLov.length - 1];
        if(!lastElement.questionDesc && lastElement.remarks) {
          this.note = this.data.pop()?.remarks;
        }

        this.initChecklistFormRow();

        if(this.isViewOnly) {
          this.initAnswer();
        }
        
      this.isLoadedChecklist = true;
      console.log("checklist form: ", this.checklistForm.getRawValue());

  }

  initChecklistFormRow() {
    this.data.forEach((item, index) => {
      this.rows.push(this.fb.group({
        declareAns: [''],
        declareCode: [item.checkItemID],
      }));
    });
  }

  initAnswer() {
    this.rows.controls.forEach((row, index) => {
      let declareAnsControl = row.get('declareAns');
      let declareCode = row.get('declareCode').value;
      let declareAns = this.getAnswer(declareCode);
      declareAnsControl.setValue(declareAns);
      declareAnsControl.disable();
    });
  }

  getQuestionDesc(declareCode) {
      return this.data.find(e => e.checkItemID === declareCode)?.questionDesc;
  }

  getAnswer(declareCode) {
    return this.checklistAns.find(e => e.declareCode === declareCode)?.declareAns;
  }

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }

  get rows() {
    return this.checklistForm.get('rows') as FormArray;
  }
}
