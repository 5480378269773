<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
                Customer Partner
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        @if(lovReturned) {
        <div nxLayout="grid nopadding" [formGroup]="eInvoiceCustomerPartnerForm">
            <!-- <div nxLayout="grid nopadding"> -->

            <ng-container>
                <div nxLayout="grid nopadding">
                    <div>
                        <div>
                            <div nxRow class="nx-margin-top-m">
                                <div nxCol="12">
                                    <div nxLayout="grid nopadding" class="grid_remove_padding summary_column_space">

                                       
                                        <table nxTable cellpadding="0" class="summary_table">
                                            @if ((endtCpType$ | async) === 'FOUND') {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                  <span nxCopytext="normal">Customer Type</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                  <span nxCopytext="normal">
                                                    <div style="
                                                            display: flex;
                                                            justify-content: space-between;
                                                          ">
                                                      <span nxCopytext="normal">{{ type | uppercase }}</span>
                                                      <!-- @if (!hideEdit) {
                                                        <button nxPlainButton type="button" (click)="onOpenEdit()">
                                                        EDIT
                                                      </button>
                                                      } -->
                                                    </div>
                                                  </span>
                                                </td>
                                              </tr>
                                            }



                                            <tr nxTableRow>
                                                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ name | uppercase }}</span>
                                                </td>
                                            </tr>

                                            @if(isIndividual) {
                                               <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Nationality</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">
                                                        {{ countryname | uppercase }}
                                                    </span>
                                                </td>
                                            </tr> 
                                            }
                                            
                                            @if(isCompany) {
                                               <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Country of Incorporation</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ countryIncorporation | uppercase }}</span>
                                                </td>
                                            </tr> 
                                            }

                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">{{ idLabel1 }}</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ idValue1 }}</span>
                                                </td>
                                            </tr>

                                            @if(isCompany && cpDetails.idType2 && cpDetails.idValue2) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">{{ idLabel2 }}</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ idValue2 }}</span>
                                                </td>
                                            </tr>
                                            }

                                            @if ((endtCpType$ | async) === 'FOUND') {
                                                <tr nxTableRow>
                                                  <td nxTableCell>
                                                    <span nxCopytext="normal">Tax Identification No. (TIN)</span>
                                                  </td>
                                                  <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ clientTinNo | uppercase}}</span>
                                                  </td>
                                                </tr>
                                            }
                                            
                                            @if(isCompany) {
                                                @if((endtCpType$ | async) === 'FOUND') {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SST Effective Date</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ clientSstRegDate | uppercase}}</span>
                                                </td>
                                            </tr> 
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SST De-Registration Date</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ clientSstDeRegDate | uppercase}}</span>
                                                </td>
                                            </tr>
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SST Resident Status</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ clientSstResidentStatus | uppercase}}</span>
                                                </td>
                                            </tr>
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SST Country</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ clientSstCountry | uppercase}}</span>
                                                </td>
                                            </tr>
                                            }
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SME Reg. No.</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ smeRegisteredNumber }}</span>
                                                </td>
                                            </tr>
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SME Category</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ smeCategory | uppercase }}</span>
                                                </td>
                                            </tr>
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SME Cert. Start Date</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ smeStartDate }}</span>
                                                </td>
                                            </tr>
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">SME Cert. End Date</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ smeEndDate }}</span>
                                                </td>
                                            </tr>
                                            }

                                            @if(isIndividual) {
                                                <tr nxTableRow>
                                                    <td nxTableCell>
                                                      <span nxCopytext="normal">Gender</span>
                                                    </td>
                                                    <td nxTableCell class="column_cp_content">
                                                      <span nxCopytext="normal">{{ gender | uppercase}}</span>
                                                    </td>
                                                </tr>
                                            }
    
                                            @if(isIndividual) {
                                                <tr nxTableRow>
                                                    <td nxTableCell>
                                                      <span nxCopytext="normal">Marital Status</span>
                                                    </td>
                                                    <td nxTableCell class="column_cp_content">
                                                      <span nxCopytext="normal">{{ marriedStatus | uppercase}}</span>
                                                    </td>
                                                </tr>
                                            }
                                              
                                                @if(showDOB()) {
                                                <tr nxTableRow>
                                                    <td nxTableCell>
                                                        <span nxCopytext="normal">Date of Birth</span>
                                                    </td>
                                                    <td nxTableCell class="column_cp_content">
                                                        <span nxCopytext="normal">{{ dateOfBirth }}</span>
                                                    </td>
                                                </tr>
                                                }
                                                
                                                @if(showAge()) {
                                                <tr nxTableRow>
                                                    <td nxTableCell>
                                                        <span nxCopytext="normal">Age</span>
                                                    </td>
                                                    <td nxTableCell class="column_cp_content">
                                                        <span nxCopytext="normal">{{ age }}</span>
                                                    </td>
                                                </tr>
                                                }
    
                                                @if((endtCpType$ | async) === 'FOUND' && isIndividual) {
                                                    <tr nxTableRow>
                                                      <td nxTableCell>
                                                        <span nxCopytext="normal">ALIM Customer</span>
                                                      </td>
                                                      <td nxTableCell class="column_cp_content">
                                                        <span nxCopytext="normal">{{ alimCustomer ? alimCustomer : 'NO' | uppercase }}</span>
                                                      </td>
                                                    </tr>
                                                }
    
                                                @if((endtCpType$ | async) === 'FOUND') {
                                                    <tr nxTableRow>
                                                      <td nxTableCell>
                                                        <span nxCopytext="normal">Mobile No.</span>
                                                      </td>
                                                      <td nxTableCell class="column_cp_content">
                                                        <span nxCopytext="normal">{{ mobilePhone }}</span>
                                                      </td>
                                                  </tr>
                                                  }
    
                                                  @if ((endtCpType$ | async) === 'FOUND') {
                                                    <tr nxTableRow>
                                                  <td nxTableCell>
                                                    <span nxCopytext="normal">Fixed Line No.</span>
                                                  </td>
                                                  <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ fixLine }}</span>
                                                  </td>
                                                </tr>
                                                }
    
                                                @if((endtCpType$ | async) === 'FOUND') {
                                                    <tr nxTableRow>
                                                  <td nxTableCell>
                                                    <span nxCopytext="normal">Fax No.</span>
                                                  </td>
                                                  <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ faxNumber }}</span>
                                                  </td>
                                                </tr>
                                                }
    
                                                @if((endtCpType$ | async) === 'FOUND') {
                                                    <tr nxTableRow>
                                                  <td nxTableCell>
                                                    <span nxCopytext="normal">Email</span>
                                                  </td>
                                                  <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{ email | uppercase }}</span>
                                                  </td>
                                                </tr>
                                                }

                                            @if ((endtCpType$ | async) === 'FOUND') {
                                                <tr nxTableRow>
                                              <td nxTableCell>
                                                <span nxCopytext="normal">Address</span>
                                              </td>
                                              <td nxTableCell class="column_cp_content">
                                                @if(isGeoShow){
                                                <button nxPlainButton type="button" aria-label="add item">
                                                  <nx-icon name="location" aria-hidden="true"></nx-icon>
                                                </button>
                                              }
                                                <span nxCopytext="normal">{{ fullAddress | uppercase }}</span>
                                              </td>
                                            </tr>
                                            }
                                            
                                        </table>

                                    </div>
                                </div>
                            </div>

                            @if((endtCpType$ | async) !== 'FOUND') {
                            <hr class="hr" class="nx-margin-top-2m" />

                            <div nxRow>
                                <div nxCol="12">
                                    @if (isIndividual) {
                                    <h4>Personal Information</h4>
                                    }
                                    @if (isCompany) {
                                    <h4>Company Information</h4>
                                    }
                                </div>
                            </div>

                            @if (isIndividual && isOldIC) {
                            <div nxRow>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="ID Type 2">

                                        <!-- <nx-dropdown value="NRIC" formControlName="idType2">
                                            <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                                        </nx-dropdown> -->
                                        <nx-dropdown [valueFormatter]="toText" formControlName="idType2">
                                            @for(idType2 of idType2List; track idType2) {
                                            <nx-dropdown-item [value]="idType2.code">{{ idType2.descp
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter ID Type 2.</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="ID No. 2">

                                        <!-- <input nxInput formControlName="idNo2" /> -->
                                        <input nxInput formControlName="idValue2" maxlength="100" #idValue2
                                            (blur)="onBlurIdValue2(idValue2)" appToUpperCase />

                                        @if (idValue2ErrorType) {
                                        <nx-error nxFormfieldError>
                                            @if(idValue2ErrorType === 'wrongNricFormat') {
                                            <strong>Wrong Format</strong>
                                            } @else if(idValue2ErrorType === 'required') {
                                            <strong>Please enter ID No. 2</strong>
                                            } @else if(idValue2ErrorType === 'wrongNewBrnoFormat') {
                                            <strong>Invalid Business Registration No.</strong>
                                            }
                                        </nx-error>
                                        }

                                    </nx-formfield>
                                </div>
                            </div>
                            }

                            <div nxRow>
                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Tax Identification No. (TIN)">
                                        <input nxInput formControlName="tinNo" appToUpperCase maxlength="13"/>
                                        <nx-error nxFormfieldError>
                                            @if(eInvoiceCustomerPartnerForm.get('tinNo').errors?.['required']) {
                                            <strong>Please enter Tax Identification No.
                                                (TIN).</strong>
                                            }

                                            @if(eInvoiceCustomerPartnerForm.get('tinNo').errors?.['pattern']) {
                                            <strong>Tax Identification No. (TIN) is invalid.</strong>
                                            }
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>

                            @if (isCompany) {
                                <app-sst-form-details [customerInfoForm]="eInvoiceCustomerPartnerForm" [countryList]="lov.clientSstCountryList"
                                    [addressTypeList]="lov.clientSstResidentStatusList" [cpDetails]="cpDetails" [isEndt]="true">
                                </app-sst-form-details>
                            }

                            <div nxRow>
                                <div nxCol="12">
                                    <hr class="hr" />
                                    <h4>Contact Details</h4>
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <!-- <nx-dropdown formControlName="mobileCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">
                                                    <nx-dropdown-item value="6010"></nx-dropdown-item>
                                                    <nx-dropdown-item value="6011"></nx-dropdown-item>
                                                    <nx-dropdown-item value="6012"></nx-dropdown-item>
                                                </nx-dropdown> -->
                                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150"
                                                    formControlName="mobileNoCode" (selectionChange)="onChangeMobileNoCode()">
                                                    @for(mobileNoCode of lov.clientMobileCode1List; track mobileNoCode)
                                                    {
                                                    <nx-dropdown-item [value]="mobileNoCode.code">{{ mobileNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Mobile No." class="info nx-margin-left-s">

                                                <input nxInput inputmode="numeric" numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="mobileNo"
                                                    (blur)="onChangeMobileNo()" />

                                                <nx-error nxFormfieldError>
                                                    @if(eInvoiceCustomerPartnerForm.get('mobileNo').errors?.['required'])
                                                    {
                                                    @if (isCompany) {
                                                    <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                                                    } @else if (isIndividual) {
                                                    <strong>Please enter the Mobile No.</strong>
                                                    }

                                                    }

                                                    @if
                                                    (eInvoiceCustomerPartnerForm.get('mobileNo').errors?.['pattern']) {
                                                    <strong>Please enter the number between 7 and 8 characters
                                                        long.</strong>
                                                    }

                                                </nx-error>

                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <nx-dropdown formControlName="fixedLineNoCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">

                                                    <!-- <nx-dropdown-item value="601"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item> -->
                                                    @for (fixLineNoCode of lov.clientFax1CodeList; track fixLineNoCode)
                                                    {
                                                    <nx-dropdown-item [value]="fixLineNoCode.code">{{
                                                        fixLineNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Fixed Line No." class="info nx-margin-left-s">

                                                <input nxInput inputmode="numeric" numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="fixedLineNo"
                                                    (blur)="onChangefixedLineNo()" />

                                                <nx-error nxFormfieldError>
                                                    @if(eInvoiceCustomerPartnerForm.get('fixedLineNo').errors?.['required'])
                                                    {
                                                    @if(isCompany) {
                                                    <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                                                    }

                                                    }

                                                    @if(eInvoiceCustomerPartnerForm.get('fixedLineNo').errors?.['pattern'])
                                                    {
                                                    <strong> Please enter the number between 7 and 8 characters
                                                        long.</strong>
                                                    }
                                                </nx-error>

                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <nx-dropdown formControlName="faxNoCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">

                                                    <!-- <nx-dropdown-item value="601"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item> -->
                                                    @for(faxNoCode of lov.clientFax1CodeList; track faxNoCode) {
                                                    <nx-dropdown-item [value]="faxNoCode.code">{{ faxNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Fax No. (Optional)" class="info nx-margin-left-s">
                                                <input nxInput inputmode="numeric" numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="faxNo"
                                                    (change)="onChangeFaxNo()" />
                                                <nx-error nxFormfieldError>
                                                    <strong>Please enter the number between 7 and 8
                                                        characters long.</strong>
                                                </nx-error>
                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">

                                        <nx-formfield label="Email">
                                            <input nxInput type="text" formControlName="email" (change)="onChangeEmail()"/>

                                            <nx-error nxFormfieldError>
                                                @if(eInvoiceCustomerPartnerForm.get('email').errors?.['required']) {
                                                <strong> Please enter the Email </strong>
                                                }
                                                @if(eInvoiceCustomerPartnerForm.get('email').errors?.['email']) {
                                                <strong> Please enter a valid email address. </strong>
                                                }
                                            </nx-error>

                                        </nx-formfield>

                                    </div>
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12">
                                    <hr class="hr" />
                                    <h4>Correspondence Details</h4>
                                </div>
                            </div>

                            <div nxRow>

                                <div nxCol="12">
                                    <nx-switcher [checked]="true" formControlName="showMapSwitcher">Show
                                        Map</nx-switcher>
                                </div>

                                @if (eInvoiceCustomerPartnerForm.get('showMapSwitcher').value) {
                                <div nxCol="12" class="search-map nx-margin-top-m">

                                    <app-branches-map
                                        [mapVisible]="eInvoiceCustomerPartnerForm.get('showMapSwitcher').value"
                                        (formPopulated)="populateMapForm($event)">
                                    </app-branches-map>

                                    <!-- <div class="pac-card" id="pac-card">
                                        <div>
                                            <div id="title">Autocomplete search</div>
                                            <div id="type-selector" class="pac-controls">
                                                <div class="horizontal-checkboxes nx-margin-top-2xs">
                                                    <span nxCopytext="normal" class="search_map_font">Search Mode
                                                        :</span>
                                                    <div class="radio-map">
                                                        <nx-radio name="group1" labelSize="small" [checked]="true"
                                                            value="address"
                                                            (valueChange)="changeSearchMode($event)"><span
                                                                nxCopytext="medium">By
                                                                Address</span>
                                                        </nx-radio>
                                                        <nx-radio name="group1" labelSize="small" value="lat/long"
                                                            (valueChange)="changeSearchMode($event)"><span
                                                                nxCopytext="medium">By
                                                                Lat/Long</span>
                                                        </nx-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        <div id="pac-container">
                                            <input *ngIf="search_mode_address" id="pac-input" type="text"
                                                placeholder="Enter a location" />

                                            <div *ngIf="!search_mode_address">
                                                <div class="lat_long">
                                                    <input id="pac-input" type="text" placeholder="LATITUDE" />
                                                    <input id="pac-input" type="text" placeholder="LONGITUDE" />
                                                </div>
                                                <div class="container_flex_center align_right nx-margin-top-s">
                                                    <button nxButton="primary small" type="button">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                                        class="map" style="border: 0" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe> -->

                                </div>
                                }

                                <div nxCol="12" class="nx-margin-top-m">
                                    <nx-formfield label="Unit No">
                                        <input nxInput formControlName="unitNo" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 1">
                                        <input nxInput formControlName="address1" appToUpperCase />

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Address 1.</strong>
                                        </nx-error>

                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 2">
                                        <input nxInput formControlName="address2" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 3">
                                        <input nxInput formControlName="address3" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Postcode">
                                        <!-- <input nxInput formControlName="postcode" /> -->
                                        <nx-dropdown [valueFormatter]="toText" formControlName="postCode"
                                            [showFilter]="true" (selectionChange)="onChangePostcode()">
                                            @for(postCode of lov.clientPostcodeList; track postCode) {
                                            <nx-dropdown-item [value]="postCode.code">{{ postCode.code
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter a valid Postcode.</strong>
                                        </nx-error>

                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="City">
                                        <nx-dropdown formControlName="city" [valueFormatter]="toText">
                                            @for(City of lov.clientCityList; track City) {
                                            <nx-dropdown-item [value]="City.code">{{ City.descp }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="State">
                                        <nx-dropdown formControlName="state" [valueFormatter]="toText">
                                            @for(State of lov.clientStateList; track State) {
                                            <nx-dropdown-item [value]="State.code">{{ State.descp }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Country">
                                        <nx-dropdown formControlName="country" [valueFormatter]="toText">
                                            @for(Country of lov.clientCountryList; track Country) {
                                            <nx-dropdown-item [value]="Country.code">{{ Country.descp
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                            </div>

                            <div nxRow>
                                <div nxCol="12">
                                    <p nxCopytext="large" class="nx-font-weight-semibold">
                                        Please select Address Type
                                    </p>
                                </div>

                                <div nxCol="12">
                                    <div nxLayout="grid nopadding" class="nx-margin-top-xs">
                                        <div nxRow>

                                            <div nxCol="12,12,3,3,2">
                                                <nx-radio name="group3" value="R"
                                                    formControlName="addressType">Residential</nx-radio>
                                            </div>

                                            <div nxCol="12,12,5,5,4" class="m_padding_top_10">
                                                <nx-radio name="group3" value="O"
                                                    formControlName="addressType">Non-residential</nx-radio>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12" class="nx-margin-top-m">
                                  <p nxCopytext="medium">
                                    <i>
                                      By clicking NEXT, you confirm that the address submitted is
                                      complete and accurate to the best of your knowledge.
                                    </i>
                                  </p>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        }
    </nx-expansion-panel>
</nx-accordion>