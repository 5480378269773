import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_UPSELL_PRODUCT_LOV, STORE_UPSELL_PRODUCT } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-upsell-product',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './upsell-product.component.html',
  styleUrl: './upsell-product.component.scss'
})
export class UpsellProductComponent implements OnInit {
  @Input() parentForm: FormGroup;
  upsellProductForm: FormGroup;
  upsellProductList: any;
  upsellProductFromState: any;
  userInfo: any;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(){
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    let productCode = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.issuance.issuance.productCode);
    let agentCode = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.agentDetail.agentCode);
    this.store.dispatch(new GET_UPSELL_PRODUCT_LOV({
      listTypes: ["upsellProductList"],
      agentCode: agentCode,
      productType: "MT",
      productCode: productCode,
    })).subscribe(state => {
      this.upsellProductList = state.SatMotorState.upsellProductLov.upsellProductList;
    });

    this.upsellProductFromState = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step4.upsellProduct);

    this.upsellProductForm = this.fb.group({
      upSellType: [this.upsellProductFromState?.upSellType ? this.upsellProductFromState.upSellType : ''],
      upSellAmt: [this.upsellProductFromState?.upSellAmt ? this.upsellProductFromState.upSellAmt : ''] 
    });

    if(this.userInfo.issueOnQuotation) {
      this.upsellProductForm.disable();
    }

    this.parentForm.addControl("upsellProductForm", this.upsellProductForm);

    this.onChangeUpsellProduct();
  }

  onChangeUpsellProduct() {
    let upsellProductControl = this.upsellProductForm.controls['upSellType'];
    let upsellProductAmountControl = this.upsellProductForm.controls['upSellAmt'];
    let upsellProductValue = upsellProductControl.value;
    
    if(upsellProductValue) {
      upsellProductAmountControl.enable();
      upsellProductAmountControl.setValidators(Validators.required);
      upsellProductAmountControl.updateValueAndValidity();
      this.storeUpsellProduct();
    }else {
      upsellProductAmountControl.removeValidators(Validators.required);
      upsellProductAmountControl.updateValueAndValidity();
      upsellProductAmountControl.reset();
      upsellProductAmountControl.disable();

    }
  }

  onChangeUpsellProductAmount() {
    let upsellProductAmount = this.upsellProductForm.controls['upSellAmt'].value;
    
    if(upsellProductAmount) {
      this.storeUpsellProduct();
    }
  }

  storeUpsellProduct() {
    let upsellProductValue = this.upsellProductForm.controls['upSellType'].value;
    let upsellProductAmount = this.upsellProductForm.controls['upSellAmt'].value;

    if(upsellProductValue && upsellProductAmount) {
      let payload = {
        upSellType: upsellProductValue,
        upSellAmt: upsellProductAmount
      }
      this.store.dispatch(new STORE_UPSELL_PRODUCT(payload));
    }
  }
}
