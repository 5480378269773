<!-- Premium Information -->

<nx-card class="card_summary">
  <table nxTable cellpadding="0" class="premium_summary_table">
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal" class="font-grey">Premium Summary</span>
      </td>
      <td nxTableCell class="std-ta-right">
        <span nxCopytext="normal" class="font-grey">Total (RM)</span>
      </td>
    </tr>
    @if (!isApproval && !isRTP && !isIssuance && !viewOnly && !viewPolicyDetails && !isEndorsement) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Vehicle Claim History</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.vehicleClaimHistory | formatDecimal}}</span>
      </td>
    </tr>
    }
    @if(viewPolicyDetails){
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Vehicle Sum Insured</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{premiumInfo?.sumInsured | formatDecimal}}</span>
      </td>
    </tr>
    }

    @if (isApproval || isRTP || isIssuance || viewOnly || isEndorsement) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Vehicle Sum Insured</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.sumInsured | formatDecimal}}</span>
      </td>
    </tr>
    }

    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Annual Premium</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.annualPremium | formatDecimal}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Basic Premium</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.basicPremium | formatDecimal}}</span>
      </td>
    </tr>
    @if(showLoading()) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Loading ({{ data?.loadPercentage }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.loadAmount | formatDecimal}}</span>
      </td>
    </tr>
    }
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">No Claim Discount ({{ data?.ncdPercentage | formatDecimal }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.ncdAmount | formatDecimal}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Total Extra Cover Premium</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.subCoverPremium | formatDecimal}}</span>
      </td>
    </tr>

    @if(isApproval && isPackageSelected) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Package Premium</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.subCovPackagePrem | formatDecimal}}</span>
      </td>
    </tr>
    }

    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Gross Premium</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.grossPremium | formatDecimal}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Rebate ({{ data?.rebatePct }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.rebateAmt | formatDecimal}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Service Tax ({{ data?.servicetaxPercentage }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.servicetaxAmount | formatDecimal}}</span>
      </td>
    </tr>

    @if(isApproval && isSelectReplacementBMW) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Optional Car Replacement Fees II</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.replacementFee | formatDecimal}}</span>
      </td>
    </tr>
    }
    @if(!(isApproval && isEndorsement)) {
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Stamp Duty</span></td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.stamp | formatDecimal}}</span>
      </td>
    </tr>
    }
    @if(!isApproval && !isEndorsement) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Commission ({{ data?.commissionPercentage }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.commissionAmount | formatDecimal}}</span>
      </td>
    </tr>
    }
    <tr nxTableRow>
      <td nxTableCell class="nx-font-weight-bold">
        <span nxCopytext="normal" class="nx-font-weight-bold">Total Amount</span>
      </td>
      <td nxTableCell class="column_cp_content_bold std-ta-right">
        <span nxCopytext="normal" class="nx-font-weight-bold">{{data?.premiumDue | formatDecimal}}</span>
      </td>
    </tr>
    <div class="nx-margin-y-m"></div>

    <tr nxTableRow>
      <td nxTableCell>
        <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
          Total Amount (Rounded)
        </h4>
      </td>
      <td nxTableCell class="column_cp_content_bold std-ta-right">
        <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
          {{data?.premiumDueRounded | formatDecimal}}
        </h4>
      </td>
    </tr>
  </table>

  @if (isEndorsement) {
  <hr class="nx-margin-y-m" />

  <table nxTable cellpadding="0" class="premium_summary_table">
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Commission ({{ data?.commissionPercentage }}%)</span>
      </td>
      <td nxTableCell class="column_cp_content std-ta-right">
        <span nxCopytext="normal">{{data?.commissionAmount | formatDecimal}}</span>
      </td>
    </tr>
  </table>
  }


</nx-card>