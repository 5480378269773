const PREFIX = 'USER';

export class GET_USER {
    static readonly type = `[${PREFIX}] GET USER`;
    constructor(public readonly payload: any) { }
}

export class GET_USER_MOCK {
    static readonly type = `[${PREFIX}] GET USER MOCK`;
    constructor(public readonly payload: any) { }
}

export class RESET_QUERY_PARAMS {
    static readonly type = `[${PREFIX}] RESET QUERY PARAMS`;
}

export class POPULATE_POLICY_MANAGEMENT {
    static readonly type = `[${PREFIX}] POPULATE POLICY MANAGEMENT`;
}

export class BACK_TO_ALPHA {
    static readonly type = `[${PREFIX}] BACK TO ALPHA`;
}

export class BACK_TO_SAT {
    static readonly type = `[${PREFIX}] BACK TO SAT`;
}

export class REDIRECT_SAT {
    static readonly type = `[${PREFIX}] REDIRECT SAT`;
    constructor(public readonly payload: any) { }
}

export class SIGN_OUT {
    static readonly type = `[${PREFIX}] SIGN OUT`;
}

export class NAVIGATE_LOGIN_PAGE {
    static readonly type = `[${PREFIX}] NAVIGATE LOGIN PAGE`;
}

export class STORE_QUERY_PARAMS {
    static readonly type = `[${PREFIX}] STORE QUERY PARAMS`;
    constructor(public readonly payload: any) { }
}

export class REDIRECT_ALPHA_CCENTRIC {
    static readonly type = `[${PREFIX}] REDIRECT ALPHA CCENTRIC`;
    constructor(public readonly payload: any) { }
}
