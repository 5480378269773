import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgIf, formatNumber } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { PremiumSummaryComponent } from "@shared/components/premium-summary/premium-summary.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ExtraCoverageComponent } from "@shared/components/coverages/extra-coverage/extra-coverage.component";
import { Select, Store } from "@ngxs/store";
import { Observable, pairwise, startWith } from "rxjs";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { UPDATE_COVERAGE_ALERT, DELETE_COVERAGE, DELETE_EXTRA_COVER_DETAILS, GET_NCD_ENQUIRY, STORE_NCD_INFO } from "@store/sat-motor/sat-motor.action";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NcdMessageComponent } from "@shared/components/modal/ncd-message/ncd-message.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { OkMessageComponent } from "@shared/components/modal/ok-message/ok-message.component";

@Component({
  selector: "app-coverage-third-party-fire-theft",
  standalone: true,
  imports: [NgIf, SharedModule, PremiumSummaryComponent, ExtraCoverageComponent],
  templateUrl: "./coverage-third-party-fire-theft.component.html",
  styleUrl: "./coverage-third-party-fire-theft.component.scss",
})
export class CoverageThirdPartyFireTheftComponent implements OnInit {
  @ViewChild(ExtraCoverageComponent) extraCoverageComponentChild: ExtraCoverageComponent;
  @Input() coverageFmGroup!: FormGroup;
  @Input() isThirdPartyFireTheft!: boolean;
  @Input() lov!: any;
  @Input() defaultDriver: any;
  @Input() issuanceInfo: any;
  @Input() partnerInfo: any;

  @Output() onCoverDeleted = new EventEmitter<unknown>();
  @Output() onCoverFormChanged = new EventEmitter<unknown>();
  @Output() alimCustomerEligible = new EventEmitter<unknown>();
  @Output() bettermentEligible = new EventEmitter<unknown>();

  @Select(SatMotorState.currentTypeOfCoverage) currentTypeOfCoverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coverage) coverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;
  @Select(SatMotorState.coverageShowAlert) coverageShowAlert$: Observable<SatMotorState>;

  thirdPartyFireTheftForm: FormGroup<any>;
  agentDetail: any;
  vehicleNo: any;
  region: any;
  selectedMakeModel: any;
  typeOfCoverage: any;
  sumInsuredRange: boolean;
  avSi: any;
  avSiAvailable: boolean;
  underInsurenceApplies: boolean;
  avIndChecked: boolean;
  isShowExtraCoverage: boolean = false;

  oldValue = {};

  decimalFmt: any;
  numberFmt: any;

  isDisabledGetNCD: boolean;
  hasClickGetNcd: boolean;
  ncdMessageModal?: NxModalRef<NcdMessageComponent>;
  warningMessageModal?: NxModalRef<SimpleModalComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;

  coverageAlert: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    public translate: TranslateService,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.thirdPartyFireTheftForm = this.fb.group({
      sumInsured: ["", Validators.required],
      avInd: false,
      excessAmount: [{ value: "", disabled: true }],
      basicPremium: [{ value: "", disabled: true }],
      annualPremium: [{ value: "", disabled: true }],
      ncdPercentage: [{ value: "", disabled: true }],
      ncdAmount: [{ value: "", disabled: true }],
      prevNcdInsurer: "",
      prevNcdVeh: ["", VehicleNoValidator()],
    });
  }

  ngOnInit() {
    this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;
    this.coverageAlert = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.coverageShowDialog);

    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    if (this.typeOfCoverage && this.isThirdPartyFireTheft) {
      this.thirdPartyFireTheftForm.patchValue({
        sumInsured: this.decimalFmt.transform(this.typeOfCoverage["sumInsured"]),
        excessAmount: this.decimalFmt.transform(this.typeOfCoverage["excessAmount"]),
        annualPremium:this.decimalFmt.transform( this.typeOfCoverage["annualPremium"]),
        basicPremium: this.decimalFmt.transform(this.typeOfCoverage["basicPremium"]),
        ncdPercentage: this.decimalFmt.transform(this.typeOfCoverage["ncdPercentage"]),
        ncdAmount: this.decimalFmt.transform(this.typeOfCoverage["ncdAmount"]),
        prevNcdVeh: this.decimalFmt.transform(this.typeOfCoverage["prevNcdVeh"]),
        prevNcdInsurer: this.typeOfCoverage["prevNcdInsurer"],
      });
    }
    
    // this.setRecommendedSumInsuredRange();

    // this.thirdPartyFireTheftForm.valueChanges.pipe(startWith(this.thirdPartyFireTheftForm.value), pairwise()).subscribe(([old, value]) => {
    //   this.oldValue = old;
    // });

    this.coverage$.subscribe((state) => {
      if (state) {
        const forceDelete = state["forceDelete"];
        if (forceDelete) {
          const coverageType = state["coverageType"];
          if (coverageType && coverageType["coverCode"] === "30") {
            this.deleteCover(null);
          }
        }
      }
    });

    this.currentTypeOfCoverage$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "30") {
          this.coverageFmGroup.controls["typeOfCoverage"] = this.thirdPartyFireTheftForm;
          this.typeOfCoverage = state;
          if (this.typeOfCoverage && this.isThirdPartyFireTheft) {
            let coverListLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.coverListLov);
            for (let coverList of coverListLov) {
              if (coverList["coverCode"] === "30") {
                this.typeOfCoverage = { ...this.typeOfCoverage, coverDescription: coverList["coverDescription"] };
              }
            } 
          }
        }
      }
    });

    this.coverageShowAlert$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "30") {
          const grossPremiumWarning = state["grossPremiumWarning"];
          const siWarning = state["siWarning"];
  
          if (state["bettermentEligible"] && !state["bettermentEligibleShowed"]) {
            this.bettermentEligible.emit();
          } 
          else if (state["alimCustomerEligible"] && !state["alimCustomerEligibleShowed"]) {
            this.alimCustomerEligible.emit();
          } 
          else if (grossPremiumWarning && !grossPremiumWarning["valid"]) {
            this.showGrossPremiumWarning(grossPremiumWarning["value"]);
          } 
          else if (siWarning && !siWarning["valid"]) {
            this.showSIWarning(siWarning["value"]);
          }
        }
      }
    });

    this.coveragePremium$.subscribe((state) => {
      if (state) {

        this.avSi = null;
        this.sumInsuredRange = this.avSiAvailable = this.underInsurenceApplies = this.avIndChecked = this.isShowExtraCoverage = false;

        const cngeNote = state["cngeNote"];
        if (cngeNote) {
          this.region = cngeNote.region;

          if (cngeNote.bettermentEligible) {
            this.coverageAlert = { ...this.coverageAlert, bettermentEligible: cngeNote.bettermentEligible };
          }

          const riskList = cngeNote["riskList"];
          if (riskList?.length) {

            for (let riskListItem of riskList) {
              this.prevNcdVeh.setValue(riskListItem.vehicleNo);
              
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  if (this.isThirdPartyFireTheft && coverListItem["coverDto"].subCovList?.length > 0) {
                    this.isShowExtraCoverage = true;
                  }

                  this.coverageAlert = { ...this.coverageAlert, coverCode: coverListItem["coverCode"] };

                  if (coverListItem["coverCode"] !== "30") return;

                  const sumInsured = coverListItem["sumInsured"];
                  const grossPremium = coverListItem["grossPremium"];
                  
                  this.setRecommendedSumInsuredRange();
                  if (sumInsured) {

                    const siWarning = this.setSIWarning(sumInsured);
                    this.coverageAlert = { ...this.coverageAlert, siWarning: siWarning };

                    const grossPremiumWarning = this.setGrossPremiumWarning(grossPremium);
                    this.coverageAlert = { ...this.coverageAlert, grossPremiumWarning: grossPremiumWarning };
                    
                    this.setSumInsuredRange(sumInsured)

                    if ([siWarning.valid, grossPremiumWarning.valid].indexOf(true) != -1 && !this.sumInsuredRange)
                      this.setUnderInsurenceApplies(sumInsured);
                    
                    this.setRecommendedValue();

                    if (coverListItem.alimCustomerEligible) {
                      this.coverageAlert = { ...this.coverageAlert, alimCustomerEligible: coverListItem.alimCustomerEligible };
                    }
                    
                    this.typeOfCoverage = {
                      ...this.typeOfCoverage,
                      ...{ grossPremium: grossPremium },
                      ...{ basicPremium: coverListItem["basicPremium"] },
                      ...{ ncdPercentage: coverListItem["ncdPercentage"] },
                      ...{ ncdAmount: coverListItem["ncdAmount"] },
                      ...{ subCoverPremium: coverListItem["subCoverPremium"] },
                    };
                  }
                }
              }
            }
          }
          this.checkDisabledGetNCD();

          }

        if (this.coverageAlert) {
          this.store.dispatch(new UPDATE_COVERAGE_ALERT(this.coverageAlert));
        }
      }
    });

  }

  onChangeForm($event: any) {

    let data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    const doChangeForm = {
      sumInsured: () => {
        this.underInsurenceApplies = false;
        if (data.value)
          this.onCoverFormChanged.emit(data);
      },
      prevNcdVeh: () => {
        this.checkDisabledGetNCD();
      },
    };

    if (["sumInsured"].includes(data.fieldName)) {
      data = { ...data, value: this.numberFmt.transform(data.value), oldValue: this.numberFmt.transform(data.oldValue) }
    }

    // if (data.oldValue !== data.value) {
    doChangeForm[data.fieldName]();
    // }
  }

  deleteCover(cover: any) {
    this.store.dispatch(new DELETE_COVERAGE(cover)).subscribe((state) => {
      this.thirdPartyFireTheftForm.reset();
      this.onCoverDeleted.emit();
    });

    this.store.dispatch(new DELETE_EXTRA_COVER_DETAILS(cover)).subscribe((state) => {});

    this.typeOfCoverage = this.coverageAlert = this.avSi = null;
    this.sumInsuredRange = this.avSiAvailable = this.underInsurenceApplies = this.avIndChecked = this.isShowExtraCoverage = false;

    this.hasClickGetNcd = false;
    this.isDisabledGetNCD = false;
  }

  checkDisabledGetNCD() {
    if(this.hasClickGetNcd) {
      this.isDisabledGetNCD = true;
      return;
    }

    let vehicleNo = this.thirdPartyFireTheftForm.get("prevNcdVeh");
    if (this.issuanceInfo.cnoteType === "NWTR" && vehicleNo.valid && vehicleNo.value && vehicleNo.value != 'NA') {
      this.isDisabledGetNCD = false;
    } else {
      this.isDisabledGetNCD = true;
    }
  }

  onClickGetNCD() {
    this.hasClickGetNcd = true;
    this.isDisabledGetNCD = true;

    let vehicleDetailsForm = this.coverageFmGroup.get("vehicleDetails");
    let typeOfCoverageForm = this.coverageFmGroup.get("typeOfCoverage");

    let payload = {
      productType: this.issuanceInfo.productCode,
      vehicleUse: vehicleDetailsForm.get("vehicleUse").value,
      vehicleNo: vehicleDetailsForm.get("vehicleNo").value,
      previousNcdVehicle: typeOfCoverageForm.get("prevNcdVeh").value,
      chassisNo: vehicleDetailsForm.get("vehicleChassis").value,
      idType: this.partnerInfo.idType1,
      idNo1: this.partnerInfo.idValue1
    };

    this.store.dispatch(new GET_NCD_ENQUIRY(payload)).subscribe((state) => {
      let ncdInfo = state.SatMotorState.ncdEnquiryDetails;
      if (ncdInfo) {
        this.store.dispatch(new STORE_NCD_INFO(ncdInfo));
        if (ncdInfo.errMsg) {
          this.okMessageModal = this.dialogService.open(OkMessageComponent, {
            width: '400px',
            data: {
              message: ncdInfo.errMsg
            }
          });
        } else {
          this.thirdPartyFireTheftForm.patchValue({
            ncdPercentage: this.decimalFmt.transform(ncdInfo.currNcdPct),
          });
          this.onCoverFormChanged.emit();
        }
      }
    });
  }
  
  isPrivateCars() {
    return ["080100", "080200", "080300"].includes(this.issuanceInfo.productCode);
  }

  isMotorcycle() {
    return ["082100", "082101"].includes(this.issuanceInfo.productCode);
  }

  setRecommendedSumInsuredRange() {
    this.selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.selectedMakeModel);
    if (this.selectedMakeModel) {
      this.avSi = {
        E: { avSiMin: this.selectedMakeModel.avEmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMin), 'en-US'), 
        	 avSiMax: this.selectedMakeModel.avEmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMax), 'en-US'), 
           },
        W: { avSiMin: this.selectedMakeModel.avWmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMin), 'en-US'), 
        	 avSiMax: this.selectedMakeModel.avWmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMax), 'en-US'), 
           },
      };
      if (this.avSi) {
        this.avSiAvailable = 
          this.avSi[this.region].avSiMin > 0  &&
          this.avSi[this.region].avSiMax > 0 &&
          this.selectedMakeModel.inactiveSiInd === "N";
      }
    }
  }  

  setSumInsuredRange(sumInsured) {
    return this.sumInsuredRange = (this.avSiAvailable) && 
      (sumInsured >= this.avSi[this.region].avSiMin && sumInsured <= this.avSi[this.region].avSiMax);
  }  

  setRecommendedValue() {
    
    this.avIndChecked = false;
    this.avInd.enable();
    
    this.avIndChecked = this.avSi[this.region] && this.selectedMakeModel.inactiveSiInd === "N" && this.sumInsuredRange;
    this.avInd.patchValue(this.avIndChecked);

    if (this.isMotorcycle() || !this.avSiAvailable || !this.sumInsuredRange) {
      this.avInd.disable();
    }
  } 

  setUnderInsurenceApplies(sumInsured) {
    return this.underInsurenceApplies = sumInsured < ((90 / 100) * this.avSi[this.region].avSiMin);
  }

  setSIWarning(sumInsured : any) {
    let value : any;
    if (this.isPrivateCars() && sumInsured < 4000) {
      value = "4000";
    }
    if (this.isMotorcycle() && sumInsured < 1000) {
      value = "1000";
    }
    
    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }
    
  showSIWarning(value : any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Sum insured must be at least RM" + formatNumber(Number(value), 'en-US') + "." },
    });
	  
    this.warningMessageModal.afterClosed().subscribe((result) => {
      this.sumInsured.setValue(this.decimalFmt.transform(value));
      this.onCoverFormChanged.emit();
    });
    }
  }

  setGrossPremiumWarning(grossPremium : any) {
    let value : any;
    if (this.isPrivateCars() && grossPremium < 50) {
      value = "50";
    }
    if (this.isMotorcycle() && grossPremium < 20) {
      value = "20";
    }
    
    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }  

  showGrossPremiumWarning(value : any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Minimum Gross Premium Applied." },
      });
	  
      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.typeOfCoverage = { ...this.typeOfCoverage, ...{ grossPremium: this.decimalFmt.transform(value) } };
        this.onCoverFormChanged.emit(this.typeOfCoverage);
      });
    }
  }

  get avInd() {
    return this.thirdPartyFireTheftForm.controls["avInd"];
  }
  
  get prevNcdVeh() {
    return this.thirdPartyFireTheftForm.controls["prevNcdVeh"];
  }

  get sumInsured() {
    return this.thirdPartyFireTheftForm.controls["sumInsured"];
  }

  callThirdPartyFireTheftComponent(){
    return this.extraCoverageComponentChild.motorRoadWarriorValidation();
  }
}
