@if(isApproval) {
    <div nxRow>
        <!--Current Extra Cover-->
        <div nxCol="12,12,12,12,6" class="nx-margin-bottom-2m">
            <div>
                <app-external-scrollbar targetSyncScroll="4"
                    [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder4 syncScroll="4">
                    <table nxTable zebra cellpadding="0" >
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Current Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_coverage"></th>
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        @if(currentExtraCoverList != null && currentExtraCoverList.length !== 0 ) {
                            @for(currentExtraCover of currentExtraCoverList; track currentExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                <td nxTableCell class="table_extra_coverage">
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverCodeDescription }}</span>
                                </td>
                                <td nxTableCell class="table_extra_coverage">
                                    <!-- @for(subItem of currentExtraCover.mtExtCovDtoList; track subItem) {
                                        @if(currentExtraCover.planCode) {
                                            <span nxCopytext="medium">{{ currentExtraCover.displayLabel }} <br></span> 
                                        }
                                    } -->
                                    @if(currentExtraCover.displayLabel && (currentExtraCover.subCoverCode == 'RPPA1' || currentExtraCover.subCoverCode == 'A212')) {
                                        <span nxCopytext="medium">{{ currentExtraCover.displayLabel}}</span>
                                    }

                                    @if(currentExtraCover.planCode){PLAN/ UNIT : {{ getDropDownDesc(currentExtraCover.subCoverCode,currentExtraCover.planCode) }} <br>}

                                    @if(currentExtraCover.subCoverCode == '112'){
                                        @if(currentExtraCover.subCoverNumberOfDays > 0 || currentExtraCover.subCoverDays > 0) {
                                        <ng-container>
                                            NO OF DAYS : {{ currentExtraCover.subCoverNumberOfDays || currentExtraCover.subCoverDays}} <br>
                                        </ng-container>
                                        }
                                        @if(currentExtraCover.subCoverAmtday > 0) {
                                        <ng-container>
                                            AMOUNT PER DAY : {{ currentExtraCover.subCoverAmtday }} <br>
                                        </ng-container>
                                        }
                                    }

                                    @if(currentExtraCover.subCoverSumInsured != 0) {
                                        <span nxCopytext="medium">SUM INSURED (RM) : {{ currentExtraCover.subCoverSumInsured | formatDecimal }}</span> 
                                    }
                                    
                                </td><!--Temp Value-->
                                <td nxTableCell class="table_extra_coverage">
                                    @if(currentExtraCover.subCoverPrem != 0) {
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverPrem | formatDecimal }}</span>
                                    }
                                </td>
                            </tr>
                        }
                          }@else {
                        <tr nxTableRow>
                           <td nxTableCell colspan="3" class="std-ta-center">NO DATA</td>
                        </tr>
                         }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Previous Extra Cover-->
        <div nxCol="12,12,12,12,6" class="nx-margin-bottom-2m">
            <div>
                <app-external-scrollbar targetSyncScroll="5"
                    [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder5 syncScroll="5">
                    <table nxTable zebra cellpadding="0">
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Previous Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_coverage"></th>
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @if(prevExtraCoverList != null && prevExtraCoverList.length !== 0 ) {
                                @for(prevExtraCover of prevExtraCoverList; track prevExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                    <td nxTableCell class="table_extra_coverage">
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverCodeDescription }}</span>
                                    </td>
                                    <td nxTableCell class="table_extra_coverage">
                                        <!-- @for(subItem of prevExtraCover.mtExtCovDtoList; track subItem) {
                                            @if(prevExtraCover.planCode) {
                                                <span nxCopytext="medium">{{ prevExtraCover.displayLabel }} <br></span>
                                            }
                                        } -->

                                        @if(prevExtraCover.displayLabel && (prevExtraCover.subCoverCode == 'RPPA1' || prevExtraCover.subCoverCode == 'A212')) {
                                            <span nxCopytext="medium">{{ prevExtraCover.displayLabel}}</span>
                                        }

                                        @if(prevExtraCover.planCode){PLAN/ UNIT : {{ getDropDownDesc(prevExtraCover.subCoverCode,prevExtraCover.planCode) }} <br>}

                                        @if(prevExtraCover.subCoverCode == '112'){
                                            @if(prevExtraCover.subCoverNumberOfDays > 0 || prevExtraCover.subCoverDays > 0) {
                                            <ng-container>
                                                NO OF DAYS : {{ prevExtraCover.subCoverNumberOfDays || prevExtraCover.subCoverDays}} <br>
                                            </ng-container>
                                            }
                                            @if(prevExtraCover.subCoverAmtday > 0) {
                                            <ng-container>
                                                AMOUNT PER DAY : {{ prevExtraCover.subCoverAmtday }} <br>
                                            </ng-container>
                                            }
                                        }

                                        @if(prevExtraCover.subCoverSumInsured != 0) {
                                            <span nxCopytext="medium">SUM INSURED (RM) : {{ prevExtraCover.subCoverSumInsured | formatDecimal }}</span> 
                                        }

                                    </td><!--Temp Value-->
                                    <td nxTableCell class="table_extra_coverage">
                                        @if(prevExtraCover.subCoverPrem != 0) {
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverPrem | formatDecimal }}</span>
                                        }
                                    </td>
                                </tr>
                                }
                                }@else {
                                 <tr nxTableRow>
                                <td nxTableCell colspan="3" class="std-ta-center">NO DATA</td>
                                 </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
