import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MarketingMaterialComponent } from '../modal/marketing-material/marketing-material.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { GET_REDIRECT_PAGE, GET_SWITCH_SITE, ISSUE_NEW } from '@store/sat-motor/sat-motor.action';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cross-sell-banner',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './cross-sell-banner.component.html',
  styleUrl: './cross-sell-banner.component.scss'
})
export class CrossSellBannerComponent implements OnInit {
  @Input() crossSellList: any;
  @Input() userInfo: any;
  @Input() marketingConsent: any;
  @Input() clientPartnerInfo: any;
  @Input() agentInfo: any;
  @Input() isApproval: boolean;
  crossSellListPerPage: any = [];
  currentCrossSellStartIndex = 0;
  marketingMaterialModal?: NxModalRef<MarketingMaterialComponent>;
  activeSlide = 1;
  slides: any;
  perPage = 1;
  previousScreenSize: any;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private router: Router
  ) {}

  @HostListener('window:resize') onWindowResize() {
    if((!this.isMediumAndSmallView && this.previousScreenSize > 993) || (this.isMediumAndSmallView && this.previousScreenSize <= 993)) {
      return;
    }

    this.slides = this.getSlideLength();
    if (this.activeSlide != 1) {
      if (this.isMediumAndSmallView && this.previousScreenSize > 993) {
        this.activeSlide = this.currentCrossSellStartIndex + 1;
      } else {
        if(this.activeSlide % 2 == 0) {
          this.activeSlide = this.activeSlide / 2;
          this.currentCrossSellStartIndex -= 1;
        }else {
          this.activeSlide = (this.activeSlide + 1) / 2;
        }
      }
    }
    this.getCrossSellListPerPage();
    this.previousScreenSize = window.innerWidth;
    
  }

  ngOnInit() {
    this.previousScreenSize = window.innerWidth;
    // this.slides = Math.ceil(this.crossSellList.length / this.perPage);
    this.slides = this.getSlideLength();
    this.getCrossSellListPerPage();
  }

  openPopUpMarketingMaterial(crossSell) {
    this.marketingMaterialModal = this.dialogService.open(MarketingMaterialComponent, {
      minWidth: '70vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      showCloseIcon: true,
      data: {
        crossSell: crossSell,
        customerPartnerInfo: this.clientPartnerInfo,
        userInfo: this.userInfo,
        agentInfo: this.agentInfo,
        isApproval: this.isApproval
      }
    });
  }

  getCSRedirectURL(crossSell) {
        const payload = {
          agentCode: this.agentInfo.agentCode,
          documentNo: '',
          // operator: this.userInfo.userId,
          // userId: this.userInfo.userId,
          productType: crossSell?.productType,
          action: 'ISSUE',
          contractId: '',
          productCode: crossSell.productCode,
          cnoteType: 'NW',
          idType: this.clientPartnerInfo.idType1,
          idValue: this.clientPartnerInfo.idValue1,
          clientType: this.clientPartnerInfo.clientType,
          renewNow: false,
          equote: false
        };

        this.store.dispatch(new GET_SWITCH_SITE("SAT_UNIFICATION_URL")).subscribe((state) => {
          let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;

          switchSiteUrl += payload.agentCode ? `&agentCode=${payload.agentCode}` : '';
          switchSiteUrl += payload.productType ? `&productType=${payload.productType}` : '';
          switchSiteUrl += payload.productCode ? `&productCode=${payload.productCode}` : '';
          switchSiteUrl += payload.action ? `&action=${payload.action}` : '';
          switchSiteUrl += payload.documentNo ? `&documentNo=${payload.documentNo}` : '';
          switchSiteUrl += payload.contractId ? `&contractId=${payload.contractId}` : '';
          switchSiteUrl += payload.cnoteType ? `&cnoteType=${payload.cnoteType}` : '';
          switchSiteUrl += payload.idType ? `&idType=${payload.idType}` : '';
          switchSiteUrl += payload.idValue ? `&idValue=${payload.idValue}` : '';
          switchSiteUrl += payload.clientType ? `&clientType=${payload.clientType}` : '';
          switchSiteUrl += `&renewNow=${payload.renewNow}`;
          switchSiteUrl += `&equote=${payload.equote}`;
          
          console.log("SwitchSiteUrl Url : " + switchSiteUrl);
          window.location.href = switchSiteUrl;
        });
            
  }

  disableMartketingAndIssueNew() {
    if(this.marketingConsent) {
      return this.userInfo.adminType === "HQ" || this.userInfo.adminType === "BDM"; 
    }else {
      return true;
    }
  }

  prevPage() {
    this.activeSlide--;
    if(!this.isMediumAndSmallView) {
      this.currentCrossSellStartIndex -= 2;
    }else {
      this.currentCrossSellStartIndex -= 1;
    }
    
    this.getCrossSellListPerPage();
  }

  nextPage() {
    this.activeSlide++;
    if(!this.isMediumAndSmallView) {
      this.currentCrossSellStartIndex += 2;
    }else {
      this.currentCrossSellStartIndex += 1;
    }
    this.getCrossSellListPerPage();
  }

  goToPage(n: number) {
    if(!this.isMediumAndSmallView) {
      this.currentCrossSellStartIndex = (n * 2) - 2;
    }else {
      this.currentCrossSellStartIndex = (n * 1) - 1;
    }

    this.activeSlide = n;

    this.getCrossSellListPerPage();
  }

  getCrossSellListPerPage() {
    if(!this.isMediumAndSmallView) {
      this.crossSellListPerPage = this.crossSellList.slice(this.currentCrossSellStartIndex, this.currentCrossSellStartIndex + 2);
    }else {
      this.crossSellListPerPage = this.crossSellList.slice(this.currentCrossSellStartIndex, this.currentCrossSellStartIndex + 1);
    }
  }

  getSlideLength() {
    if(this.isMediumAndSmallView || this.crossSellList.length == 1) {
      return this.crossSellList.length;
    }

    if(this.crossSellList.length % 2 == 0) {
      return this.crossSellList.length / 2;
    }else {
      return Math.ceil(this.crossSellList.length / 2);
    }
  }

  get isMediumAndSmallView() {
    return window.innerWidth <= 993;
  }
}
