<form [formGroup]="emailDocumentForm">
<!-- PopUp for Email Document (to be move to new component) -->
<div *ngIf="popup_type == 'emaildocument'">
    <div nxModalContent>
        <nx-message context="success" class="nx-margin-bottom-m" *ngIf="emailDocumentResponse?.success">
            <span class="nx-font-weight-bold">{{ message }}</span>
        </nx-message>
          <nx-message context="error" class="nx-margin-bottom-m" *ngIf="!emailDocumentResponse?.success && isEmailDocumentClicked">
            <span class="nx-font-weight-bold">Failed to sent email. Please refer to email status history.</span>
        </nx-message>  
        <h3 size="s" class="nx-margin-bottom-xs nx-margin-top-0">
            Email Document
        </h3>

        <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,4">
                    <p nxCopytext="large" class="nx-font-weight-semibold nx-margin-bottom-xs">e-Document Consent</p>
                </div>
                <div nxCol="12,12,8">
                    <div nxLayout="grid nopadding" class="grid_remove_padding">
                        <nx-radio-group formControlName="edocConsentInd">
                            <div nxRow>
                                <div nxCol="12,6,4,3">
                                    <nx-radio value="true" class="nx-margin-bottom-xs">
                                        Yes
                                    </nx-radio>
                                </div>
                                <div nxCol="12,6,4,3">
                                    <nx-radio value="false" class="nx-margin-bottom-xs">
                                        No
                                    </nx-radio>
                                </div>
                            </div>
                        </nx-radio-group>
                    </div>
                </div>
            </div>
        </div>

        <hr class="nx-margin-bottom-2m nx-margin-top-s">

        <p nxCopytext="large" class="nx-font-weight-bold nx-margin-bottom-2m">Customer email address will not be updated to the Policy and Customer Partner Information.</p>

        <nx-data-display size="small" class="nx-margin-bottom-2m">
            <nx-data-display-label class="font-grey">Policyholder Name</nx-data-display-label>
            <p nxCopytext="large">{{ clientName }}</p>
        </nx-data-display>

        <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,6">
                    <nx-formfield label="Customer’s Email">
                        <input nxInput formControlName="clientEmail" />
                        @if(emailDocumentForm.get('clientEmail').errors?.['required']) {
                        <nx-error nxFormfieldError>
                            <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'EMAIL_DOCUMENT.FIELD.CUSTOMER_EMAIL' | translate }
                            }}</strong>
                        </nx-error>
                        }
                        @if(emailDocumentForm.get('clientEmail').errors?.['email']) {
                            <nx-error nxFormfieldError>
                                <strong> Please enter a valid email address. </strong>
                            </nx-error>
                        }
                    </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                    <nx-formfield label="Customer’s Additional Email">
                        <input nxInput formControlName="clientadditionalEmail" />
                        @if(emailDocumentForm.get('clientadditionalEmail').errors?.['email']) {
                            <nx-error nxFormfieldError>
                                <strong> Please enter a valid email address. </strong>
                            </nx-error>
                        }
                    </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                    <nx-formfield label="Agent’s Email">
                        <input nxInput formControlName="agentEmail" />
                        @if(emailDocumentForm.get('agentEmail').errors?.['email']) {
                            <nx-error nxFormfieldError>
                                <strong> Please enter a valid email address. </strong>
                            </nx-error>
                        }
                    </nx-formfield>
                </div>
            </div>
        </div>

        <hr class="nx-margin-bottom-2m">

        <div *ngIf="emailListing">
         <p nxCopytext="large" class="nx-font-weight-bold nx-margin-bottom-2m">Email Listing History</p> 

         <nx-swipebar label="Swipe to see more">
            <table nxTable zebra cellpadding=0>
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">No.</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Recipients</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Document</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Sent By</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Sent Date</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Email Status</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow *ngFor="let item of emailListing; let i = index">
                        <td nxTableCell><span nxCopytext="medium">{{ i + 1 }}</span></td>
                        <td nxTableCell><span nxCopytext="medium">{{ item?.emailRecipients }}</span></td>
                        <td nxTableCell><span nxCopytext="medium">{{ item?.templateCode }} [{{ item?.policyNo }}]</span></td>
                        <td nxTableCell><span nxCopytext="medium">{{ item?.emailSentBy }}</span></td>
                        <td nxTableCell><span nxCopytext="medium">{{ formatData(formatDate(item?.emailSentDate)) }}</span></td> 
                        <td nxTableCell><span nxCopytext="medium">{{ item?.emailStatus }}</span></td>
                    </tr>
                </tbody>
            </table>
        </nx-swipebar> 
        </div>
    </div>


    <div class="nx-margin-top-2m">
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            Close
        </button>
        <button nxButton="primary" type="button" (click)="onEmailDocumentClicked()" [disabled]="emailDocumentForm.invalid || emailDocumentForm.get('edocConsentInd')?.value === 'false'">
            SEND EMAIL
        </button>
    </div>

</div>
</form>