import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { Store, Select } from '@ngxs/store';
import { FormGroup, FormBuilder } from "@angular/forms";
import { Subject, takeUntil } from 'rxjs';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';

@Component({
  selector: 'app-road-tax-payable',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './road-tax-payable.component.html',
  styleUrl: './road-tax-payable.component.scss'
})
export class RoadTaxPayableComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() parentForm: FormGroup;
  @Output() onChangePaymentSection = new EventEmitter();
  lov: any;

  roadTaxPayableForm: FormGroup;
  unsubscribe$ = new Subject<void>();
  decimalFmt: any;
  userInfo: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.roadTaxPayableForm && changes['data']) {
      this.roadTaxPayableForm.patchValue({
        gstRoadTaxAmt: changes['data'].currentValue.gstRoadTaxAmt ? changes['data'].currentValue.gstRoadTaxAmt : 0.00,
        merchantCharges: changes['data'].currentValue.merchantCharges ? changes['data'].currentValue.merchantCharges : 0.00
      });
    }
  }

  ngOnInit() {
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);

    this.roadTaxPayableForm = this.fb.group({
      roadTax: [this.data?.roadTax ? this.decimalFmt.transform(this.data.roadTax) : ''],
      myegFees: [this.data?.myegFees ? this.decimalFmt.transform(this.data.myegFees) : ''],
      courierFees: [this.data?.courierFees ? this.data.courierFees : false],
      merchantCharges: [this.data?.merchantCharges ? this.data.merchantCharges : 0.00],
      gstRoadTaxAmt: [this.data?.gstRoadTaxAmt ? this.data.gstRoadTaxAmt : 0.00]
    });

    if(this.userInfo.issueOnQuotation) {
      this.roadTaxPayableForm.disable();
    }

    this.parentForm.addControl("roadTaxPayableForm", this.roadTaxPayableForm);
  }

  onChangeForm() {
    this.onChangePaymentSection.emit();
  }

}
