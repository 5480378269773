<!-- WORKING:  Extra Cover -->

<nx-accordion variant="light">
  <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title class="accordion_edit">
        Coverage
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <!-- <div nxLayout="grid nopadding" formGroupName="extraCoverage"> -->
    <div nxLayout="grid nopadding">

      <!-- Extra Coverage -->
      <app-extra-coverage></app-extra-coverage>

      <!-- <app-extra-cover-form>
      </app-extra-cover-form> -->

      <!-- Comprehensive -->
      @if (isComprehensive) {
        <!-- <app-comprehensive-extra-cover>
        </app-comprehensive-extra-cover> -->
      }

      <!-- third party -->
      @if (isThirdParty) {
        <!-- <app-third-party-extra-cover>
        </app-third-party-extra-cover> -->
      }

      <!-- third party, Fire and Theft -->
      @if (isThirdPartyFireTheft) {
        <!-- <app-third-party-fire-theft-extra-cover>
        </app-third-party-fire-theft-extra-cover> -->
      }
      <!-- end Extra Coverage -->

      <!-- e-Hailing Driver Name -->

      <ng-container>
        <div nxLayout="grid" class="grid_remove_padding coverage">
          <div nxRow>
            <div nxCol="12">
              <hr class="nx-margin-y-2m" />
            </div>
          </div>

          <!-- <app-e-hailing-driver [parentForm]="extraCoverDetailForm">
          </app-e-hailing-driver> -->

        </div>
      </ng-container>

      <!-- Thailand Trip -->
      <app-thailand-trip>
      </app-thailand-trip>

      <!-- ////////////////////////////////////////////// -->
    </div>
  </nx-expansion-panel>
</nx-accordion>