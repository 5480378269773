import { AfterViewInit, ChangeDetectorRef, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NxSmallStageModule } from "@aposin/ng-aquila/small-stage";
import { UserResponse } from "@interfaces/user";
import { Select, Store } from "@ngxs/store";
import { CrossSellBannerComponent } from "@shared/components/cross-sell-banner/cross-sell-banner.component";
import { ProgressBarComponent } from "@shared/components/global/progress-bar/progress-bar.component";
import { StepsComponent } from "@shared/components/global/steps/steps.component";
import { SharedModule } from "@shared/shared.module";
import { GET_CROSS_SELL_LIST, SET_ACTION } from "@store/sat-motor/sat-motor.action";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable, combineLatestWith } from "rxjs";
import { STEPPER_CONFIGURATIONS } from "./stepper-list";

@Component({
    selector: "app-motor",
    standalone: true,
    imports: [RouterOutlet, SharedModule, StepsComponent, NxSmallStageModule, CrossSellBannerComponent],
    templateUrl: "./motor.component.html",
    styleUrl: "./motor.component.scss",
})
export class MotorComponent implements AfterViewInit {
    @Select(SatMotorState.productName) productName$: Observable<SatMotorState>;
    @Select(SatMotorState.progressStep) progressStep$;
    @Select(SatMotorState.isCancelAtComplete) isCancelAtComplete$;

    getAction: any;
    productName: any = "Motor";
    productList: any;
    selectedStepperList: any;
    getCurrentStep: number;
    progressValue: number;

    crossSellList: any = [];
    userInfo: UserResponse;
    marketingConsent: any;
    customerPartnerInfo: any;
    agentInfo: any;
    isShowCrossSell: boolean = false;

    constructor(
        public store: Store,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.getAction = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.actionType);
        this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);

        if (this.getAction === "UPDATE") {
            let draftDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.draftDetails);
            if (draftDetails) {
                if (draftDetails.cnoteType) {
                    if (["NWNN", "NWOO", "NWTR"].includes(draftDetails.cnoteType)) {
                        this.getAction = "ISSUE";
                    } else if (["RP"].includes(draftDetails.cnoteType)) {
                        this.getAction = "RTP";
                    } else if (["ENDT"].includes(draftDetails.cnoteType)) {
                        this.getAction = "ENDT";
                    }
                    this.store.dispatch(new SET_ACTION(this.getAction));
                }
            }
        }

        this.productName$.subscribe((state) => {
            if (state) {
                this.productList = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.lov?.productList);
                if (this.productList) {
                    this.productName = this.productList.find(({ code }) => code === state);
                }
            }
        });

        this.progressStep$.pipe(
            combineLatestWith(this.isCancelAtComplete$)
        ).subscribe(([step, isCancelAtComplete]) => {
            if (step) {
                if (this.getAction === "ISSUE" && step == 5 && !isCancelAtComplete) {
                    this.showCrossSellBanner();
                } else {
                    this.isShowCrossSell = false;
                }
            }
        });
    }

    showCrossSellBanner() {
        if (this.getAction === "ISSUE") {
            let generateQuotationData = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step4?.generateQuotation?.data);
            let sendToJPJData = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step4?.sendToJPJ?.data);
            let cngeNote;
            if (sendToJPJData) {
                cngeNote = sendToJPJData;
            } else if (generateQuotationData) {
                cngeNote = generateQuotationData;
            }

            this.customerPartnerInfo = cngeNote.clientPartner;
            this.agentInfo = cngeNote.mkagAgent;

            let getCrossSellListPayload = {
                agentCode: this.agentInfo.agentCode,
                partnerId: this.customerPartnerInfo.partnerId,
                idType: this.customerPartnerInfo.idType1,
                idValue: this.customerPartnerInfo.idValue1,
            };

            this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload)).subscribe((state) => {
                if (state.SatMotorState.data.step5.crossSellList) {
                    this.crossSellList = state.SatMotorState.data.step5.crossSellList;
                    this.marketingConsent = state.SatMotorState.data.step5.marketingConsent;
                    this.isShowCrossSell = true;
                    console.log("cross sell list: ", this.crossSellList);
                }
            });
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.selectedStepperList = this.getStepperList(this.getAction);
        });

        this.progressStep$.subscribe((state) => {
            if (state) {
                this.getCurrentStep = state;
                this.cdr.detectChanges();
            }
        });

        this.cdr.detectChanges();
    }

    getStepperList(action: string) {
        return STEPPER_CONFIGURATIONS[action] || [];
    }
}
