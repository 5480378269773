import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VehicleNoValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    const containsAlphabet = /[a-zA-Z]/.test(control.value);
    const containsNumber = /[0-9]/.test(control.value);
    const containsSpaces = /\s/.test(control.value);
    const validPattern = /^[a-zA-Z0-9-]+$/.test(control.value);
    
    if (control.value === "NA") {
      return null;
    }
    
    if (!(containsAlphabet && containsNumber && !containsSpaces && validPattern)) {
      return { invalid: true };
    }
    return null;
  };
}
