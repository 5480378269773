import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { Store } from '@ngxs/store';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';
import { SharedModule } from '@shared/shared.module';
import { GET_PAYMENT_MODE_LOV, GET_PAYMENT_TO_LOV, UPDATE_PAYMENT_LIST } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-payment-mode',
  standalone: true,
  imports: [
    SharedModule,
  ],
  providers: [DecimalPipe],
  templateUrl: './payment-mode.component.html',
  styleUrl: './payment-mode.component.scss'
})
export class PaymentModeComponent implements OnInit {
  addPaymentForm: FormGroup;
  payInSlipMaxLength: number = 8;
  editMode: boolean;
  editIndex: number;
  agentInfo: any;
  userId: any;
  paymentToList: any = [];
  paymentModeList: any = [];
  issueBankList: any = [];
  paymentList: any = [];
  numberFmt: any;

  constructor(
    private fb: FormBuilder,
    private decimalPipe: DecimalPipe,
    @Inject(NX_MODAL_DATA) readonly parentData: any,
    private dialogRef: NxModalRef<any>,
    private store: Store
  ){
    this.numberFmt = new RemoveCommaPipe();
    this.paymentToList = parentData.paymentMadeToLov;
    this.paymentModeList = parentData.paymentModeLov;
  }

  ngOnInit(){
    const isMotorStep1 = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1?.agentDetail);
    if (isMotorStep1) { // logics to fix null agentDetail during endt
      this.agentInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.agentDetail);
    } else {
      this.agentInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endtUpdatedData.mkagAgent);
    }
    this.userId = this.store.selectSnapshot<any>(state => state.UserState.user.userId);
    this.issueBankList = this.store.selectSnapshot<any>(state => state.SatMotorState.lov.bankList);
    this.paymentList = [...this.parentData.paymentList];
    
    this.addPaymentForm = this.fb.group({
      amount: ['', Validators.required],
      paymentMadeTo: ['CC', Validators.required],
      paymentMode: ['', Validators.required],
      payInSlip: [''],
      cdmId: [''],
      seqNo: [''],
      issueBank: [''],
      issueBankBranch: [''],
      chequeNo: [''],
      atmId: [''],
      traceNo: [''],
      approvalCode: [''],
      creditCardNo: [''],
      accountHolder: [''],
      transactionDate: ['']
    });

    // this.getLov();

    this.editMode = this.parentData.editMode;

    if(this.editMode) {
      this.editIndex = this.parentData.editIndex;
      this.addPaymentForm.patchValue(this.parentData.paymentData);
    }
  }

  currencyFormat(ev) {
    let currency = this.formatToNumber(ev.target.value);

    if (typeof currency === 'number') {
      currency = this.decimalPipe.transform(currency, '1.2-2');
      this.addPaymentForm.controls['amount'].setValue(currency);
    }
  }

  formatToNumber(sumInsured) {
    if (typeof sumInsured === 'string') {
      let number = sumInsured;
      number = number.replace(',', '');

      return Number(number);
    }
    else return sumInsured;
  }

  removeSpecialChar() {
    const amount = this.addPaymentForm.controls['amount'].value;

    if (amount) {
      let number;
      number = amount.toString().replace(',', '');

      this.addPaymentForm.controls['amount'].setValue(number);
    }
  }

  onChangePaymentMode() {
    let value = this.addPaymentForm.controls['paymentMode'].value;
      if (value) {
        this.payInSlipMaxLength = 8;
        Object.keys(this.addPaymentForm.controls).forEach(key => {
          if (key == 'amount' || key == 'paymentMadeTo' || key == 'paymentMode') return;

          this.addPaymentForm.get(key).setValue('');
          this.addPaymentForm.get(key).clearValidators();
          this.addPaymentForm.get(key).updateValueAndValidity();
          this.addPaymentForm.get(key).enable();
        });

        switch (value) {
          case 'CS': {
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 8, /^[A-Za-z0-9]+$/);
            break;
          }
          case 'CDM': {
            this.updateFormValidity(this.addPaymentForm, 'cdmId', null, 9, /^[A-Za-z0-9]{4}\/[0-9]{4}$/);
            this.updateFormValidity(this.addPaymentForm, 'seqNo', 4, 4, /^\d{4}$/);
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 8, /^[A-Za-z0-9]+$/);
            this.addPaymentForm.controls['payInSlip'].disable();
            break;
          }
          case 'CDMD': {
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 8, /^[A-Za-z0-9]+$/);
            this.updateFormValidity(this.addPaymentForm, 'issueBank');
            this.updateFormValidity(this.addPaymentForm, 'issueBankBranch');
            this.updateFormValidity(this.addPaymentForm, 'chequeNo', 6, 6);
            break;
          }
          case 'ATM': {
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 20, /^[A-Za-z0-9]+$/);
            this.updateFormValidity(this.addPaymentForm, 'atmId', 4, 8, /^[0-9A-Za-z]{4,8}$/);
            this.updateFormValidity(this.addPaymentForm, 'traceNo', 4, 4, /^\d{4}$/);
            this.addPaymentForm.controls['payInSlip'].disable();
            break
          }
          case 'CC': {
            this.updateFormValidity(this.addPaymentForm, 'creditCardNo', 16, 16, /^\d{16}$/);
            this.updateFormValidity(this.addPaymentForm, 'approvalCode', 1, 8, /^[A-Za-z0-9]+$/);
            break;
          }
          case 'IFUND': {
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 8, /^[A-Za-z0-9]+$/);
            this.updateFormValidity(this.addPaymentForm, 'accountHolder');
            this.updateFormValidity(this.addPaymentForm, 'transactionDate');
            break;
          }
          case 'IBG': {
            this.payInSlipMaxLength = 16;
            this.updateFormValidity(this.addPaymentForm, 'payInSlip', 4, 16, /^[A-Za-z0-9]+$/);
            this.updateFormValidity(this.addPaymentForm, 'issueBank');
            this.updateFormValidity(this.addPaymentForm, 'accountHolder');
            this.updateFormValidity(this.addPaymentForm, 'transactionDate');
            break;
          }
        }
      }
  }

  private updateFormValidity(form: FormGroup, formField: string, min?: number, max?: number, pattern?: RegExp) {
    const validatorsArray = [];
    validatorsArray.push(Validators.required);

    if (min) validatorsArray.push(Validators.minLength(min));

    if (max) validatorsArray.push(Validators.maxLength(max));

    if (pattern) validatorsArray.push(Validators.pattern(pattern));

    form.get(formField).setValidators(validatorsArray);
    form.get(formField).markAsUntouched();
    form.get(formField).updateValueAndValidity();
  }

  addOrUpdatePayment(formData) {

    let number;
    number = formData.amount.toString().replace(',', '');

    formData.amount = Number(this.numberFmt.transform(number));
    formData.payInSlip = formData.payInSlip ? formData.payInSlip.toUpperCase() : '';
    formData.atmId = formData.atmId ? formData.atmId.toUpperCase() : '';
    formData.cdmId = formData.cdmId ? formData.cdmId.toUpperCase() : '';
    formData.accountHolder = formData.accountHolder ? formData.accountHolder.toUpperCase() : '';
    formData.approvalCode = formData.approvalCode ? formData.approvalCode.toUpperCase() : '';
    formData.chequeNo = formData.chequeNo ? formData.chequeNo.toUpperCase() : '';

    if (this.editMode) {
      this.paymentList[this.editIndex] = formData;
    }else {
      this.paymentList.push(formData);
    }
    
    this.dialogRef.close(this.paymentList);

  }

  combinedPaySlip() {
    let paymentModeVal = this.addPaymentForm.controls['paymentMode'].value;
    switch (paymentModeVal) {
      case 'CDM': 
        if (this.addPaymentForm.controls['cdmId'].value && this.addPaymentForm.controls['seqNo'].value) {
          if (this.addPaymentForm.controls['cdmId'].valid && this.addPaymentForm.controls['seqNo'].valid) {
            const firstFourValue = this.addPaymentForm.controls['cdmId'].value.substring(0, 4);
            const lastFourValue = this.addPaymentForm.controls['seqNo'].value;
    
            this.addPaymentForm.controls['payInSlip'].setValue((firstFourValue + lastFourValue).toUpperCase());
          }
        }
      break;
 
      case 'ATM':
        if (this.addPaymentForm.controls['atmId'].value && this.addPaymentForm.controls['traceNo'].value) {
          if (this.addPaymentForm.controls['atmId'].valid && this.addPaymentForm.controls['traceNo'].valid) {
            const firstFourValue = this.addPaymentForm.controls['atmId'].value.substring(0, 4);
            const lastFourValue = this.addPaymentForm.controls['traceNo'].value;
    
            this.addPaymentForm.controls['payInSlip'].setValue((firstFourValue + lastFourValue).toUpperCase());
          }
        }
      break;

    }
  }

  getLov() {
    const paymentToPayload = {
      agentCode: this.agentInfo.agentCode,
      bankBranchCode: this.agentInfo.branchCode ? this.agentInfo.branchCode : "",
      operator: this.userId
    }

    const paymentModePayload= {
      criteria: "paymentTo",
      criteriaValue: this.addPaymentForm.controls['paymentMadeTo'].value,
      bankBranchCode: this.agentInfo.branchCode ? this.agentInfo.branchCode : ""
    }


    this.store.dispatch(new GET_PAYMENT_TO_LOV(paymentToPayload)).subscribe(state => {
      this.paymentToList = state.SatMotorState.paymentToLov.filter(paymentTo => paymentTo.code === "CC");
      this.store.dispatch(new GET_PAYMENT_MODE_LOV(paymentModePayload)).subscribe(state => {
        this.paymentModeList = state.SatMotorState.paymentModeLov;
      });
    });
  }

  
}
