@if (!isUpdatePayment) {
        @if(isEquotation && data.cnoteStatus === 'PRINTED' && (!data.referList || data.referList.length === 0)) {
                <!-- successful message for e-Quotation (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                        e-Quotation has been generated.</span>
                </nx-message>
                }
                
                @if(isEquotation && data.cnoteStatus === 'PRINTED' && data.referList && data.referList.length > 0) {
                        <!-- successful message for e-Quotation (to be move to new component) -->
                        <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                                e-Quotation has been successfully generated, but conversion to Cover Note will be a referral case.</span>
                                
                                <div class="nx-margin-bottom-s"></div>
                                <span nxCopytext="normal">Referral reason:
                                        <ul nxList="small" class="completed_list">
                                                @for(item of data.referList; track item) {
                                                <li><span class="nx-font-weight-bold">{{ item.referCode }}</span> - {{ item.referDescp }}</li>
                                                }
                                        </ul>
                                </span>
                                
                        </nx-message>
                }
                
                @if(isEquotation && data.cnoteStatus === 'CANCELLED') {
                <!-- cancel message for e-Quotation (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                        class="nx-font-weight-bold">e-Quotation has been cancelled successfully.</span></nx-message>
                }
                
                @if(isCoverNote && data.cnoteStatus === 'PRINTED') {
                <!-- successful message for e-Cover Note (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                        e-Cover Note has been successfully generated.</span></nx-message>
                }
                
                @if(isCoverNote && data.cnoteStatus === 'PENDING') {
                <!-- pending approval (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m">
                    <span class="nx-font-weight-bold">Your e-Cover Note has been submitted as pending approval.</span>
                
                    <div class="nx-margin-bottom-s"></div>
                
                @if (data.referList && data.referList.length > 0) {
                <span nxCopytext="normal">Referral reason:
                        <ul nxList="small" class="completed_list">
                                @for(item of data.referList; track item) {
                                <li><span class="nx-font-weight-bold">{{ item.referCode }}</span> - {{ item.referDescp }}</li>
                                }
                        </ul>
                </span>
                }
                
                
                </nx-message>
                }
                
                <!-- @if(isCoverNote && data.cnoteStatus === 'CANCELLED') {
                <!- cancel message for e-Quotation (to be move to new component) ->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                                class="nx-font-weight-bold">e-Cover Note has been cancelled successfully.</span></nx-message>
                } -->
                
                @if(isEndorsement && data.cnoteStatus === 'PRINTED') {
                <!-- successful message for Endorsement (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                        Endorsement has been successfully generated.</span></nx-message>
                }
                
                @if(isEndorsement && data.cnoteStatus === 'PENDING') {
                <!-- pending approval (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                        Endorsement has been submitted as pending approval.</span></nx-message>
                }
                
                @if(isEndorsement && data.cnoteStatus === 'CANCELLED') {
                <!-- cancel message for endorsement (to be move to new component) -->
                <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span class="nx-font-weight-bold">Your
                        Your Endorsement has been cancelled.</span></nx-message>
                }
}

@if (isUpdatePayment) {
        <nx-message context="info" class="green_color_notification nx-margin-bottom-2m">
                <span class="nx-font-weight-bold">
                        Your Payment Details has been successfully updated.
                </span>
        </nx-message>  
}