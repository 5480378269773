<div class="blue_panel nx-margin-top-xs" *ngIf="isComprehensive">
  <nx-expansion-panel expanded="[true]">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title>
        <div nxLayout="grid" class="coverage">
          <div nxRow>
            <div nxCol="12,12,8" class="m_padding_bottom_10">
              <p nxCopytext="small" style="font-size: 14px;">{{ 'COVERAGE.FIELD.COVERAGE_DESCRIPTION' | translate }}</p>
              <h2 class="nx-margin-0" style="font-size: 20px;">{{ typeOfCoverage.coverDescription | titlecase }}</h2>
            </div>
            <div nxCol="12,12,4" class="container_flex_center align_right">
              <div class="gross">
                <p nxCopytext="small">{{ 'COVERAGE.FIELD.GROSS_PREMIUM' | translate }}</p>
                <p nxCopytext="large">{{ typeOfCoverage.grossPremium | number: '1.2-2' }}</p>
              </div>
              <div class="nx-margin-left-s">
                <nx-icon name="trash-o" size="s" class="gross_trash" (click)="deleteCover(typeOfCoverage)"></nx-icon>
              </div>
            </div>
          </div>
        </div>
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <div nxLayout="grid" class="coverage">
      
      @if (isPrivateCars()) {
      <div nxRow>
        <div nxCol="12">
          <p nxCopytext="normal" class="nx-margin-y-m">
            <i>Note: If the selected Sum Insured is not Allianz Agreed Value, Indemnity Value shall apply.</i>
          </p>
        </div>
      </div>
      }
      @else if (isMotorcycle()) {
      <div nxRow>
        <div nxCol="12">
          <p nxCopytext="normal" class="nx-margin-y-m">
            <i>Note: Please select Sum Insured within recommended Sum Insured range to avoid underinsurance.</i>
          </p>
        </div>
      </div>
      }
      @else {
      <div nxRow>
        <div nxCol="12">
          <p nxCopytext="normal" class="nx-margin-y-m"></p>
        </div>
      </div>
      }

      <div [formGroup]="comprehensiveForm">
        <div nxRow>
          <div nxCol="12,12,6" class="agreed_value">
            <div nxLayout="grid" class="grid_remove_padding">
              <div nxRow rowAlignItems="center,center">
                <div nxCol="12,12,6">
                  <nx-formfield label="{{ 'COVERAGE.FIELD.SUM_INSURED_RM' | translate }}">
                    <input nxInput formControlName="sumInsured" (blur)="onChangeForm($event)" appCusCurrencyFormat />
                  </nx-formfield>
                  @if (avSiAvailable) {
                    <p nxCopytext="small" class="nx-margin-bottom-s nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                      @if (avSi[this.region].avSiMin === avSi[this.region].avSiMax) {
                        <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI' | translate: { makeModelSI: avSi[this.region].avSiMinDesc } }}</i>
                      }
                      @else {
                        <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI_RANGE' | translate: { makeModelSIMin: avSi[this.region].avSiMinDesc, makeModelSIMax: avSi[this.region].avSiMaxDesc } }}</i>
                      }
                    </p>
                  }
                </div>
                <div nxCol="12,12,6">
                  <nx-checkbox class="nx-margin-bottom-xs" formControlName="avInd" [checked]="avIndChecked"> 
                    @if (isPrivateCars()) {
                      {{ 'COVERAGE.FIELD.AGREED_VALUE' | translate }}
                    }
                    @else {
                      {{ 'COVERAGE.FIELD.RECOMMENDED_VALUE' | translate }}
                    }
                  </nx-checkbox>
                  @if (sumInsured.errors && sumInsured.errors['required']) {
                    <nx-message context="error" class="nx-margin-y-m nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                      <span class="nx-font-weight-bold">{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.SUM_INSURED' | translate } }}</span>
                    </nx-message>
                  }
                  @if (!(sumInsured.errors && sumInsured.errors['required']) && underInsurenceApplies) {
                    <nx-message context="error" class="nx-margin-y-m nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                      <span class="nx-font-weight-bold">{{ 'COVERAGE.ERROR.SUM_INSURED.UNDERINSURANCE_APPLIES' | translate }}</span>
                    </nx-message>
                    }
                </div>
              </div>
            </div>
            <div class="nx-hidden-xs nx-hidden-s nx-margin-bottom-m">
              @if (avSiAvailable) {
                <p nxCopytext="small" class="nx-margin-bottom-m">
                  @if (avSi[this.region].avSiMin === avSi[this.region].avSiMax) {
                    <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI' | translate: { makeModelSI: avSi[this.region].avSiMinDesc } }}</i>
                  }
                  @else {
                    <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI_RANGE' | translate: { makeModelSIMin: avSi[this.region].avSiMinDesc, makeModelSIMax: avSi[this.region].avSiMaxDesc } }}</i>
                  }
                </p>
              }
              @if (sumInsured.errors && sumInsured.errors['required']) {
              <nx-message context="error">
                <span class="nx-font-weight-bold">{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.SUM_INSURED' | translate } }}</span>
              </nx-message>
              }
              @if (!(sumInsured.errors && sumInsured.errors['required']) && underInsurenceApplies) {
              <nx-message context="error">
                <span class="nx-font-weight-bold">{{ 'COVERAGE.ERROR.SUM_INSURED.UNDERINSURANCE_APPLIES' | translate }}</span>
              </nx-message>
              }
            </div>
          </div>
          <div nxCol="12,12,6" class="m_padding_top_20">
            <nx-formfield label="{{ 'COVERAGE.FIELD.RISK_EXCESS_AMOUNT' | translate }}">
              <input nxInput formControlName="excessAmount" appCusCurrencyFormat />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.BASIC_PREMIUM' | translate }}">
              <input nxInput formControlName="basicPremium" appCusCurrencyFormat />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.ANNUAL_PREMIUM' | translate }}">
              <input nxInput formControlName="annualPremium" appCusCurrencyFormat />
            </nx-formfield>
          </div>
        </div>
        @if (isMotorcycle()) {
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="{{ 'COVERAGE.FIELD.ALL_RIDER_PREMIUM' | translate }}">
                <input nxInput formControlName="allriderPrem" appCusCurrencyFormat />
              </nx-formfield>
            </div>
          </div>
        }
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD' | translate }}">
              <input nxInput formControlName="ncdPercentage" />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_AMOUNT' | translate }}">
              <input nxInput formControlName="ncdAmount" />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_FROM' | translate }}">
              <nx-dropdown formControlName="prevNcdInsurer">
                <nx-dropdown-item *ngFor="let item of lov.insurerList" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_VEHICLE_NO' | translate }}">
              <input nxInput formControlName="prevNcdVeh" (blur)="onChangeForm($event)" appToUpperCase/>
                @if (prevNcdVeh.errors && prevNcdVeh.errors["invalid"]) {
                  <nx-error nxFormfieldError>
                    <strong>{{ 'COVERAGE.ERROR.VEHICLE_NO.INVALID' | translate }}</strong>
                  </nx-error>
                }
            </nx-formfield>
          </div>
        </div>
      </div>

      <div nxRow>
        <div nxCol="12">
          <button nxButton="secondary small" type="button" class="btn_small_full_width" [disabled]="isDisabledGetNCD" (click)="onClickGetNCD()">GET NO CLAIM DISCOUNT</button>
        </div>
      </div>

      @if (isShowExtraCoverage) {
        <app-extra-coverage [coverageFmGroup]="coverageFmGroup" [isComprehensive]="isComprehensive" [defaultDriver]="defaultDriver" [partnerInfo]="partnerInfo"></app-extra-coverage>
      }

      <app-premium-summary [isThirdParty]="false"></app-premium-summary>

    </div>
  </nx-expansion-panel>
</div>
