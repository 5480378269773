<div class="std-ta-left">
  <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
    {{ type ?? "" }}
  </h3>
  <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m" style="font-size: 26px;">
    {{ message }}
  </p>
  <div class="button-container nx-margin-top-50">
    <button nxModalClose class="nx-margin-top-20" style='width: 150px;' nxButton="secondary" type="button">No</button>
    <button nxModalClose class="nx-margin-top-20" style='width: 150px' nxButton="primary" type="button" (click)="onclickOK()">Yes</button>
  </div>
</div>
