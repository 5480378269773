import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  DOWNLOAD_PDF,
  GENERATE_ENDORSEMENT,
  GENERATE_ENDORSEMENT_QUOTATION,
  GET_CHECKLIST_LOV,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SET_CURRENT_STEP,
  STORE_ISSUANCE_SUMMARY,
  ENQUIRE_ENDT_NARRATION,
  GET_DOCUMENT_LOV,
  CALCULATE_PREMIUM_SUMMARY,
  STORE_ROAD_TAX_PAYABLE,
  ENDT_CALCULATE_PREMIUM_SUMMARY,
  STORE_PAYMENT_INFO
} from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { PaymentInformationComponent } from '@shared/components/payment-information/payment-information.component';
import { RoadTaxPayableComponent } from "@shared/components/road-tax-payable/road-tax-payable.component";
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { UserResponse } from "@interfaces/user";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { EndorsementNarrationComponent } from "@shared/components/endorsement-narration/endorsement-narration.component";
import { PaymentComponent } from "@shared/components/payment/payment.component";
import { ChecklistComponent } from '@shared/components/checklist/checklist.component';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';

@Component({
  selector: 'app-endorsement-summary',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    PaymentInformationComponent,
    RoadTaxPayableComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    EndorsementNarrationComponent,
    PaymentComponent,
    ChecklistComponent,
  ],
  templateUrl: './endorsement-summary.component.html',
  styleUrl: './endorsement-summary.component.scss'
})
export class EndorsementSummaryComponent implements OnInit {

  isEndorsement: boolean = true;
  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  premiumInfo: any;
  coverageInfo: any;
  cpData: any;
  lov: any;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showPayment: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;

  endtPolicy: any;
  endtHtgePol: any;
  endtType: any;
  isDataLoaded: boolean = false;

  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;

  endtSummaryForm: FormGroup;
  checklistLov: any;
  endtNarration: any;

  numberFmt: any;

  payment: any;
  roadTaxPayable: any;
  paymentList: any = [];
  paymentInformation: any;

  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.numberFmt = new RemoveCommaPipe();
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.store.dispatch(new SET_CURRENT_STEP(3));
    this.endtSummaryForm = this.fb.group({
      checklistForm: new FormGroup({}),
    });

    this.setProgressType();
    this.populateEndorsementData();
    this.isDataLoaded = true;
    this.showComponents();
    this.getDocumentListLov();
  }

  showComponents() { 
    [
      'showAgentInfo', 'showIssuanceInfo', 'showCustomerPartner', 
      'showCoverage', 'showHistoricalClaim', 'showPremiumInformation', 
      'showChecklist', 'showEndorsementNarration'
    ].forEach(component => this[component] = true); 

    const basicPremium = this.endtPolicy.riskList[0]?.coverList[0]?.basicPremium;
    if (this.userInfo.callCenter && basicPremium > 0) {
      this.showPaymentDetails = true;
      this.showPaymentInformation = true;
      this.showPayment = true;
      if (this.endtType === "XP") {
        this.showRoadTaxPayable = true;
      }
    }
     
  }

  getDocumentListLov() {
    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.endtPolicy.productCode,
      productType: ""
    }));
  }

  setProgressType() {
    this.progressType = 'endorsement';
  }

  populateEndorsementData() {
    //this.endtPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    this.endtPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
    console.log("populateEndorsementData this.endtPolicy endtUpdatedData:", this.endtPolicy);
    
    this.endtHtgePol = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData ?? null);
    this.endtType = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.issuance?.issuance?.endorsementType);

    const { mkagAgent, riskList = [{}], clientPartner: cpData, ...cngeNote } = this.endtPolicy;
    const [risk] = riskList;
    const [coverList] = riskList[0]?.coverList || [];

    this.setAgentInfo(mkagAgent, cngeNote);
    this.clientInfo(cpData);
    this.setIssuanceInfo(cngeNote, risk);
    this.setCoverageInfo(this.endtPolicy, cpData);
    this.setPremiumInfo(coverList, cngeNote);
    this.setClaimHistory(cngeNote);

    let endtData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step2?.endorsementDetails);
    if (this.endtType) {
      const vehicleDetailsEndtType = ['COE', 'ENDT18', 'ENDT10', 'ENDT12', 'ENDT34'];
      let data;

      switch (this.endtType) {
        case 'ENDT30':
          data = endtData.vehicleSumInsured;
          break;
        case 'ENDT17':
        case 'ENDT29':
          data = endtData.extraCover;
          break;
        case 'ENDT23':
        case 'ENDT24':
        case 'ENDT25':
          data = endtData.ncd;
          break;
        case 'ENDT22':
          data = endtData.namedDriver;
          break;
        case 'EC175':
          data = endtData.eInvoiceCustomerPartner;
          break;
        default:
          if (vehicleDetailsEndtType.includes(this.endtType)) {
            data = endtData.vehicleDetails;
            this.updateVehicleDetails(data);
          }
          break;
      }
    }

    this.getChecklistLov();

    this.getEndorsementNarration();

    if(this.userInfo.callCenter) {
      this.getPaymentData(this.endtPolicy);
    }
  }

  setAgentInfo(mkagAgent: any, cngeNote: any) {
    this.agentData = {
      agentCode: mkagAgent.agentCode,
      name: mkagAgent.name,
      issuedBy: this.userInfo.userId,
      issuedDate: new Date().toISOString().substring(0, 10),
    };
  }

  clientInfo(cpData: any) {
    let eInvoiceCustomerPartner = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step2?.endorsementDetails?.eInvoiceCustomerPartner);
    // console.log("clientInfo eInvoiceCustomerPartner:", eInvoiceCustomerPartner);
    
    if (eInvoiceCustomerPartner && this.endtType === 'EC175') {
      this.cpData = eInvoiceCustomerPartner;
    } else {
      this.cpData = cpData;
    }

    // this.cpData = cpData;
    // console.log("clientInfo cpData:", cpData);
  }

  setIssuanceInfo(cngeNote: any, risk: any) {
    let issuanceData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);
    // console.log("setIssuanceInfo issuanceData:", issuanceData);

    this.issuanceInfo = {
      issuance: {
        endorsementType: this.endtType,
        policyNo: issuanceData.issuance.policyNo,
        endtReferenceNo: this.endtPolicy.endtReferenceNo, //will be available after sendToJPJ
        endtNo: this.endtPolicy.endtNo, //will be available after saveQuotation
        endtEffectiveDate: issuanceData.issuance.endtEffectiveDate,
        endtExpiryDate: issuanceData.issuance.endtExpiryDate,
        productCode: cngeNote.productCode,
        vehicleNo: cngeNote.vehicleNo ?? risk.vehicleNo,
        cnoteType: cngeNote.cnoteType,
        prevPolicyNo: cngeNote.previousPolicyNo,
        effectiveDate: cngeNote.effectiveDate,
        expiryDate: cngeNote.expiryDate,
        equotationNo: cngeNote.proposalNo,
        equotationCreatedDate: cngeNote.createDate,
        eCoverNoteNo: cngeNote.cnoteNo,
        jpjStatus: risk.jpjStatus,
        jpjMessage: cngeNote.jpjMessage,
      },
      pdpa: {
        consentDetails: {
          einvoiceConsentInd: issuanceData?.pdpa?.consentDetails?.einvoiceConsentInd,
        },
      },
    };
  }

  setCoverageInfo(policy: any, cpData) {
    let risk = policy.riskList[0];
    let coverList = risk.coverList[0];

    this.coverageInfo = {
      vehicleDetails: risk,
      coverageDetails: {
        ...coverList,
        avInd: risk.avInd
      },
      driverNameDetails: this.getDriverList(risk.cngeRiskDrvrDtoList, true, cpData),
      ehailingDriverDetails: this.getDriverList(risk.cngeRiskDrvrDtoList, false, cpData),
      extraCoverPackageSummary: this.getExtraCoverage(coverList),
    };
  }

  setPremiumInfo(premiumInfo: any, cngeNote: any) {
    this.premiumInfo = {
      ...premiumInfo,
      stamp: cngeNote.stamp,
      premiumDue: cngeNote.premiumDue,
      premiumDueRounded: cngeNote.premiumDueRounded
    };
  }

  setClaimHistory(cngeNote: any) {
    this.clientClaimHistory = cngeNote?.claimCnt ?? '0';
    this.vehicleClaimHistory = cngeNote?.vehClaimCount ?? '0';
  }

  getDriverList(cngeRiskDrvrDtoList, isNormalDriver, cpData) {
    let filteredList = cngeRiskDrvrDtoList?.filter(({ cngeRiskDrvrPkDto: { driverType } }) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    );

    if (filteredList && filteredList.length > 0) {
      if (!filteredList[0].driverId) {
        filteredList = filteredList.map(driver => ({
          ...driver,
          driverId: driver.driverId || cpData.idValue1 || cpData.idValue2
        }));
      }
    }

    return filteredList?.map(({ cngeRiskDrvrPkDto: { driverName }, driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getExtraCoverage(coverList: any) {
    if (!coverList || !coverList.subCoverList) {
      return {
        subCovPackageDesc: null,
        rahmahPackageList: [],
        topExtraCoverList: [],
        selectedExtraCoverList: [],
      };
    }

    const extraCoverPackage = coverList.subCoverList.map(cv => ({
      ...cv,
      description: cv.subCoverCodeDescription,
      selected: cv.selectedInd,
      enabled: cv.enableInd ?? false,
    }));

    return {
      subCovPackageDesc: coverList.subCovPackageDesc ?? null,
      rahmahPackageList: extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.enabled && !o1.subCoverPrem),
      topExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && !o1.enabled && !o1.subCoverPrem && !o1.subCovPackage),
      selectedExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && o1.subCoverPrem > 0),
    };
  }

  goToIssueDeclaration() {

  }

  goToCoverageScreen() {

  }

  goBack() {
    this.router.navigate(['endorsement/endorsement-details']);
  }

  generate() {
    let issuanceData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);
    this.endtPolicy = {
      ...this.endtPolicy,
      endtEffectiveDate: issuanceData?.issuance?.endtEffectiveDate ? new Date(issuanceData.issuance.endtEffectiveDate).toISOString().split('T')[0] : null,
      endtExpiryDate: issuanceData?.issuance?.endtExpiryDate ? new Date(issuanceData.issuance.endtExpiryDate).toISOString().split('T')[0] : null,
      einvoiceConsentInd: issuanceData?.pdpa?.consentDetails?.einvoiceConsentInd,
      issueBy: this.userInfo.userId,
      endtNarration: this.endtNarration
    };

    if(this.endtSummaryForm.get("checklistForm")) {
      this.endtPolicy.declareList = this.endtSummaryForm.get("checklistForm")?.get("rows")?.value;
      this.endtPolicy.chklistRemarks = this.endtSummaryForm.get("checklistForm")?.get("remarks")?.value;
    }

    let resetPayload = {
      isEndorsement: true
    };

    let payload = {
      policy: this.endtPolicy,
      endtType: this.endtType,
      userId: this.userInfo.userId
    }

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    this.store.dispatch(new GENERATE_ENDORSEMENT_QUOTATION(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation ?? null);

      if (response && response.data && response.success) {
        this.router.navigate(['endorsement/complete'], {
           queryParamsHandling: 'preserve',
        });
      } else {
        this.showResponseError(response);
      }
    });
  }

  sendToJPJ() {
    let issuanceData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);
    this.endtPolicy = {
      ...this.endtPolicy,
      endtEffectiveDate: issuanceData?.issuance?.endtEffectiveDate ? new Date(issuanceData.issuance.endtEffectiveDate).toISOString().split('T')[0] : null,
      endtExpiryDate: issuanceData?.issuance?.endtExpiryDate ? new Date(issuanceData.issuance.endtExpiryDate).toISOString().split('T')[0] : null,
      einvoiceConsentInd: issuanceData?.pdpa?.consentDetails?.einvoiceConsentInd,
      issueBy: this.userInfo.userId,
      nettPremium: this.endtPolicy.riskList[0].coverList[0].nettPremium,
      endtNarration: this.endtNarration
    };

    if(this.endtSummaryForm.get("checklistForm")) {
      this.endtPolicy.declareList = this.endtSummaryForm.get("checklistForm")?.get("rows")?.value;
      this.endtPolicy.chklistRemarks = this.endtSummaryForm.get("checklistForm")?.get("remarks")?.value;
    }

    let resetPayload = {
      isEndorsement: true
    };

    let payload = {
      policy: this.endtPolicy,
      endtType: this.endtType,
      userId: this.userInfo.userId
    }

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    this.store.dispatch(new GENERATE_ENDORSEMENT(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ ?? null);

      if (response && response.data && response.success) {
        this.router.navigate(['endorsement/complete'], {
          queryParamsHandling: 'preserve',
        });
      } else {
        this.showResponseError(response);
      }
    });
  }

  showResponseError(response: any) {
    console.log('showResponseError response:' + response);
    let message = (response.message && response.message != '') ? response.message : (response.errorCode && response.errorCode != '') ? response.errorCode : null;

    if (message) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        width: '400px',
        data: {
          message: message
        }
      });
    }
  }

  uploadDocument() {
    let dialogConfig = {
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      height: '96%',
      width: '90%',
      panelClass: 'uploadDocumentDialogCss',
      data: {
        contractId: this.endtPolicy.contractId
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {  }
    });
  }

  getJPJButtonName() {
    if (this.endtType === 'COE' || this.endtType === 'XP') {
      return 'SENT TO JPJ';
    } else {
      return 'GENERATE ENDORSEMENT';
    }
  }

  updateVehicleDetails(updatedData: any) {
    this.coverageInfo = {
      ...this.coverageInfo,
      vehicleDetails: updatedData
    };
  }

  showGenerateBtn() {
    return (this.endtPolicy.quotationInd && this.endtType === 'XP') ? true : false;
    //return true;
  }

  getEndorsementNarration() {
    const payload = {
      cngeNoteDto: {
        productType: this.endtPolicy.productType,
        policyNo: this.endtPolicy.policyNo,
        cnoteType: this.endtType,
        productCode: this.endtPolicy.productCode,
        effectiveDate: this.endtPolicy.effectiveDate,
        expiryDate: this.endtPolicy.expiryDate,
        cnoteDate: this.endtPolicy.cnoteDate,
        proposalDate: this.endtPolicy.proposalDate ? this.endtPolicy.proposalDate : '',
        endtEffectiveDate: new Date(this.issuanceInfo.issuance.endtEffectiveDate).toISOString().split('T')[0],
        endtExpiryDate: new Date(this.issuanceInfo.issuance.endtExpiryDate).toISOString().split('T')[0],
        sumInsured: this.endtPolicy.sumInsured,
        mkagAgent: {
          agentCode: this.agentData.agentCode
        }
      },
      htgePolDto: {
        productCode: this.endtHtgePol?.productCode,
        effectiveDate: this.endtHtgePol?.effectiveDate,
        sumInsured: this.endtHtgePol?.sumInsured,
        mkagAgent: {
          agentCode: this.endtHtgePol?.mkagAgent.agentCode
        }
      }
    };

    this.store.dispatch(new ENQUIRE_ENDT_NARRATION(payload)).subscribe((state) => {
      let endtNarration = state.SatMotorState?.endorsement?.step3?.endtNarration;
      if(endtNarration) {
        this.endtNarration = endtNarration;
      }
    });
  }

  getChecklistLov() {
    let endtCode = "";
    if (this.endtType === 'COE' || this.endtType === 'XP') {
      endtCode = null;
    } else if (this.endtType.startsWith('ENDT')) {
      endtCode = this.endtType.substring(4);
    } else if (this.endtType.startsWith('EC')) {
      endtCode = this.endtType.substring(2);
    }

    this.store.dispatch(new GET_CHECKLIST_LOV(endtCode)).subscribe(state => {
      this.checklistLov = state.SatMotorState.checklistLov;
    });
  }

  updatePaymentList(paymentList) {
    this.paymentList = paymentList;
    this.onChangePaymentSection();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  getPaymentData(data) {
    this.payment = {
      ...data?.payment,
      accountCode: this.agentData.agentCode,
      insuredName: this.cpData.clientName,
      totalPremiumDue: this.premiumInfo.premiumDue
    };

    this.paymentList = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.paymentList) ? this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step3.paymentList) : [];

    this.roadTaxPayable = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.roadTaxPayable) ? this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step3.roadTaxPayable) : {};

    this.paymentInformation = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.paymentInfo) ? this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.paymentInfo) : {};
  }

  onChangePaymentSection() {
    let issuanceData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);
    let summaryPremium = this.store.selectSnapshot<any>(state => state.SatMotorState?.endtSummaryPremium?.cngeNoteDto);
    let coveragePremium = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endtUpdatedData);
    let cngeNote = summaryPremium ? summaryPremium : coveragePremium;
    if(this.endtSummaryForm.get('roadTaxPayableForm')) {
      let roadTaxPayableForm = this.endtSummaryForm.get('roadTaxPayableForm');
      cngeNote = {
        ...cngeNote,
        ...roadTaxPayableForm.getRawValue(),
        roadTax: this.numberFmt.transform(roadTaxPayableForm.get('roadTax').value),
        myegFees: this.numberFmt.transform(roadTaxPayableForm.get('myegFees').value),
        merchantCharges:this.numberFmt.transform(roadTaxPayableForm.get('merchantCharges').value),
        courierFees: roadTaxPayableForm.get('courierFees').value ? 6.00 : 0.00,
      };
    }

    cngeNote = {
      ...cngeNote,
      paymentList: this.paymentList ? this.paymentList : []
    };

    cngeNote.cnoteType = issuanceData.issuance.endorsementType;

    // let policyData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1?.policyData);

    let payload = {
      // ...policyData,
      cngeNoteDto: cngeNote,
      operator: this.userInfo.userId
    };

    this.store.dispatch(new ENDT_CALCULATE_PREMIUM_SUMMARY(payload)).subscribe(state => {
      let premiumSummaryResponse = state.SatMotorState.endtSummaryPremium.data.cngeNoteDto;
      let roadTaxPayableResponse = {
        roadTax: premiumSummaryResponse.roadTax,
        myegFees: premiumSummaryResponse.myegFees,
        courierFees: premiumSummaryResponse.courierFees,
        merchantCharges: premiumSummaryResponse.merchantCharges,
        gstRoadTaxAmt: premiumSummaryResponse.gstRoadTaxAmt
      };
      this.roadTaxPayable = roadTaxPayableResponse;
      this.store.dispatch(new STORE_ROAD_TAX_PAYABLE({isEndorsement: this.isEndorsement, roadTaxPayable: roadTaxPayableResponse}));

      this.paymentInformation = {
        totalPayable: premiumSummaryResponse.totalPayable,
        totalPayableRounded: premiumSummaryResponse.totalPayableRounded,
        totalPaid: premiumSummaryResponse.totalPaid,
        balancePayable: premiumSummaryResponse.balancePayable
      };
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      this.store.dispatch(new STORE_PAYMENT_INFO({isEndorsement: this.isEndorsement, paymentInfo: this.paymentInformation}));
    });
    
  }

}