import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CPPopupComponent } from "../../../../popup/cp-popup/cp-popup.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { STEP2 } from 'src/app/interfaces/motor';
import { CLIENTTYPE, CONSTANTS, GENDER, IDTYPE } from 'src/app/shared/enums/index.enum';
import { takeUntil, Subject } from 'rxjs';
import { NricService } from '@services/nric/nric.service';
import { Router } from '@angular/router';
import { BrnoService } from '@services/brno/brno.service';
import { SharedModule } from '@shared/shared.module';
import { SmeCertFormComponent } from '../sme-cert-form/sme-cert-form.component';
import { SstFormDetailsComponent } from '../sst-form-details/sst-form-details.component';
import { Store } from '@ngxs/store';
import { GET_SWITCH_SITE, SAVE_AS_DRAFT, SAVE_CUSTOMER_PARTNER, SET_CUSTOMER_PARTNER_SEARCH_TYPE } from '@store/sat-motor/sat-motor.action';
import moment from 'moment';
import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';
import { CustomerPartnerFormComponent } from '../customer-partner-form/customer-partner-form.component';
import { SimpleModalComponent } from '@shared/components/modal/simple-modal/simple-modal.component';
import { ScrollToError } from '@shared/classes/scroll-to-error';

@Component({
  selector: 'app-customer-partner-not-found',
  standalone: true,
  imports: [
    SharedModule,
    CustomerPartnerFormComponent
  ],
  templateUrl: './customer-partner-not-found.component.html',
  styleUrl: './customer-partner-not-found.component.scss'
})
export class CustomerPartnerNotFoundComponent implements OnInit {
  @Input() lov: any;
  @Input() dataSearch: STEP2.SearchDataStore;
  @Input() eInvoiceConsent: boolean = false;
  @Input() autoRenewal: boolean = false;
  @Input() mkagAgent: any;
  @Input() edocConsentInd: boolean = false;
  @Output() searchAgain: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back = new EventEmitter();

  getCountry = CONSTANTS.getCountry();

  dataDetails: any;
  customerPartnerNotFoundForm: FormGroup;
  componentDialogRef?: NxModalRef<CPPopupComponent>;
  search_mode_address: boolean = true;
  idType2List: any;
  cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;
  infoMessageModal?: NxModalRef<SimpleModalComponent>;
  userInfo: any;

  getLabel = CONSTANTS.getIdLabel();

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialogService: NxDialogService,
    private fb: FormBuilder,
    private nricService: NricService,
    private router: Router,
    private brnoService: BrnoService,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);

    this.dataDetails = {
      clientType:this.dataSearch.clientType,
      idType1: this.dataSearch.idType,
      idValue1: this.dataSearch.idValue,
      clientPartnerAddress: {
        clientAddressType: this.dataSearch.clientAddressType
      }
    };

    this.customerPartnerNotFoundForm =this.fb.group({
      name:['', Validators.required],
      nationality: [''],
      idType2:[''],
      idValue2:[''],
      tinNo:['', [Validators.pattern("^[a-zA-Z0-9]*$")]],
      gender:[''],
      maritalStatus:[''],
      dob:[''],
      age:[{value : this.dataDetails.idType1 === IDTYPE.NRIC ? this.getCalculateAge(this.dataDetails.idValue1) : '', disabled: true}],
      mobileNoCode:[''],
      mobileNo:['', [Validators.required, Validators.pattern("^\\d{7,8}$")]],
      fixedLineNoCode:[''],
      fixedLineNo:['', Validators.pattern("^\\d{7,8}$")],
      faxNoCode:[''],
      faxNo:['', Validators.pattern("^\\d{7,8}$")],
      email:['', Validators.email],
      showMapSwitcher:[true],
      unitNo:[''],
      address1:['',], //Validators.required],
      address2:[''],
      address3:[''],
      postCode:['', Validators.required],
      city:[{value: '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      state:[{value: '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      country:[{value: '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      addressType:[this.isCompany ? 'O' : 'R'],
      clientGeoLatitude: [''],
      clientGeoLongitude: [''],
      clientGeoPlaceId: [''],
      clientGeoPlusCode: [''],
      clientGeoFormattedAddress: ['']
    });

  }
  initAddressValidators(){
    // this.customerPartnerNotFoundForm.controls['address1'].setValidators(Validators.required);
    this.customerPartnerNotFoundForm.controls['postCode'].setValidators(Validators.required);
    // this.customerPartnerNotFoundForm.controls['address1'].updateValueAndValidity();
    this.customerPartnerNotFoundForm.controls['postCode'].updateValueAndValidity();
  }

  //clear validator for addr
  clearvalidatoraddr(){
    this.customerPartnerNotFoundForm.controls['address1'].clearValidators();
    this.customerPartnerNotFoundForm.controls['address1'].updateValueAndValidity();
  }

  nextPage() {

    this.initAddressValidators();
    this.clearvalidatoraddr();

    let formValues = this.customerPartnerNotFoundForm.getRawValue();

    if(this.customerPartnerNotFoundForm.valid) {
      console.log("customer partner not found form: ", this.customerPartnerNotFoundForm.getRawValue());

      if(this.dataDetails.idType1 === IDTYPE.OLD_IC && formValues.idType2 !== IDTYPE.NRIC) {
        this.openNricNotUpdatePopup();
        return;
      }

      if(!this.isCompany && formValues.age < 16) {
        this.openAgeNotMetPopup();
        return;
      }

      //connect to BE to save the not found customer info
      const payload = this.generateSaveCPPayload();
      this.store.dispatch(new SAVE_CUSTOMER_PARTNER(payload)).subscribe(state => {
        let cpDetails = state.SatMotorState.motor.step2;
        if(cpDetails) {
          this.store.dispatch(new SET_CUSTOMER_PARTNER_SEARCH_TYPE("FOUND"));
          this.router.navigate(['issuance/coverage']);
        }
      })
      
    }else {
      const invalidFields = [];
      Object.keys(this.customerPartnerNotFoundForm.controls).forEach(field => {
        const control = this.customerPartnerNotFoundForm.get(field);
        if (control && control.invalid && (control.dirty || control.touched)) {
          invalidFields.push(field);
        }
      });
      console.log("customer partner form invalid field: ", invalidFields);
      this.customerPartnerNotFoundForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
  }

  generateSaveCPPayload() {
    const formValue = this.customerPartnerNotFoundForm.getRawValue();
    const searchCriteria = this.store.selectSnapshot(state => state.SatMotorState.data.step2.search);
    const date = new Date();
    const effectiveDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-M-D').format('YYYY-MM-DD');
    const user = this.store.selectSnapshot<string>((state) => state.UserState.user.userId);

    const payload = {
      clientPartner: {
        clientAge: formValue.age,
        clientDateofBirth: formValue.dob ? moment(formValue.dob, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientEmail: formValue.email,
        clientGender: formValue.gender,
        clientMobileCode1: formValue.mobileNoCode,
        clientMobileNo1: formValue.mobileNo,
        clientName: formValue.name,
        clientNationality: formValue.nationality,
        clientMaritalStatus: formValue.maritalStatus,
        clientPartnerAddress: {
          clientUnitNo: formValue.unitNo,
          clientAddress1: formValue.address1,
          clientAddress2: formValue.address2,
          clientAddress3: formValue.address3,
          clientAddressType: formValue.addressType,
          clientCity: formValue.city,
          clientCountry: formValue.country,
          clientPhoneCode: formValue.fixedLineNoCode,
          clientPhoneNo: formValue.fixedLineNo,
          clientFax1Code: formValue.faxNoCode,
          clientFax1No: formValue.faxNo,
          clientPostcode: formValue.postCode,
          clientState: formValue.state,
          clientGeoLatitude : formValue.clientGeoLatitude,
          clientGeoLongitude : formValue.clientGeoLongitude,
          clientGeoPlaceId : formValue.clientGeoPlaceId,
          clientGeoPlusCode : formValue.clientGeoPlusCode,
          clientGeoFormattedAddress : formValue.clientGeoFormattedAddress
        },
        clientType: searchCriteria.clientType,
        defaultMailReq: false,
        idType1: searchCriteria.idType,
        idType2: formValue.idType2,
        idValue1: searchCriteria.idValue,
        idValue2: formValue.idValue2,
        updateCustomerPartner: false,
        clientTinNo: formValue.tinNo,
        clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
        clientSstRegNo: formValue.sstNo,
        clientSstRegDate: formValue.sstEffDate && moment(formValue.sstEffDate).isValid() ? moment(formValue.sstEffDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstDeRegDate: formValue.sstDeRegDate && moment(formValue.sstDeRegDate).isValid() ? moment(formValue.sstDeRegDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstResidentStatus: formValue.sstResidentStatus,
        clientSstCountry: formValue.sstCountry,
        smeCategory: formValue.smeCategory,
        smeEndDate: formValue.smeEndDate ? moment(formValue.smeEndDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        smeRegisteredInd: formValue.smeRegisteredInd === 'Y' ? true : false,
        smeRegisteredNumber: formValue.smeRegNo,
        smeStartDate: formValue.smeStartDate ? moment(formValue.smeStartDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : ''
      },
      mkagAgent: this.mkagAgent,
      effectiveDate: effectiveDate,
      productType: 'MT',
      operator: user
    }

    return payload;
  }

  goBack() {
    this.back.emit();
  }

  saveAsDraft() {
  this.clearvalidatoraddr();
    if(this.customerPartnerNotFoundForm.valid) {
      const payload = this.generateSaveCPPayload();
      this.store.dispatch(new SAVE_CUSTOMER_PARTNER(payload)).subscribe(state => {
        this.store.dispatch(new SAVE_AS_DRAFT({ userId: this.userInfo.userId })).subscribe(state => {
          let cpDetails = state.SatMotorState.motor.step2;
          if(cpDetails) {
            this.store.dispatch(new SET_CUSTOMER_PARTNER_SEARCH_TYPE("FOUND"));
            this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "630px",
              data: { 
                type: "e-Quotation Saved", 
                message: "Your e-Quotation has been saved as a Draft. Here is your <br/>Draft <br/>Reference No. <b>" + state.SatMotorState.motor.step2.draftNo + "</b>. You may return to this e-Quotation through the Draft Summary Screen.",
                btnOkLabel: "BACK TO SUMMARY LISTING", 
              },
            });
            this.infoMessageModal.afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });       	      
            });
          }
        })
      });
      
    }else {
      this.customerPartnerNotFoundForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  restartSearch() {
    // this.store.dispatch(new IS_STEP_3(false));
    this.searchAgain.emit(true);
  }

  openNricNotUpdatePopup() {
    this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
      showCloseIcon: false,
      width: '400px',
      data: { 
        type: "Warning", 
        message: "NRIC not updated for Policyholder. Please update."
      },
    });
  }

  openAgeNotMetPopup() {
    this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
      showCloseIcon: false,
      width: '400px',
      data: { 
        type: "Warning", 
        message: "Minimum age of the client is not met."
      },
    });
  }

  getCalculateAge(value) {
    return this.nricService.getAge(value);
  }


  get isCompany() {
    return this.dataSearch.clientType === CLIENTTYPE.COMPANY;
  }
}
