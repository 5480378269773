import { Component, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DriverNameComponent } from '@shared/components/driver-name/driver-name.component';
import { EHailingDriverComponent } from '@shared/components/e-hailing-driver/e-hailing-driver.component';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { CoveragePopupComponent } from '@shared/components/modal/coverage-popup/extra-coverage-popup.component';
import { CALCULATE_PREMIUM, CALCULATE_PREMIUM_PAYLOAD, STORE_COVERAGE_TYPE, STORE_COVERAGE_VEHICLE_DETAILS, STORE_EXTRA_COVER_PACKAGE, STORE_SHOWED_ONE_ALLIANZ_POP_UP } from '@store/sat-motor/sat-motor.action';
import { UserResponse } from '@interfaces/user';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-extra-coverage',
  standalone: true,
  imports: [
    SharedModule,
    DriverNameComponent,
    EHailingDriverComponent,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule
  ],
  templateUrl: './extra-coverage.component.html',
  styleUrl: './extra-coverage.component.scss',
})
export class ExtraCoverageComponent {
  @Input() coverageFmGroup!: FormGroup;
  @Input() isComprehensive = false;
  @Input() isThirdParty = false;
  @Input() isThirdPartyFireTheft = false;
  @Input() defaultDriver: any;
  @Input() partnerInfo: any;
  @Output() isSelected = new EventEmitter();
  @Output() selectedItem = new EventEmitter();
  templateDialogRef?: NxModalRef<any>;
  extraCoverDetailForm: FormGroup;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;


  constructor(private readonly dialogService: NxDialogService, private fb: FormBuilder, private store: Store, private cdr: ChangeDetectorRef) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.extraCoverDetailForm = this.fb.group({
      checkedAll: [false],
      extraCoverPackage: [""],
      extraCoverPackageSummary: [""],
      driverNameDetails: new FormGroup({}),
      ehailingDriverDetails: new FormGroup({}),
      subCoverSumInsured: [""], // for sub sumInsured
      //subCoverSumInsured:["", [Validators.required]],
      dropdownSubCover: [""],// for sub dropdown
      extraCoverPackageDto: [""]
    });

  }
  componentDialogRef?: NxModalRef<CoveragePopupComponent>;
  selection: any;
  allComplete: boolean = true;
  subCovList: any;
  subCovPackageList: any;
  extraCoverDetails: any;
  packageDescription: any;
  userInfo: any;
  extraCoverList: any = [];
  checkPrivateHireCar = [];
  privateHireCarIsSelected: boolean = false;
  rahmahExtraCoverList = [];
  topExtraCoverList = [];
  defaultedExtraCoverList = [];
  fixedDefaultedExtraCoverList = [];
  allSelectedWithRahmahExtraCoverList = [];
  selectExtraCoverAppDrive = [];
  unnamedDriverExtraCoverList = [];
  coverDto: any;
  updateSelectedAndAllListToOPUS: any;
  subExtraCoverDropdownList: any;
  numberFmt: any;
  decimalFmt: any;
  isWaiver: boolean = false;
  isAllDriver: boolean = false;
  subCovPackageDesc: any;
  costInd: boolean = false;
  selectedCheckboxP2p = [];
  selectedCheckboxP2pCode = [];
  vendorCodeList = [];
  cngeSubcoverVendor = [];
  removeSumInsured = [
    "M01", "M02", "M03", "M07", "M08", "22", "22(a)", "24(c)", "24(d)", "25",
    "72", "100", "101", "101(a)", "102", "105", "108", "109", "111",
    "A200", "A201", "A202", "A202-1", "A202-2", "A203", "A203-1", "A203-2", "A203-3"
    , "A207", "PAB-OCR",
  ]

  motorRoadWarriroList = [
    {
      "code": "MR1",
      "descp": "1 UNIT"
    },
    {
      "code": "MR2",
      "descp": "2 UNITS"
    },
    {
      "code": "MR3",
      "descp": "3 UNITS"
    },
    {
      "code": "MR4",
      "descp": "4 UNITS"
    },
    {
      "code": "MR5",
      "descp": "5 UNITS"
    }
  ]

  motorEnhanceRoadWarriroList = [
    {
      "code": "PABERWA",
      "descp": "PLAN A"
    },
    {
      "code": "PABERWB",
      "descp": "PLAN B"
    },
    {
      "code": "PABERWC",
      "descp": "PLAN C"
    }
  ]
  mtRoadPlacementTblInd: boolean = false;
  mtRoadEligibleInd: boolean = false;

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.coverageFmGroup.controls["extraCoverDetails"] = this.extraCoverDetailForm;
    this.selection = new SelectionModel<any>(true, []);
    this.extraCoverList.forEach(element => {
      this.selection.select(element)
    });

    this.extraCoverList = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.extraCoverPackage);
    this.extraCoverDetailForm.patchValue({
      dropdownSubCover: this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.dropdownSubCover)
    });

    this.isAllDriver = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.driverNameDetails.vehicleAlldrvrInd);
    this.isWaiver = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.driverNameDetails.vehicleUnNamedDrvrInd);

    this.setExtraCoverageForm();
  }

  ngAfterViewInit() {
    this.coveragePremium$.subscribe((state) => {
      let stCngeNote: any, stRiskList: any, stCoverList: any, stCoverDto: any;
      if (state) {
        if ((stCngeNote = state["cngeNote"]) && (stRiskList = stCngeNote["riskList"]) && stRiskList.length > 0) {
          this.mtRoadPlacementTblInd = stCngeNote?.mtRoadPlacementTblInd;
          this.mtRoadEligibleInd = stCngeNote?.mtRoadEligibleInd;

          if ((stCoverList = stRiskList[0]["coverList"]) && stCoverList.length > 0) {
            this.subCovPackageDesc = stCoverList[0].subCovPackageDesc;
            this.costInd = stCoverList[0].costInd;
            this.coverageFmGroup.patchValue({
              basicPremium: stCoverList[0].basicPremium,
            });
            if ((stCoverList[0]["coverDto"])) {
              this.coverDto = stCoverList[0]["coverDto"]; // temp use
              stCoverDto = stCoverList[0]["coverDto"];
              if (stCoverDto && stCoverDto["subCovList"]) {
                this.subCovList = stCoverDto["subCovList"];
                this.updateSelectedAndAllListToOPUS = this.subCovList;

              }
              if (stCoverDto && stCoverDto["subCovPackageList"]) {
                this.subCovPackageList = stCoverDto["subCovPackageList"][0];
              }

              if (this.subCovPackageList?.packageCode) {
                this.rahmahExtraCoverList = this.subCovList.filter(o1 => o1.top == true && o1.subCovPackage === this.subCovPackageList.packageCode &&
                  o1.enabled == false && o1.subCoverPrem == 0);
              } else {
                this.rahmahExtraCoverList = [];
              }
            }
          }
        }
      }

      if (this.subCovList) {

        const packageCode = this.subCovPackageList?.packageCode;
        const validPackageCode = packageCode !== undefined ? packageCode : null;

        this.topExtraCoverList = this.subCovList.filter(o1 =>
          o1.top === true && o1.selected === true
          // cater specific for motor RW & motor ERW
          && ((o1.subCoverCode === "PAB-RW" && o1.planCode != null && o1.planCode !== '' && this.mtRoadPlacementTblInd == false) || (o1.subCoverCode === "PAB-ERW" && o1.planCode !== '') || o1.enabled === false)
          && o1.subCoverPrem == 0
          && (validPackageCode === null || o1.subCovPackage !== validPackageCode)
        );

        this.fixedDefaultedExtraCoverList = this.defaultedExtraCoverList = this.subCovList.filter(o1 => o1.top === true && o1.selected === false && (validPackageCode === null || o1.subCovPackage !== validPackageCode));
        this.extraCoverList = this.subCovList.filter(o1 => o1.selected === true && (validPackageCode === null || o1.subCovPackage !== validPackageCode));
        //filter topExtraCoverList from this.extraCoverList
        this.extraCoverList = this.extraCoverList.filter(o1 => !this.topExtraCoverList.some(o2 => o2.subCoverCode === o1.subCoverCode));
        //for all driver checkbox
        this.unnamedDriverExtraCoverList = this.subCovList.filter(o1 => o1.selected === true && o1.subCoverCode === "A200");

        this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
        this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList); //to save quotation
        this.extraCoverDetailForm.controls['extraCoverPackageDto'].setValue(this.updateSelectedAndAllListToOPUS);//send to ODM

        //to display in Summary
        let updateSumInsuredExtraCoverList = this.extraCoverList.map((obj) => {
          if (this.removeSumInsured.includes(obj.subCoverCode)) {
            return { ...obj, subCoverSumInsured: 0 };
          }
          return obj;
        });

        let toDisplayInSummary = {
          subCovPackageList: this.subCovPackageList,
          subCovPackageDesc: this.subCovPackageDesc,
          costInd: this.costInd,
          rahmahPackageList: this.rahmahExtraCoverList,
          topExtraCoverList: this.topExtraCoverList,
          selectedExtraCoverList: updateSumInsuredExtraCoverList
        }

        this.extraCoverDetailForm.controls['extraCoverPackageSummary'].setValue(toDisplayInSummary);
        this.privateHireCar();
        this.setExtraCoverageForm();
      }
    });
  }

  setExtraCoverageForm() {
    this.extraCoverList?.forEach((obj, idx) => {
      obj.mtExtCovDtoList?.forEach((subItem, subIdx) => {
        if (subItem.type === 'INPUT') {
          if (!subItem.disable) {
            this.extraCoverDetailForm.addControl(obj.subCoverCode, new FormControl('', Validators.required));
            if (obj.subCoverSumInsured > 0) {
              this.extraCoverDetailForm.patchValue({
                [obj.subCoverCode]: this.decimalFmt.transform(obj.subCoverSumInsured)
              });
            }
          } else {
            if (!this.extraCoverDetailForm.contains(obj.subCoverCode) && obj.subCoverSumInsured > 0) {
              this.extraCoverDetailForm.addControl(obj.subCoverCode, new FormControl(this.decimalFmt.transform(obj.subCoverSumInsured)));
              this.extraCoverDetailForm.get(obj.subCoverCode)?.disable();
            }
          }

        } else if (subItem.type === 'DROPDOWN' || subItem.type === 'CHECKBOX_P2P') {

          if (obj.subCoverCode === '112' && subItem.label == 'NO OF DAY') {
            let noOfDayFormName = obj.subCoverCode + "_NO_OF_DAY";
            this.extraCoverDetailForm.addControl(noOfDayFormName, new FormControl('', Validators.required));
            if (obj.subCoverNumberOfDays > 0) {
              this.extraCoverDetailForm.patchValue({
                [noOfDayFormName]: this.getSubCoverNumberOfDaysAsString(obj.subCoverNumberOfDays)
              });
            }

          } else if (obj.subCoverCode === '112' && subItem.label == 'AMOUNT PER DAY') {
            let amountPerDayFormName = obj.subCoverCode + "_AMOUNT_PER_DAY";
            this.extraCoverDetailForm.addControl(amountPerDayFormName, new FormControl('', Validators.required));
            if (obj.subCoverAmtday > 0) {
              this.extraCoverDetailForm.patchValue({
                [amountPerDayFormName]: obj.subCoverAmtday
              });
            }

          } else if (subItem.type === 'CHECKBOX_P2P') {
            let multiCheckboxFormName = "CHECKBOX_P2P";
            this.extraCoverDetailForm.addControl(multiCheckboxFormName, new FormControl('', Validators.required));
            if (obj.vendorList) {
              this.extraCoverDetailForm.patchValue({
                CHECKBOX_P2P: obj.vendorList.split(",")
              });

            }
          } else {
            this.extraCoverDetailForm.addControl(obj.subCoverCode, new FormControl('', Validators.required));
            if (obj.planCode) {
              this.extraCoverDetailForm.patchValue({
                [obj.subCoverCode]: obj.planCode
              });
            }
          }
        }
      });
    });
  }

  setExtraCoverageFormForDisabledInput() {

    this.extraCoverList?.forEach((obj, idx) => {
      if (!this.extraCoverDetailForm.contains(obj.subCoverCode)) {
        obj.mtExtCovDtoList?.forEach((subItem, subIdx) => {
          if (subItem.type === 'INPUT' && subItem.disable && obj.subCoverSumInsured > 0) {
            this.extraCoverDetailForm.addControl(obj.subCoverCode, new FormControl(this.decimalFmt.transform(obj.subCoverSumInsured)));
            this.extraCoverDetailForm.get(obj.subCoverCode)?.disable();
          }
        });
      }
    });
  }

  openPopupComp(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "800px",
      maxWidth: "800px",
      data: {
        popup_type: popup_type,
        selectedExtraCoverList: this.allSelectedWithRahmahExtraCoverList,
        originalExtraCoverList: this.subCovList,
        packageCode: this.subCovPackageList?.packageCode
      },
    });

    this.componentDialogRef.afterClosed().subscribe((result) => {
      console.log('result is : ' + result)
      if (result) {
        result.selection.forEach(objDialog => {
          const newObj = { ...objDialog, selected: true };
          this.extraCoverList.push(newObj);
          this.extraCoverList.forEach(element => {
            this.selection.select(element)
          });
          this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
            if (objDialog.subCoverCode === obj.subCoverCode) {
              return { ...obj, selected: !obj.selected };
            }
            return obj;
          });
        });

        this.privateHireCar();
        this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
        this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
        this.calculatePremium();
      }
    });
  }

  emitData(item: boolean, code: any, location: any) {

    if (this.extraCoverDetailForm.contains(code)) {
      this.extraCoverDetailForm.removeControl(code);
    }

    if (location === 'topHeader') {
      this.subCovPackageList = {
        ...this.subCovPackageList,
        selected: !this.subCovPackageList.selected
      };
    }

    if (location === 'defaultedExtraCover') {
      this.defaultedExtraCoverList.forEach((obj, index) => {
        if (code == obj.subCoverCode) {
          this.defaultedExtraCoverList.splice(index, 1);
          const newObj = { ...obj, selected: true };
          this.extraCoverList.push(newObj);
          this.extraCoverList.sort((a, b) => {
            if (a.top !== b.top) {
              return a.top ? -1 : 1;  // 'true' (top) comes first
            }
            return a.sequenceNumber - b.sequenceNumber;
          });
        }
      });
      this.setExtraCoverageFormForDisabledInput();

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (code === obj.subCoverCode) {
          return { ...obj, selected: !obj.selected };
        }
        return obj;
      });
    } else {
      this.extraCoverList.forEach((obj, index) => {
        if (code == obj.subCoverCode) {
          this.extraCoverList.splice(index, 1);
          if (obj.top == true) {
            const newObj = { ...obj, selected: false };
            this.defaultedExtraCoverList.push(newObj);
            // Ascending order defaultedExtraCoverList
            this.defaultedExtraCoverList.sort((a, b) => {
              return a.sequenceNumber - b.sequenceNumber;
            });

            this.extraCoverList.sort((a, b) => {
              if (a.top !== b.top) {
                return a.top ? -1 : 1;  // 'true' (top) comes first
              }
              return a.sequenceNumber - b.sequenceNumber;
            });
          }
        }
      });

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (code === obj.subCoverCode) {
          if (!obj.selected == false) {
            return { ...obj, selected: !obj.selected, subCoverPrem: 0, mtExtCovDtoList: null };

          } else {
            return { ...obj, selected: !obj.selected };
          }
        }
        return obj;
      });
    }
    //this.privateHireCar();
    this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
    this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
    this.calculatePremium();
    console.log("this.extraCoverList" + this.extraCoverList);
    console.log("this.topExtraCoverList" + this.topExtraCoverList);
  }

  privateHireCar() {
    this.checkPrivateHireCar = this.extraCoverList.find(top => top.subCoverCode === 'A202');
    if (this.checkPrivateHireCar) {
      this.privateHireCarIsSelected = true;
    } else {
      this.privateHireCarIsSelected = false;
    }
  }

  selectExtraCover($event) {
    this.isAllDriver = $event;
  }

  selectWaiver($event: boolean) {
    this.isWaiver = $event;
    this.calculatePremium();
  }

  selectAllDriver($event: boolean) {
    this.isAllDriver = $event;
    this.calculatePremium();
  }

  removeExtraCover($event) {
    let obj = this.subCovList.find(item => item.subCoverCode === $event);
    const newObj = { ...obj, selected: false };
    this.extraCoverList = this.extraCoverList.filter(item => item.subCoverCode !== $event);

    this.extraCoverList.forEach(element => {
      this.selection.deselect(element)
    });
    this.allSelectedWithRahmahExtraCoverList = this.topExtraCoverList.concat(this.extraCoverList);
  }

  checkedAllChange() {
    if (this.extraCoverDetailForm.controls['checkedAll'].value) {
      this.defaultedExtraCoverList.forEach((obj) => {
        const newObj = { ...obj, selected: true };
        this.extraCoverList.push(newObj);
      });

      this.defaultedExtraCoverList = [];

      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj2) => {
        const matchingObj = this.extraCoverList.find(obj1 => obj1.subCoverCode === obj2.subCoverCode);
        if (matchingObj) {
          return { ...obj2, selected: true };
        }
        return obj2;
      });

    } else {
      this.extraCoverList = [];
      this.updateSelectedAndAllListToOPUS = [];
    }



    this.privateHireCar();
    this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
    this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList); //to save quotation
    this.calculatePremium();


  }

  get extraCoverPackage() {
    return this.extraCoverDetailForm.controls["extraCoverPackage"];
  }

  calculatePremium() {
    //p2p
    this.allSelectedWithRahmahExtraCoverList = this.allSelectedWithRahmahExtraCoverList.map((obj) => {
      if (obj.subCoverCode === 'A207') {
        return { ...obj, cngeSubcoverVendor: this.cngeSubcoverVendor ? this.cngeSubcoverVendor : null };
      }
      return obj;
    });

    let vehicleDetails = this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue());
    let typeOfCoverage = this.populateTypeOfCoverageData({
      ...this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType),
      ...this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()
    });

    let driverNameList = this.populateDriverNameData()

    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(vehicleDetails)).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(typeOfCoverage)).subscribe((state) => {
        this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({
          coverage: {
            mtRoadPlacementTblInd: this.mtRoadPlacementTblInd,
            mtRoadEligibleInd: this.mtRoadEligibleInd,
            vehicleDetails: vehicleDetails,
            typeOfCoverage: typeOfCoverage,
            extracover: this.allSelectedWithRahmahExtraCoverList,
            cngeRiskDrvrDtoList: driverNameList,
            coverDto: {
              ...this.coverDto, // Spread the existing coverDto
              subCovList: [
                ...this.updateSelectedAndAllListToOPUS // Add updatedExtraCoverList to subCovList
              ],
              subCovPackageList: [this.subCovPackageList],
              vendorCodeList: this.vendorCodeList ? this.vendorCodeList : null
            },
            vehicleUnNamedDrvrInd: this.isWaiver,
            vehicleAlldrvrInd: this.isAllDriver
          },
          operator: this.userInfo.userId,
        }),
        ).subscribe((state) => {
          this.store.dispatch(new CALCULATE_PREMIUM(state.SatMotorState.data.step3.coverage.coveragePremiumPayload));
        });
      });
    });
  }

  populateDriverNameData() {
    let driverNameForm = this.coverageFmGroup.controls["driverNameForm"].getRawValue();
    const namedDriverList = driverNameForm.rows
    let cngeRiskDrvrDtoList = namedDriverList.map((driver) => {
      let driverName;
      if (driver.driverName) {
        driverName = driver.driverName;
      } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
        driverName = driver.cngeRiskDrvrPkDto.driverName;
      }

      let cngeRiskDrvrPkDto = {
        cngeRiskDrvrPkDto: {
          driverName: driverName ? driverName : "",
          driverType: "NORM",
        },
        driverId: driver.driverId,
        driverAge: driver.driverAge,
        driverSeq: driver.driverSeq,
      };

      return cngeRiskDrvrPkDto;
    });
    return cngeRiskDrvrDtoList;
  }



  populateVehicleDetailsData(data) {
    let selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.selectedMakeModel);
    let makeModelPrintInd = data.makeModelPrintInd ? "Y" : "N";
    return {
      ...data, ...selectedMakeModel,
      makeModelPrintInd: makeModelPrintInd,
      importStatus: selectedMakeModel.importStatus,
      vehModelNvic: selectedMakeModel.vehModelCodeNvic,
      vehicleModel: selectedMakeModel.vehModelCodeNvic,
      nvicNo: selectedMakeModel.nvicCode,
      vehicleCapacity: this.numberFmt.transform(data.vehicleCapacity)
    };
  }

  onChangeSubCoverSumInsured(subCoverCodeValue: any, subCoverSumInsuredValue: any) {
    let formatSubCoverSumInsuredValue = subCoverSumInsuredValue.value ? subCoverSumInsuredValue.value : 0.00;
    if (formatSubCoverSumInsuredValue) {
      formatSubCoverSumInsuredValue = formatSubCoverSumInsuredValue.toString().replace(/,/g, "");


      this.extraCoverList = this.extraCoverList.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, subCoverSumInsured: formatSubCoverSumInsuredValue };
        }
        return obj;
      });

      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });


      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, subCoverSumInsured: formatSubCoverSumInsuredValue };
        }
        return obj;
      });
      this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
      this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
      this.calculatePremium();

    }
  }

  populateTypeOfCoverageData(data) {
    return {
      ...data, sumInsured: this.numberFmt.transform(data.sumInsured), excessAmount: this.numberFmt.transform(data.excessAmount),
      basicPremium: this.numberFmt.transform(data.basicPremium), annualPremium: this.numberFmt.transform(data.annualPremium),
      grossPremium: this.numberFmt.transform(data.grossPremium), ncdAmount: this.numberFmt.transform(data.ncdAmount),
      ncdPercentage: this.numberFmt.transform(data.ncdPercentage)
    };
  }

  onChangeProduct(subCoverCodeValue: any, dropdownCode: any, dropdownDesc: any) {
    // Helper function to update and sort lists
    const updateAndSortLists = (property: string, value: any) => {
      this.extraCoverList = this.extraCoverList.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, [property]: value };
        }
        return obj;
      });

      //update to OPUS list
      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, [property]: value };
        }
        return obj;
      });

      // Sorting logic
      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });

      // Combining lists
      this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
      this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
    };

    if (dropdownDesc === 'PLAN / UNIT') {
      updateAndSortLists('planCode', dropdownCode);
    } else if (dropdownDesc === 'NO OF DAY') {
      updateAndSortLists('subCoverNumberOfDays', dropdownCode);
      updateAndSortLists('subCoverDays', dropdownCode);
    } else if (dropdownDesc === 'AMOUNT PER DAY') {
      updateAndSortLists('subCoverAmtPerDay', dropdownCode);
      updateAndSortLists('subCoverAmtday', dropdownCode);
    } else if (dropdownDesc === 'CHECKBOX_P2P') { //might change
      updateAndSortLists('vendorList', dropdownCode);
    }

    this.calculatePremium();
  }

  getSubCoverNumberOfDaysAsString(code: string) {
    return code.toString();
  }

  getDropDownDesc(subCoverCode: any, code: any) {
    if (subCoverCode === 'PAB-RW') {
      return this.getMRWDesc(code);
    } else if (subCoverCode === 'PAB-ERW') {
      return this.getMERWDesc(code);
    } else {
      return code;
    }
  }

  getMRWDesc(code: any) {
    const item = this.motorRoadWarriroList.find(item => item.code === code);
    return item ? item.descp : code;
  }

  getMERWDesc(code: any) {
    const item = this.motorEnhanceRoadWarriroList.find(item => item.code === code);
    return item ? item.descp : code;
  }

  motorRoadWarriorValidation(): Boolean {
    if (this.mtRoadEligibleInd) {
      const item = this.subCovList.find(item => item.subCoverCode === 'PAB-RW' && !item.selected);
      if (item) {
        this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(false));
        this.mtRoadPlacementTblInd = false;
        this.mtRoadEligibleInd = false;
        this.calculatePremium();
        return false;
      }
    }
    return true;
  }

  selectionChange(event: any): void {
    this.selectedCheckboxP2p = event;
    this.vendorCodeList = []

    if (!Array.isArray(this.selectedCheckboxP2p)) {
      this.selectedCheckboxP2p = [];
    }

    //for coverDto save to db
    this.vendorCodeList = event.map(code => ({
      description: code
    }));

    //for subCoverList calculate premium
    this.cngeSubcoverVendor = event.map(code => ({
      vendorCode: code
    }));

    const selectedCodesString = this.selectedCheckboxP2p.join(","); 

    this.onChangeProduct("A207", selectedCodesString, "CHECKBOX_P2P");
  }

  //design purpose
  selectedCheckboxItem(): boolean {
    return this.selectedCheckboxP2p.length > 1;
  }

}
